/**
 *
 * Filters have two goals:
 *   1. Filter the data to match the current state
 *   2. Group the data for each filter by its filter options
 *
 *
 * Filtering and grouping operations run whenever a filter option is toggled. For this reason, we need to try to limit the number of times we filter  * and group the data.
 *
 *
 * Example:
 *
 * Consider the following example, where nums is the data filtered by these three filters: Positive, Even, and UpperBound. Assume we only want to show  * positive numbers and each filter has its own grouping function. Params represent the selected state of the filters.
 *
 *   const nums = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 *
 *   const defaultsParams = {
 *     "even": false,  ---> FilterType: UserSelected
 *     "positive": true, ---> FilterType: Preselected -- not toggled by user
 *     "upperBound": 10, ---> FilterType: UserSelected
 *   }
 * - Note: Preselected filters should be written to handle both Applied and Unapplied states.
 *
 *   const filters = (param: Params) => ({
 *     "positive": (item: number) => item >= 0,
 *     "even": (item: number) => item %2 === 0,
 *     "upperBound": (item: number) => item <= param.upperBound,
 *   });
 *
 *
 * Assume we have set our params to:
 *
 *   const currentParams = {
 *     even: true, ---> FilterState: Applied and Active
 *     positive: true, ---> FilterState: Active
 *     upperBound: 10, ---> FilterState: Unapplied
 *   };
 *
 * Both Even and Positive would be considered Active filters. UpperBound would be considered an Unapplied filter, since its current selection is equal  * to the default selection.
 *
 *
 * Assuming we start with Even, we'd apply only Positve, since UpperBound is Unapplied in this case and Even is the current filter. We'd get the  * following:
 *
 *   const partiallyFiltered = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];
 *
 *
 * We can take partiallyFiltered and do two things with it:
 *
 * 1. Apply the grouping function for the current filter, Positive, to get the following:
 *
 *   groupedData: { byEven: { even: 6, odd: 5 } }
 *
 * In the UI, we can use this to say six data points would be displayed when the filter is set to true, and five when set to false.
 *
 * - Note: If we had applied all of our Active filters, including the current filter, before grouping, we would have ended up with: [0, 2, 4, 6, 8,  * 10] --> groupedData: { byEven: { even: 6, odd: 0 } }
 *
 * 2. Apply our final filter to get the complete FilteredData. In this case, we need to apply Even, the active filter we excluded earlier. This would  * give us the following:
 *
 *   const completeFilteredData = [0, 2, 4, 6, 8, 10];
 *
 * For this example, we still need to get the grouped data for Positive and UpperBound. Here is the partial data for those iterations:
 *
 *   const positivePartiallyFilteredData = [ -2, 0, 2, 4, 6, 8, 10 ]
 *   const upperBoundPartiallyFilteredData = [ 0, 2, 4, 6, 8, 10 ]
 *
 * - Note: Applying the excluded filter to its respective partially filtered data here would result in the same complete filteredData of [0, 2, 4, 6,  * 8, 10].
 *
 * After applying the grouping function for the remaining filters, we'd end up with the following final result:
 *
 *   return {
 *     filteredData: [0, 2, 4, 6, 8, 10],
 *     groupedData: {
 *       byEven: { even: 6, odd: 5 },
 *       byPositive: { positive: 6, negative: 1 },
 *       byUpperBound: { 0: 1, 2: 2, 4: 3, 6: 4 ... },
 *     }
 *   }
 *
 */

import type { ReactElement } from "react";
import * as b from "fp-ts/lib/boolean";
import * as Eq from "fp-ts/lib/Eq";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Predicate } from "fp-ts/lib/Predicate";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as R from "fp-ts/lib/Record";
import * as Sep from "fp-ts/lib/Separated";
import { useStableMemo } from "fp-ts-react-stable-hooks";
import { capitalize } from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { allC, anyC, type FilterOperationType, noneC } from "@scripts/codecs/filter";
import { Ord } from "@scripts/fp-ts";
import { delProp } from "@scripts/util/delProp";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";
import { applyPredicatesAll, applyPredicatesAny, applyPredicatesNone } from "@scripts/util/predicate";
import type { SearchFilterFn } from "@scripts/util/uFuzzy";


export type FilterPredicate<D> = Predicate<D>;
export type FilterPredicates<D> = ReadonlyArray<FilterPredicate<D>>;

/**
 * A function used for applying a list of filters based on the selected operation.
 *
 * @param {FilterOperationType} operation Specifies whether a item must match all, some, or none of the selected filters
 * @param {object} filters A readonly array of {@link FilterPredicate}
 *
 * @see {@link FilterPredicates}
 */
export const allAnyFilter = (config: BLConfigWithLog) => (operation: FilterOperationType): <D, >(filters: FilterPredicates<D>) => FilterPredicate<D> => {
  switch (operation) {
    case allC.value:
      return applyPredicatesAll;
    case anyC.value:
      return applyPredicatesAny;
    case noneC.value:
      return applyPredicatesNone;
  }
  return config.exhaustive(operation);
};

export type FilterPredicateRecord<K extends PropertyKey, D> = Record<K, FilterPredicate<D>>;

export const makeFilterPredicateList = <K extends PropertyKey, D>(filterPredicateRecord: FilterPredicateRecord<K, D>): (keys: ReadonlyArray<K>) => FilterPredicates<D> =>
  RA.filterMap(flow(
    O.fromNullable,
    O.chain(k => fromNullableOrOption(k in filterPredicateRecord ? filterPredicateRecord[k] : O.none)),
  ));

/**
 * A type used to get the inner most primitive type of domain table types. Used in {@link ParamValue} for grouping data.
 *
 * @example
 * ```typescript
 * const californiaC = { _tag: `California`, abbrev: `CA`, id: 7, name: `California` } as const;
 *
 * type expectedValue = DomainTableInnerType<typeof californiaC>; // 7
 * ```
 *
 * @note Ids are prefered over _tag types.
 * @see {@link ParamValue}
 */
type DomainTableInnerType<T> = T extends { id: infer TId }
  ? TId extends number
    ? number
    : never
  : T extends { _tag: infer TTag }
    ? TTag
    : T;

/**
 * A type used to get the inner most primitive type of a filter state parameter. This type is used for grouping data.
 *
 * @example
 * ```typescript
 * const californiaC = { _tag: `California`, abbrev: `CA`, id: 7, name: `California` } as const;
 * const params = { states: [californiaC] };
 *
 * type expectedValue = ParamValue<typeof params[states]>; // 7
 * ```
 * @see {@link DomainTableInnerType}
 */
type ParamValue<TParamValue> = TParamValue extends ReadonlyArray<infer ArrayValue>
  ? DomainTableInnerType<ArrayValue>
  : TParamValue extends ReadonlySet<infer TSetValue>
    ? DomainTableInnerType<TSetValue>
    : TParamValue extends { selection: ReadonlyArray<infer SelectionValue> }
      ? DomainTableInnerType<SelectionValue>
      : DomainTableInnerType<TParamValue>;

export type GroupedData<K, D> = Map<K, Set<D>>;
/**
 * A type used to link grouped data and filter state parameter types.
 *
 * @see {@link GroupedData}
 */
export type GroupedDataFromParam<P, D = unknown> = GroupedData<ParamValue<P[keyof P]>, D>;
/**
 * The key used when grouping a set of data for a filter
 *
 * @example
 * ```typescript
 * type paramKey = 'states';
 * type GroupedDataByState = GroupKey<paramKey>; // 'byStates'
 * ```
 *
 * @see {@link GroupedData}
 */
type GroupKey<K> = K extends string ? `by${Capitalize<K>}` : never;
/**
 * The function used to group data based on a filter state parameter.
 */
type GroupingFn<K, D> = (d: ReadonlyArray<D>) => GroupedData<ParamValue<K>, D>;
/**
 * The grouped data for each filter state that has been specified as requiring grouping (i.e. GroupKey)
 *
 * @param {string} K A key belonging to the filter state and the filters object, used to group the data
 *
 * @note K will be formatted as {@link GroupKey} by the filtering component
 */
export type GroupedDataByGroupKey<D, P, GroupKeys extends keyof P> = {
  [K in keyof Pick<P, GroupKeys> as GroupKey<K>]: GroupedData<ParamValue<P[K]>, D>;
};

/**
 *
 * @param {Eq} Eq The equality function used to compare the filter state parameter with the default value. If different,
 * the filter is considered Applied.
 * @param {true} overrideFilterState If true, the filter is considered Active regardless of the Eq comparison. This is
 * useful for filters that are expected to filter data in the default state.
 *
 */
type FilterBase<P, K extends keyof P = keyof P> = {
  eq: Eq.Eq<P[K]>;
  overrideFilterState?: true;
};

/**
 * A filter object used for creating filters for any filter state parameter.
 *
 * @note v is the value of the current filter state parameter
 * @note p is the entire filter state
 *
 */
export type Filter<D, P, K extends keyof P = keyof P> = FilterBase<P, K> & {
  filterFn: (v: P[K], p: P) => FilterPredicate<D>;
};
/**
 * An internal type used once filter state parameters and filter state params have been curried into the
 * {@link Filter} filterFn
 *
 * @note groupingFn is nullable here since not all filters require grouping
 *
 */
type FilterWithPredicate<D, P, K extends keyof P = keyof P> = FilterBase<P, K> & {
  filterFn: FilterPredicate<D>;
  groupingFn?: GroupingFn<P[K], D>;
};

type SearchFilter<D> = {
  searchFn: SearchFilterFn<D>;
};

/**
 * A type used to specify the filters object for the Filtering component. Use {@link FilterModel} whenever possible.
 *
 * @param {string} GroupKeys The keys of the filter state that will require grouped data
 *
 * @note For keys not specified in GroupKeys, a groupingFn will not be allowed
 *
 * @see {@link FilterModel}
 *
 */
export type Filters<D, P, GroupKeys extends keyof P = never, SearchKeys extends Exclude<keyof P, GroupKeys> = never> =
  {
    [K in keyof Pick<P, GroupKeys>]: Filter<D, P, K> & { groupingFn: GroupingFn<P[K], D> };
  } & {
    [EK in Exclude<keyof P, GroupKeys | SearchKeys>]?: Filter<D, P, EK>;
  } & {
    [SK in keyof Pick<P, SearchKeys>]: SearchFilter<D>;
  };

/**
 * A type used to specify the filters object for the Filtering component and the type for the grouped data. Useful since
 * both of these should require the same generics.
 *
 * @note If GroupKeys are specified, the groupedData type will also be included
 * @note If no GroupKeys are specified, this type will be equivalent to {@link Filters}
 *
 * @example
 * ```typescript
 * // If groupKeys are specified:
 * type StatesFilterModel = FilterModel<D, P, "states">;
 * type StatesFilters = StatesFilterModel["filters"];
 * type StatesGroupedData = StatesFilterModel["groupedData"];
 *
 * // If groupKeys are NOT specified:
 * type StatesFilterModel = FilterModel<D, P>;
 * type StatesFilters = StatesFilterModel; // Equivalent to Filters<D, P>
 *```
 *
 * @see {@link Filters}
 * @see {@link GroupedDataByGroupKey}
 */
export type FilterModel<D, P, GroupKeys extends keyof P = never, SearchKeys extends Exclude<keyof P, GroupKeys> = never> = [GroupKeys] extends [never]
  ? Filters<D, P, GroupKeys, SearchKeys>
  : { groupedData: GroupedDataByGroupKey<D, P, GroupKeys>, filters: Filters<D, P, GroupKeys, SearchKeys> };

/**
 * Filter types:
 * 1. UserSelected - Must be activated by the user in order to be applied
 *    * In the default state, these filters will return all data
 * 2. PreSelected - Applied regardless of user selection
 *    * In the default state, these filters return a subset of the original data

 * Filters states:
 * 1. Applied - filters selected by the user
 * 2. Unapplied - filters NOT selected by the user
 * 3. Active - filters that are Applied and/or PreSelected
*/
type FilterState = "applied" | "unapplied" | "active";

/**
 * A function used to partition filters based on whether they are Applied or Unapplied
 *
 * @param defaultParams The default filter state to compare against
 * @param currentParams The current filter state
 *
 * @note Useful for determining how many filters have been applied by the user
 */
const partitionFiltersByAppliedState = <
  D,
  P extends Record<string, unknown>,
  K extends string = string,
>(defaultParams: P, currentParams: P) =>
  R.partitionWithIndex<K, FilterWithPredicate<D, P>>((k, v) => !v.eq.equals(defaultParams[k], currentParams[k]));

const isAppliedOrPreselectedFilter = <D, P, K extends string>(appliedFilters: Record<K, FilterWithPredicate<D, P>>) =>
  (filterKey: K, _: FilterWithPredicate<D, P>) =>
    _.overrideFilterState || R.has(filterKey, appliedFilters);

const getActiveFilters = <D, P, K extends string>(appliedFilters: Record<K, FilterWithPredicate<D, P>>) =>
  R.filterWithIndex(isAppliedOrPreselectedFilter(appliedFilters));

type FiltersByFilterState<D, P extends Record<string, unknown>, K extends string> = Record<FilterState, Record<K, FilterWithPredicate<D, P>>>;

export const getFiltersByFilterState = <
  D,
  P extends Record<string, unknown>,
  K extends string = string,
>(defaultParams: P, currentParams: P) =>
  (filters: Record<K, FilterWithPredicate<D, P>>): FiltersByFilterState<D, P, K> => {
    const { left: unapplied, right: applied } = partitionFiltersByAppliedState<D, P>(defaultParams, currentParams)(filters);

    const active = getActiveFilters(applied)(filters);

    return { applied, unapplied, active };
  };


export const makeFiltersWithParams = <
  D,
  P extends Record<string, unknown>,
  K extends string = string,
>(current: P) =>
  R.mapWithIndex<K, Filter<D, P>, FilterWithPredicate<D, P>>((k, v) => ({ ...v, filterFn: v.filterFn(current[k], current) }));


const applyFilterPredicates = <D,>(d: ReadonlyArray<D>) => (f: FilterPredicates<D>) => RA.filter(applyPredicatesAll(f))(d);

const applyFilterMap = <A,>(filterPredicate: FilterPredicate<A>): (a: ReadonlyArray<A>) => ReadonlyArray<A> =>
  RA.filterMap(O.fromPredicate(filterPredicate));

const applyFilterIfActive = <D,>(data: ReadonlyArray<D>, filterFn: FilterPredicate<D>) =>
  flow(
    b.fold(
      () => data,
      () => applyFilterPredicates(data)([filterFn]),
    ),
    O.some,
  );

const filterOnlyOnFirstIteration = <D, P extends Record<string, unknown>, K extends string>(
  data: ReadonlyArray<D>,
  groupKey: K,
  filter: FilterPredicate<D>,
  activeFilters: Record<K, FilterWithPredicate<D, P>>
) =>
  O.alt(() => applyFilterIfActive(data, filter)(R.has(groupKey, activeFilters)));


export const groupDataByKey = <K, D>(getKey: (d: D) => K, total: O.Option<K>) => (data: ReadonlyArray<D>): GroupedData<K, D> =>
  RA.reduce(new Map<K, Set<D>>(), (groupedData, d: D) => {
    const key = getKey(d);

    O.fold(
      () => groupedData,
      (f: K) => groupedData.set(f, (groupedData.get(f) ?? new Set()).add(d)),
    )(total);

    return groupedData.set(key, (groupedData.get(key) || new Set()).add(d));
  })(data);

export const groupDataByKeys = <K, D>(getKeys: (d: D) => Readonly<Iterable<K>>) =>
  (fallback: O.Option<K>) =>
    (data: Readonly<Iterable<D>>): GroupedData<K, D> => {

      const groupedData = new Map<K, Set<D>>();
      for (const d of data) {
        const keys = getKeys(d);

        const keysOrFallback = O.fold(
          () => keys,
          (fb: K) => Array.from(keys).length ? keys : [fb],
        )(fallback);

        for (const kf of keysOrFallback) {
          const dataSet = groupedData.get(kf);
          dataSet ? dataSet.add(d) : groupedData.set(kf, new Set([d]));
        }
      }
      return groupedData;
    };

const groupDataByGroupKey = <P extends Record<string, unknown>, K extends string, D>(groupKey: K, groupedData: GroupedDataByGroupKey<D, P, K>, data: ReadonlyArray<D>) =>
  O.fold(
    () => groupedData,
    (groupingFn: GroupingFn<unknown, D>) => ({ ...groupedData, [`by${capitalize(groupKey)}`]: groupingFn(data) }),
  );


const searchData = <P extends Record<string, unknown>, DT>(searchFilters: Record<string, SearchFilter<DT>>, params: P) => (data: ReadonlyArray<DT>) => pipe(
  searchFilters,
  R.reduceWithIndex(Ord.preserve())(data, (k: keyof P, acc: ReadonlyArray<DT>, a) => {
    const needle = params[k];
    return typeof needle === "string" && needle.length > 0 ? a.searchFn(needle)(acc) : acc;
  })
);


type FilteredAndGroupedData<D, P extends Record<string, unknown>, K extends string, isAcc extends boolean = false> = {
  filteredData: isAcc extends true ? O.Option<ReadonlyArray<D>> : ReadonlyArray<D>;
  groupedData: GroupedDataByGroupKey<D, P, K>;
};

/**
 * The props required by the Filtering component.
 *
 * @param {object} activeFilters Filters that are applied and/or preselected {@link FilterState}
 *
 * @note The complete filteredData requires all Active filters to be applied. Grouping the data requires all Active filters except the current filter being grouped by. Therefore, we use the grouping operation as a stepping stone, calculating the filtered data alongside the first groupedData calculation. For all subsequent filters, only groupedData needs to be calculated.
 * @note In the accumulator, the filteredData is an Option type. This is to ensure that the filteredData is only calculated on the first iteration.
 * @note Filters run in the order they were defined in the filters object. Try to order them by complexity, with the simplest filters first, so that complex filtering is applied to less data.
 * @note We only filter the data fully if we haven't already done so or if the current filter has a grouping function.
 *
 */
export const makeFilteredAndGroupedData = <DT, P extends Record<string, unknown>, K extends string>(data: ReadonlyArray<DT>, activeFilters: Record<K, FilterWithPredicate<DT, P>>): (filters: Record<K, FilterWithPredicate<DT, P>>) => FilteredAndGroupedData<DT, P, K> =>
  flow(
    R.reduceWithIndex(Ord.preserve())(
      { filteredData: O.none, groupedData: Object.create(null) },
      (groupKey, acc: FilteredAndGroupedData<DT, P, K, true>, currentFilter) => {

        const groupingFnO = O.fromNullable(currentFilter.groupingFn);

        const shouldFilterForCount = O.isNone(acc.filteredData) || O.isSome(groupingFnO);
        if (!shouldFilterForCount) { return acc; }

        const combinedPredicates = pipe(
          delProp(activeFilters, groupKey),
          R.foldMap(Ord.preserve())<FilterPredicates<DT>>(RA.getMonoid())((f: FilterWithPredicate<DT, P>) => [f.filterFn]),
          applyPredicatesAll,
        );

        const filteredDataForGrouping: ReadonlyArray<DT> = applyFilterMap(combinedPredicates)(data);

        return {
          filteredData: filterOnlyOnFirstIteration(filteredDataForGrouping, groupKey, currentFilter.filterFn, activeFilters)(acc.filteredData),
          groupedData: groupDataByGroupKey(groupKey, acc.groupedData, filteredDataForGrouping)(groupingFnO),
        };
      }
    ),
    r => ({ ...r, filteredData: O.getOrElse((): ReadonlyArray<DT> => [])(r.filteredData) }),
  );

/**
* The props required by the Filtering component.
*
 * @param {object} P The filter state (e.g. URL params)
 * @param {string} K A key belonging to the filter state and the filters object
 * @param {object} D The data to be filtered
 * @param {object} DT The transformed data
 * @param {object} SK The keys of any search filters
*
* @see {@link Filtering}
*/
type FilteringProps<P extends Record<string, unknown>, K extends string, D, DT, SK extends Exclude<keyof P, K>> = {
  params: P;
  defaultParams: P;
  data: ReadonlyArray<D>;
  transform: (d: D) => DT;
  filters: Filters<DT, P, K, SK>;
  children: (filteredData: ReadonlyArray<DT>, groupedData: GroupedDataByGroupKey<DT, P, K>, appliedFilters: Set<keyof FiltersByFilterState<DT, P, K>["applied"]>) => ReactElement;
};

/**
 * A react component used to filter and group an array of data based on the provided FilterWithPredicate object.
 *
 * @param {object} params The current filter state (e.g. URL params)
 * @param {object} defaultParams The initial filter state. Used to compare against the current filter state
 * @param {array} data A readonly array of data to be filtered
 * @param {function} transform A function used to transform the data before filtering (e.g. data from api --> table data)
 * @param {object} filters The filters object used to filter and group the data
 * @param {function} children A function that takes the filtered data, grouped data, and applied filter count and returns a React element
 *
 * @see {@link FilteringProps}
 */

export const Filtering = <P extends Record<string, unknown>, K extends string, D, DT, SK extends Exclude<keyof P, K>>(props: FilteringProps<P, K, D, DT, SK>) => {
  const {
    left: mainFilters,
    right: searchFilters,
  }: Sep.Separated<Record<string, Filter<DT, P>>, Record<string, SearchFilter<DT>>> = pipe(
    props.filters,
    R.partition((a: Filter<DT, P> | SearchFilter<DT>): a is SearchFilter<DT> => "searchFn" in a),
    Sep.mapLeft(R.filter((a): a is Filter<DT, P> => "filterFn" in a))
  );

  const transformedData = useStableMemo(
    () => props.data.map(props.transform),
    [props.data, props.transform],
    Eq.tuple(RA.getEq(Eq.eqStrict), Eq.eqStrict)
  );

  const filters = makeFiltersWithParams<DT, P>(props.params)(mainFilters);

  const { active, applied } = getFiltersByFilterState<DT, P, K>(props.defaultParams, props.params)(filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchedData = useStableMemo(() => searchData(searchFilters, props.params)(transformedData), [props.params, transformedData], Eq.tuple(Eq.eqStrict, RA.getEq(Eq.eqStrict)));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { filteredData, groupedData } = useStableMemo(() => makeFilteredAndGroupedData(searchedData, active)(filters), [props.params, searchedData], Eq.tuple(Eq.eqStrict, RA.getEq(Eq.eqStrict)));

  return props.children(filteredData, groupedData, new Set(R.keys(applied)));
};
