import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const admin = {
  _tag: `Admin`,
  id: 1,
  role: `Admin`
} as const;

export const adminTaggedC = t.type({
  _tag: t.literal(`Admin`)
});
export type AdminTaggedC = typeof adminTaggedC;
export type AdminTagged = t.TypeOf<AdminTaggedC>;
export type Admin = AdminTagged & typeof admin;
export const adminC = pipe(adminTaggedC, c => new t.Type<Admin, AdminTagged>(
  `Admin`,
  (u: unknown): u is Admin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Admin> => pipe(c.decode(u), E.map(x => ({ ...x, ...admin }))),
  (x: Admin): AdminTagged => ({ ...x, _tag: `Admin`}),
));
export type AdminC = typeof adminC;


export const salesAdmin = {
  _tag: `SalesAdmin`,
  id: 2,
  role: `SalesAdmin`
} as const;

export const salesAdminTaggedC = t.type({
  _tag: t.literal(`SalesAdmin`)
});
export type SalesAdminTaggedC = typeof salesAdminTaggedC;
export type SalesAdminTagged = t.TypeOf<SalesAdminTaggedC>;
export type SalesAdmin = SalesAdminTagged & typeof salesAdmin;
export const salesAdminC = pipe(salesAdminTaggedC, c => new t.Type<SalesAdmin, SalesAdminTagged>(
  `SalesAdmin`,
  (u: unknown): u is SalesAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SalesAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...salesAdmin }))),
  (x: SalesAdmin): SalesAdminTagged => ({ ...x, _tag: `SalesAdmin`}),
));
export type SalesAdminC = typeof salesAdminC;


export const issuerAdmin = {
  _tag: `IssuerAdmin`,
  id: 3,
  role: `IssuerAdmin`
} as const;

export const issuerAdminTaggedC = t.type({
  _tag: t.literal(`IssuerAdmin`)
});
export type IssuerAdminTaggedC = typeof issuerAdminTaggedC;
export type IssuerAdminTagged = t.TypeOf<IssuerAdminTaggedC>;
export type IssuerAdmin = IssuerAdminTagged & typeof issuerAdmin;
export const issuerAdminC = pipe(issuerAdminTaggedC, c => new t.Type<IssuerAdmin, IssuerAdminTagged>(
  `IssuerAdmin`,
  (u: unknown): u is IssuerAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerAdmin }))),
  (x: IssuerAdmin): IssuerAdminTagged => ({ ...x, _tag: `IssuerAdmin`}),
));
export type IssuerAdminC = typeof issuerAdminC;


export const issuerUser = {
  _tag: `IssuerUser`,
  id: 4,
  role: `IssuerUser`
} as const;

export const issuerUserTaggedC = t.type({
  _tag: t.literal(`IssuerUser`)
});
export type IssuerUserTaggedC = typeof issuerUserTaggedC;
export type IssuerUserTagged = t.TypeOf<IssuerUserTaggedC>;
export type IssuerUser = IssuerUserTagged & typeof issuerUser;
export const issuerUserC = pipe(issuerUserTaggedC, c => new t.Type<IssuerUser, IssuerUserTagged>(
  `IssuerUser`,
  (u: unknown): u is IssuerUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerUser }))),
  (x: IssuerUser): IssuerUserTagged => ({ ...x, _tag: `IssuerUser`}),
));
export type IssuerUserC = typeof issuerUserC;


export const issuerPreviewer = {
  _tag: `IssuerPreviewer`,
  id: 9,
  role: `IssuerPreviewer`
} as const;

export const issuerPreviewerTaggedC = t.type({
  _tag: t.literal(`IssuerPreviewer`)
});
export type IssuerPreviewerTaggedC = typeof issuerPreviewerTaggedC;
export type IssuerPreviewerTagged = t.TypeOf<IssuerPreviewerTaggedC>;
export type IssuerPreviewer = IssuerPreviewerTagged & typeof issuerPreviewer;
export const issuerPreviewerC = pipe(issuerPreviewerTaggedC, c => new t.Type<IssuerPreviewer, IssuerPreviewerTagged>(
  `IssuerPreviewer`,
  (u: unknown): u is IssuerPreviewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPreviewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPreviewer }))),
  (x: IssuerPreviewer): IssuerPreviewerTagged => ({ ...x, _tag: `IssuerPreviewer`}),
));
export type IssuerPreviewerC = typeof issuerPreviewerC;


export const bankerAdmin = {
  _tag: `BankerAdmin`,
  id: 7,
  role: `BankerAdmin`
} as const;

export const bankerAdminTaggedC = t.type({
  _tag: t.literal(`BankerAdmin`)
});
export type BankerAdminTaggedC = typeof bankerAdminTaggedC;
export type BankerAdminTagged = t.TypeOf<BankerAdminTaggedC>;
export type BankerAdmin = BankerAdminTagged & typeof bankerAdmin;
export const bankerAdminC = pipe(bankerAdminTaggedC, c => new t.Type<BankerAdmin, BankerAdminTagged>(
  `BankerAdmin`,
  (u: unknown): u is BankerAdmin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerAdmin> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerAdmin }))),
  (x: BankerAdmin): BankerAdminTagged => ({ ...x, _tag: `BankerAdmin`}),
));
export type BankerAdminC = typeof bankerAdminC;


export const bankerUser = {
  _tag: `BankerUser`,
  id: 8,
  role: `BankerUser`
} as const;

export const bankerUserTaggedC = t.type({
  _tag: t.literal(`BankerUser`)
});
export type BankerUserTaggedC = typeof bankerUserTaggedC;
export type BankerUserTagged = t.TypeOf<BankerUserTaggedC>;
export type BankerUser = BankerUserTagged & typeof bankerUser;
export const bankerUserC = pipe(bankerUserTaggedC, c => new t.Type<BankerUser, BankerUserTagged>(
  `BankerUser`,
  (u: unknown): u is BankerUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerUser }))),
  (x: BankerUser): BankerUserTagged => ({ ...x, _tag: `BankerUser`}),
));
export type BankerUserC = typeof bankerUserC;


export const bankerPreviewer = {
  _tag: `BankerPreviewer`,
  id: 10,
  role: `BankerPreviewer`
} as const;

export const bankerPreviewerTaggedC = t.type({
  _tag: t.literal(`BankerPreviewer`)
});
export type BankerPreviewerTaggedC = typeof bankerPreviewerTaggedC;
export type BankerPreviewerTagged = t.TypeOf<BankerPreviewerTaggedC>;
export type BankerPreviewer = BankerPreviewerTagged & typeof bankerPreviewer;
export const bankerPreviewerC = pipe(bankerPreviewerTaggedC, c => new t.Type<BankerPreviewer, BankerPreviewerTagged>(
  `BankerPreviewer`,
  (u: unknown): u is BankerPreviewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankerPreviewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankerPreviewer }))),
  (x: BankerPreviewer): BankerPreviewerTagged => ({ ...x, _tag: `BankerPreviewer`}),
));
export type BankerPreviewerC = typeof bankerPreviewerC;


export const member = {
  _tag: `Member`,
  id: 5,
  role: `Member`
} as const;

export const memberTaggedC = t.type({
  _tag: t.literal(`Member`)
});
export type MemberTaggedC = typeof memberTaggedC;
export type MemberTagged = t.TypeOf<MemberTaggedC>;
export type Member = MemberTagged & typeof member;
export const memberC = pipe(memberTaggedC, c => new t.Type<Member, MemberTagged>(
  `Member`,
  (u: unknown): u is Member => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Member> => pipe(c.decode(u), E.map(x => ({ ...x, ...member }))),
  (x: Member): MemberTagged => ({ ...x, _tag: `Member`}),
));
export type MemberC = typeof memberC;


export const bondlinkUser = {
  _tag: `BondlinkUser`,
  id: 6,
  role: `BondLinkUser`
} as const;

export const bondlinkUserTaggedC = t.type({
  _tag: t.literal(`BondlinkUser`)
});
export type BondlinkUserTaggedC = typeof bondlinkUserTaggedC;
export type BondlinkUserTagged = t.TypeOf<BondlinkUserTaggedC>;
export type BondlinkUser = BondlinkUserTagged & typeof bondlinkUser;
export const bondlinkUserC = pipe(bondlinkUserTaggedC, c => new t.Type<BondlinkUser, BondlinkUserTagged>(
  `BondlinkUser`,
  (u: unknown): u is BondlinkUser => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondlinkUser> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondlinkUser }))),
  (x: BondlinkUser): BondlinkUserTagged => ({ ...x, _tag: `BondlinkUser`}),
));
export type BondlinkUserC = typeof bondlinkUserC;


export const allRoleC = [adminC, salesAdminC, issuerAdminC, issuerUserC, issuerPreviewerC, bankerAdminC, bankerUserC, bankerPreviewerC, memberC, bondlinkUserC] as const;
export const allRoleNames = [`Admin`, `SalesAdmin`, `IssuerAdmin`, `IssuerUser`, `IssuerPreviewer`, `BankerAdmin`, `BankerUser`, `BankerPreviewer`, `Member`, `BondlinkUser`] as const;
export type RoleName = (typeof allRoleNames)[number];

export const RoleCU = t.union([adminC, salesAdminC, issuerAdminC, issuerUserC, issuerPreviewerC, bankerAdminC, bankerUserC, bankerPreviewerC, memberC, bondlinkUserC]);
export type RoleCU = typeof RoleCU;
export type RoleU = t.TypeOf<RoleCU>;

export const roleOrd: Ord.Ord<RoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRole = [admin, salesAdmin, issuerAdmin, issuerUser, issuerPreviewer, bankerAdmin, bankerUser, bankerPreviewer, member, bondlinkUser] as const;
export type RoleMap<A> = { [K in RoleName]: A };


export const allBondLinkRoleC = [adminC, salesAdminC] as const;
export const allBondLinkRoleNames = [`Admin`, `SalesAdmin`] as const;
export type BondLinkRoleName = (typeof allBondLinkRoleNames)[number];

export const BondLinkRoleCU = t.union([adminC, salesAdminC]);
export type BondLinkRoleCU = typeof BondLinkRoleCU;
export type BondLinkRoleU = t.TypeOf<BondLinkRoleCU>;

export const bondLinkRoleOrd: Ord.Ord<BondLinkRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkRole = [admin, salesAdmin] as const;
export type BondLinkRoleMap<A> = { [K in BondLinkRoleName]: A };


export const allIssuerRoleC = [issuerAdminC, issuerUserC, issuerPreviewerC] as const;
export const allIssuerRoleNames = [`IssuerAdmin`, `IssuerUser`, `IssuerPreviewer`] as const;
export type IssuerRoleName = (typeof allIssuerRoleNames)[number];

export const IssuerRoleCU = t.union([issuerAdminC, issuerUserC, issuerPreviewerC]);
export type IssuerRoleCU = typeof IssuerRoleCU;
export type IssuerRoleU = t.TypeOf<IssuerRoleCU>;

export const issuerRoleOrd: Ord.Ord<IssuerRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerRole = [issuerAdmin, issuerUser, issuerPreviewer] as const;
export type IssuerRoleMap<A> = { [K in IssuerRoleName]: A };


export const allBankerRoleC = [bankerAdminC, bankerUserC, bankerPreviewerC] as const;
export const allBankerRoleNames = [`BankerAdmin`, `BankerUser`, `BankerPreviewer`] as const;
export type BankerRoleName = (typeof allBankerRoleNames)[number];

export const BankerRoleCU = t.union([bankerAdminC, bankerUserC, bankerPreviewerC]);
export type BankerRoleCU = typeof BankerRoleCU;
export type BankerRoleU = t.TypeOf<BankerRoleCU>;

export const bankerRoleOrd: Ord.Ord<BankerRoleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBankerRole = [bankerAdmin, bankerUser, bankerPreviewer] as const;
export type BankerRoleMap<A> = { [K in BankerRoleName]: A };


