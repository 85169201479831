import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import * as E from "fp-ts/lib/Either";
import { taggedContentC as imported116_taggedContentC, taggedContentC as imported118_taggedContentC, taggedContentC as imported112_taggedContentC, taggedContentC as imported115_taggedContentC, taggedContentC as imported113_taggedContentC, taggedContentC as imported114_taggedContentC, taggedContentC as imported119_taggedContentC, taggedContentC as imported117_taggedContentC } from "./taggedContent";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { subscribedC as imported17_subscribedC, subscribedC as imported18_subscribedC } from "./subscribed";
import { eventC as imported8_eventC } from "./event";
import { roadShowDataC as imported14_roadShowDataC, roadShowDataC as imported15_roadShowDataC } from "./roadshow";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { withIdC as imported160_withIdC, withModInfoC as imported104_withModInfoC, withModInfoC as imported103_withModInfoC, withIdC as imported161_withIdC, withModInfoC as imported102_withModInfoC, withModInfoC as imported100_withModInfoC, withIdC as imported164_withIdC, withIdC as imported159_withIdC, withModInfoC as imported99_withModInfoC, withIdC as imported155_withIdC, withModInfoC as imported101_withModInfoC, withIdC as imported162_withIdC, withIdC as imported163_withIdC, withIdC as imported156_withIdC, withIdC as imported158_withIdC, withIdC as imported157_withIdC } from "./threadThrough";
import { issuerC as imported33_issuerC, issuerC as imported32_issuerC, issuerC as imported30_issuerC, issuerC as imported28_issuerC, issuerC as imported29_issuerC, issuerC as imported31_issuerC } from "./issuer";
import { Ord as stringOrd } from "fp-ts/lib/string";
import { IssuerOrProgramRatingCU as imported3_IssuerOrProgramRatingCU } from "./ratingBase";
import { issuerNewsC as imported11_issuerNewsC } from "./issuerNewsBase";
import { documentWithCategoryC as imported11_documentWithCategoryC } from "./document";
import { mediaC as imported45_mediaC, mediaC as imported46_mediaC } from "./media";
import { LocalDateTimeFromIsoStringC, LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported14_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported29_rfpC } from "./rfpBase";

export const create = {
  _tag: `Create`,
  name: `Create`
} as const;

export const createTaggedC = t.type({
  _tag: t.literal(`Create`)
});
export type CreateTaggedC = typeof createTaggedC;
export type CreateTagged = t.TypeOf<CreateTaggedC>;
export type Create = CreateTagged & typeof create;
export const createC = pipe(createTaggedC, c => new t.Type<Create, CreateTagged>(
  `Create`,
  (u: unknown): u is Create => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Create> => pipe(c.decode(u), E.map(x => ({ ...x, ...create }))),
  (x: Create): CreateTagged => ({ ...x, _tag: `Create`}),
));
export type CreateC = typeof createC;


export const update = {
  _tag: `Update`,
  name: `Update`
} as const;

export const updateTaggedC = t.type({
  _tag: t.literal(`Update`)
});
export type UpdateTaggedC = typeof updateTaggedC;
export type UpdateTagged = t.TypeOf<UpdateTaggedC>;
export type Update = UpdateTagged & typeof update;
export const updateC = pipe(updateTaggedC, c => new t.Type<Update, UpdateTagged>(
  `Update`,
  (u: unknown): u is Update => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Update> => pipe(c.decode(u), E.map(x => ({ ...x, ...update }))),
  (x: Update): UpdateTagged => ({ ...x, _tag: `Update`}),
));
export type UpdateC = typeof updateC;


export const allActivityActionC = [createC, updateC] as const;
export const allActivityActionNames = [`Create`, `Update`] as const;
export type ActivityActionName = (typeof allActivityActionNames)[number];

export const ActivityActionCU = t.union([createC, updateC]);
export type ActivityActionCU = typeof ActivityActionCU;
export type ActivityActionU = t.TypeOf<ActivityActionCU>;

export const activityActionOrd: Ord.Ord<ActivityActionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityAction = [create, update] as const;
export type ActivityActionMap<A> = { [K in ActivityActionName]: A };


export const offeringItemC = t.type({
  _tag: t.literal(`OfferingItem`),
  item: imported17_subscribedC(imported155_withIdC(imported99_withModInfoC(imported112_taggedContentC(imported14_bondOfferingC)))),
  roadshows: t.readonlyArray(imported156_withIdC(imported113_taggedContentC(imported14_roadShowDataC))),
  documents: t.readonlyArray(imported157_withIdC(imported114_taggedContentC(imported45_mediaC))),
  issuer: imported28_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type OfferingItemC = typeof offeringItemC;
export type OfferingItem = t.TypeOf<OfferingItemC>;


export const newsItemC = t.type({
  _tag: t.literal(`NewsItem`),
  item: imported158_withIdC(imported100_withModInfoC(imported115_taggedContentC(imported11_issuerNewsC))),
  issuer: imported29_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type NewsItemC = typeof newsItemC;
export type NewsItem = t.TypeOf<NewsItemC>;


export const calendarItemC = t.type({
  _tag: t.literal(`CalendarItem`),
  item: imported159_withIdC(imported101_withModInfoC(imported8_eventC)),
  issuer: imported30_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type CalendarItemC = typeof calendarItemC;
export type CalendarItem = t.TypeOf<CalendarItemC>;


export const documentUploadC = t.type({
  _tag: t.literal(`DocumentUpload`),
  item: imported160_withIdC(imported102_withModInfoC(imported116_taggedContentC(imported11_documentWithCategoryC))),
  issuer: imported31_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type DocumentUploadC = typeof documentUploadC;
export type DocumentUpload = t.TypeOf<DocumentUploadC>;


export const bondRatingItemC = t.type({
  _tag: t.literal(`BondRatingItem`),
  item: imported161_withIdC(imported103_withModInfoC(imported3_IssuerOrProgramRatingCU)),
  issuer: imported32_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type BondRatingItemC = typeof bondRatingItemC;
export type BondRatingItem = t.TypeOf<BondRatingItemC>;


export const rFPItemC = t.type({
  _tag: t.literal(`RFPItem`),
  item: imported18_subscribedC(imported162_withIdC(imported104_withModInfoC(imported117_taggedContentC(imported29_rfpC)))),
  roadshows: t.readonlyArray(imported163_withIdC(imported118_taggedContentC(imported15_roadShowDataC))),
  documents: t.readonlyArray(imported164_withIdC(imported119_taggedContentC(imported46_mediaC))),
  issuer: imported33_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type RFPItemC = typeof rFPItemC;
export type RFPItem = t.TypeOf<RFPItemC>;


export const allIssuerActivityTypeC = [offeringItemC, newsItemC, calendarItemC, documentUploadC, bondRatingItemC, rFPItemC] as const;
export const allIssuerActivityTypeNames = [`OfferingItem`, `NewsItem`, `CalendarItem`, `DocumentUpload`, `BondRatingItem`, `RFPItem`] as const;
export type IssuerActivityTypeName = (typeof allIssuerActivityTypeNames)[number];

export const IssuerActivityTypeCU = t.union([offeringItemC, newsItemC, calendarItemC, documentUploadC, bondRatingItemC, rFPItemC]);
export type IssuerActivityTypeCU = typeof IssuerActivityTypeCU;
export type IssuerActivityTypeU = t.TypeOf<IssuerActivityTypeCU>;

export type IssuerActivityTypeMap<A> = { [K in IssuerActivityTypeName]: A };


export const issuerActivityC = readonlyMapFromEntries(LocalDateC, LocalDateOrd, readonlyNonEmptyArrayC(IssuerActivityTypeCU));
export type IssuerActivityC = typeof issuerActivityC;
export type IssuerActivity = t.TypeOf<IssuerActivityC>;


export const issuerActivityFeedC = t.type({
  items: t.readonlyArray(IssuerActivityTypeCU)
});
export type IssuerActivityFeedC = typeof issuerActivityFeedC;
export type IssuerActivityFeed = t.TypeOf<IssuerActivityFeedC>;


