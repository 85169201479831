import { O, pipe, RA } from "@scripts/fp-ts";
import { bondOfferings, bondOfferingsGeneralInfo } from "@scripts/generated/domaintables/featureFlags";
import type { ArchivedBondsSsrData } from "@scripts/generated/models/bondsSsrData";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { bondArchive } from "@scripts/routes/routing/ssr/issuersites";
import { bondSalesJumplinkId, generalOfferingInfoJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { deepLink } from "@scripts/util/url";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { GeneralOfferingInfo, generalOfferingInfoSectionTitle } from "../../components/offering-pages/GeneralOfferingInfo";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { BondSales } from "./BondSales";

const sidebarLinkIds = {
  archivedBondSales: bondSalesJumplinkId,
  generalOfferingInfo: generalOfferingInfoJumplinkId,
};

const archivedBondSalesSectionTitle = "Archived Bond Sales";

const setupSidebarLinkSections = (
  linkIds: typeof sidebarLinkIds,
  show: { [K in keyof typeof sidebarLinkIds]: boolean },
): ReadonlyArray<JumpLink> => RA.compact([
  makeJumplinkO(archivedBondSalesSectionTitle, linkIds.archivedBondSales, show.archivedBondSales),
  makeJumplinkO(generalOfferingInfoSectionTitle, linkIds.generalOfferingInfo, show.generalOfferingInfo),
]);

export const BondArchive = (props: ArchivedBondsSsrData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const bondArchiveRouteMeta = bondArchive({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const bondArchiveDescription = bondArchiveRouteMeta.description(pages, issuer);

  const showGeneralOfferingInfo = isFFEnabled(bondOfferingsGeneralInfo)(iffs) && O.isSome(props.generalInfo);
  const activeBondsEnabled = isFFEnabled(bondOfferings)(iffs);

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      archivedBondSales: true,
      generalOfferingInfo: showGeneralOfferingInfo,
    },
  );

  return <DirectSitesPageDescriptionLayout
    description={bondArchiveDescription}
    relatedPage={activeBondsEnabled
      ? O.some({
        text: "View Current Bond Offerings",
        url: deepLink({
          type: "Basic",
          route: SR.issuersitesBondOfferingsControllerIndex,
          selector: bondSalesJumplinkId,
        })({ issuerId: issuer.id, issuerSlug: issuer.slug }).url,
      })
      : O.none
    }
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkCustomAnchorContent={O.none}
          jumpLinks={sidebarLinks}
          routeMeta={bondArchiveRouteMeta}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <BondSales
      activeBonds={props.offerings}
      isBondArchiveEnabled={false}
      sidebarLinkId={sidebarLinkIds.archivedBondSales}
      sectionTitle={archivedBondSalesSectionTitle}
    />
    {showGeneralOfferingInfo && pipe(
      props.generalInfo,
      mapOrEmpty(generalInfo =>
        <GeneralOfferingInfo
          offeringInfo={generalInfo}
          sidebarLinkHandle={sidebarLinkIds.generalOfferingInfo}
        />
      )
    )}
  </DirectSitesPageDescriptionLayout>;
};
