import type { DependencyList } from "react";
import { useEffect } from "react";
import type { Dispatch } from "redux";

import { analytics, type ContentGroupU, type ContentSubGroup } from "@scripts/analytics";
import type { BLConfigWithLog } from "@scripts/bondlink";
import { blFetch } from "@scripts/fetch";
import { flow, O, pipe, TE } from "@scripts/fp-ts";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { useVersionChange } from "@scripts/react/bootstrap";
import { useConfig } from "@scripts/react/context/Config";
import type { FlashAction } from "@scripts/react/state/flash";
import type { NotificationAction } from "@scripts/react/state/notifications";
import { useSessionChange } from "@scripts/react/util/useSessionChange";

const useAnalytics = (dependencies: DependencyList, contentGroup: ContentGroupU, contentSubGroup: ContentSubGroup) => {
  useEffect(() => {
    analytics(contentGroup, contentSubGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const useBootstrap = (
  analyticsDependencies: DependencyList,
  dispatch: Dispatch<FlashAction | NotificationAction>,
  contentGroup: ContentGroupU,
  contentSubGroup: ContentSubGroup,
) => {
  useAnalytics(analyticsDependencies, contentGroup, contentSubGroup);
  useSessionChange(dispatch);
  useVersionChange(dispatch);
};

const reloadPage = () => window.location.reload();

const draftPreviewListener = (config: BLConfigWithLog) => (ev: MouseEvent) =>
  pipe(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    O.fromNullable(ev.target as Element | null),
    O.chain(O.fromPredicate(target => target.id === "preview-switcher")),
    O.map(flow(
      O.fromPredicate<Element>(target => target.getAttribute("data-mode") === "preview"),
      O.fold(
        SitesRouter.issuersitesIssuerControllerEnterPreview,
        SitesRouter.issuersitesIssuerControllerExitPreview
      ),
      blFetch(config),
      TE.bimap(reloadPage, reloadPage),
      TE.run,
    )),
  );

export const useDraftPreview = () => {
  const config = useConfig();

  useEffect(() => {
    document.addEventListener("click", draftPreviewListener(config), { capture: true });

    return () => {
      document.removeEventListener("click", draftPreviewListener(config));
    };
  }, [config]);
};
