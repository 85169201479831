import { type Breakpoint } from "@scripts/bondlinkStatic";
import { E, type O, pipe } from "@scripts/fp-ts";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { Issuer } from "@scripts/generated/models/issuer";
import { ButtonLinkIcon } from "@scripts/react/components/Button";
import { DataHorizontal, DataPointSmall } from "@scripts/react/components/Data";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { klass } from "@scripts/react/util/classnames";
import { type OpenModalO, useModalStableO } from "@scripts/react/util/useModal";
import { shortMonthAndYear } from "@scripts/syntax/date/joda";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import ratingIcon from "@svgs/graph.svg";

import { RatingDetailsModal } from "./RatingDetailsModal";

export const RatingBlock = (props: {
  rating: CommonRatingsByAgency[number];
  openDetailsModal: OpenModalO<RatingAgencyU>;
}) =>
  <div>
    <h5 {...klass("truncate truncate-1")}>{props.rating[0].shortName}</h5>
    <div
      {...klass(
        "accent-2-700-bg inverted font-sans-normal-700 pb-1 pt-1 border-radius-1 data-point-large-text text-center"
      )}
    >
      {props.rating[1][0].data.record.rating.rating}
    </div>
    <div {...klass("mb-05 mt-05")}>
      <DataHorizontal>
        <DataPointSmall
          title="Outlook"
          value={props.rating[1][0].data.record.rating.ratingOutlook.ratingOutlook}
        />
        <DataPointSmall
          title="Date"
          value={shortMonthAndYear(props.rating[1][0].data.record.rating.ratingDate)}
        />
      </DataHorizontal>
    </div>
    <ButtonLinkIcon
      onClick={() => props.openDetailsModal(props.rating[0])}
      icon={ratingIcon}
      textOrAriaLabel={E.left("Rating Details")}
    />
  </div>;


export const defaultRatingBlockBreakpoint: Breakpoint = "lg";

export const RatingBlockSection = (props: {
  ratings: CommonRatingsByAgency;
  name: O.Option<string>;
  modalName: string;
  issuer: Issuer;
  breakpoint?: Breakpoint;
}) => {
  const [detailsModalOpen, defaultRatingAgency, openDetailsModal, closeDetailsModal] = useModalStableO<RatingAgencyU>("ratings block section details modal");
  return <div>
    {pipe(props.name, mapOrEmpty(n => <h3 {...klass("mb-1")}>{n}</h3>))}
    {props.ratings.length <= 0
      ? <p>There are currently no ratings available.</p>
      : <div {...klass("grid grid-sx-2 rg-2")}>
        {props.ratings.map(rating =>
          <div key={rating[1][0].data.id} {...klass(`c-xl-6 c-${props.breakpoint ?? defaultRatingBlockBreakpoint}-8 c-sm-12 c-24`)}>
            <RatingBlock rating={rating} openDetailsModal={openDetailsModal} />
          </div>
        )}
        <RatingDetailsModal
          modalOpen={detailsModalOpen}
          defaultAgency={defaultRatingAgency}
          dismissAction={closeDetailsModal}
          ratings={props.ratings}
          name={props.modalName}
          issuer={props.issuer}
        />
      </div>
    }
  </div >;
};
