import { issuerC as imported54_issuerC } from "./issuer";
import * as t from "io-ts";
import { bondOfferingC as imported23_bondOfferingC, bondOfferingC as imported24_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported11_addressC } from "./address";

export const issuerWithOfferingsC = t.type({
  issuer: imported54_issuerC,
  address: optionFromNullable(imported11_addressC),
  activeOfferings: t.readonlyArray(imported23_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported24_bondOfferingC)
});
export type IssuerWithOfferingsC = typeof issuerWithOfferingsC;
export type IssuerWithOfferings = t.TypeOf<IssuerWithOfferingsC>;


export const issuersWithOfferingsC = t.type({
  issuers: t.readonlyArray(issuerWithOfferingsC)
});
export type IssuersWithOfferingsC = typeof issuersWithOfferingsC;
export type IssuersWithOfferings = t.TypeOf<IssuersWithOfferingsC>;


