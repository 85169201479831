import { toUnion } from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { bondOfferings, showArchivedBonds } from "@scripts/generated/domaintables/featureFlags";
import type { BondOffering, BondOfferingSeries } from "@scripts/generated/models/bondOfferingBase";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { qualifierFormat } from "@scripts/syntax/date/dateQualifier";

import { type DateFormatter, dateTBDConst, humanDateLong } from "./date/joda";
import { isFFEnabled } from "./featureFlags";
import { issuerSiteAbsUrl } from "./issuer";

export const offeringUrl =
  (config: BLConfigWithLog) => (o: WithId<TaggedContent<BondOffering>>, issuer: Issuer) =>
    issuerSiteAbsUrl(config)(issuer, O.none)(
      SitesRouter.issuersitesBondOfferingsControllerOffering
    )({ offeringId: o.id });

export const saleDateFormat = (config: BLConfigWithLog) => (
  d: BondOfferingSeries["saleDate"],
  formatFn: DateFormatter = humanDateLong,
): string =>
  pipe(d, O.fold(dateTBDConst, flow(toUnion, _ => qualifierFormat(config)(_, formatFn))));

export const offeringToFF = (bond: BondOffering) => bond.isArchived ? showArchivedBonds : bondOfferings;

export const isOfferingViewable = (iffs: ClientFeatureFlags) => (bond: BondOffering) =>
  isFFEnabled(offeringToFF(bond))(iffs);
