import { type ReactElement, useState } from "react";
import { singular } from "pluralize";

import { flow, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import type { BondOfferingWithRoadshowsAndDocs } from "@scripts/generated/models/bondOffering";
import type { RfpWithRoadshowsAndDocs } from "@scripts/generated/models/rfp";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { TabRow, type TabRowItems } from "@scripts/react/components/TabRow";
import { klass } from "@scripts/react/util/classnames";

import { useBondSubscribe, useRfpSubscribe } from "../../api/watchlist";
import { useIssuerSitesSelector } from "../../state/store";
import { IssuerSitesBondCard } from "../BondCard";
import { IssuerSitesRfpCard } from "../RfpCard";

type DealTabU = "active" | "archived";

export const getNonEmptyItemsRecordO: <K extends string, V>(r: Record<K, ReadonlyArray<V>>) => O.Option<Record<K, O.Option<RNEA.ReadonlyNonEmptyArray<V>>>> = flow(
  R.map(RNEA.fromReadonlyArray),
  O.fromPredicate(R.some(O.isSome))
);

type ActiveAndArchivedDeals<A> = {
  active: O.Option<RNEA.ReadonlyNonEmptyArray<A>>;
  archived: O.Option<RNEA.ReadonlyNonEmptyArray<A>>;
};

type DealSectionProps<A> = {
  title?: string;
  itemName: string;
  mapFn: (item: A) => ReactElement;
  sectionId: string;
  deals: ActiveAndArchivedDeals<A>;
};

const getItemsLength = flow(
  O.map((_: RNEA.ReadonlyNonEmptyArray<unknown>) => _.length),
  O.getOrElse(() => 0)
);

export const DealSection = <A,>(props: DealSectionProps<A>) => {
  const [currentTab, setCurrentTab] = useState<DealTabU>("active");
  const tabRowItems: TabRowItems<DealTabU> = [
    {
      title: `Active ${props.itemName} (${getItemsLength(props.deals.active)})`,
      value: "active",
      onClick: () => setCurrentTab("active"),
      icon: O.none,
    },
    {
      title: `Archived ${props.itemName} (${getItemsLength(props.deals.archived)})`,
      value: "archived",
      onClick: () => setCurrentTab("archived"),
      icon: O.none,
    },
  ];

  return (
    <AccentDividerSection title={O.some(props.title ?? props.itemName)} sectionId={props.sectionId} klasses="accent-border-top">
      {(O.isSome(props.deals.archived)) && (
        <>
          <TabRow
            orientation="horizontal"
            isSm={true}
            variant="default"
            items={tabRowItems}
            current={currentTab}
            klasses={"d-none-until-sm"}
          />
          <TabRow
            orientation="vertical"
            isSm={false}
            variant="default"
            items={tabRowItems}
            current={currentTab}
            klasses={["d-sm-none", "mb-15"]}
          />
        </>
      )}
      {currentTab === "active" && pipe(
        props.deals.active,
        O.fold(
          () =>
            <div>
              There are currently no active {props.itemName.toLowerCase()}.
            </div>,
          (activeItems) =>
            <div {...klass("grid", "card-grid")}>
              {pipe(activeItems, RA.map(props.mapFn))}
            </div>,
        )
      )}
      {currentTab === "archived" && pipe(
        props.deals.archived,
        mapOrEmpty(archivedItems =>
          <div {...klass("grid", "card-grid")}>
            {pipe(archivedItems, RA.map(props.mapFn))}
          </div>,
        )
      )}
    </AccentDividerSection>
  );
};

type RoadshowsAndDocs = Pick<BondOfferingWithRoadshowsAndDocs, "roadShows" | "documents">;

const modWithRoadshowsAndDocs = <R extends RoadshowsAndDocs, A>(
  a: Subscribed<WithStatusU<TaggedContent<R>>>,
  mkObj: (r: R) => A,
): Subscribed<WithStatusU<TaggedContent<A & { relatedContent: RoadshowsAndDocs }>>> => ({
  ...a,
  data: {
    ...a.data,
    data: {
      ...a.data.data,
      record: {
        ...a.data.data.record,
        data: {
          ...mkObj(a.data.data.record.data),
          relatedContent: { roadShows: a.data.data.record.data.roadShows, documents: a.data.data.record.data.documents },
        },
      },
    },
  },
});

export type RelatedOffering = Subscribed<WithStatusU<TaggedContent<BondOfferingWithRoadshowsAndDocs>>>;

const BondCard = (props: { bond: RelatedOffering }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const makeOnSubscribe = useBondSubscribe();

  const [isSubscribed, setIsSubscribed] = useState(props.bond.subscribed);

  return (
    <div  {...klass("c-24")}>
      <IssuerSitesBondCard
        issuer={issuer}
        bond={modWithRoadshowsAndDocs(props.bond, _ => ({ offering: _.offering }))}
        isSubscribed={isSubscribed}
        onSubscribe={makeOnSubscribe(
          { issuerId: O.some(issuer.id), bankId: O.none },
          !isSubscribed,
          props.bond.data.data.id,
          setIsSubscribed,
          user,
          userSubscribedToIssuer
        )}
      />
    </div>
  );
};

const bondMapFn = (offering: RelatedOffering) =>
  <BondCard
    bond={offering}
    key={`bond-${offering.data.data.id}`}
  />;

export const BondsSection = (props: Omit<DealSectionProps<RelatedOffering>, "mapFn">) =>
  <DealSection
    deals={props.deals}
    mapFn={bondMapFn}
    sectionId={props.sectionId}
    title={props.title}
    itemName={props.itemName}
  />;

export type RelatedRfp = Subscribed<WithStatusU<TaggedContent<RfpWithRoadshowsAndDocs>>>;

export const RfpCard = (props: {
  rfp: RelatedRfp;
  itemName: string;
}) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const [isSubscribed, setIsSubscribed] = useState(props.rfp.subscribed);

  const makeOnSubscribe = useRfpSubscribe();

  return <div  {...klass("c-24")}>
    <IssuerSitesRfpCard
      issuer={issuer}
      rfp={modWithRoadshowsAndDocs(props.rfp, _ => ({ rfp: _.rfp }))}
      isSubscribed={isSubscribed}
      onSubscribe={makeOnSubscribe(
        { issuerId: O.some(issuer.id), bankId: O.none },
        !isSubscribed,
        props.rfp.data.data.id,
        setIsSubscribed,
        user,
        userSubscribedToIssuer
      )}
    />
  </div>;
};

const rfpMapFn = (itemName: string) => (rfp: RelatedRfp) =>
  <RfpCard
    key={`rfp-${rfp.data.data.id}`}
    rfp={rfp}
    itemName={itemName}
  />;

export const RfpsSection = (props: Omit<DealSectionProps<RelatedRfp>, "mapFn">) =>
  <DealSection
    deals={props.deals}
    mapFn={rfpMapFn(singular(props.itemName))}
    sectionId={props.sectionId}
    title={props.title}
    itemName={props.itemName}
  />;
