import { type ReactNode, useState } from "react";
import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";

import { E, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { type FeatureU, roadShows } from "@scripts/generated/domaintables/featureFlags";
import type { BondOffering } from "@scripts/generated/models/bondOfferingBase";
import type { BondProgram } from "@scripts/generated/models/bondProgramBase";
import type { BondProgramWithRatings } from "@scripts/generated/models/bondProgramWithRatings";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { ExternalLink } from "@scripts/generated/models/externalLink";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Media } from "@scripts/generated/models/media";
import type { Project } from "@scripts/generated/models/project";
import type { RelatedExternalLinkLink, RelatedOfferingLink, RelatedProgramLink, RelatedProjectLink, RelatedRfpLink, RelatedRoadShowLink } from "@scripts/generated/models/relatedContent";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { HasManyLink, WithId, WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import type { ReactChild } from "@scripts/react/syntax/react";
import { klass, type KlassProp } from "@scripts/react/util/classnames";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { parentIdOrId } from "@scripts/syntax/threadThrough";
import { delProp } from "@scripts/util/delProp";
import { prop } from "@scripts/util/prop";

import bondIcon from "@svgs/bond.svg";
import projectIcon from "@svgs/construction.svg";
import documentIcon from "@svgs/document.svg";
import linkIcon from "@svgs/link.svg";
import programIcon from "@svgs/program-tree.svg";
import rfpIcon from "@svgs/rfp.svg";

import { AnchorIcon, AnchorIconUnsafe } from "../Anchor";
import { mapOrEmpty, trueOrEmpty } from "../Empty";
import { AccentDividerSection, DividerSection, ModalSection, type SectionProps } from "../layout/Section";
import { type LeafIconAsProp, type LeafIconBaseProps } from "../LeafIcon";
import { RoadshowCardGrid } from "../RoadshowCardGrid";
import { ShowHideToggle } from "../ShowMore";

export const LeafIconLayout = (props: LeafIconAsProp & { leafIconProps: LeafIconBaseProps, text: ReactChild }) => <>
  <span {...klass("mr-025")}>{props.text}</span><props.leafIcon {...props.leafIconProps} />
</>;

export const makeRelatedContentDataO = <A,>(
  ff: FeatureU,
  iffs: ClientFeatureFlags,
  items: ReadonlyArray<A>,
): O.Option<RNEA.ReadonlyNonEmptyArray<A>> => pipe(
  RNEA.fromReadonlyArray(items),
  O.filter(() => isFFEnabled(ff)(iffs))
);

export type RelatedContentItemVariant = "modal" | "page";

type RelatedContentItemProps<A> = {
  klasses?: KlassProp;
  headline: string;
  items: RNEA.ReadonlyNonEmptyArray<A>;
  mapFn: (_: A, issuer: Issuer) => ReactNode;
  issuer: Issuer;
  variant: RelatedContentItemVariant;
};

export const RelatedContentList = <A,>(props: Pick<RelatedContentItemProps<A>, "items" | "headline" | "mapFn" | "issuer">) =>
  <div {...klass("d-flex", "flex-col", "gap-1")}>
    {pipe(
      props.items,
      RA.mapWithIndex((i, _) =>
        <div key={`${props.headline}-${i}`}>
          {props.mapFn(_, props.issuer)}
        </div>
      )
    )}
  </div>;

export const RelatedContentListTruncated = <A,>(props: Pick<RelatedContentItemProps<A>, "items" | "headline" | "mapFn" | "issuer"> & { truncateLength: number }) => {
  const [showMore, setShowMore] = useState(false);
  const items: ReadonlyNonEmptyArray<A> = showMore ? props.items : RNEA.firstNRaw(props.truncateLength)(props.items);
  return <>
    <RelatedContentList {...props} items={items} />
    {props.items.length > props.truncateLength && <div {...klass("mt-1")}>
      <ShowHideToggle isShowingMore={showMore} setIsShowingMore={setShowMore} text={{ show: "Show all", hide: "Show less" }} appendIcon />
    </div>
    }
  </>;
};

export const RelatedContentItem = <A,>(props: RelatedContentItemProps<A> & Pick<SectionProps, "sectionId">) => {
  const Section = props.variant === "modal" ? ModalSection : DividerSection;
  return <Section
    klasses={props.klasses}
    title={O.some(props.headline)}
    sectionId={props.sectionId}
  >
    <RelatedContentList {...delProp(props, "sectionId")} />
  </Section>;
};

export type DocumentDownloadRoute = (issuerId: number, documentId: number) => UrlInterface<"GET">;
export type DocumentRelatedContent = WithStatusU<TaggedContent<Media>>;
export const documentMap = (downloadRoute: DocumentDownloadRoute, leafIcon: LeafIconAsProp["leafIcon"]) => (_: DocumentRelatedContent, issuer: Issuer) =>
  <AnchorIcon
    textOrAriaLabel={E.left(<LeafIconLayout leafIcon={leafIcon} text={_.data.record.data.uploadResponse.viewName} leafIconProps={{ taggedContent: _.data.record }} />)}
    icon={documentIcon}
    route={{
      title: _.data.record.data.uploadResponse.viewName,
      route: downloadRoute(issuer.id, parentIdOrId(_)),
    }}
    target={"_blank"}
  />;

export const DocumentsRelatedContent = (props: LeafIconAsProp & {
  documents: RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>;
  headline: string;
  issuer: Issuer;
  downloadRoute: DocumentDownloadRoute;
  variant: RelatedContentItemVariant;
  sectionId?: string;
}) => {
  return <RelatedContentItem
    headline={props.headline}
    items={props.documents}
    mapFn={documentMap(props.downloadRoute, props.leafIcon)}
    issuer={props.issuer}
    variant={props.variant}
    sectionId={props.sectionId}
  />;
};

export type LinkRelatedContent = HasManyLink<WithStatusU<ExternalLink>, RelatedExternalLinkLink>;
const linkMap = (_: LinkRelatedContent) =>
  <AnchorIconUnsafe
    textOrAriaLabel={E.left(_.data.data.record.text)}
    target={"_blank"}
    icon={linkIcon}
    href={_.data.data.record.url}
  />;

export const LinksRelatedContent = (props: { links: RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>, issuer: Issuer, variant: RelatedContentItemVariant }) => {
  return <RelatedContentItem
    headline={"Links"}
    items={props.links}
    mapFn={linkMap}
    issuer={props.issuer}
    variant={props.variant}
  />;
};

export type RoadshowRelatedContent = HasManyLink<WithId<TaggedContent<RoadShowData>>, RelatedRoadShowLink>;
export const parseRoadshows = (
  hasManyLinkRoadshows: ReadonlyArray<RoadshowRelatedContent>
): ReadonlyArray<RoadshowRelatedContent["data"]> => pipe(
  hasManyLinkRoadshows,
  RA.map(prop("data"))
);
export const makeRoadshowDataO = (iffs: ClientFeatureFlags, roadshows: ReadonlyArray<RoadshowRelatedContent["data"]>) =>
  makeRelatedContentDataO(roadShows, iffs, roadshows);

export const roadshowsSectionTitle = "Roadshows";
export const RoadshowsSection = (props: LeafIconAsProp & {
  issuer: Issuer;
  roadshows: RNEA.ReadonlyNonEmptyArray<WithId<TaggedContent<RoadShowData>>>;
  sidebarLinkHandle?: string;
}) => {
  return (
    <AccentDividerSection
      sectionId={props.sidebarLinkHandle}
      title={O.some(roadshowsSectionTitle)}
    >
      <RoadshowCardGrid
        issuer={props.issuer}
        leafIcon={props.leafIcon}
        roadshows={props.roadshows}
      />
    </AccentDividerSection>
  );
};


export const SummaryRelatedContent = (props: LeafIconAsProp & {
  documentsHeadline: string;
  documentDownloadRoute: DocumentDownloadRoute;
  issuer: Issuer;
  documentsO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  linksO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  variant: RelatedContentItemVariant;
  sectionId?: string;
}) => {

  return pipe(
    (O.isSome(props.documentsO) || O.isSome(props.linksO)),
    trueOrEmpty(
      <>
        {pipe(
          props.documentsO,
          mapOrEmpty(ds =>
            <DocumentsRelatedContent
              documents={ds}
              headline={props.documentsHeadline}
              issuer={props.issuer}
              downloadRoute={props.documentDownloadRoute}
              variant={props.variant}
              leafIcon={props.leafIcon}
              sectionId={props.sectionId}
            />
          )
        )}
        {pipe(
          props.linksO,
          mapOrEmpty(ls => <LinksRelatedContent links={ls} issuer={props.issuer} variant={props.variant} />)
        )}
      </>
    )
  );
};


type BondRelatedContent = HasManyLink<WithStatusU<TaggedContent<BondOffering>>, RelatedOfferingLink>;
const bondsMap = (leafIcon: LeafIconAsProp["leafIcon"]) => (_: BondRelatedContent, issuer: Issuer) =>
  <AnchorIcon
    textOrAriaLabel={E.left(<LeafIconLayout leafIcon={leafIcon} text={_.data.data.record.data.name} leafIconProps={{ taggedContent: _.data.data.record }} />)}
    icon={bondIcon}
    route={{
      title: _.data.data.record.data.name,
      route: SitesRouter.issuersitesBondOfferingsControllerOffering({ issuerSlug: issuer.slug, issuerId: issuer.id, offeringId: _.link.record.offeringId }),
    }}
    target={"_self"}
  />;

export const BondsRelatedContent = (props: LeafIconAsProp & { bonds: RNEA.ReadonlyNonEmptyArray<BondRelatedContent>, issuer: Issuer, variant: RelatedContentItemVariant }) => {
  return <RelatedContentItem
    headline={"Bonds"}
    items={props.bonds}
    mapFn={bondsMap(props.leafIcon)}
    issuer={props.issuer}
    variant={props.variant}
  />;
};

type RfpRelatedContent = HasManyLink<WithStatusU<TaggedContent<Rfp>>, RelatedRfpLink>;
const rfpsMap = (leafIcon: LeafIconAsProp["leafIcon"]) => (_: RfpRelatedContent, issuer: Issuer) =>
  <AnchorIcon
    textOrAriaLabel={E.left(<LeafIconLayout leafIcon={leafIcon} text={_.data.data.record.data.name} leafIconProps={{ taggedContent: _.data.data.record }} />)}
    icon={rfpIcon}
    route={{
      title: _.data.data.record.data.name,
      route: SitesRouter.issuersitesRfpsControllerRfp({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: _.link.record.rfpId }),
    }}
    target={"_self"}
  />;

export const RfpsRelatedContent = (props: LeafIconAsProp & { rfps: RNEA.ReadonlyNonEmptyArray<RfpRelatedContent>, rfpsTitle: string, issuer: Issuer, variant: RelatedContentItemVariant }) => {
  return <RelatedContentItem
    headline={props.rfpsTitle}
    items={props.rfps}
    mapFn={rfpsMap(props.leafIcon)}
    issuer={props.issuer}
    variant={props.variant}
  />;
};

export type ProjectRelatedContent = HasManyLink<WithStatusU<TaggedContent<Project>>, RelatedProjectLink>;
const projectsMap = (leafIcon: LeafIconAsProp["leafIcon"]) => (_: ProjectRelatedContent, issuer: Issuer) =>
  <AnchorIcon
    textOrAriaLabel={E.left(<LeafIconLayout leafIcon={leafIcon} text={_.data.data.record.data.projectTitle} leafIconProps={{ taggedContent: _.data.data.record }} />)}
    icon={projectIcon}
    route={{
      title: _.data.data.record.data.projectTitle,
      route: SitesRouter.issuersitesAboutControllerProjectItem({ issuerSlug: issuer.slug, issuerId: issuer.id, projectId: _.link.record.projectId }),
    }}
    target={"_self"}
  />;

export const ProjectsRelatedContent = (props: LeafIconAsProp & { projects: RNEA.ReadonlyNonEmptyArray<ProjectRelatedContent>, issuer: Issuer, variant: RelatedContentItemVariant }) => {
  return <RelatedContentItem
    headline={"Projects"}
    items={props.projects}
    mapFn={projectsMap(props.leafIcon)}
    issuer={props.issuer}
    variant={props.variant}
  />;
};

export type ProgramsRelatedContent = HasManyLink<WithStatusU<BondProgram>, RelatedProgramLink>;
const programsMap = (_: ProgramsRelatedContent, issuer: Issuer) =>
  <AnchorIcon
    icon={programIcon}
    route={{
      title: _.data.data.record.name,
      route: SitesRouter.issuersitesBondProgramsControllerBondProgram({ issuerSlug: issuer.slug, issuerId: issuer.id, programId: _.data.data.id }),
    }}
    target={"_self"}
    textOrAriaLabel={E.left(_.data.data.record.name)}
  />;

export const ProgramsRelatedContent = (props: { programs: RNEA.ReadonlyNonEmptyArray<ProgramsRelatedContent>, issuer: Issuer, variant: RelatedContentItemVariant }) => {
  return <RelatedContentItem
    headline={"Programs"}
    items={props.programs}
    mapFn={programsMap}
    issuer={props.issuer}
    variant={props.variant}
  />;
};

const mapProgramWithRating = (program: WithStatusU<BondProgramWithRatings>, issuer: Issuer) =>
  <AnchorIcon
    icon={programIcon}
    route={{
      title: program.data.record.program.name,
      route: SitesRouter.issuersitesBondProgramsControllerBondProgram({ issuerSlug: issuer.slug, issuerId: issuer.id, programId: program.data.id }),
    }}
    target={"_self"}
    textOrAriaLabel={E.left(program.data.record.program.name)}
  />;

export const ProgramWithRatingRelatedContent = (props: { program: WithStatusU<BondProgramWithRatings>, issuer: Issuer, variant: RelatedContentItemVariant }) =>
  <RelatedContentItem
    headline={"Program"}
    items={[props.program]}
    mapFn={mapProgramWithRating}
    issuer={props.issuer}
    variant={props.variant}
  />;
