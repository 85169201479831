import { type PropsWithChildren, useMemo, useState } from "react";
import type { Lazy } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { SVGString } from "@*.svg";

import type { MarkdownTag } from "@scripts/codecs/markdown";
import { RA, RNEA } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { Officer } from "@scripts/generated/models/officer";
import type { ProjectWithPhotosAndQuickFacts } from "@scripts/generated/models/project";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { fullName } from "@scripts/syntax/fullName";

import construction from "@svgs/construction.svg";
import person from "@svgs/person.svg";

import { klass } from "../util/classnames";
import { type OpenModalFn, useModal } from "../util/useModal";
import { AnchorMailTo } from "./Anchor";
import { ButtonLinkMultiline } from "./Button";
import { mapOrEmpty } from "./Empty";
import { FactsRow } from "./Facts";
import { Image } from "./Image";
import { ImageCarouselModal } from "./ImageCarouselModal";
import { Grid, GridCol } from "./layout/Grid";
import { Markdown } from "./Markdown";
import { Modal } from "./modal/Modal";
import { SidebarSection } from "./SidebarSection";
import { Svg } from "./Svg";
import type { TabRowItem } from "./TabRow";
import { TabRow } from "./TabRow";

const ModalLink = (props: {
  svg: SVGString;
  openModal: OpenModalFn;
  onClickEffect: Lazy<void>;
  content: string;
  subcontent: O.Option<string>;
}) => <div {...klass("d-flex")}>
    <Svg {...klass("accent-1-700", "mr-025")} src={props.svg} />
    <div {...klass("d-flex", "flex-col", "align-items-start")}>
      <ButtonLinkMultiline
        {...klass("modal-button", "text-left", "bt-0", "font-sans-normal-500")}
        onClick={() => { props.onClickEffect(); props.openModal(); }}
        text={props.content}
      />
      {mapOrEmpty((s: string) => <span {...klass("gray-800")}>{s}</span>)(props.subcontent)}
    </div>
  </div>;

const TeamContent = (props: { officer: WithStatusU<Officer> }) => {
  const { title, phoneNumber, email, blurb, media } = props.officer.data.record;
  const full = fullName(props.officer.data.record);
  return <>
    {mapOrEmpty((src: Media) => <div {...klass("float-sm-right", "pl-1", "mb-1", "w-40", "w-md-35")}>
      <Image
        alt={O.getOrElse(() => `Image of ${full}`)(src.altText)}
        src={src.uploadResponse.uri}
      />
    </div>)(media)}
    <h2 {...klass("mt-0", "mb-05")}>{full}</h2>
    <div>
      <h3 {...klass("m-0")}>{title}</h3>
      {mapOrEmpty((p: string) => <div>{p}</div>)(phoneNumber)}
      {mapOrEmpty((emailAddress: string) => <AnchorMailTo email={emailAddress} />)(email)}
    </div>
    {mapOrEmpty((b: MarkdownTag) => <Markdown content={b} />)(blurb)}
  </>;
};

export type TeamSidebarSectionProps = {
  team: RNEA.ReadonlyNonEmptyArray<WithStatusU<Officer>>;
};
export const TeamSidebarSection = (props: TeamSidebarSectionProps) => {
  const [currentTab, setCurrentTab] = useState(RNEA.head(props.team).data.id);
  const [isOpen, open, close] = useModal("project-modal");

  const officers = useMemo(() => RNEA.map((officer: WithStatusU<Officer>) => ({
    title: `${officer.data.record.firstName} ${officer.data.record.lastName}`,
    subtitle: officer.data.record.title,
    value: officer.data.id,
    onClick: () => setCurrentTab(officer.data.id),
    icon: O.none,
  }))(props.team), [props.team]);

  const currentOfficer = useMemo(() => pipe(
    props.team,
    RA.findFirst((officer) => officer.data.id === currentTab),
    mapOrEmpty((info) => <TeamContent officer={info} />)
  ), [props.team, currentTab]);

  return <>
    <Modal
      id={"teamModal"}
      dismissAction={close}
      title={"Team"}
      icon={O.some(person)}
      type={"primary"}
      open={isOpen}
      body={<ModalContentWithTabRow items={officers} current={currentTab}>
        {currentOfficer}
      </ModalContentWithTabRow>}
      size={"modal-lg"}
    />
    <SidebarSection
      imageElement={O.none}
      subtitle={O.none}
      text={O.some("Click someone’s name below to see their bio and contact information.")}
      title={"Team"}
    >
      {props.team.map((officer) => <ModalLink
        key={officer.data.id}
        svg={person}
        content={fullName(officer.data.record)}
        subcontent={O.some(officer.data.record.title)}
        onClickEffect={() => setCurrentTab(officer.data.id)}
        openModal={open}
      />)}
    </SidebarSection>
  </>;
};

const ModalContentWithTabRow = <A extends PropertyKey>(props: PropsWithChildren<{
  current: A;
  items: RNEA.ReadonlyNonEmptyArray<TabRowItem<A>>;
}>) => <Grid attrs={O.none} klasses={O.none}>
    <GridCol cols={[".c-24", ".c-md-17"]} klasses={O.some("pr-0 pr-md-15")}>
      {props.children}
    </GridCol>
    <hr {...klass("divider", "mb-15", "d-md-none")} />
    <GridCol cols={[".c-24", ".c-md-7"]} klasses={O.none}>
      <TabRow
        orientation={"vertical"}
        isSm={false}
        items={props.items}
        current={props.current}
        variant={"default"}
      />
    </GridCol>
  </Grid>;

const ProjectContent = (props: { project: WithStatusU<TaggedContent<ProjectWithPhotosAndQuickFacts>> }) => <div {...klass("d-flex", "flex-col")} >
  <h2 {...klass("mt-0", "mb-1")}>{props.project.data.record.data.project.projectTitle}</h2>
  {pipe(
    props.project.data.record.data.quickFacts,
    RNEA.fromReadonlyArray,
    mapOrEmpty(_ => <div {...klass("mb-1")}>
      <FactsRow variant={"small"} items={_.map(f => ({ title: f.data.data.record.fieldHeader, value: f.data.data.record.fieldValue }))} />
    </div>)
  )}
  <div>
    {pipe(
      props.project.data.record.data.photos,
      RA.map((image) => image.data.data.record),
      RNEA.fromReadonlyArray,
      mapOrEmpty((images) => <div {...klass("float-sm-right", "ml-md-1", "w-100", "w-md-50", "px-1", "px-md-0")}>
        <ImageCarouselModal images={images} />
      </div>)
    )}
    <Markdown content={props.project.data.record.data.project.projectText} />
  </div>
</div>;

export type ProjectSidebarSectionProps = { projects: RNEA.ReadonlyNonEmptyArray<WithStatusU<TaggedContent<ProjectWithPhotosAndQuickFacts>>> };
export const ProjectSidebarSection = (props: ProjectSidebarSectionProps) => {

  const [currentProjectId, setCurrentProjectId] = useState(RNEA.head(props.projects).data.id);
  const [isOpen, open, close] = useModal("project-modal");

  const projects = useMemo(() => RNEA.map((v: WithStatusU<TaggedContent<ProjectWithPhotosAndQuickFacts>>) => ({
    title: v.data.record.data.project.projectTitle,
    value: v.data.id,
    onClick: () => setCurrentProjectId(v.data.id),
    icon: O.none,
  }))(props.projects), [props.projects]);

  const currentProject = useMemo(() => pipe(
    props.projects,
    RA.findFirst((p) => p.data.id === currentProjectId),
    mapOrEmpty(p => <ProjectContent project={p} />)
  ), [props.projects, currentProjectId]);

  return <>
    <Modal
      id={"ProjectModal"}
      dismissAction={close}
      title={"Projects"}
      icon={O.some(construction)}
      type={"primary"}
      open={isOpen}
      body={<ModalContentWithTabRow items={projects} current={currentProjectId}>
        {currentProject}
      </ModalContentWithTabRow>}
      size={"modal-lg"}
    />
    <SidebarSection
      imageElement={O.none}
      subtitle={O.none}
      text={O.some("Learn more about our projects by clicking on the project names below.")}
      title={"Projects"}
    >
      {props.projects.map((s) => <ModalLink
        key={s.data.id}
        svg={construction}
        content={s.data.record.data.project.projectTitle}
        subcontent={O.none}
        onClickEffect={() => setCurrentProjectId(s.data.id)}
        openModal={open}
      />)}
    </SidebarSection>
  </>;
};
