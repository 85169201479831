import { O } from "@scripts/fp-ts";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import type { HasTag } from "@scripts/util/codecTypeGuards";

export const makeJumplink = (anchorContent: string, link: string): JumpLink => ({
  anchorContent,
  link: `#${link}`,
});

export const makeJumplinkO = (
  anchorContent: string,
  link: string,
  show: boolean
): O.Option<JumpLink> =>
  O.fromPredicate(() => show)(makeJumplink(anchorContent, link));

export const makePageHeaderSideBarLinkHandle = <X extends HasTag>(x: X) => `anchor-${x._tag}`;
