import { pipe } from "fp-ts/lib/function";

import { E, O, RA, RNEA } from "@scripts/fp-ts";
import { bidAwardedC } from "@scripts/generated/domaintables/biddingStates";
import type { BondProgramWithRatings } from "@scripts/generated/models/bondProgramWithRatings";
import type { RfpRelatedContent, RfpWithRelatedContent } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { FactsColumn, FactsRow, type FactType, makeFactO } from "@scripts/react/components/Facts";
import { Faq } from "@scripts/react/components/Faq";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { type DocumentRelatedContent, type LinkRelatedContent, ProgramWithRatingRelatedContent, SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { klass } from "@scripts/react/util/classnames";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { isQuestionAndAnswerAvailible, isQuestionsAndClarificationsAvailble } from "@scripts/syntax/rfp";
import { currency } from "@scripts/util/currency";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";

const makeRowFacts = (rfp: Rfp) => RA.compact([
  makeFactO("Total Loan Amount", rfp.loanAmount, currency),
  makeFactO("Service Type", rfp.typeOfServicing, st => st.name),
  makeFactO("Bids Due", rfp.bidsDue, E.fold(dateWithOptionalTimeToString(humanDateFull), _ => _.format)),
]);
const makeColumnFacts = (rfp: Rfp) => RA.compact<FactType>([
  makeFactO("Listing Date", rfp.dateListed, humanDateFull),
  makeFactO("Deadline for Questions", rfp.deadlineForQuestions, dateWithOptionalTimeToString(humanDateFull)),
  makeFactO(`${rfp.awardDateTentative ? "Tentative " : ""}Award Date`, rfp.awardDate, humanDateFull),
  makeFactO(`${rfp.contractStartDateTentative ? "Tentative " : ""}Contract Start Date`, rfp.contractStartDate, humanDateFull),
]);

export const rfpSummarySectionTitle = "Summary";
export const RfpSummary = (props: {
  rfp: Subscribed<WithStatusU<TaggedContent<RfpWithRelatedContent>>>;
  documentsO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  linksO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  programsO: O.Option<WithStatusU<BondProgramWithRatings>>;
  sidebarLinkHandle: string;
}) => {
  const rfp = props.rfp.data.data.record.data.rfp;
  const issuer = useIssuerSitesSelector("issuer");
  const rowFacts = makeRowFacts(rfp);
  const columnFacts = makeColumnFacts(rfp);

  return (
    <AccentDividerSection
      klasses={["accent-border-top"]}
      sectionId={props.sidebarLinkHandle}
      title={O.some(rfpSummarySectionTitle)}
    >
      <DividerSection title={O.none}>
        <div {...klass("mb-1")}>
          <FactsRow items={rowFacts} variant={"bold"} />
        </div>
        <div>
          <FactsColumn
            items={[
              { title: "Sector", value: rfp.sector.name },
              { title: "State", value: rfp.state.name },
              ...columnFacts,
            ]}
          />
        </div>
      </DividerSection>
      {pipe(
        rfp.projectDescription,
        mapOrEmpty(_ =>
          <DividerSection
            title={O.some("Project Description")}
          >
            <Markdown content={_} klasses={["last-child-mb-0"]} />
          </DividerSection>
        ))}
      {pipe(
        rfp.submissionRequirements,
        mapOrEmpty(_ =>
          pipe(
            rfp.biddingState.name !== "BidAwarded",
            trueOrEmpty(
              <DividerSection
                klasses={["bt-0125", "pt-1"]}
                title={O.some("Submission Requirements")}
              >
                <Markdown content={_} klasses={["last-child-mb-0"]} />
              </DividerSection>
            )
          )
        ))
      }
      <SummaryRelatedContent
        documentsO={props.documentsO}
        documentsHeadline="Documents"
        documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: props.rfp.data.data.id, mediaId })}
        issuer={issuer}
        linksO={props.linksO}
        variant="page"
        leafIcon={IssuerSitesLeafIcon}
      />
      {pipe(
        props.programsO,
        mapOrEmpty(_ =>
          <ProgramWithRatingRelatedContent
            program={_}
            issuer={issuer}
            variant={"page"}
          />
        )
      )}
    </AccentDividerSection>
  );
};

export const makeWinningBidFacts = (rfp: Rfp) => RA.compact([
  makeFactO("Rate", rfp.winningBidRate, r => `${r}%`),
  makeFactO("Net Interest Cost (NIC)", rfp.winningBidNetInterestCost, currency),
  makeFactO("Date Bid Recieved", rfp.winningBidReceived, humanDateFull),
]);

export const showWinningBidSection = (rfp: Rfp, winningBidFacts: ReadonlyArray<FactType>) =>
  bidAwardedC.is(rfp.biddingState)
  && rfp.showWinningBid
  && (O.isSome(rfp.winningBidCompany) || RA.isNonEmpty(winningBidFacts) || O.isSome(rfp.winningBidAdditionalInfo));

export const winningBidSectionTitle = "Winning Bid";
export const WinningBidSection = (props: {
  facts: ReadonlyArray<FactType>;
  rfp: Rfp;
  sidebarLinkHandle: string;
}) =>
  <AccentDividerSection sectionId={props.sidebarLinkHandle} title={O.some(winningBidSectionTitle)}>
    {pipe(props.rfp.winningBidCompany, mapOrEmpty(_ => <h5>{_}</h5>))}
    {pipe(
      RA.isNonEmpty(props.facts),
      trueOrEmpty(
        <div {...klass("mt-1")} >
          <FactsRow
            variant={"accent"}
            items={props.facts}
          />
        </div>
      )
    )}
    {pipe(
      props.rfp.winningBidAdditionalInfo,
      mapOrEmpty(_ => <Markdown content={_} klasses={["last-child-mb-0", "mt-1"]} />)
    )}
  </AccentDividerSection>;

const RfpQuestionAndAnswer = (props: { faqs: RfpRelatedContent["faqs"], isLastChild: boolean }) => pipe(
  props.faqs,
  RNEA.fromReadonlyArray,
  mapOrEmpty(faqs =>
    <DividerSection title={O.none} klasses="top-divider">
      {faqs.map(faq =>
        <Faq
          faq={faq.data.data.record}
          key={faq.data.data.id}
          klass={O.none}
        />
      )}
    </DividerSection>
  )
);

type QuestionAndAnswerPropsBase = {
  titleSingular: string;
  rfpWithRelatedContent: RfpWithRelatedContent;
  rfpId: number;
  sidebarLinkHandle?: string;
};

export const questionAndAnswerSectionTitle = "Questions & Clarifications";
export const QuestionAndAnswerSectionBase = (props: QuestionAndAnswerPropsBase & { showHaveAQuestionSection: boolean }) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");

  return (
    <AccentDividerSection
      sectionId={props.sidebarLinkHandle}
      title={O.some(questionAndAnswerSectionTitle)}
    >
      <RfpQuestionAndAnswer
        faqs={props.rfpWithRelatedContent.relatedContent.faqs}
        isLastChild={!props.showHaveAQuestionSection}
      />
      {pipe(
        props.showHaveAQuestionSection,
        trueOrEmpty(
          <DividerSection title={O.none} klasses="mt-1">
            <h3 className="font-sans-normal-500 mt-0 mb-1">Have a question?</h3>
            <p className="mb-1">Contact us if you need more information or have questions about this {props.titleSingular}.</p>
            <ContactModalCallout
              bondOfferingId={O.none}
              btnText="Contact Us"
              header="Contact Us"
              introText={<>Use this form to ask questions or get more information from <strong>{issuer.name}</strong> about “<strong>{props.rfpWithRelatedContent.rfp.name}</strong>.”</>}
              issuer={issuer}
              klasses={"mt-0"}
              rfpId={O.some(props.rfpId)}
              user={O.map((withRoles: UserWithRoles) => withRoles.user)(user)}
            />
          </DividerSection>
        ))}
    </AccentDividerSection>
  );
};

export const QuestionAndAnswerSection = (props: QuestionAndAnswerPropsBase) => {
  const showHaveAQuestionSection = isQuestionAndAnswerAvailible(props.rfpWithRelatedContent.rfp);
  const showQuestionsAndClarificationsSection = isQuestionsAndClarificationsAvailble(props.rfpWithRelatedContent.rfp, props.rfpWithRelatedContent.relatedContent.faqs);

  return trueOrEmpty(
    <QuestionAndAnswerSectionBase
      {...props}
      showHaveAQuestionSection={showHaveAQuestionSection}
    />
  )(showQuestionsAndClarificationsSection);
};
