import { type ReactElement } from "react";

import { O, pipe, RNEA } from "@scripts/fp-ts";
import type { BankOfferingData } from "@scripts/generated/models/bondOffering";
import type { Issuer } from "@scripts/generated/models/issuer";
import { mapOrEmpty, toEmpty } from "@scripts/react/components/Empty";
import { Grid } from "@scripts/react/components/layout/Grid";
import { MainGridLeft, SideBarGridRightWithBorder } from "@scripts/react/components/layout/PageGrid";
import type { LeafIconAsProp } from "@scripts/react/components/LeafIcon";
import { Markdown } from "@scripts/react/components/Markdown";
import { Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { RatingsSection } from "@scripts/react/components/offering-pages/Ratings";
import { type RoadshowRelatedContent, RoadshowsSection } from "@scripts/react/components/offering-pages/RelatedContent";
import { SidebarAboutSection } from "@scripts/react/components/SidebarAbout";
import { ProjectSidebarSection, TeamSidebarSection } from "@scripts/react/components/SidebarModalLinks";
import { relatedRatingsToCommonRatings } from "@scripts/syntax/rating";

type BankOfferingContentProps = LeafIconAsProp & {
  header: O.Option<ReactElement>;
  issuer: Issuer;
  offeringData: BankOfferingData;
  offeringSummary: ReactElement;
  roadshowsDataO: O.Option<RNEA.ReadonlyNonEmptyArray<RoadshowRelatedContent["data"]>>;
};

export const BankOfferingContent = (props: BankOfferingContentProps) => {
  return (
    <Grid klasses={[]} attrs={O.none}>
      <MainGridLeft>
        {pipe(props.header, toEmpty)}
        {props.offeringSummary}
        {pipe(
          relatedRatingsToCommonRatings(props.offeringData.offering.data.data.record.data.relatedContent.ratings),
          ratings => <RatingsSection
            bondRatings={ratings}
            program={props.offeringData.offering.data.data.record.data.relatedContent.program}
            issuer={props.issuer}
            isBLP
          />
        )}
        {pipe(
          props.roadshowsDataO,
          mapOrEmpty(roadshows =>
            <RoadshowsSection
              issuer={props.issuer}
              leafIcon={props.leafIcon}
              roadshows={roadshows}
            />
          )
        )}
        {pipe(
          RNEA.fromReadonlyArray(props.offeringData.participants),
          mapOrEmpty(offeringParticipants =>
            <Participants
              title="Offering Participants"
              participants={offeringParticipants}
            />
          )
        )}
      </MainGridLeft>
      <SideBarGridRightWithBorder>
        {pipe(
          props.offeringData.issuerAboutText,
          mapOrEmpty(issuerAboutText =>
            <SidebarAboutSection issuer={props.issuer}>
              <Markdown content={issuerAboutText.data} />
            </SidebarAboutSection>
          )
        )}
        {pipe(
          RNEA.fromReadonlyArray(props.offeringData.officers),
          mapOrEmpty(team => <TeamSidebarSection team={team} />)
        )}
        {pipe(
          RNEA.fromReadonlyArray(props.offeringData.projects),
          mapOrEmpty(projects => <ProjectSidebarSection projects={projects} />)
        )}
      </SideBarGridRightWithBorder>
    </Grid>
  );
};
