import { WithStatusCU as imported273_WithStatusCU, WithStatusCU as imported268_WithStatusCU, WithStatusCU as imported272_WithStatusCU, hasManyLinkC as imported119_hasManyLinkC, hasManyLinkC as imported117_hasManyLinkC, hasManyLinkC as imported113_hasManyLinkC, hasManyLinkC as imported116_hasManyLinkC, hasManyLinkC as imported115_hasManyLinkC, WithStatusCU as imported271_WithStatusCU, WithStatusCU as imported266_WithStatusCU, hasManyLinkC as imported114_hasManyLinkC, hasManyLinkC as imported120_hasManyLinkC, WithStatusCU as imported267_WithStatusCU, WithStatusCU as imported265_WithStatusCU, WithStatusCU as imported269_WithStatusCU, WithStatusCU as imported270_WithStatusCU, hasManyLinkC as imported118_hasManyLinkC } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { programRatingC as imported14_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported36_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { externalLinkC as imported18_externalLinkC, externalLinkC as imported19_externalLinkC } from "./externalLink";
import { projectC as imported16_projectC } from "./project";
import { mediaC as imported70_mediaC, mediaC as imported71_mediaC, imageUploadResponseC as imported7_imageUploadResponseC, mediaUploadResponseWithCategoryC as imported11_mediaUploadResponseWithCategoryC } from "./media";
import { taggedContentC as imported167_taggedContentC, taggedContentC as imported166_taggedContentC, taggedContentC as imported168_taggedContentC } from "./taggedContent";
import { quickFactC as imported9_quickFactC, quickFactC as imported10_quickFactC } from "./quickFact";
import { bondProgramC as imported20_bondProgramC } from "./bondProgramBase";
import { eventC as imported13_eventC } from "./event";
import { sortOrderC as imported28_sortOrderC, sortOrderC as imported29_sortOrderC, sortOrderC as imported30_sortOrderC, sortOrderC as imported31_sortOrderC } from "./sortOrder";
import { postAttachedC as imported21_postAttachedC, relatedCusip9LinkC as imported10_relatedCusip9LinkC, relatedProjectLinkC as imported20_relatedProjectLinkC, postRelatedC as imported53_postRelatedC, postAttachedC as imported19_postAttachedC, postRelatedC as imported52_postRelatedC, postRelatedAndAttachedC as imported11_postRelatedAndAttachedC, postRelatedC as imported51_postRelatedC, relatedCusip9LinkC as imported11_relatedCusip9LinkC, postAttachedC as imported20_postAttachedC, relatedProjectLinkC as imported21_relatedProjectLinkC, relatedNewsLinkC as imported14_relatedNewsLinkC, relatedDocumentLinkC as imported28_relatedDocumentLinkC, relatedNewsLinkC as imported15_relatedNewsLinkC, relatedPhotoLinkC as imported4_relatedPhotoLinkC, relatedDocumentLinkC as imported29_relatedDocumentLinkC, relatedQuickFactLinkC as imported3_relatedQuickFactLinkC, relatedEventLinkC as imported15_relatedEventLinkC, relatedEventLinkC as imported14_relatedEventLinkC, isAttachedC as imported17_isAttachedC, relatedExternalLinkLinkC as imported9_relatedExternalLinkLinkC, postRelatedC as imported54_postRelatedC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { issuerNewsC as imported15_issuerNewsC } from "./issuerNewsBase";
import { ratingWithRelatedContentC as imported21_ratingWithRelatedContentC } from "./rating";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { cusip9C as imported9_cusip9C } from "./cusip";

export class bondProgramRelatedContentCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  offerings: t.readonlyArray(A1),
  rfps: t.readonlyArray(A2),
  ratings: t.readonlyArray(t.tuple([imported36_RatingAgencyCU, readonlyNonEmptyArrayC(imported265_WithStatusCU(imported21_ratingWithRelatedContentC(imported14_programRatingC)))])),
  photos: t.readonlyArray(imported113_hasManyLinkC(imported266_WithStatusCU(imported70_mediaC), imported4_relatedPhotoLinkC)),
  quickFacts: t.readonlyArray(imported114_hasManyLinkC(imported267_WithStatusCU(imported9_quickFactC), imported3_relatedQuickFactLinkC)),
  documents: t.readonlyArray(imported115_hasManyLinkC(imported268_WithStatusCU(imported166_taggedContentC(imported71_mediaC)), imported17_isAttachedC(imported28_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported116_hasManyLinkC(imported269_WithStatusCU(imported18_externalLinkC), imported9_relatedExternalLinkLinkC)),
  projects: t.readonlyArray(imported117_hasManyLinkC(imported270_WithStatusCU(imported167_taggedContentC(imported16_projectC)), imported20_relatedProjectLinkC)),
  news: t.readonlyArray(imported118_hasManyLinkC(imported271_WithStatusCU(imported168_taggedContentC(imported15_issuerNewsC)), imported14_relatedNewsLinkC)),
  events: t.readonlyArray(imported119_hasManyLinkC(imported272_WithStatusCU(imported13_eventC), imported14_relatedEventLinkC)),
  cusip9s: t.readonlyArray(imported120_hasManyLinkC(imported273_WithStatusCU(imported9_cusip9C), imported10_relatedCusip9LinkC))
})}
export const bondProgramRelatedContentC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new bondProgramRelatedContentCC<A1,A2>().codec(A1,A2);
export type BondProgramRelatedContentC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<bondProgramRelatedContentCC<A1,A2>["codec"]>;
export type BondProgramRelatedContent<A1,A2> = t.TypeOf<BondProgramRelatedContentC<t.Type<A1>,t.Type<A2>>>;

export class bondProgramWithRelatedContentCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  program: imported20_bondProgramC,
  relatedContent: bondProgramRelatedContentC(A1, A2)
})}
export const bondProgramWithRelatedContentC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new bondProgramWithRelatedContentCC<A1,A2>().codec(A1,A2);
export type BondProgramWithRelatedContentC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<bondProgramWithRelatedContentCC<A1,A2>["codec"]>;
export type BondProgramWithRelatedContent<A1,A2> = t.TypeOf<BondProgramWithRelatedContentC<t.Type<A1>,t.Type<A2>>>;

export const bondProgramPostRelatedContentC = t.type({
  photos: imported19_postAttachedC(imported7_imageUploadResponseC, imported28_sortOrderC),
  quickFacts: imported20_postAttachedC(imported10_quickFactC, imported29_sortOrderC),
  documents: imported11_postRelatedAndAttachedC(imported29_relatedDocumentLinkC, imported11_mediaUploadResponseWithCategoryC, imported30_sortOrderC),
  externalLinks: imported21_postAttachedC(imported19_externalLinkC, imported31_sortOrderC),
  projects: imported51_postRelatedC(imported21_relatedProjectLinkC),
  news: imported52_postRelatedC(imported15_relatedNewsLinkC),
  events: imported53_postRelatedC(imported15_relatedEventLinkC),
  cusip9s: imported54_postRelatedC(imported11_relatedCusip9LinkC)
});
export type BondProgramPostRelatedContentC = typeof bondProgramPostRelatedContentC;
export type BondProgramPostRelatedContent = t.TypeOf<BondProgramPostRelatedContentC>;


export const bondProgramPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  overview: optionFromNullable(markdownC),
  cusip6: t.string,
  sectorId: optionFromNullable(t.number),
  sortOrder: t.number,
  relatedContent: bondProgramPostRelatedContentC
});
export type BondProgramPostC = typeof bondProgramPostC;
export type BondProgramPost = t.TypeOf<BondProgramPostC>;


