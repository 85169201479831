import type * as t from "io-ts";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { ratingsSectionTitle } from "@scripts/react/components/offering-pages/Ratings";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import { type IssuerSitesRouteMeta, program, type ProgramPageData, type ProgramPageDataC, rfps } from "@scripts/routes/routing/ssr/issuersites";
import { bondSalesJumplinkId, cusip9sJumplinkId, programOverviewJumplinkId, ratingsJumplinkId, relatedContentJumplinkId, rfpsSectionJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import { DirectSitesPageLayout } from "../../components/DirectSitesPageLayout";
import { HeaderWithoutSubscription } from "../../components/offering-pages/Header";
import { CusipsTable, makeEventsDataO, makeNewsDataO, makeProjectsDataO, RelatedContentSection, relatedContentSectionTitle, SharedRelatedContent } from "../../components/offering-pages/RelatedContent";
import { BondsSection, getNonEmptyItemsRecordO, RfpsSection } from "../../components/related-content/DealSection";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { ProgramOverviewSection, programOverviewSectionTitle } from "./program/Overview";
import { RatingsSection } from "./program/RatingsSection";

const bondsSectionTitle = "Bonds";

const sidebarLinkIds = {
  overview: programOverviewJumplinkId,
  bondSales: bondSalesJumplinkId,
  rfps: rfpsSectionJumplinkId,
  ratings: ratingsJumplinkId,
  cusips: cusip9sJumplinkId,
  relatedContent: relatedContentJumplinkId,
};

const cusip9sSectionTitle = "CUSIP-9s";
const setupSidebarLinkSections = (
  linkIds: typeof sidebarLinkIds,
  show: { [K in keyof typeof sidebarLinkIds]: boolean },
  rfpsTitle: string,
): ReadonlyArray<JumpLink> => RA.compact([
  makeJumplinkO(programOverviewSectionTitle, linkIds.overview, show.overview),
  makeJumplinkO(bondsSectionTitle, linkIds.bondSales, show.bondSales),
  makeJumplinkO(rfpsTitle, linkIds.rfps, show.rfps),
  makeJumplinkO(ratingsSectionTitle, linkIds.ratings, show.ratings),
  makeJumplinkO(cusip9sSectionTitle, linkIds.cusips, show.cusips),
  makeJumplinkO(relatedContentSectionTitle, linkIds.relatedContent, show.relatedContent),
]);

const ProgramSidebarLinks = (props: {
  jumplinks: ReadonlyArray<JumpLink>;
  programRouteMeta: IssuerSitesRouteMeta<ProgramPageData, t.OutputOf<ProgramPageDataC>>;
  program: ProgramPageData;
}) => pipe(
  RNEA.fromReadonlyArray(props.jumplinks),
  mapOrEmpty(jumplinks =>
    <SidebarLinksSites
      headerLinkCustomAnchorContent={O.some(props.program.data.record.program.name)}
      jumpLinks={jumplinks}
      routeMeta={props.programRouteMeta}
    />
  )
);

export const ProgramPage = (props: { programWithRelatedContent: ProgramPageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const programRouteMeta = program({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    programId: props.programWithRelatedContent.data.id,
  });
  const rfpsRouteMeta = rfps({
    issuerId: issuer.id,
    issuerSlug: issuer.slug,
  });
  const rfpsTitle = rfpsRouteMeta.title(pages);

  const documentsDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.externalLinks);
  const bondsDataO = pipe(
    props.programWithRelatedContent.data.record.relatedContent.offerings,
    RA.partition(_ => _.data.data.record.data.offering.isArchived),
    (_) => getNonEmptyItemsRecordO({ active: _.left, archived: _.right })
  );
  const rfpsDataO = pipe(
    props.programWithRelatedContent.data.record.relatedContent.rfps,
    RA.partition(_ => _.data.data.record.data.rfp.biddingState.name === "BidAwarded"),
    (_) => getNonEmptyItemsRecordO({ active: _.left, archived: _.right })
  );
  const cusip9sDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.cusip9s);
  const projectsDataO = makeProjectsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.projects);
  const newsDataO = makeNewsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.news);
  const eventsDataO = makeEventsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.events);
  const ratingsDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.ratings);

  const showOverview = O.isSome(props.programWithRelatedContent.data.record.program.cusip6)
    || O.isSome(props.programWithRelatedContent.data.record.program.sector)
    || RA.isNonEmpty(props.programWithRelatedContent.data.record.relatedContent.quickFacts)
    || O.isSome(documentsDataO)
    || O.isSome(linksDataO)
    || O.isSome(props.programWithRelatedContent.data.record.program.overview)
    || RA.isNonEmpty(props.programWithRelatedContent.data.record.relatedContent.photos);
  const showRelatedContent = O.isSome(projectsDataO) || O.isSome(newsDataO) || O.isSome(eventsDataO);

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      overview: showOverview,
      bondSales: O.isSome(bondsDataO),
      rfps: O.isSome(rfpsDataO),
      ratings: O.isSome(ratingsDataO),
      cusips: O.isSome(cusip9sDataO),
      relatedContent: showRelatedContent,
    },
    rfpsTitle
  );

  return (
    <DirectSitesPageLayout
      headerComponent={
        <HeaderWithoutSubscription
          title={props.programWithRelatedContent.data.record.program.name}
          viewAllRoute={O.some({
            urlInterface: SR.issuersitesBondProgramsControllerBondPrograms({ issuerId: issuer.id, issuerSlug: issuer.slug }),
            itemType: O.none,
          })}
          taggedContent={O.none}
        />
      }
      sidebarContent={
        <>
          <ProgramSidebarLinks
            jumplinks={sidebarLinks}
            program={props.programWithRelatedContent}
            programRouteMeta={programRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("pt-0")}>
        {pipe(
          showOverview,
          trueOrEmpty(
            <ProgramOverviewSection
              documentsDataO={documentsDataO}
              linksDataO={linksDataO}
              program={props.programWithRelatedContent.data.record}
              sectionId={sidebarLinkIds.overview}
            />
          )
        )}
        {pipe(
          bondsDataO,
          mapOrEmpty(items =>
            <BondsSection
              itemName={bondsSectionTitle}
              deals={items}
              sectionId={sidebarLinkIds.bondSales}
            />
          )
        )}
        {pipe(
          rfpsDataO,
          mapOrEmpty(items =>
            <RfpsSection
              deals={items}
              itemName={rfpsTitle}
              sectionId={sidebarLinkIds.rfps}
            />
          )
        )}
        {pipe(
          ratingsDataO,
          mapOrEmpty(ratings =>
            <RatingsSection
              programName={props.programWithRelatedContent.data.record.program.name}
              ratings={ratings}
              sectionId={sidebarLinkIds.ratings}
            />
          )
        )}
        {pipe(
          cusip9sDataO,
          mapOrEmpty(cusips =>
            <AccentDividerSection
              title={O.some(cusip9sSectionTitle)}
              sectionId={sidebarLinkIds.cusips}
            >
              <CusipsTable cusips={cusips} />
            </AccentDividerSection>
          )
        )}
        {pipe(
          showRelatedContent,
          trueOrEmpty(
            <RelatedContentSection sectionId={sidebarLinkIds.relatedContent}>
              <SharedRelatedContent
                events={eventsDataO}
                news={newsDataO}
                projects={projectsDataO}
              />
            </RelatedContentSection>
          )
        )}
      </div>
    </DirectSitesPageLayout>
  );
};
