import type { Action } from "redux";

import type { IssuerSitesActions } from "./store";

const subscriptionActionType = "SUBSCRIPTION_ISSUER_UPDATE";
export const setIsSubscribedToIssuer = (_: boolean) => ({ type: subscriptionActionType, isSubscribedToIssuer: _ });
export type SubscriptionAction = ReturnType<typeof setIsSubscribedToIssuer>;

export function isSubscriptionAction<T extends Action<string>>(action: T | IssuerSitesActions): action is SubscriptionAction {
  return action.type.startsWith("SUBSCRIPTION_");
}
