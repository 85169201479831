/* eslint-disable no-console */
import * as React from "react";
import { createRoot } from "react-dom/client";
import { renderToStaticMarkup } from "react-dom/server";
import { Provider } from "react-redux";
import { useStableMemo } from "fp-ts-react-stable-hooks";
import DOMPurify from "isomorphic-dompurify";
import type { AnyAction, Store } from "redux";
import V from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { isDev } from "@scripts/bondlink";
import type { RespOrErrors } from "@scripts/fetch";
import type { Either } from "@scripts/fp-ts";
import { E, Eq, flow, identity, pipe } from "@scripts/fp-ts";
import type { SafeCallError } from "@scripts/util/dangerousSafeCall";
import { dangerousSafeCall } from "@scripts/util/dangerousSafeCall";
import type { LogErrors } from "@scripts/util/log";
import { isFunction, isNotNull } from "@scripts/util/refinements";

import { ConfigProvider } from "../context/Config";

export type ReactText = string | number;
export type ReactChild = React.ReactElement | ReactText;

/** @internal */
type ErrorOrString = Either<SafeCallError, string>;
/** @internal */
type ServerErrorComponent = React.FC<RespOrErrors>;

export type SetState<S> = React.Dispatch<React.SetStateAction<S>>;
export type StateTuple<S> = [S, SetState<S>];
export type StateStruct<S> = { state: S, setState: SetState<S> };
export type NullableRef<A> = React.MutableRefObject<A | null>;

function noStoreError(e: Error) {
  return { _tag: "No store error" as const, error: e };
}

const isReactText = (v: ReactChild): v is ReactText => {
  return typeof v === "string" || typeof v === "number";
};

const reactStringE = (config: BLConfigWithLog) =>
  (v: ReactChild, renderFn: (element: React.ReactElement) => string): ErrorOrString =>
    isReactText(v)
      ? E.right(v.toString())
      : dangerousSafeCall(noStoreError)(renderFn)(v)(config);

const applyStoreProvider = <S, A extends AnyAction>(e: ReactChild, store?: Store<S, A>) => store ? <Provider store={store}>{e}</Provider> : e;

const applyConfigContext = (config: BLConfigWithLog) => (e: ReactChild) => isReactText(e) ? e : <ConfigProvider value={config}>{e}</ConfigProvider>;

export const getReactMarkupAsString = (config: BLConfigWithLog) => flow(
  applyStoreProvider,
  applyConfigContext(config),
  elem => reactStringE(config)(elem, renderToStaticMarkup),
);

const isSetState = <S,>(u: unknown): u is (s: S) => S => isFunction(u) && u.length === 1;

const stateReducer = <S,>(eq: Eq.Eq<S>) => (prev: S, next: S | ((s: S) => S)) => {
  const nextState = isSetState<S>(next) ? next(prev) : next;
  return eq.equals(prev, nextState) ? prev : nextState;
};

const useStateInitializer = <S,>(init: S | (() => S)) =>
  isFunction(init) ? init() : init;

/**
 * `S` as in `struct`. Useful when you want to initialize
 * some state and reference it without introducing 2 names
 * into the current scope.
 *
 * NOTE: The struct that is returned is stable, so you can
 * safely destructure it in a component's props, or pass it
 * around as an object. As long as you don't spread it
 * when passing it on props, the reference to the original
 * object will remain the same until a new state is sent.
 */
export const useStateS = <S,>(init: S | (() => S), eq: Eq.Eq<S> = Eq.strict): StateStruct<S> => {
  const [state, setState] = React.useReducer(stateReducer(eq), useStateInitializer(init));
  return useStableMemo(() => ({ state, setState }), [state], Eq.tuple(eq));
};

export function dangerouslyGetReactElementAsString<S, A extends AnyAction>(
  config: BLConfigWithLog,
  v: ReactChild,
  store?: Store<S, A>
): ErrorOrString {
  // react-dom/server will complain about any browser specific things such as useLayoutEffect being called among other hooks
  // This searches for any error that has ssr (server side render) mentioned in the error message and prevents them
  // from being logged to the console during development. These errors are not rendered in production mode. -JDL
  if (isDev(config)) {
    // WARNING: Monkey patching console.error!!!! - JDL
    const error = console.error;
    console.error = (...args: Parameters<typeof console.error>) => {
      if (typeof args[0] === "string" && args[0].includes("ssr")) {
        return;
      }
      console.error(...args);
    };

    const errOrStr = getReactMarkupAsString(config)(v, store);

    // Replace console.error - JDL
    console.error = error;

    return errOrStr;
  } else {
    return getReactMarkupAsString(config)(v, store);
  }
}

/**
 * `createElement` is a curried version of `React.createElement` that is
 * useful when you want to build up a React component inside a pipeline,
 * or re-use a "base" function to create several react components that
 * share a piece of functionality more easily.
 *
 * Currently only works with function components (i.e. `React.FC`), as this is
 * the most common use case. If we later need to support others, we can add
 * them here as overloads.
 */
export type CreateElement = <P extends object>(element: React.FC<P>) =>
  (props: P, ...children: React.ReactNode[]) =>
    React.FunctionComponentElement<P>;
export const createElement: CreateElement =
  (element) =>
    (props, ...children) =>
      React.createElement(element, props, ...children);

/** @internal */
export const getRoot = (document: globalThis.Document) => document.getElementById("root")!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
/** @internal A curried version of `ReactDOM.render` that is specialized to function components */
const renderTo = (document: globalThis.Document) =>
  <A,>(element: React.FunctionComponentElement<A>): void =>
    createRoot(getRoot(document)).render(element);

/** @internal */
type RenderErrorsTo = (document: globalThis.Document) => (ErrorComponent: ServerErrorComponent) => (e: LogErrors) => void;
/** @internal */
const renderErrorsTo: RenderErrorsTo = document => ErrorComponent =>
  flow(
    E.right,
    createElement(ErrorComponent),
    renderTo(document)
  );

export type RenderAPI = {
  ok: <A>(element: React.FunctionComponentElement<A>) => void;
  error: (element: ServerErrorComponent) => (e: LogErrors) => void;
};

export const render = (document: globalThis.Document): RenderAPI => ({
  ok: renderTo(document),
  error: renderErrorsTo(document),
});

/**
 * Type predicate that narrows a Ref `current` property to be non-null.

 * @example
 * ```typescript
 * import { isNonNullableRef } from "@scripts/syntax/react"
 *  const maybeStringRef = React.useRef<string | null>(Math.random() > 0.5 ? "hey" | null);
 *
 *  if(isNonNullableRef(maybeStringRef)) {
 *    maybeStringRef.current; // ExpectType: string
 *  }
 * ```
 */
export const isNonNullableRef = <A,>(ref: NullableRef<A>): ref is React.MutableRefObject<NonNullable<A>> => isNotNull(ref.current);

export const refEq = Eq.struct({ current: Eq.strict });

export const reactChildToStringE = <S, A extends AnyAction>(config: BLConfigWithLog, child: ReactChild, store?: Store<S, A>) => pipe(
  dangerouslyGetReactElementAsString(config, child, store),
  E.map(str => pipe(
    DOMPurify.sanitize(str, { ALLOWED_TAGS: [] }),
    V.unescapeHtml,
  ))
);

export const reactChildToString = (config: BLConfigWithLog, child: ReactChild) => pipe(
  reactChildToStringE(config, child),
  E.fold(
    () => "",
    identity
  )
);
