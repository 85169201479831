import * as t from "io-ts";

export const externalLinkC = t.type({
  text: t.string,
  url: t.string
});
export type ExternalLinkC = typeof externalLinkC;
export type ExternalLink = t.TypeOf<ExternalLinkC>;


