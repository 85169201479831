import * as t from "io-ts";
import { withIdC as imported246_withIdC } from "./threadThrough";

export const usersIssuerC = t.type({
  clientId: t.number,
  name: t.string,
  slug: t.string,
  primary: t.boolean
});
export type UsersIssuerC = typeof usersIssuerC;
export type UsersIssuer = t.TypeOf<UsersIssuerC>;


export const usersIssuerListC = t.type({
  issuers: t.readonlyArray(imported246_withIdC(usersIssuerC))
});
export type UsersIssuerListC = typeof usersIssuerListC;
export type UsersIssuerList = t.TypeOf<UsersIssuerListC>;


