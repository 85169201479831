import * as t from "io-ts";
import { rfpWithIssuerC as imported4_rfpWithIssuerC } from "./rfp";
import { subscribedC as imported25_subscribedC } from "./subscribed";
import { StateInfoCU as imported7_StateInfoCU } from "../domaintables/states";
import { bondOfferingDetailsWithIssuerC as imported2_bondOfferingDetailsWithIssuerC } from "./bondOffering";
import { withIdC as imported236_withIdC, withIdC as imported235_withIdC } from "./threadThrough";
import { issuerWithAddressC as imported5_issuerWithAddressC, issuerC as imported47_issuerC } from "./issuer";

export const watchlistIssuerC = t.type({
  issuer: imported25_subscribedC(imported5_issuerWithAddressC),
  offerings: t.number,
  rfps: t.number
});
export type WatchlistIssuerC = typeof watchlistIssuerC;
export type WatchlistIssuer = t.TypeOf<WatchlistIssuerC>;


export const watchlistStateDataC = t.type({
  state: imported7_StateInfoCU,
  issuer: t.readonlyArray(imported47_issuerC),
  offerings: t.readonlyArray(imported235_withIdC(imported2_bondOfferingDetailsWithIssuerC)),
  rfps: t.readonlyArray(imported236_withIdC(imported4_rfpWithIssuerC))
});
export type WatchlistStateDataC = typeof watchlistStateDataC;
export type WatchlistStateData = t.TypeOf<WatchlistStateDataC>;


