import type { PropsWithChildren, ReactElement } from "react";
import { Fragment } from "react";

import { E, O, pipe } from "@scripts/fp-ts";
import { invalidValue, type ValErrMsgFn } from "@scripts/react/form/errors";
import type { UnsafeFormProp, ValErrMsgAR } from "@scripts/react/form/form";
import { hasErrors } from "@scripts/react/form/form";
import type { CSSType } from "@scripts/react/util/classnames";

import { form as formStyle } from "@styles/components/_form";

import { mapOrEmpty, toEmpty } from "../Empty";
import type { TooltipProps } from "../Tooltip";
import { TooltipInfo } from "../Tooltip";


export const disabledClass = (d: boolean = false) => d ? O.some("disabled") : O.none;
export const errorClass: (v: E.Either<UnsafeFormProp<object>, ReadonlyArray<unknown>>) => O.Option<CSSType> =
  E.fold(e => O.fromPredicate(() => Boolean(e && Object.entries(e).flat().length > 0))(formDangerStyles), (arr: ReadonlyArray<unknown>) => O.fromPredicate(() => arr.length > 0)(formDangerStyles));

export const formDangerStyles: CSSType = formStyle[".form-input"].attrs[".has-danger"];
export const errorClassO: (err: O.Option<string>) => O.Option<CSSType> = O.map(() => formDangerStyles);

export const errorMsgEl = (msg: string, key: string) => <p className="error-text form-error" key={key}>{msg}</p>;
export const errorMsgEls = (msgs: ReadonlyArray<string>) => msgs.map((m, idx) => errorMsgEl(m, idx.toString()));

export const valErrMsgElsO = (label: O.Option<string>, value: O.Option<string>) => <A,>(v: ValErrMsgAR<A>): O.Option<ReactElement> =>
  hasErrors(v)
    ? O.some(
      <Fragment>
        {pipe(v, E.fold(
          () => [errorMsgEl(invalidValue("label")(label, value), "0")],
          err => err.map((fn: ValErrMsgFn, idx: number) => errorMsgEl(fn(label, value), idx.toString()))
        ))}
      </Fragment>
    )
    : O.none;

export const valErrMsgEls = (label: O.Option<string>, value: O.Option<string>) => <A,>(v: ValErrMsgAR<A>): ReactElement =>
  toEmpty(valErrMsgElsO(label, value)(v));

type LabelElBaseProps = PropsWithChildren<{
  label: string;
  id: string;
  required: boolean;
}>;

export const LabelElBase = (props: LabelElBaseProps): ReactElement =>
  <label htmlFor={props.id}>
    {props.label}{props.required && " *"}
    {props.children}
  </label>;

export const LabelEl = (props: LabelElBaseProps & {
  tooltip?: TooltipProps;
}): ReactElement =>
  <LabelElBase
    id={props.id}
    label={props.label}
    required={props.required}
  >
    {pipe(
      props.tooltip,
      O.fromNullable,
      mapOrEmpty(tooltip => <TooltipInfo addClassToTargetNode={"ml-025"} {...tooltip} />)
    )}
  </LabelElBase>;
