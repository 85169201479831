import * as t from "io-ts";
import { InvestorFocusCU as imported3_InvestorFocusCU, investorFocusOrd as imported2_investorFocusOrd } from "../domaintables/investorFocuses";
import { InvestorTypeCU as imported3_InvestorTypeCU } from "../domaintables/investorTypes";
import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported12_SectorCU, sectorOrd as imported2_sectorOrd } from "../domaintables/sectors";

export const userIdC = t.type({
  _tag: t.literal(`UserId`),
  userId: t.number
});
export type UserIdC = typeof userIdC;
export type UserId = t.TypeOf<UserIdC>;


export const actorC = t.type({
  id: userIdC,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  fullName: t.string,
  isNewActor: t.boolean,
  personaId: optionFromNullable(t.number),
  investorType: optionFromNullable(imported3_InvestorTypeCU),
  investorFocuses: readonlySetFromArray(imported3_InvestorFocusCU, imported2_investorFocusOrd),
  investorSectors: readonlySetFromArray(imported12_SectorCU, imported2_sectorOrd)
});
export type ActorC = typeof actorC;
export type Actor = t.TypeOf<ActorC>;


export const minimalActorC = t.type({
  id: userIdC,
  firstName: t.string,
  lastName: t.string,
  personaId: optionFromNullable(t.number),
  company: t.string
});
export type MinimalActorC = typeof minimalActorC;
export type MinimalActor = t.TypeOf<MinimalActorC>;


export const emailContactIdC = t.type({
  _tag: t.literal(`EmailContactId`),
  emailContactId: t.number
});
export type EmailContactIdC = typeof emailContactIdC;
export type EmailContactId = t.TypeOf<EmailContactIdC>;


export const allActorIdC = [emailContactIdC, userIdC] as const;
export const allActorIdNames = [`EmailContactId`, `UserId`] as const;
export type ActorIdName = (typeof allActorIdNames)[number];

export const ActorIdCU = t.union([emailContactIdC, userIdC]);
export type ActorIdCU = typeof ActorIdCU;
export type ActorIdU = t.TypeOf<ActorIdCU>;

export type ActorIdMap<A> = { [K in ActorIdName]: A };


export const emailContactActorC = t.type({
  id: t.number,
  email: t.string
});
export type EmailContactActorC = typeof emailContactActorC;
export type EmailContactActor = t.TypeOf<EmailContactActorC>;


