import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";

import { pipe } from "@scripts/fp-ts";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";
import type { ProjectsPageData } from "@scripts/routes/routing/ssr/issuersites";
import { projects, projectsTitle } from "@scripts/routes/routing/ssr/issuersites";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { ProjectCard } from "../../components/related-content/ProjectsSection";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export const ProjectsPage = (props: {
  data: ProjectsPageData;
  issuerId: number;
  issuerSlug: string;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  const page = projects({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, issuer);

  const projectTitle = projectsTitle(pages);

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkCustomAnchorContent={O.some(projectTitle)}
        jumpLinks={[]}
        routeMeta={page}
      />
      <ContactUsActionSection containerKlass={"d-none-until-md"} />
    </>}
  >
    <div {...klass("accent-border-top", "pt-0")}>
      <AccentDividerSection title={O.some(projectTitle)}>
        <Grid klasses={"card-grid"} attrs={O.none}>
          {pipe(
            props.data,
            RA.map(project =>
              <GridCol cols={[".c-24", ".c-lg-12"]} klasses={[]} key={project.data.id}>
                <ProjectCard project={project} />
              </GridCol>
            )
          )}
        </Grid>
      </AccentDividerSection>
      <ContactUsActionSection containerKlass={"d-md-none"} />
    </div>
  </DirectSitesPageDescriptionLayout>;
};
