import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { E, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { documents } from "@scripts/generated/domaintables/featureFlags";
import type { BankRfpSitesData } from "@scripts/generated/models/rfp";
import * as sitesRouter from "@scripts/generated/routers/sitesRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { FactsColumn, FactsRow, type FactType, makeFactO } from "@scripts/react/components/Facts";
import { Grid } from "@scripts/react/components/layout/Grid";
import { MainGridLeft, SideBarGridRightWithBorder } from "@scripts/react/components/layout/PageGrid";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRelatedContentDataO, makeRoadshowDataO, parseRoadshows, ProgramWithRatingRelatedContent, RoadshowsSection, SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { SidebarAboutSection } from "@scripts/react/components/SidebarAbout";
import { ProjectSidebarSection, TeamSidebarSection } from "@scripts/react/components/SidebarModalLinks";
import { RfpBadge } from "@scripts/react/rfp/rfpBadge";
import { klass } from "@scripts/react/util/classnames";
import { rfp as rfpRoute } from "@scripts/routes/routing/ssr/issuersites";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { isDrafted } from "@scripts/syntax/threadThrough";
import { currency } from "@scripts/util/currency";

import { useRfpSubscribe } from "../../api/watchlist";
import { BankIssuerChrome } from "../../components/Chrome";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { useIssuerSitesSelector } from "../../state/store";
import { QuestionAndAnswerSection } from "../direct-rfp/RfpRelatedContent";

type BankSitesRFP = BankRfpSitesData["rfp"]["data"]["data"]["record"]["data"]["rfp"];

const makeRowFacts = (rfp: BankSitesRFP) => RA.compact([
  makeFactO("Total Loan Amount", rfp.loanAmount, currency),
  makeFactO("Service Type", rfp.typeOfServicing, st => st.name),
  makeFactO("Bids Due", rfp.bidsDue, E.fold(dateWithOptionalTimeToString(humanDateFull), _ => _.format)),
]);

const makeColumnFacts = (rfp: BankSitesRFP) => RA.compact<FactType>([
  O.some({ title: "Sector", value: rfp.sector.name }),
  O.some({ title: "State", value: rfp.state.name }),
  makeFactO("Listing Date", rfp.dateListed, humanDateFull),
  makeFactO("Deadline for Questions", rfp.deadlineForQuestions, dateWithOptionalTimeToString(humanDateFull)),
  makeFactO(`${rfp.awardDateTentative ? "Tentative " : ""}Award Date`, rfp.awardDate, humanDateFull),
  makeFactO(`${rfp.contractStartDateTentative ? "Tentative " : ""}Contract Start Date`, rfp.contractStartDate, humanDateFull),
]);

export const BankRfpPage = (props: BankRfpSitesData) => {
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const makeOnSubscribe = useRfpSubscribe();
  const pages = useIssuerSitesSelector("pages");
  const page = rfpRoute({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: props.rfp.data.data.id });
  const pageTitle = page.title(pages);

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(props.rfp.data.data.record.data.relatedContent.roadShows));
  const documentsDataO = makeRelatedContentDataO(documents, iffs, props.rfp.data.data.record.data.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(props.rfp.data.data.record.data.relatedContent.externalLinks);

  const loginRedirect = sitesRouter.issuersitesRfpsControllerRfp({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    rfpId: props.rfp.data.data.id,
  }).url;

  return (
    <BankIssuerChrome bank={props.bank} loginRequired={true} loginRedirectUrl={loginRedirect} offeringId={props.rfp.data.data.id}>
      <div {...klass("container")}>
        <Grid klasses={O.none} attrs={O.none}>
          <MainGridLeft>
            <AccentDividerSection title={O.none} suppressDivider="xs" klasses={"mb-0"}>
              <div className="mb-05">
                <RfpBadge biddingState={props.rfp.data.data.record.data.rfp.biddingState} />
              </div>
              <HeaderWithSubscription
                isSubscribed={props.rfp.subscribed}
                makeOnSubscribe={makeOnSubscribe}
                subscriptionId={props.rfp.data.data.id}
                taggedContent={O.some(props.rfp.data.data.record)}
                title={props.rfp.data.data.record.data.rfp.name}
                viewAllRoute={O.none}
                subscribeDisabled={isDrafted(props.rfp.data)}
              />
            </AccentDividerSection>
            <AccentDividerSection title={O.some("Summary")}>
              <DividerSection title={O.none}>
                <div {...klass("d-flex", "flex-col", "gap-1")}>
                  <FactsRow items={makeRowFacts(props.rfp.data.data.record.data.rfp)} variant={"bold"} />
                  <FactsColumn items={makeColumnFacts(props.rfp.data.data.record.data.rfp)} />
                </div>
              </DividerSection>
              {mapOrEmpty((md: MarkdownType) =>
                <DividerSection
                  klasses={O.none}
                  title={O.some("Project Description")}
                >
                  <Markdown content={md} />
                </DividerSection>
              )(props.rfp.data.data.record.data.rfp.projectDescription)}
              {mapOrEmpty((md: MarkdownType) =>
                <DividerSection
                  klasses={O.none}
                  title={O.some("Submission Requirements")}
                >
                  <Markdown content={md} />
                </DividerSection>
              )(props.rfp.data.data.record.data.rfp.submissionRequirements)}
              <SummaryRelatedContent
                documentsO={documentsDataO}
                documentsHeadline="Documents"
                documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: props.rfp.data.data.id, mediaId })}
                issuer={issuer}
                linksO={linksDataO}
                variant="page"
                leafIcon={IssuerSitesLeafIcon}
              />
              {pipe(
                props.rfp.data.data.record.data.relatedContent.program,
                mapOrEmpty(_ =>
                  <ProgramWithRatingRelatedContent
                    program={_}
                    issuer={issuer}
                    variant={"page"}
                  />
                )
              )}
            </AccentDividerSection>
            {pipe(
              roadshowDataO,
              mapOrEmpty(roadshows =>
                <RoadshowsSection
                  issuer={issuer}
                  roadshows={roadshows}
                  leafIcon={IssuerSitesLeafIcon}
                />
              ))}
            {pipe(
              RNEA.fromReadonlyArray(props.participants),
              mapOrEmpty(offeringParticipants =>
                <Participants
                  title="Participants"
                  participants={offeringParticipants}
                />
              ))}
            <QuestionAndAnswerSection
              titleSingular={pageTitle}
              rfpWithRelatedContent={props.rfp.data.data.record.data}
              rfpId={props.rfp.data.data.id}
            />
          </MainGridLeft>
          <SideBarGridRightWithBorder>
            {pipe(
              props.issuerAboutText,
              mapOrEmpty(issuerAboutText =>
                <SidebarAboutSection issuer={issuer}>
                  <Markdown content={issuerAboutText.data} />
                </SidebarAboutSection>
              )
            )}
            {pipe(
              RNEA.fromReadonlyArray(props.officers),
              mapOrEmpty(team => <TeamSidebarSection team={team} />)
            )}
            {pipe(
              RNEA.fromReadonlyArray(props.projects),
              mapOrEmpty(projects => <ProjectSidebarSection projects={projects} />)
            )}
          </SideBarGridRightWithBorder>
        </Grid>
      </div>
    </BankIssuerChrome>
  );
};
