import { WithStatusCU as imported290_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported79_mediaC, mediaUploadResponseC as imported18_mediaUploadResponseC } from "./media";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PhotoEditableCU as imported12_PhotoEditableCU, PhotoEditableCU as imported13_PhotoEditableCU } from "../domaintables/pages";

export const headerPhotoC = t.type({
  page: imported12_PhotoEditableCU,
  media: imported290_WithStatusCU(imported79_mediaC)
});
export type HeaderPhotoC = typeof headerPhotoC;
export type HeaderPhoto = t.TypeOf<HeaderPhotoC>;


export const headerPhotoPostC = t.type({
  id: optionFromNullable(t.number),
  page: imported13_PhotoEditableCU,
  uploadResponse: optionFromNullable(imported18_mediaUploadResponseC)
});
export type HeaderPhotoPostC = typeof headerPhotoPostC;
export type HeaderPhotoPost = t.TypeOf<HeaderPhotoPostC>;


