import { WithStatusCU as imported167_WithStatusCU, WithStatusCU as imported166_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { rfpWithRoadshowsAndDocsC as imported4_rfpWithRoadshowsAndDocsC } from "./rfp";
import { taggedContentC as imported111_taggedContentC, taggedContentC as imported110_taggedContentC } from "./taggedContent";
import { bondProgramC as imported12_bondProgramC } from "./bondProgramBase";
import { bondOfferingWithRoadshowsAndDocsC as imported4_bondOfferingWithRoadshowsAndDocsC } from "./bondOffering";

export const bondProgramOfferingsAndRfpsC = t.type({
  offerings: t.readonlyArray(imported166_WithStatusCU(imported110_taggedContentC(imported4_bondOfferingWithRoadshowsAndDocsC))),
  rfps: t.readonlyArray(imported167_WithStatusCU(imported111_taggedContentC(imported4_rfpWithRoadshowsAndDocsC)))
});
export type BondProgramOfferingsAndRfpsC = typeof bondProgramOfferingsAndRfpsC;
export type BondProgramOfferingsAndRfps = t.TypeOf<BondProgramOfferingsAndRfpsC>;


export const bondProgramWithOfferingsAndRfpsC = t.type({
  program: imported12_bondProgramC,
  relatedContent: bondProgramOfferingsAndRfpsC
});
export type BondProgramWithOfferingsAndRfpsC = typeof bondProgramWithOfferingsAndRfpsC;
export type BondProgramWithOfferingsAndRfps = t.TypeOf<BondProgramWithOfferingsAndRfpsC>;


