import { withIdC as imported199_withIdC, WithStatusCU as imported187_WithStatusCU, WithStatusCU as imported186_WithStatusCU, WithStatusCU as imported183_WithStatusCU, WithStatusCU as imported188_WithStatusCU, WithStatusCU as imported184_WithStatusCU, WithStatusCU as imported185_WithStatusCU, withIdC as imported200_withIdC } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { offeringParticipantC as imported13_offeringParticipantC } from "./offeringParticipant";
import { issuerRatingC as imported10_issuerRatingC, programRatingC as imported12_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported31_RatingAgencyCU, RatingAgencyCU as imported32_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { bondProgramWithOfferingsAndRfpsC as imported1_bondProgramWithOfferingsAndRfpsC } from "./bondProgramWithOfferingsAndRfps";
import { ratingWithRelatedContentC as imported17_ratingWithRelatedContentC, ratingWithRelatedContentC as imported18_ratingWithRelatedContentC } from "./rating";
import { taggedContentC as imported123_taggedContentC, taggedContentC as imported122_taggedContentC, taggedContentC as imported124_taggedContentC } from "./taggedContent";
import { bondProgramC as imported17_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported19_subscribedC, subscribedC as imported20_subscribedC } from "./subscribed";
import { customPageDataOC as imported4_customPageDataOC } from "./customPages";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported16_roadShowDataC } from "./roadshow";
import { bondOfferingWithRelatedContentC as imported9_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported10_bondOfferingWithRelatedContentC } from "./bondOffering";
import { clientTextItemC as imported14_clientTextItemC, clientTextItemC as imported15_clientTextItemC } from "./clientTextItem";

export const bondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported19_subscribedC(imported183_WithStatusCU(imported122_taggedContentC(imported9_bondOfferingWithRelatedContentC)))),
  roadshows: t.readonlyArray(imported199_withIdC(imported123_taggedContentC(imported16_roadShowDataC))),
  participants: t.readonlyArray(imported184_WithStatusCU(imported13_offeringParticipantC)),
  generalInfo: optionFromNullable(imported14_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported31_RatingAgencyCU, readonlyNonEmptyArrayC(imported185_WithStatusCU(imported17_ratingWithRelatedContentC(imported10_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported200_withIdC(imported17_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported32_RatingAgencyCU, readonlyNonEmptyArrayC(imported186_WithStatusCU(imported18_ratingWithRelatedContentC(imported12_programRatingC)))]))])),
  programs: t.readonlyArray(imported187_WithStatusCU(imported1_bondProgramWithOfferingsAndRfpsC)),
  customPages: t.readonlyArray(imported4_customPageDataOC)
});
export type BondsSsrDataC = typeof bondsSsrDataC;
export type BondsSsrData = t.TypeOf<BondsSsrDataC>;


export const archivedBondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported20_subscribedC(imported188_WithStatusCU(imported124_taggedContentC(imported10_bondOfferingWithRelatedContentC)))),
  generalInfo: optionFromNullable(imported15_clientTextItemC)
});
export type ArchivedBondsSsrDataC = typeof archivedBondsSsrDataC;
export type ArchivedBondsSsrData = t.TypeOf<ArchivedBondsSsrDataC>;


