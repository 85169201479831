import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, O, pipe, RA } from "@scripts/fp-ts";
import type { FeaturedItem } from "@scripts/generated/models/featuredItem";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import { Badge } from "@scripts/react/components/Badge";
import { FeaturedItemCard } from "@scripts/react/components/card/FeaturedItemCard";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { useConfig } from "@scripts/react/context/Config";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { urlInterface } from "@scripts/routes/urlInterface";

import offeringIcon from "@svgs/bond.svg";
import eventIcon from "@svgs/calendar.svg";
import projectIcon from "@svgs/construction.svg";
import documentIcon from "@svgs/document.svg";
import newsIcon from "@svgs/newspaper.svg";
import rfpIcon from "@svgs/rfp.svg";
import roadshowIcon from "@svgs/roadshow.svg";
import pageIcon from "@svgs/star-empty.svg";

import { IssuerSitesLeafIcon } from "../../components/LeafIcon";

const defaultBadgeProps = {
  color: "accent-2-700",
  variant: "tall",
} as const;

const makeUniqueProps = (config: BLConfigWithLog, item: TaggedContent<FeaturedItem>) => {
  switch (item.data.itemType._tag) {
    case "Document":
      return {
        badgeIcon: documentIcon,
        badgeText: "Document",
        routeTitleName: "Document",
      };
    case "Event":
      return {
        badgeIcon: eventIcon,
        badgeText: "Event",
        routeTitleName: "Event",
      };
    case "News":
      return {
        badgeIcon: newsIcon,
        badgeText: "News Update",
        routeTitleName: "News",
      };
    case "Offering":
      return {
        badgeIcon: offeringIcon,
        badgeText: "Bond Offering",
        routeTitleName: "Offering",
      };
    case "Page":
      return {
        badgeIcon: pageIcon,
        badgeText: "Featured",
        routeTitleName: "Page",
      };
    case "Project":
      return {
        badgeIcon: projectIcon,
        badgeText: "Project",
        routeTitleName: "Project",
      };
    case "Rfp":
      return {
        badgeIcon: rfpIcon,
        badgeText: "RFP",
        routeTitleName: "RFP",
      };
    case "RoadShow":
      return {
        badgeIcon: roadshowIcon,
        badgeText: "Road Show",
        routeTitleName: "Road Show",
      };
  }

  return config.exhaustive(item.data.itemType);
};

const ItemCard = (props: { item: TaggedContent<FeaturedItem> }) => {
  const config = useConfig();

  const { badgeIcon, badgeText, routeTitleName } = makeUniqueProps(config, props.item);

  return <FeaturedItemCard
    klass={"h-100"}
    action={O.some(E.left({
      route: urlInterface("GET", props.item.data.url),
      title: `View ${routeTitleName}`,
    }))}
    badge={<>
      <Badge
        {...defaultBadgeProps}
        icon={O.some(badgeIcon)}
        text={badgeText}
      />
      <IssuerSitesLeafIcon klass="ml-025" taggedContent={props.item} />
    </>}
    date={props.item.data.dateLabel}
  >
    <div {...klass("font-sans-normal-500")}>{props.item.data.header}</div>
  </FeaturedItemCard>;
};

const FeaturedItemsGrid = (props: {
  items: RA.ReadonlyArrayMaxN<TaggedContent<FeaturedItem>, 4>;
  klass?: KlassProp;
  variant: "xs" | "md";
}) =>
  <Grid
    attrs={O.some(props.variant === "xs" ? ".grid-sa-15" : ".grid-sa-2")}
    klasses={klassPropO("")(props.klass).className}
  >{props.items.map(_ =>
    <GridCol key={`${_.data.itemType._tag}-${_.data.itemId}`} cols={[".c-24", ".c-md-12", ".c-xl-6"]} klasses={O.none}>
      <ItemCard item={_} />
    </GridCol>)}
  </Grid>;

export const FeaturedItems = (props: { featuredItems: ReadonlyArray<TaggedContent<FeaturedItem>> }) =>
  <section {...klass("mb-0", "pt-0")} id="anchor-featured-items container subtle no-header">{
    pipe(
      props.featuredItems,
      RA.firstN(4),
      firstFour =>
        <div {...klass("featured-items-container")}>
          <FeaturedItemsGrid items={firstFour} klass={"d-md-none"} variant="xs" />
          <FeaturedItemsGrid items={firstFour} klass={"d-none-until-md"} variant="md" />
        </div>)
  }</section>;
