import { O, pipe } from "@scripts/fp-ts";
import { ButtonPrimary } from "@scripts/react/components/Button";
import { InputRaw } from "@scripts/react/components/form/TextInput";
import { Svg } from "@scripts/react/components/Svg";
import { klass } from "@scripts/react/util/classnames";
import type { LabelOrAriaLabel } from "@scripts/util/labelOrAriaLabel";

import closeIcon from "@svgs/close-x.svg";
import searchIcon from "@svgs/magnifying-glass.svg";

export const IssuerSitesSearchInput = (props: {
  ariaLabelledById: string;
  placeholder: O.Option<string>;
  search: O.Option<string>;
  labelOrAriaLabel: LabelOrAriaLabel;
  setSearch: (s: O.Option<string>) => void;
}) => <div {...klass("form-input input-group issuer-sites-search")}>
    <div {...klass("input-group-container")}>
      <InputRaw
        ariaLabelledById={props.ariaLabelledById}
        onChange={e => props.setSearch(O.fromPredicate((_: string) => _.length > 0)(e.target.value))}
        placeholder={props.placeholder}
        errorComponent={O.none}
        labelComponent={O.none}
        labelOrAriaLabel={props.labelOrAriaLabel}
        type="text"
        value={props.search}
      />
      <div {...klass("input-group-postfix")}>
        <ButtonPrimary {...klass("p-0 mt-0")} onClick={() => props.setSearch(O.none)}>
          <Svg src={pipe(props.search, O.filter(_ => _.length > 0), O.fold(() => searchIcon, () => closeIcon))} />
        </ButtonPrimary>
      </div>
    </div>
  </div>;
