import { WithStatusCU as imported238_WithStatusCU, WithStatusCU as imported237_WithStatusCU, withModInfoC as imported139_withModInfoC, withIdC as imported229_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { taggedContentC as imported149_taggedContentC, taggedContentC as imported150_taggedContentC, taggedContentC as imported148_taggedContentC } from "./taggedContent";
import { roadShowDataC as imported21_roadShowDataC } from "./roadshow";
import { bondOfferingC as imported16_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported52_rfpC } from "./rfpBase";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const roadShowsListC = t.type({
  shows: t.readonlyArray(imported229_withIdC(imported139_withModInfoC(imported148_taggedContentC(imported21_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported237_WithStatusCU(imported149_taggedContentC(imported16_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported238_WithStatusCU(imported150_taggedContentC(imported52_rfpC))))
});
export type RoadShowsListC = typeof roadShowsListC;
export type RoadShowsList = t.TypeOf<RoadShowsListC>;


