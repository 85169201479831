import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const s3PublicReadAcl = {
  _tag: `S3PublicReadAcl`,
  acl: `public-read`
} as const;

export const s3PublicReadAclTaggedC = t.type({
  _tag: t.literal(`S3PublicReadAcl`)
});
export type S3PublicReadAclTaggedC = typeof s3PublicReadAclTaggedC;
export type S3PublicReadAclTagged = t.TypeOf<S3PublicReadAclTaggedC>;
export type S3PublicReadAcl = S3PublicReadAclTagged & typeof s3PublicReadAcl;
export const s3PublicReadAclC = pipe(s3PublicReadAclTaggedC, c => new t.Type<S3PublicReadAcl, S3PublicReadAclTagged>(
  `S3PublicReadAcl`,
  (u: unknown): u is S3PublicReadAcl => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, S3PublicReadAcl> => pipe(c.decode(u), E.map(x => ({ ...x, ...s3PublicReadAcl }))),
  (x: S3PublicReadAcl): S3PublicReadAclTagged => ({ ...x, _tag: `S3PublicReadAcl`}),
));
export type S3PublicReadAclC = typeof s3PublicReadAclC;


export const s3PrivateAcl = {
  _tag: `S3PrivateAcl`,
  acl: `private`
} as const;

export const s3PrivateAclTaggedC = t.type({
  _tag: t.literal(`S3PrivateAcl`)
});
export type S3PrivateAclTaggedC = typeof s3PrivateAclTaggedC;
export type S3PrivateAclTagged = t.TypeOf<S3PrivateAclTaggedC>;
export type S3PrivateAcl = S3PrivateAclTagged & typeof s3PrivateAcl;
export const s3PrivateAclC = pipe(s3PrivateAclTaggedC, c => new t.Type<S3PrivateAcl, S3PrivateAclTagged>(
  `S3PrivateAcl`,
  (u: unknown): u is S3PrivateAcl => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, S3PrivateAcl> => pipe(c.decode(u), E.map(x => ({ ...x, ...s3PrivateAcl }))),
  (x: S3PrivateAcl): S3PrivateAclTagged => ({ ...x, _tag: `S3PrivateAcl`}),
));
export type S3PrivateAclC = typeof s3PrivateAclC;


export const allS3AclC = [s3PublicReadAclC, s3PrivateAclC] as const;
export const allS3AclNames = [`S3PublicReadAcl`, `S3PrivateAcl`] as const;
export type S3AclName = (typeof allS3AclNames)[number];

export const S3AclCU = t.union([s3PublicReadAclC, s3PrivateAclC]);
export type S3AclCU = typeof S3AclCU;
export type S3AclU = t.TypeOf<S3AclCU>;

export const s3AclOrd: Ord.Ord<S3AclU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allS3Acl = [s3PublicReadAcl, s3PrivateAcl] as const;
export type S3AclMap<A> = { [K in S3AclName]: A };


export const s3MultipartUploadInfoC = t.type({
  bucket: t.string,
  key: t.string,
  uploadId: t.string
});
export type S3MultipartUploadInfoC = typeof s3MultipartUploadInfoC;
export type S3MultipartUploadInfo = t.TypeOf<S3MultipartUploadInfoC>;


export const s3PresignMultipartUploadPartsPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC,
  partNumbers: t.readonlyArray(t.number)
});
export type S3PresignMultipartUploadPartsPostC = typeof s3PresignMultipartUploadPartsPostC;
export type S3PresignMultipartUploadPartsPost = t.TypeOf<S3PresignMultipartUploadPartsPostC>;


export const s3PresignedMultipartUploadPartsC = t.type({
  presignedUrls: readonlyMapFromEntries(t.number, numberOrd, t.string)
});
export type S3PresignedMultipartUploadPartsC = typeof s3PresignedMultipartUploadPartsC;
export type S3PresignedMultipartUploadParts = t.TypeOf<S3PresignedMultipartUploadPartsC>;


export const s3SuccessPostC = t.type({
  key: t.string,
  name: t.string,
  bucket: t.string
});
export type S3SuccessPostC = typeof s3SuccessPostC;
export type S3SuccessPost = t.TypeOf<S3SuccessPostC>;


export const s3MultipartUploadPartC = t.type({
  partNumber: t.number,
  size: optionFromNullable(t.number),
  etag: t.string
});
export type S3MultipartUploadPartC = typeof s3MultipartUploadPartC;
export type S3MultipartUploadPart = t.TypeOf<S3MultipartUploadPartC>;


export const s3CreateMultipartUploadPostC = t.type({
  bucket: t.string,
  key: t.string,
  acl: S3AclCU,
  contentType: t.string,
  fileName: t.string
});
export type S3CreateMultipartUploadPostC = typeof s3CreateMultipartUploadPostC;
export type S3CreateMultipartUploadPost = t.TypeOf<S3CreateMultipartUploadPostC>;


export const s3CompleteMultipartUploadPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC,
  parts: readonlyNonEmptyArrayC(s3MultipartUploadPartC)
});
export type S3CompleteMultipartUploadPostC = typeof s3CompleteMultipartUploadPostC;
export type S3CompleteMultipartUploadPost = t.TypeOf<S3CompleteMultipartUploadPostC>;


export const s3CompleteMultipartUploadResponseC = t.type({
  location: t.string
});
export type S3CompleteMultipartUploadResponseC = typeof s3CompleteMultipartUploadResponseC;
export type S3CompleteMultipartUploadResponse = t.TypeOf<S3CompleteMultipartUploadResponseC>;


export const s3ListMultipartUploadPartsPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC
});
export type S3ListMultipartUploadPartsPostC = typeof s3ListMultipartUploadPartsPostC;
export type S3ListMultipartUploadPartsPost = t.TypeOf<S3ListMultipartUploadPartsPostC>;


export const s3ListMultipartUploadPartsResponseC = t.type({
  parts: t.readonlyArray(s3MultipartUploadPartC)
});
export type S3ListMultipartUploadPartsResponseC = typeof s3ListMultipartUploadPartsResponseC;
export type S3ListMultipartUploadPartsResponse = t.TypeOf<S3ListMultipartUploadPartsResponseC>;


