import * as O from "fp-ts/lib/Option";

import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import type {
  DataMetaBase,
  DataMetaCallout,
  DataMetaDraftable,
  DataMetaSvg,
  DataMetaViewable,
  UnsafeDataMetaIgnoreTitleCase,
} from "@scripts/meta/dataMeta";

import bondIcon from "@svgs/bond.svg";
import categoriesIcon from "@svgs/categories.svg";
import constructionIcon from "@svgs/construction.svg";
import documentIcon from "@svgs/document.svg";
import emailIcon from "@svgs/email.svg";
import gearIcon from "@svgs/gear.svg";
import governmentDocument from "@svgs/government-document.svg";
import graphIcon from "@svgs/graph.svg";
import issuerIcon from "@svgs/issuer.svg";
import leafIcon from "@svgs/leaf.svg";
import linkIcon from "@svgs/link.svg";
import newsIcon from "@svgs/newspaper.svg";
import personIcon from "@svgs/person.svg";
import questionIcon from "@svgs/question-and-answer.svg";
import roadshowIcon from "@svgs/roadshow.svg";
import websiteIcon from "@svgs/website.svg";

export const aboutIssuerMeta: DataMetaBase<"About Issuer"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesAboutControllerIndexParams, "SiteLink"> & DataMetaDraftable = {
  type: "About Issuer",
  customDisplayName: O.none,
  svg: issuerIcon,
  siteLink: SitesRouter.issuersitesAboutControllerIndex,
  draftable: true,
};

export const bestPracticeMeta: DataMetaBase<"Best Practice"> = {
  type: "Best Practice",
  customDisplayName: O.none,
};

export const bondMeta: DataMetaBase<"Bond Offerings"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesBondOfferingsControllerIndexParams, "SiteLink"> & DataMetaDraftable & DataMetaCallout = {
  type: "Bond Offerings",
  customDisplayName: O.none,
  svg: bondIcon,
  siteLink: SitesRouter.issuersitesBondOfferingsControllerIndex,
  draftable: true,
  calloutTitle: "Bond",
};

export const bondRatingMeta: DataMetaBase<"Rating"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesBondOfferingsControllerIndexParams, "SiteLink"> & DataMetaDraftable = {
  type: "Rating",
  customDisplayName: O.none,
  svg: graphIcon,
  siteLink: SitesRouter.issuersitesBondOfferingsControllerRatings,
  draftable: true,
};

export const categoriesMeta: DataMetaBase<"Categories"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesReportsControllerIndexParams, "DeepLink"> = {
  type: "Categories",
  customDisplayName: O.none,
  svg: categoriesIcon,
  deepLink: {
    type: "Basic",
    route: SitesRouter.issuersitesReportsControllerIndex,
    selector: "anchor-document-categories",
  },
};

export const changeApprovalMeta: DataMetaBase<"Change Approval"> = {
  type: "Change Approval",
  customDisplayName: O.none,
};

export const colorsAndHeadersMeta: DataMetaBase<"Colors & Headers"> = {
  type: "Colors & Headers",
  customDisplayName: O.none,
};

export const contactMeta: DataMetaBase<"Contact"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesResourcesControllerIndexParams, "DeepLink"> = {
  type: "Contact",
  customDisplayName: O.none,
  svg: emailIcon,
  deepLink: {
    type: "Basic",
    route: SitesRouter.issuersitesResourcesControllerIndex,
    selector: "anchor-contact-us",
  },
};

export const cusip6sMeta: DataMetaBase<"CUSIP-6"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesEmmaControllerLinksParams, "DeepLink"> & DataMetaDraftable & UnsafeDataMetaIgnoreTitleCase = {
  type: "CUSIP-6",
  customDisplayName: O.none,
  deepLink: {
    type: "Basic",
    selector: "anchor-cusip6-table",
    route: SitesRouter.issuersitesEmmaControllerLinks,
  },
  svg: governmentDocument,
  draftable: true,
  ignoreTitleCase: true,
};

export const cusip9sMeta: DataMetaBase<"CUSIP-9"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesEmmaControllerLinksParams, "DeepLink"> & DataMetaDraftable & UnsafeDataMetaIgnoreTitleCase = {
  type: "CUSIP-9",
  customDisplayName: O.none,
  deepLink: {
    type: "Basic",
    selector: "anchor-emma-table",
    route: SitesRouter.issuersitesEmmaControllerLinks,
  },
  svg: governmentDocument,
  draftable: true,
  ignoreTitleCase: true,
};

export const customPageMeta: DataMetaBase<"Custom Page"> & DataMetaSvg & DataMetaDraftable = {
  type: "Custom Page",
  customDisplayName: O.none,
  svg: websiteIcon,
  draftable: true,
};

export const customReportMeta: DataMetaBase<"Custom Report"> = {
  type: "Custom Report",
  customDisplayName: O.none,
};

export const dashboardMeta: DataMetaBase<"Dashboard"> = {
  type: "Dashboard",
  customDisplayName: O.none,
};

export const dataManagementMeta: DataMetaBase<"Data Management"> = {
  type: "Data Management",
  customDisplayName: O.none,
};

export const debtProfileMeta: DataMetaBase<"Debt Profile"> = {
  type: "Debt Profile",
  customDisplayName: O.none,
};

export const disclaimersMeta: DataMetaBase<"Disclaimers"> = {
  type: "Disclaimers",
  customDisplayName: O.none,
};

export const documentMeta: DataMetaBase<"Document"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesReportsControllerIndexParams, "SiteLink"> & DataMetaDraftable = {
  type: "Document",
  customDisplayName: O.none,
  svg: documentIcon,
  siteLink: SitesRouter.issuersitesReportsControllerIndex,
  draftable: true,
};
export const documentActivityMeta: DataMetaBase<"Document Activity"> = {
  type: "Document Activity",
  customDisplayName: O.none,
};
export const documentStatisticMeta: DataMetaBase<"Document Statistic"> = {
  type: "Document Statistic",
  customDisplayName: O.none,
};
export const esgMeta: DataMetaBase<"ESG Program"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesAboutControllerEsgProgramParams, "SiteLink"> = {
  type: "ESG Program",
  customDisplayName: O.none,
  siteLink: SitesRouter.issuersitesAboutControllerEsgProgram,
  svg: leafIcon,
};

export const faqMeta: DataMetaBase<"FAQ"> & DataMetaSvg & DataMetaDraftable & UnsafeDataMetaIgnoreTitleCase & DataMetaViewable<SitesRouter.IssuersitesResourcesControllerFaqParams, "SiteLink"> = {
  type: "FAQ",
  customDisplayName: O.none,
  siteLink: SitesRouter.issuersitesResourcesControllerFaq,
  svg: questionIcon,
  ignoreTitleCase: true,
  draftable: true,
};

export const footerAndMenuMeta: DataMetaBase<"Footer & Menu"> = {
  type: "Footer & Menu",
  customDisplayName: O.none,
};

export const homeMeta: DataMetaBase<"Home">
  & DataMetaSvg
  & DataMetaViewable<SitesRouter.IssuersitesIssuerControllerIndexParams, "SiteLink"> =
{
  type: "Home",
  customDisplayName: O.some("Home"),
  svg: bondIcon,
  siteLink: SitesRouter.issuersitesIssuerControllerIndex,
};

export const institutionalInvestorMeta: DataMetaBase<"Institutional Investor"> & DataMetaSvg = {
  type: "Institutional Investor",
  customDisplayName: O.none,
  svg: personIcon,
};

export const invoiceMeta: DataMetaBase<"Invoices"> = {
  type: "Invoices",
  customDisplayName: O.none,
};

export const irmaMeta: DataMetaBase<"IRMA Letter"> & DataMetaSvg & DataMetaDraftable & DataMetaViewable<SitesRouter.IssuersitesReportsControllerIrmaLetterParams, "SiteLink"> = {
  type: "IRMA Letter",
  customDisplayName: O.none,
  svg: governmentDocument,
  siteLink: SitesRouter.issuersitesReportsControllerIrmaLetter,
  draftable: true,
};

export const issuerSettingsMeta: DataMetaBase<"Issuer Settings"> = {
  type: "Issuer Settings",
  customDisplayName: O.none,
};

export const linksMeta: DataMetaBase<"Links"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesResourcesControllerLinksParams, "SiteLink"> & DataMetaDraftable = {
  type: "Links",
  customDisplayName: O.none,
  svg: linkIcon,
  siteLink: SitesRouter.issuersitesResourcesControllerLinks,
  draftable: true,
};

export const marketInsightMeta: DataMetaBase<"Muni Market News"> = {
  type: "Muni Market News",
  customDisplayName: O.none,
};

export const newsMeta: DataMetaBase<"News"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesAboutControllerNewsEventsParams, "SiteLink"> & DataMetaDraftable & DataMetaCallout = {
  type: "News",
  customDisplayName: O.none,
  svg: newsIcon,
  siteLink: SitesRouter.issuersitesAboutControllerNewsEvents,
  draftable: true,
  calloutTitle: "News",
};

export const offeringParticipantMeta: DataMetaBase<"Offering Participant"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesBondOfferingsControllerIndexParams, "DeepLink"> & DataMetaDraftable = {
  type: "Offering Participant",
  customDisplayName: O.none,
  svg: issuerIcon,
  deepLink: {
    type: "Basic",
    route: SitesRouter.issuersitesBondOfferingsControllerIndex,
    selector: "anchor-offering-participants",
  },
  draftable: true,
};

export const pageSetupMeta: DataMetaBase<"Page Setup"> = {
  type: "Page Setup",
  customDisplayName: O.none,
};

export const personalizedTipMeta: DataMetaBase<"Personalized Tip"> = {
  type: "Personalized Tip",
  customDisplayName: O.none,
};

export const portalUsersMeta: DataMetaBase<"Portal Users"> = {
  type: "Portal Users",
  customDisplayName: O.none,
};

export const projectsMeta: DataMetaBase<"Projects"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesIssuerControllerIndexParams, "SiteLink"> & DataMetaDraftable & DataMetaCallout = {
  type: "Projects",
  customDisplayName: O.none,
  siteLink: SitesRouter.issuersitesAboutControllerProjects,
  svg: constructionIcon,
  draftable: true,
  calloutTitle: "Project",
};

export const questionAndAnswerMeta: DataMetaBase<"Question & Answer"> & DataMetaSvg & DataMetaDraftable = {
  type: "Question & Answer",
  customDisplayName: O.none,
  svg: questionIcon,
  draftable: true,
};

export const relatedIssuersMeta: DataMetaBase<"Related Issuers"> = {
  type: "Related Issuers",
  customDisplayName: O.none,
};

export const relatedIssuerMeta: DataMetaBase<"Related Issuer"> & DataMetaSvg & DataMetaDraftable = {
  type: "Related Issuer",
  customDisplayName: O.none,
  svg: issuerIcon,
  draftable: true,
};

export const roadshowMeta: DataMetaBase<"Roadshow"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesBondOfferingsControllerIndexParams, "DeepLink"> & DataMetaCallout = {
  type: "Roadshow",
  customDisplayName: O.none,
  svg: roadshowIcon,
  deepLink: {
    type: "Basic",
    route: SitesRouter.issuersitesBondOfferingsControllerIndex,
    selector: "anchor-roadshows",
  },
  calloutTitle: "Roadshow",
};

export const seriesBreakdownMeta: DataMetaBase<"Series Breakdown"> = {
  type: "Series Breakdown",
  customDisplayName: O.none,
};

export const seriesDataMeta: DataMetaBase<"Series Data"> = {
  type: "Series Data",
  customDisplayName: O.none,
};

export const settingMeta: DataMetaBase<"Setting"> = {
  type: "Setting",
  customDisplayName: O.none,
};

export const siteActivityMeta: DataMetaBase<"Site Activity"> = {
  type: "Site Activity",
  customDisplayName: O.none,
};

export const tagSummaryMeta: DataMetaBase<"Tag Summary"> = {
  type: "Tag Summary",
  customDisplayName: O.none,
};

export const teamMeta: DataMetaBase<"Team"> & DataMetaSvg & DataMetaViewable<SitesRouter.IssuersitesAboutControllerTeamParams, "SiteLink"> & DataMetaDraftable = {
  type: "Team",
  customDisplayName: O.none,
  svg: personIcon,
  siteLink: SitesRouter.issuersitesAboutControllerTeam,
  draftable: true,
};

export const userActivityMeta: DataMetaBase<"User Activity"> = {
  type: "User Activity",
  customDisplayName: O.none,
};

export const userGuideMeta: DataMetaBase<"User Guide"> = {
  type: "User Guide",
  customDisplayName: O.none,
};

export const userActivityDetailMeta: DataMetaBase<"User Profile"> = {
  type: "User Profile",
  customDisplayName: O.none,
};

export const yearlyBreakdownMeta: DataMetaBase<"Yearly Breakdown"> = {
  type: "Yearly Breakdown",
  customDisplayName: O.none,
};

export const debtReviewCenterMeta: DataMetaBase<"Data Review Center"> = {
  type: "Data Review Center",
  customDisplayName: O.none,
};

export const reviewDetailsMeta: DataMetaBase<"Review Details"> = {
  type: "Review Details",
  customDisplayName: O.none,
};

export const analyticsEmailMetricsMeta: DataMetaBase<"Emails"> & DataMetaSvg = {
  type: "Emails",
  svg: emailIcon,
  customDisplayName: O.some("Email Analytics"),
};

export const analyticsEmailDetailsMeta: DataMetaBase<"Email Details"> & DataMetaSvg = {
  type: "Email Details",
  svg: emailIcon,
  customDisplayName: O.none,
};

export const analystMeta: DataMetaBase<"Analyst"> & DataMetaSvg & DataMetaDraftable = {
  type: "Analyst",
  customDisplayName: O.none,
  svg: personIcon,
  draftable: true,
};

export const issuerRatingMeta: DataMetaBase<"Issuer Rating"> & DataMetaSvg & DataMetaDraftable = {
  type: "Issuer Rating",
  customDisplayName: O.none,
  svg: graphIcon,
  draftable: true,
};

export const programRatingMeta: DataMetaBase<"Program Rating"> & DataMetaSvg & DataMetaDraftable = {
  type: "Program Rating",
  customDisplayName: O.none,
  svg: graphIcon,
  draftable: true,
};

export const accountSettingsMeta: DataMetaBase<"Account Settings"> & DataMetaSvg = {
  type: "Account Settings",
  customDisplayName: O.none,
  svg: gearIcon,
};
