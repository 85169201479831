import * as t from "io-ts";
import { offeringParticipantWithTypeC as imported1_offeringParticipantWithTypeC } from "./offeringParticipant";
import { mediaC as imported89_mediaC } from "./media";
import { taggedContentC as imported210_taggedContentC, taggedContentC as imported211_taggedContentC, taggedContentC as imported212_taggedContentC } from "./taggedContent";
import { bankC as imported21_bankC } from "./bank";
import { bondOfferingC as imported26_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported27_roadShowDataC } from "./roadshow";
import { relatedOfferingParticipantLinkC as imported13_relatedOfferingParticipantLinkC } from "./relatedContent";
import { withIdC as imported292_withIdC, withIdC as imported291_withIdC, hasManyLinkC as imported139_hasManyLinkC, withIdC as imported290_withIdC, withIdC as imported293_withIdC } from "./threadThrough";
import { issuerWithAddressC as imported9_issuerWithAddressC } from "./issuer";

export const investorPortalOfferingC = t.type({
  offering: imported290_withIdC(imported210_taggedContentC(imported26_bondOfferingC)),
  issuer: imported9_issuerWithAddressC,
  bank: optionFromNullable(imported21_bankC),
  subscribed: t.boolean,
  documents: t.readonlyArray(imported291_withIdC(imported211_taggedContentC(imported89_mediaC))),
  roadshows: t.readonlyArray(imported292_withIdC(imported212_taggedContentC(imported27_roadShowDataC))),
  participants: t.readonlyArray(imported139_hasManyLinkC(imported293_withIdC(imported1_offeringParticipantWithTypeC), imported13_relatedOfferingParticipantLinkC))
});
export type InvestorPortalOfferingC = typeof investorPortalOfferingC;
export type InvestorPortalOffering = t.TypeOf<InvestorPortalOfferingC>;


