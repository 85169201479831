import * as b from "fp-ts/lib/boolean";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import * as O from "fp-ts/lib/Option";
import { fromIO } from "fp-ts/lib/Task";
import { useStable, useStableEffect } from "fp-ts-react-stable-hooks";

import type { TEWithEffect } from "@scripts/api/methods";
import { Joda, localDateTimeNow } from "@scripts/syntax/date/joda";
import { LocalDateEq } from "@scripts/syntax/date/jodaSyntax";

import type { TimeStamp } from "./useReload";

export const dataEitherEq = O.getEq(E.getEq(Eq.eqStrict, Eq.eqStrict));

export const useDataLoader = <E, A>(te: TEWithEffect<E, A>, expiration: O.Option<Joda.Duration>, ts?: TimeStamp) => {
  const [data, setData] = useStable<{ data: O.Option<E.Either<E, A>>, isLoading: boolean }>(
    { data: O.none, isLoading: true },
    Eq.struct({ data: dataEitherEq, isLoading: b.Eq })
  );

  const duration = O.getOrElse(() => Joda.Duration.ofDays(1))(expiration);
  const [ex, setEx] = useStable<Joda.LocalDateTime>(duration.addTo(localDateTimeNow()), LocalDateEq);
  localDateTimeNow().isAfter(ex) && setEx(duration.addTo(localDateTimeNow()));

  useStableEffect(() => {
    setData({ data: data.data, isLoading: true });
    te(
      (e: E) => fromIO(() => setData({ data: O.some(E.left(e)), isLoading: false }))(),
      (d: A) => fromIO(() => setData({ data: O.some(E.right(d)), isLoading: false }))()
    )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ex, ts], Eq.tuple(LocalDateEq, Eq.eqStrict));
  return [data.isLoading, data.data, setData] as const;
};
