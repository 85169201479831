import { flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { slugify } from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { draftMode } from "@scripts/generated/domaintables/featureFlags";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { Address } from "@scripts/generated/models/address";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { encodeURIPair } from "@scripts/routes/router";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { urlInterface } from "@scripts/routes/urlInterface";

import { ffAllEnabled } from "./featureFlags";
import { noneStr } from "./none";

export const issuerShortNameOrName = (i: Issuer) => O.getOrElse(() => i.name)(i.shortName);

const getDraftModeParams = (isDraftable: boolean, ffs: ClientFeatureFlags) => {
  return ffAllEnabled([draftMode])(ffs) && isDraftable ? [forceDraftPreviewParam] : [];
};

export type IsDraftable = O.Option<readonly [iffs: ClientFeatureFlags, isDraftable: boolean]>;

export const getDomain =
  (config: BLConfigWithLog): (issuer: O.Option<Issuer>) => string => flow(
    O.chain(_ => _.customDomain),
    O.map(domain => new URL(domain).hostname),
    O.getOrElse(() => new URL(config.baseUrl).hostname),
  );

export type IssuerBaseArgs = { issuerSlug: string, issuerId: number };
export type OmitIssuerBaseArgs<A extends IssuerBaseArgs> = Omit<A, keyof IssuerBaseArgs>;

const issuerSitePath0 = (modPath: (path: string) => string) => (
  i: Issuer,
  ip: IsDraftable,
) => <A extends IssuerBaseArgs>(
  path: (params: A) => UrlInterface<"GET">,
  extraParams: IssuerSiteParam[] = []
) => (args: OmitIssuerBaseArgs<A>): UrlInterface<"GET"> => {
  const ep = extraParams.concat(pipe(ip, O.fold(() => [], _ => getDraftModeParams(_[1], _[0]))));
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const basePath = path({ issuerSlug: i.slug, issuerId: i.id, ...args } as A).url;
  const fullPath = ep.length > 0
    ? basePath + (basePath.includes("?") ? "&" : "?") + ep.map(encodeURIPair).join("&")
    : basePath;
  return urlInterface("GET", modPath(fullPath));
};

export const issuerSitePath = issuerSitePath0(identity);

export const issuerSiteAbsUrl = (config: BLConfigWithLog) => (
  i: Issuer,
  ip: IsDraftable,
) =>
  issuerSitePath0(u => `${O.getOrElse(() => config.baseUrl)(i.customDomain)}${u}`)(i, ip);

export const issuerHomeUrl =
  (config: BLConfigWithLog) =>
    (i: Issuer) =>
      issuerSiteAbsUrl(config)(i, O.none)(
        SitesRouter.issuersitesIssuerControllerIndex
      )({});

// Explicitly define additional params that can be passed in addition to the params required by the router
export const forceDraftPreviewParam = ["forcePreview", "true"] as const;
const expectedISParams = [forceDraftPreviewParam];
export type IssuerSiteParam = typeof expectedISParams[number];

export const formatIssuerAddress: (address: O.Option<Address>) => string =
  O.fold(() => noneStr, _ => `${_.city}, ${_.state.abbrev}`);

// The format of this link matches the scala generated html.
export const makeRatingAgencyDeepLink = (config: BLConfigWithLog, issuer: Issuer, ratingAgency: RatingAgencyU) =>
  `${issuerSiteAbsUrl(config)(issuer, O.none)(
    SitesRouter.issuersitesBondOfferingsControllerIndex
  )({}).url}#anchor-${slugify(ratingAgency.agencyName)}`;
