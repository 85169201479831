import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const issuer = {
  _tag: `Issuer`,
  id: 1,
  personaName: `Issuer`
} as const;

export const issuerTaggedC = t.type({
  _tag: t.literal(`Issuer`)
});
export type IssuerTaggedC = typeof issuerTaggedC;
export type IssuerTagged = t.TypeOf<IssuerTaggedC>;
export type Issuer = IssuerTagged & typeof issuer;
export const issuerC = pipe(issuerTaggedC, c => new t.Type<Issuer, IssuerTagged>(
  `Issuer`,
  (u: unknown): u is Issuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuer }))),
  (x: Issuer): IssuerTagged => ({ ...x, _tag: `Issuer`}),
));
export type IssuerC = typeof issuerC;


export const investorIndividual = {
  _tag: `InvestorIndividual`,
  id: 2,
  personaName: `Investor (Individual)`
} as const;

export const investorIndividualTaggedC = t.type({
  _tag: t.literal(`InvestorIndividual`)
});
export type InvestorIndividualTaggedC = typeof investorIndividualTaggedC;
export type InvestorIndividualTagged = t.TypeOf<InvestorIndividualTaggedC>;
export type InvestorIndividual = InvestorIndividualTagged & typeof investorIndividual;
export const investorIndividualC = pipe(investorIndividualTaggedC, c => new t.Type<InvestorIndividual, InvestorIndividualTagged>(
  `InvestorIndividual`,
  (u: unknown): u is InvestorIndividual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorIndividual> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorIndividual }))),
  (x: InvestorIndividual): InvestorIndividualTagged => ({ ...x, _tag: `InvestorIndividual`}),
));
export type InvestorIndividualC = typeof investorIndividualC;


export const ratingAgency = {
  _tag: `RatingAgency`,
  id: 3,
  personaName: `Rating Agency / Credit Guarantor`
} as const;

export const ratingAgencyTaggedC = t.type({
  _tag: t.literal(`RatingAgency`)
});
export type RatingAgencyTaggedC = typeof ratingAgencyTaggedC;
export type RatingAgencyTagged = t.TypeOf<RatingAgencyTaggedC>;
export type RatingAgency = RatingAgencyTagged & typeof ratingAgency;
export const ratingAgencyC = pipe(ratingAgencyTaggedC, c => new t.Type<RatingAgency, RatingAgencyTagged>(
  `RatingAgency`,
  (u: unknown): u is RatingAgency => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingAgency> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingAgency }))),
  (x: RatingAgency): RatingAgencyTagged => ({ ...x, _tag: `RatingAgency`}),
));
export type RatingAgencyC = typeof ratingAgencyC;


export const bankingInvestmentBanker = {
  _tag: `BankingInvestmentBanker`,
  id: 4,
  personaName: `Banking (Investment Banker)`
} as const;

export const bankingInvestmentBankerTaggedC = t.type({
  _tag: t.literal(`BankingInvestmentBanker`)
});
export type BankingInvestmentBankerTaggedC = typeof bankingInvestmentBankerTaggedC;
export type BankingInvestmentBankerTagged = t.TypeOf<BankingInvestmentBankerTaggedC>;
export type BankingInvestmentBanker = BankingInvestmentBankerTagged & typeof bankingInvestmentBanker;
export const bankingInvestmentBankerC = pipe(bankingInvestmentBankerTaggedC, c => new t.Type<BankingInvestmentBanker, BankingInvestmentBankerTagged>(
  `BankingInvestmentBanker`,
  (u: unknown): u is BankingInvestmentBanker => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingInvestmentBanker> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingInvestmentBanker }))),
  (x: BankingInvestmentBanker): BankingInvestmentBankerTagged => ({ ...x, _tag: `BankingInvestmentBanker`}),
));
export type BankingInvestmentBankerC = typeof bankingInvestmentBankerC;


export const municipalAdvisor = {
  _tag: `MunicipalAdvisor`,
  id: 5,
  personaName: `Municipal Advisor`
} as const;

export const municipalAdvisorTaggedC = t.type({
  _tag: t.literal(`MunicipalAdvisor`)
});
export type MunicipalAdvisorTaggedC = typeof municipalAdvisorTaggedC;
export type MunicipalAdvisorTagged = t.TypeOf<MunicipalAdvisorTaggedC>;
export type MunicipalAdvisor = MunicipalAdvisorTagged & typeof municipalAdvisor;
export const municipalAdvisorC = pipe(municipalAdvisorTaggedC, c => new t.Type<MunicipalAdvisor, MunicipalAdvisorTagged>(
  `MunicipalAdvisor`,
  (u: unknown): u is MunicipalAdvisor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalAdvisor> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalAdvisor }))),
  (x: MunicipalAdvisor): MunicipalAdvisorTagged => ({ ...x, _tag: `MunicipalAdvisor`}),
));
export type MunicipalAdvisorC = typeof municipalAdvisorC;


export const media = {
  _tag: `Media`,
  id: 6,
  personaName: `Media / Non-Financial / Other`
} as const;

export const mediaTaggedC = t.type({
  _tag: t.literal(`Media`)
});
export type MediaTaggedC = typeof mediaTaggedC;
export type MediaTagged = t.TypeOf<MediaTaggedC>;
export type Media = MediaTagged & typeof media;
export const mediaC = pipe(mediaTaggedC, c => new t.Type<Media, MediaTagged>(
  `Media`,
  (u: unknown): u is Media => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Media> => pipe(c.decode(u), E.map(x => ({ ...x, ...media }))),
  (x: Media): MediaTagged => ({ ...x, _tag: `Media`}),
));
export type MediaC = typeof mediaC;


export const counsel = {
  _tag: `Counsel`,
  id: 7,
  personaName: `Counsel`
} as const;

export const counselTaggedC = t.type({
  _tag: t.literal(`Counsel`)
});
export type CounselTaggedC = typeof counselTaggedC;
export type CounselTagged = t.TypeOf<CounselTaggedC>;
export type Counsel = CounselTagged & typeof counsel;
export const counselC = pipe(counselTaggedC, c => new t.Type<Counsel, CounselTagged>(
  `Counsel`,
  (u: unknown): u is Counsel => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Counsel> => pipe(c.decode(u), E.map(x => ({ ...x, ...counsel }))),
  (x: Counsel): CounselTagged => ({ ...x, _tag: `Counsel`}),
));
export type CounselC = typeof counselC;


export const investorInstitutional = {
  _tag: `InvestorInstitutional`,
  id: 8,
  personaName: `Investor (Institutional)`
} as const;

export const investorInstitutionalTaggedC = t.type({
  _tag: t.literal(`InvestorInstitutional`)
});
export type InvestorInstitutionalTaggedC = typeof investorInstitutionalTaggedC;
export type InvestorInstitutionalTagged = t.TypeOf<InvestorInstitutionalTaggedC>;
export type InvestorInstitutional = InvestorInstitutionalTagged & typeof investorInstitutional;
export const investorInstitutionalC = pipe(investorInstitutionalTaggedC, c => new t.Type<InvestorInstitutional, InvestorInstitutionalTagged>(
  `InvestorInstitutional`,
  (u: unknown): u is InvestorInstitutional => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorInstitutional> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorInstitutional }))),
  (x: InvestorInstitutional): InvestorInstitutionalTagged => ({ ...x, _tag: `InvestorInstitutional`}),
));
export type InvestorInstitutionalC = typeof investorInstitutionalC;


export const bankingUnderwriting = {
  _tag: `BankingUnderwriting`,
  id: 9,
  personaName: `Banking (Underwriting)`
} as const;

export const bankingUnderwritingTaggedC = t.type({
  _tag: t.literal(`BankingUnderwriting`)
});
export type BankingUnderwritingTaggedC = typeof bankingUnderwritingTaggedC;
export type BankingUnderwritingTagged = t.TypeOf<BankingUnderwritingTaggedC>;
export type BankingUnderwriting = BankingUnderwritingTagged & typeof bankingUnderwriting;
export const bankingUnderwritingC = pipe(bankingUnderwritingTaggedC, c => new t.Type<BankingUnderwriting, BankingUnderwritingTagged>(
  `BankingUnderwriting`,
  (u: unknown): u is BankingUnderwriting => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingUnderwriting> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingUnderwriting }))),
  (x: BankingUnderwriting): BankingUnderwritingTagged => ({ ...x, _tag: `BankingUnderwriting`}),
));
export type BankingUnderwritingC = typeof bankingUnderwritingC;


export const bankingSales = {
  _tag: `BankingSales`,
  id: 10,
  personaName: `Banking (Sales)`
} as const;

export const bankingSalesTaggedC = t.type({
  _tag: t.literal(`BankingSales`)
});
export type BankingSalesTaggedC = typeof bankingSalesTaggedC;
export type BankingSalesTagged = t.TypeOf<BankingSalesTaggedC>;
export type BankingSales = BankingSalesTagged & typeof bankingSales;
export const bankingSalesC = pipe(bankingSalesTaggedC, c => new t.Type<BankingSales, BankingSalesTagged>(
  `BankingSales`,
  (u: unknown): u is BankingSales => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingSales> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingSales }))),
  (x: BankingSales): BankingSalesTagged => ({ ...x, _tag: `BankingSales`}),
));
export type BankingSalesC = typeof bankingSalesC;


export const investorRIAWealthMgmt = {
  _tag: `InvestorRIAWealthMgmt`,
  id: 11,
  personaName: `Investor (RIA / Wealth Mgmt.)`
} as const;

export const investorRIAWealthMgmtTaggedC = t.type({
  _tag: t.literal(`InvestorRIAWealthMgmt`)
});
export type InvestorRIAWealthMgmtTaggedC = typeof investorRIAWealthMgmtTaggedC;
export type InvestorRIAWealthMgmtTagged = t.TypeOf<InvestorRIAWealthMgmtTaggedC>;
export type InvestorRIAWealthMgmt = InvestorRIAWealthMgmtTagged & typeof investorRIAWealthMgmt;
export const investorRIAWealthMgmtC = pipe(investorRIAWealthMgmtTaggedC, c => new t.Type<InvestorRIAWealthMgmt, InvestorRIAWealthMgmtTagged>(
  `InvestorRIAWealthMgmt`,
  (u: unknown): u is InvestorRIAWealthMgmt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorRIAWealthMgmt> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorRIAWealthMgmt }))),
  (x: InvestorRIAWealthMgmt): InvestorRIAWealthMgmtTagged => ({ ...x, _tag: `InvestorRIAWealthMgmt`}),
));
export type InvestorRIAWealthMgmtC = typeof investorRIAWealthMgmtC;


export const allUserPersonaC = [issuerC, investorIndividualC, ratingAgencyC, bankingInvestmentBankerC, municipalAdvisorC, mediaC, counselC, investorInstitutionalC, bankingUnderwritingC, bankingSalesC, investorRIAWealthMgmtC] as const;
export const allUserPersonaNames = [`Issuer`, `InvestorIndividual`, `RatingAgency`, `BankingInvestmentBanker`, `MunicipalAdvisor`, `Media`, `Counsel`, `InvestorInstitutional`, `BankingUnderwriting`, `BankingSales`, `InvestorRIAWealthMgmt`] as const;
export type UserPersonaName = (typeof allUserPersonaNames)[number];

export const UserPersonaCU = t.union([issuerC, investorIndividualC, ratingAgencyC, bankingInvestmentBankerC, municipalAdvisorC, mediaC, counselC, investorInstitutionalC, bankingUnderwritingC, bankingSalesC, investorRIAWealthMgmtC]);
export type UserPersonaCU = typeof UserPersonaCU;
export type UserPersonaU = t.TypeOf<UserPersonaCU>;

export const userPersonaOrd: Ord.Ord<UserPersonaU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserPersona = [issuer, investorIndividual, ratingAgency, bankingInvestmentBanker, municipalAdvisor, media, counsel, investorInstitutional, bankingUnderwriting, bankingSales, investorRIAWealthMgmt] as const;
export type UserPersonaMap<A> = { [K in UserPersonaName]: A };


