import { O, RNEA } from "@scripts/fp-ts";
import { contact as contactFF, documents } from "@scripts/generated/domaintables/featureFlags";
import type { BankOfferingData } from "@scripts/generated/models/bondOffering";
import * as sitesRouter from "@scripts/generated/routers/sitesRouter";
import { BankOfferingContent } from "@scripts/react/actions/offering-pages/BankOfferingContent";
import { OfferingSummary } from "@scripts/react/components/offering-pages/OfferingSummary";
import { makeRelatedContentDataO, makeRoadshowDataO, parseRoadshows } from "@scripts/react/components/offering-pages/RelatedContent";
import { klass } from "@scripts/react/util/classnames";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { isDrafted } from "@scripts/syntax/threadThrough";

import { useBondSubscribe } from "../../api/watchlist";
import { BankIssuerChrome } from "../../components/Chrome";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { BondOfferingContactModal } from "../../components/offering-pages/RelatedContent";
import { useIssuerSitesSelector } from "../../state/store";

export const BankOfferingPage = (props: BankOfferingData) => {
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const makeOnSubscribe = useBondSubscribe();

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(props.offering.data.data.record.data.relatedContent.roadShows));
  const documentsDataO = makeRelatedContentDataO(documents, iffs, props.offering.data.data.record.data.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(props.offering.data.data.record.data.relatedContent.externalLinks);

  const loginRedirect = sitesRouter.issuersitesBondOfferingsControllerOffering({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    offeringId: props.offering.data.data.id,
  }).url;

  return (
    <BankIssuerChrome
      bank={props.bank}
      loginRequired={props.offering.data.data.record.data.offering.loginRequired}
      loginRedirectUrl={loginRedirect}
      offeringId={props.offering.data.data.id}
    >
      <div {...klass("container")}>
        <BankOfferingContent
          header={O.some(
            <HeaderWithSubscription
              isSubscribed={props.offering.subscribed}
              makeOnSubscribe={makeOnSubscribe}
              subscriptionId={props.offering.data.data.id}
              taggedContent={O.some(props.offering.data.data.record)}
              title={props.offering.data.data.record.data.offering.name}
              viewAllRoute={O.none}
              subscribeDisabled={isDrafted(props.offering.data)}
            />
          )}
          issuer={issuer}
          leafIcon={IssuerSitesLeafIcon}
          offeringData={props}
          offeringSummary={
            <OfferingSummary
              contactModal={O.fromPredicate(() => isFFEnabled(contactFF)(iffs))(<BondOfferingContactModal offering={props.offering} />)}
              documentsO={documentsDataO}
              issuer={issuer}
              linksO={linksDataO}
              offering={props.offering}
              klasses={"accent-border-top"}
              leafIcon={IssuerSitesLeafIcon}
            />
          }
          roadshowsDataO={roadshowDataO}
        />
      </div>
    </BankIssuerChrome>
  );
};
