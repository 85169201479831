import type { PropsWithChildren, ReactNode } from "react";
import { Fragment, useCallback, useMemo, useState } from "react";
import * as E from "fp-ts/lib/Either";
import type { Lazy } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import type * as TE from "fp-ts/lib/TaskEither";
import * as These from "fp-ts/lib/These";
import type * as t from "io-ts";

import type { SVGString } from "*.svg";

import type { ApiData, ApiFetchWithCredResp } from "@scripts/api/methods";
import { responseErrorHandler } from "@scripts/api/responseErrorHandler";
import type { RespOrErrors } from "@scripts/fetch";
import { danger } from "@scripts/generated/domaintables/alertTypes";
import type { EmptyObjectC } from "@scripts/generated/models/emptyObject";
import { Alert } from "@scripts/react/components/Alert";
import { ButtonAsync, ButtonDanger, ButtonPrimary, ButtonsContainer, ButtonSecondary } from "@scripts/react/components/Button";
import { retryLater } from "@scripts/react/components/form/FormErrorMessages";
import { AlertModal } from "@scripts/react/components/modal/Alert";
import type { ModalDismissable } from "@scripts/react/components/modal/Modal";
import type { FormStateBase } from "@scripts/react/form/form";
import { responseErrorsC } from "@scripts/react/form/responseCodecs";
import { klass } from "@scripts/react/util/classnames";
import type { ModalOpen } from "@scripts/react/util/useModal";
import { closeModalFn, useModal } from "@scripts/react/util/useModal";
import type { UrlInterfaceIO } from "@scripts/routes/urlInterface";
import { trimFilter } from "@scripts/util/trimFilter";

import checkmark from "@svgs/checkmark.svg";
import gear from "@svgs/gear.svg";
import trash from "@svgs/trash.svg";
import warning from "@svgs/warning.svg";

export type CustomIconProps = { customIcon: O.Option<SVGString> };

export type DeleteApiProps = {
  recordId: number;
  deleteUrl: (params: { id: number }) => UrlInterfaceIO<"POST", EmptyObjectC, t.Mixed>;
  apiFetchWithCredResp: ApiFetchWithCredResp;
};

type ConfirmationProps = ModalDismissable & {
  title: string;
  open: ModalOpen;
};

export const Confirmation = (props: PropsWithChildren<ConfirmationProps>) => {
  return (
    <AlertModal
      title={props.title}
      icon={checkmark}
      open={props.open}
      dismissAction={O.some(props.dismissAction)}
      body={
        <Fragment>
          {props.children}
          <ButtonsContainer klasses={O.none}>
            <ButtonPrimary {...klass("mr-1")} onClick={props.dismissAction}>Close</ButtonPrimary>
          </ButtonsContainer>
        </Fragment>
      }
    />
  );
};

export type ConfirmDiscardChangesProps = ModalDismissable & {
  open: ModalOpen;
  onDiscardChanges: Lazy<void>;
};

export const ConfirmDiscardChanges = (props: PropsWithChildren<ConfirmDiscardChangesProps>) => {
  const continueEditing = () => {
    props.dismissAction();
  };
  const discardChanges = () => {
    props.dismissAction();
    props.onDiscardChanges();
  };
  return (
    <AlertModal
      title={"Unsaved Changes"}
      icon={warning}
      open={props.open}
      dismissAction={O.some(props.dismissAction)}
      body={
        <Fragment>
          {props.children}
          <ButtonPrimary {...klass("mr-1")} onClick={continueEditing}>Continue Editing</ButtonPrimary>
          <ButtonSecondary {...klass("mr-1")} onClick={discardChanges}>Discard Changes</ButtonSecondary>
        </Fragment>
      }
    />
  );
};

export const useDiscardConfirmModal = (states: ReadonlyArray<FormStateBase>, dismissAction: Lazy<void>, suppressUnsavedChangesModal?: boolean) => {
  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] = useModal("Discard Confirmation Modal");

  const handleDiscard = useCallback(() => {
    dismissAction();
  }, [dismissAction]);

  const handleDismiss = useCallback(() => {
    states.some(_ => _.modified) && !suppressUnsavedChangesModal
      ? openConfirmModal()
      : dismissAction();
  }, [dismissAction, openConfirmModal, states, suppressUnsavedChangesModal]);

  return {
    closeConfirmModal,
    handleDiscard,
    handleDismiss: closeModalFn(handleDismiss),
    isConfirmModalOpen,
  };
};

type ConfirmationDeleteProps = ModalDismissable & {
  title: string;
  onSuccess: () => void;
  open: ModalOpen;
  deleteApiProps: O.Option<DeleteApiProps>;
  confirmButtonText: "Deletion" | "Removal";
};

export const ConfirmationDelete = (props: PropsWithChildren<ConfirmationDeleteProps>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<{ error: boolean, message: O.Option<string> }>({ error: false, message: O.none });
  const postDeleteMethod: O.Option<TE.TaskEither<RespOrErrors, ApiData<{ [key: string]: unknown }>>> = useMemo(() =>
    pipe(
      props.deleteApiProps,
      O.map(({ deleteUrl, recordId, apiFetchWithCredResp }) => apiFetchWithCredResp(deleteUrl({ id: recordId }))({ id: recordId })(
        (e) => {
          pipe(e, These.mapLeft(handleRequestErrors));
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          props.onSuccess();
        },
      ))
    ),
    [props]
  );

  const handleRequestErrors = (or: O.Option<Response>) => {
    const defaultErrorHandler = () => setErr({ error: true, message: O.none });
    const serverErrorHandler = (a: unknown) => pipe(
      responseErrorsC.decode(a),
      E.map(dsr => dsr.errors),
      E.fold(
        () => setErr({ error: true, message: O.none }),
        (errors) => setErr({ error: true, message: pipe(RA.head(errors), O.chain(errorInfo => trimFilter(errorInfo.extra))) })
      )
    );
    responseErrorHandler(or)(defaultErrorHandler, defaultErrorHandler, defaultErrorHandler, serverErrorHandler);
  };

  const deleteAction = () => {
    setErr({ error: false, message: O.none });
    setIsLoading(true);
    pipe(
      postDeleteMethod,
      O.fold(
        () => {
          setIsLoading(false);
          props.onSuccess();
        },
        p => p(),
      )
    );
  };

  return (
    <AlertModal
      klass={"has-danger"}
      title={props.title}
      icon={trash}
      open={props.open}
      dismissAction={O.some(props.dismissAction)}
      body={
        <Fragment>
          {props.children}
          <ButtonsContainer klasses={O.none}>
            <ButtonAsync
              loading={isLoading}
              loadingText={"Deleting"}
              variant={"danger"}
              {...klass("mr-1", "mt-15")}
              onClick={deleteAction}
              text={`Confirm ${props.confirmButtonText}`}
            />
            <ButtonSecondary onClick={props.dismissAction}>
              Cancel
            </ButtonSecondary>
          </ButtonsContainer>
          {err.error && <div {...klass("confirmation-error")}>
            <Alert icon={false} pill={false} type={danger}>
              {pipe(
                err.message,
                O.getOrElse<ReactNode>(() => retryLater)
              )}
            </Alert>
          </div>}
        </Fragment>
      }
    />
  );
};

type ConfirmationToggleProps = ModalDismissable & {
  title: string;
  confirmAction: () => void;
  confirmText: O.Option<string>;
  open: ModalOpen;
  status: "enable" | "disable";
};

export const ConfirmationToggle = (props: PropsWithChildren<ConfirmationToggleProps> & CustomIconProps) => {
  return (
    <AlertModal
      title={props.title}
      icon={O.getOrElse(() => gear)(props.customIcon)}
      open={props.open}
      dismissAction={O.some(props.dismissAction)}
      body={
        <Fragment>
          {props.children}
          <ButtonsContainer klasses={O.none}>
            {props.status === "enable"
              ? <ButtonDanger onClick={props.confirmAction}>
                {O.getOrElse(() => "Disable")(props.confirmText)}
              </ButtonDanger>
              : <ButtonPrimary onClick={props.confirmAction}>
                {O.getOrElse(() => "Enable")(props.confirmText)}
              </ButtonPrimary>
            }
            <ButtonSecondary onClick={props.dismissAction}>
              Cancel
            </ButtonSecondary>
          </ButtonsContainer>
        </Fragment>
      }
    />
  );
};
