import * as Eq from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as s from "fp-ts/lib/string";
import * as t from "io-ts";
import { Lens } from "monocle-ts";

import { E, RA } from "@scripts/fp-ts";
import type { OperationFilterParam } from "@scripts/routes/routing/params/base";
import { type Joda } from "@scripts/syntax/date/joda";
import { LocalDateEq } from "@scripts/syntax/date/jodaSyntax";
import { emptyStr } from "@scripts/syntax/none";

import { type FilterOperationType, filterOperationTypeC } from "./filter";
import { LocalDateC } from "./localDate";

// Separators that don't get URL encoded by `URLSearchParams` which is used by fp-ts-routing
export const arrayElementSeparator = "·"; // Middle Dot - U+00B7 - Unicode 1.1 (June 1993)
export const objectFieldSepartor = "፡"; // Ethiopic Wordspace - U+1361 - Unicode 3.0 (September 1999)

export type QueryStringArray<A> = t.Type<ReadonlyArray<A>, string | undefined>;
export const QueryStringArray = <A, O = A>(codec: t.Type<A, O>): t.Type<ReadonlyArray<A>, string | undefined> => new t.Type(
  "QueryStringArray",
  t.readonlyArray(codec).is,
  (input: unknown, context: t.Context) =>
    pipe(
      t.string.validate(input, context),
      E.chain((val) => pipe(
        val.split(arrayElementSeparator),
        RA.traverse(E.Applicative)(a => codec.validate(a, context))
      )),
    ),
  // eslint-disable-next-line no-undefined
  a => a.length === 0 ? undefined : a.map(codec.encode).join(arrayElementSeparator),
);

export const QueryAnyAllNone = <A, O = A>(codec: t.Type<A, O>): t.Type<OperationFilterParam<A>, string | undefined> => new t.Type(
  "QueryAnyAll",
  t.type({ operation: filterOperationTypeC, selection: t.readonlyArray(codec) }).is,
  (input: unknown, context: t.Context) =>
    pipe(
      t.string.validate(input, context),
      E.chain((val: string) => {
        const [op, sel] = val.split(objectFieldSepartor);

        return pipe(
          filterOperationTypeC.validate(op, context),
          E.chain(operation => pipe(
            O.fromNullable(sel),
            O.fold(() => [], _ => _.split(arrayElementSeparator)),
            RA.traverse(E.Applicative)(a => codec.validate(a, context)),
            E.map(selection => ({ operation, selection }))
          ))
        );
      }),
    ),
  a => `${a.operation}${a.selection.length === 0 ? emptyStr : objectFieldSepartor}${a.selection.map(codec.encode).join(arrayElementSeparator)}`,
);

export const AnyAllNoneParamEq = Eq.struct<OperationFilterParam<unknown>>({ operation: s.Eq, selection: RA.getEq(Eq.eqStrict) });

const OperationFilterL = <S>() => Lens.fromProp<OperationFilterParam<S>>();
export const OperationFilterOperationSetterL = <S>(op: FilterOperationType) => OperationFilterL<S>()("operation").set(op);
export const OperationFilterSelectionSetterL = <S>(sel: ReadonlyArray<S>) => OperationFilterL<S>()("selection").set(sel);


// Date Range

const dateRangeTypeUC = <A extends AnyDateRangeCUnion>(additional: A) => t.union([...additional]);

const dateRangeTypeC = <A extends AnyDateRangeCUnion>(additional: A) => new t.Type<string, string, unknown>(
  "DateRangeType",
  dateRangeTypeUC(additional).is,
  (input: unknown, context: t.Context) => pipe(
    t.string.validate(input, context),
    E.chain((val) => dateRangeTypeUC(additional).validate(val, context)),
  ),
  (a) => dateRangeTypeUC(additional).is(a) ? a : "error",
);

type AnyDateRangeC = t.Mixed;
export type AnyDateRangeCArray = readonly [AnyDateRangeC, ...AnyDateRangeC[]];
type AnyDateRangeCUnion = readonly [AnyDateRangeC, AnyDateRangeC, ...AnyDateRangeC[]];
const DateRangeBase = <A extends AnyDateRangeCUnion>(additional: A) => new t.Type<{ type: t.TypeOf<A[number]>, start: Joda.LocalDate, end: Joda.LocalDate }, string, unknown>(
  "DateRange",
  t.type({ type: dateRangeTypeUC(additional), start: LocalDateC, end: LocalDateC }).is,
  (input: unknown, context: t.Context) =>
    pipe(
      t.string.validate(input, context),
      E.chain((val) => {
        const [dateRangeType, ...lds] = val.split(objectFieldSepartor);

        return pipe(
          dateRangeTypeC(additional).validate(dateRangeType, context),
          E.chain((type) => {
            const [startDate, endDate] = lds.join().split(arrayElementSeparator);

            return pipe(
              LocalDateC.validate(startDate, context),
              E.chain((start) => pipe(
                LocalDateC.validate(endDate, context),
                E.map((end) => ({ type, start, end })),
              )),
            );
          }),
        );
      }),
    ),
  (a) => `${dateRangeTypeC(additional).encode(a.type)}${objectFieldSepartor}${LocalDateC.encode(a.start)}${arrayElementSeparator}${LocalDateC.encode(a.end)}`,
);

const standardC = t.literal("standard");
const customC = t.literal("custom");

export const dateRangeC = <const A extends AnyDateRangeC[]>(...a: A) => DateRangeBase([standardC, customC, ...a]);
export type DateRangeAny = t.TypeOf<ReturnType<typeof dateRangeC>>;

export type DateRangeGeneric<A extends string> = {
  dateRange: {
    type: typeof standardC._A | A;
    start: Joda.LocalDate;
    end: Joda.LocalDate;
  };
};

export const dateRangeEq = Eq.struct<DateRangeAny>({ type: s.Eq, start: LocalDateEq, end: LocalDateEq });
