import type { ChangeEvent, Dispatch, FocusEventHandler, HTMLInputTypeAttribute, InputHTMLAttributes, MouseEventHandler, PropsWithChildren, ReactElement, ReactEventHandler, RefObject, SetStateAction } from "react";
import { useCallback, useState } from "react";
import type { NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constVoid, flow, pipe } from "fp-ts/lib/function";
import * as s from "fp-ts/lib/string";
import { useStableEffect } from "fp-ts-react-stable-hooks";

import type { SVGString } from "*.svg";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import type { BaseInputProps } from "@scripts/react/components/form/Form";
import {
  disabledClass,
  errorClass,
  formDangerStyles,
  LabelEl,
  valErrMsgEls,
  valErrMsgElsO,
} from "@scripts/react/components/form/Labels";
import { useConfig } from "@scripts/react/context/Config";
import type { Codec } from "@scripts/react/form/codecs";
import { isRequiredC, numericFormatMatchDecimal } from "@scripts/react/form/codecs";
import type { DataCodec, FormState, UnsafeDataLens, UnsafeFormProp } from "@scripts/react/form/form";
import { clearAndSetState, getValErrViaCodec, parseValue, updateStateViaParsed, useVeOnChangeTransition } from "@scripts/react/form/form";
import type { Klass, KlassList } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import type { LabelOrAriaLabel } from "@scripts/util/labelOrAriaLabel";
import { getLabel, idFromLabelOrAriaLabel, toLabelString } from "@scripts/util/labelOrAriaLabel";
import type { NonArray } from "@scripts/util/nonArray";
import type { FuzzyResult } from "@scripts/util/uFuzzy";

import { ButtonLinkIcon } from "../Button";
import { Empty, mapOrEmpty, toEmpty } from "../Empty";
import type { TooltipProps } from "../Tooltip";
import type { PasswordAutocompleteAttribute } from "./PasswordInput";

export const onLocalStateChangeBase = <PC extends DataCodec, KV>(
  state: FormState<PC>,
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  setLocalState: Dispatch<SetStateAction<string>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<NonArray<KV>>>,
  codec: Codec<NonArray<KV>>) =>
  (e: string) => e.length <= 0
    ? clearAndSetState(setState, lens)
    : pipe(
      e,
      parseValue(codec),
      _ => {
        E.isRight(_) && setLocalState(e);
        setState(updateStateViaParsed<PC, UnsafeFormProp<NonArray<KV>>>(lens, codec)(_)(state));
      }
    );

export const onLocalStateChange =
  <PC extends DataCodec, KV, CE extends HTMLInputElement | HTMLTextAreaElement>(
    state: FormState<PC>,
    setState: Dispatch<SetStateAction<FormState<PC>>>,
    setLocalState: Dispatch<SetStateAction<string>>,
    lens: UnsafeDataLens<PC, UnsafeFormProp<NonArray<KV>>>,
    codec: Codec<NonArray<KV>>
  ) =>
    (e: ChangeEvent<CE>) =>
      onLocalStateChangeBase(state, setState, setLocalState, lens, codec)(e.target.value);

const mapInputmode = (config: BLConfigWithLog) => (inputType: InputType): InputMode => {
  switch (inputType) {
    case "text":
      return "text";
    case "email":
      return "email";
    case "password":
      return "text";
    case "tel":
      return "tel";
    case "url":
      return "url";
    case "number":
      return "numeric";
  }

  return config.exhaustive(inputType);
};

type TextInputLabelRawProps = {
  children?: ReactElement;
  id: string;
  required: boolean;
  label: string;
  tooltip?: TooltipProps;
  klasses?: Klass[];
};

type TextInputLabelProps<PC extends DataCodec, KV> = TextInputLabelRawProps
  & Omit<InputBaseProps<PC, KV>, "klasses" | "lens" | "placeholder" | "setState" | "state" | "type" | "labelComponent" | "ariaLabelledById">;

export function TextInputLabelRaw(p: TextInputLabelRawProps): ReactElement {
  return <div {...klassPropO(["label-container"])(p.klasses)}>
    <LabelEl {...p} />
    {p.children}
  </div>;
}
export const MaxLengthLabel = (p: { val: O.Option<string>, maxLength: number }) => (
  <p {...klass("char-count", "small")}>{pipe(p.val, O.fold(() => 0, (v) => v.length))} characters (max {p.maxLength})</p>
);

export function TextInputLabel<PC extends DataCodec, KV>(p: TextInputLabelProps<PC, KV>): ReactElement {
  return (
    <TextInputLabelRaw {...p}>
      {p.maxLength && p.maxLength > 0 ? <MaxLengthLabel maxLength={p.maxLength} val={p.value} /> : <Empty />}
    </TextInputLabelRaw>
  );
}

export const TextInputLabelWithAction = <PC extends DataCodec, KV>(props: PropsWithChildren<Omit<TextInputLabelProps<PC, KV>, "labelComponent" | "value" | "klasses">>): ReactElement =>
  <TextInputLabelRaw
    {...props}
    klasses={["w-100"]}
    required={props.requiredOverride ?? isRequiredC(props.codec)}
    label={toLabelString(props.labelOrAriaLabel)}
  >
    {props.children}
  </TextInputLabelRaw>;

export const labelToComponentO = (label: LabelOrAriaLabel, labelComponent: ReactElement) => E.isLeft(label) ? O.some(labelComponent) : O.none;

export const TextInputWithAction = <PC extends DataCodec, KV>(props: Omit<InputProps<PC, KV>, "type"> & { children: ReactElement, type: InputType }) => {

  const ariaLabelledById = idFromLabelOrAriaLabel(props.labelOrAriaLabel);

  const labelComponent = labelToComponentO(
    props.labelOrAriaLabel,
    <TextInputLabelWithAction
      {...props}
      id={ariaLabelledById}
      required={props.requiredOverride ?? isRequiredC(props.codec)}
      label={toLabelString(props.labelOrAriaLabel)}
    >
      {props.children}
    </TextInputLabelWithAction>
  );

  return <InputBase {...props} ariaLabelledById={ariaLabelledById} labelComponent={labelComponent} />;
};

export type AutocompleteAttribute =
  | "on"
  | "off"
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "email"
  | "username"
  | PasswordAutocompleteAttribute
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "address-level1"
  | "address-level2"
  | "address-level3"
  | "address-level4"
  | "country"
  | "country-name"
  | "postal-code"
  | "tel"
  | "tel-country-code"
  | "tel-national"
  | "tel-area-code"
  | "tel-local"
  | "tel-extension"
  | "url";

type InputType = Extract<HTMLInputTypeAttribute, "text" | "email" | "password" | "tel" | "url" | "number">;
type InputTypeStrict = Exclude<InputType, "email" | "url" | "tel">;
type InputMode = InputHTMLAttributes<HTMLInputElement>["inputMode"];
type IconWithAction = {
  icon: SVGString;
  ariaLabel: string;
  action: O.Option<ReactEventHandler>;
};
type InputAffix = E.Either<string, IconWithAction>;
export const iconWithAction = (icon: SVGString, ariaLabel: string, action: O.Option<ReactEventHandler>): IconWithAction => ({ icon, action, ariaLabel });
const foldInputAffix = (position: "prefix" | "postfix") => mapOrEmpty(E.fold(
  (st: string) => <span {...klass(`input-${position}`)}>{st}</span>,
  (r: IconWithAction) => pipe(
    r.action,
    O.fold(
      () => ({
        class: "no-action",
        action: constVoid,
      }),
      (action) => ({
        class: "",
        action,
      })
    ),
    (c) =>
      <span {...klass(`input-${position}`, c.class)}>
        <ButtonLinkIcon
          {...klass("b-0", position === "prefix" ? "ml-025" : "mr-025")}
          onClick={c.action}
          icon={r.icon}
          textOrAriaLabel={E.right(r.ariaLabel)}
        />
    </span>
  ))
);

export type InputRawProps = {
  ariaLabelledById: string;
  autoCapitalize?: string;
  autoComplete?: AutocompleteAttribute;
  disabled?: boolean;
  errorComponent: O.Option<ReactElement>;
  inputMode?: InputMode;
  inputRef?: RefObject<HTMLInputElement>;
  klasses?: KlassList;
  containerKlasses?: KlassList;
  labelComponent: O.Option<ReactElement>;
  labelOrAriaLabel: LabelOrAriaLabel;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: O.Option<string>;
  prefix?: InputAffix;
  postfix?: InputAffix;
  type: InputType;
  value: O.Option<string>;
  readOnly?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
};

type InputBaseProps<PC extends DataCodec, KV> = Omit<InputRawProps, "errors" | "onChange" | "errorComponent"> & BaseInputProps<PC, NonArray<KV>> & {
  maxLength?: number;
  tooltip?: TooltipProps;
};

export type InputProps<PC extends DataCodec, KV> = Omit<InputBaseProps<PC, KV>, "ariaLabelledById" | "labelComponent" | "value"> & {
  type: InputTypeStrict;
};

export type InputPropsStrict<PC extends DataCodec, KV> = Omit<InputProps<PC, KV>, "type" | "inputMode" | "placeholder" | "prefix" | "postfix">;

const mapAffixClasses = (position: "prefix" | "postfix") => O.map(
  (type: InputAffix) => {
    const affixType = E.fold(() => `text-${position}`, () => `icon-${position}`)(type);
    return `form-input-${position} ${affixType}`;
  }
);
export const InputLabelWrapper = (p: PropsWithChildren<Pick<InputRawProps, "disabled" | "labelComponent" | "errorComponent" | "prefix" | "postfix" | "containerKlasses">>): ReactElement => {
  const prefix = O.fromNullable(p.prefix);
  const postfix = O.fromNullable(p.postfix);
  return (
    <div
      {...klass(
        "form-input",
        disabledClass(p.disabled),
        O.map(() => formDangerStyles)(p.errorComponent),
        mapAffixClasses("prefix")(prefix),
        mapAffixClasses("postfix")(postfix),
      )}
    >
      {toEmpty(p.labelComponent)}
      <div {...klassPropO("input-container-inner")(p.containerKlasses)}>
        {foldInputAffix("prefix")(prefix)}
        {foldInputAffix("postfix")(postfix)}
        {p.children}
      </div>
      {toEmpty(p.errorComponent)}
    </div>
  );
};

export const InputRaw = (p: InputRawProps): ReactElement => {
  const config = useConfig();

  return <InputLabelWrapper {...p}>
    <input
      {...klassPropO("")(p.klasses)}
      aria-label={toLabelString(p.labelOrAriaLabel)}
      aria-labelledby={p.ariaLabelledById}
      autoCapitalize={p.autoCapitalize}
      autoComplete={p.autoComplete}
      disabled={p.disabled}
      inputMode={p.inputMode ?? mapInputmode(config)(p.type)}
      onChange={p.onChange}
      placeholder={O.getOrElse(() => "")(p.placeholder)}
      ref={p.inputRef}
      type={p.type === "url" ? "text" : p.type}
      value={O.getOrElse(() => "")(p.value)}
      readOnly={p.readOnly}
      onClick={p.onClick}
      onFocus={p.onFocus}
    />
  </InputLabelWrapper>;
};

export type DefaultOrSearchedItems<A, B> = E.Either<ReadonlyArray<A>, ReadonlyArray<FuzzyResult<B>>>;

export function InputBase<PC extends DataCodec, KV>(p: Omit<InputBaseProps<PC, KV>, "value">): ReactElement {
  const id = idFromLabelOrAriaLabel(p.labelOrAriaLabel);
  const [ve, localValue, onChangeFn] = useVeOnChangeTransition(p.state, p.lens, p.codec);

  const onChangeWithClear = useCallback((_: ChangeEvent<HTMLInputElement>) =>
    s.isEmpty(_.target.value) ? clearAndSetState(p.setState, p.lens) : onChangeFn(p.setState, p.lens, p.codec)(_)
    , [p.setState, p.lens, p.codec, onChangeFn]);

  return (
    <InputRaw
      {...p}
      ariaLabelledById={id}
      errorComponent={valErrMsgElsO(O.some(toLabelString(p.labelOrAriaLabel)), ve.val)(ve.err)}
      inputMode={p.inputMode}
      onChange={onChangeWithClear}
      value={localValue}
    />
  );
}

export function Input<PC extends DataCodec, KV>(p: InputProps<PC, KV>): ReactElement {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(p.state, p.lens, p.codec);
  const ariaLabelledById = idFromLabelOrAriaLabel(p.labelOrAriaLabel);

  const labelComponent: O.Option<ReactElement> =
    labelToComponentO(
      p.labelOrAriaLabel,
      <TextInputLabel
        codec={p.codec}
        value={ve.val}
        label={toLabelString(p.labelOrAriaLabel)}
        labelOrAriaLabel={p.labelOrAriaLabel}
        required={p.requiredOverride ?? isRequiredC(p.codec)}
        id={ariaLabelledById}
        tooltip={p.tooltip}
      />
    );

  return (
    <InputBase {...p} ariaLabelledById={ariaLabelledById} labelComponent={labelComponent} />
  );
}

type NumberOfDecimalPlaces = 0 | 2;
type NumericFormatInputProps<PC extends DataCodec, KV> = Omit<InputPropsStrict<PC, KV>, "inputRef">
  & Pick<NumericFormatProps, "allowLeadingZeros" | "allowNegative">
  & {
    numberOfDecimalPlaces: NumberOfDecimalPlaces;
  prefix?: InputAffix;
  postfix?: InputAffix;
  };

export function NumericFormatInput<PC extends DataCodec, KV>(p: NumericFormatInputProps<PC, KV>): ReactElement {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(p.state, p.lens, p.codec);
  const [localState, setLocalState] = useState(O.getOrElse(() => "")(ve.val));

  useStableEffect(() => {
    pipe(
      ve.val,
      O.fold(
        () => O.some(s.empty),
        formVal => pipe(
          localState,
          p.codec.decode,
          E.map(p.codec.encode),
          E.fold(
            () => O.some(formVal),
            flow(
              O.filter(encodedLocal => !s.Eq.equals(formVal, encodedLocal)),
              O.map(() => p.numberOfDecimalPlaces === 2 ? formVal : formVal.replace(numericFormatMatchDecimal, ""))
            )
          )
        )
      ),
      O.map(setLocalState)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ve.val], Eq.tuple(O.getEq(s.Eq)));

  const id: string = idFromLabelOrAriaLabel(p.labelOrAriaLabel);
  const labelComponent = labelToComponentO(
    p.labelOrAriaLabel,
    <TextInputLabel
      codec={p.codec}
      labelOrAriaLabel={p.labelOrAriaLabel}
      value={ve.val}
      required={p.requiredOverride ?? isRequiredC(p.codec)}
      label={toLabelString(p.labelOrAriaLabel)}
      id={id}
    />
  );

  return (
    <InputLabelWrapper
      errorComponent={valErrMsgElsO(O.some(toLabelString(p.labelOrAriaLabel)), ve.val)(ve.err)}
      labelComponent={labelComponent}
      prefix={p.prefix}
      postfix={p.postfix}
    >
      <NumericFormat
        aria-labelledby={id}
        value={localState}
        onChange={onLocalStateChange(p.state, p.setState, setLocalState, p.lens, p.codec)}
        allowLeadingZeros={p.allowLeadingZeros}
        allowNegative={p.allowNegative}
        decimalScale={p.numberOfDecimalPlaces}
        inputMode={p.numberOfDecimalPlaces === 0 ? "numeric" : "decimal"}
        fixedDecimalScale={false}
        thousandSeparator=","
        type="text"
      />
    </InputLabelWrapper>
  );
}

type DollarsAndCentsProps<PC extends DataCodec, KV> = Omit<NumericFormatInputProps<PC, KV>, "allowLeadingZeroes" | "prefix" | "postfix" | "allowNegative">;
export const CurrencyInput = <PC extends DataCodec, KV>(p: DollarsAndCentsProps<PC, KV>): ReactElement =>
  <NumericFormatInput
    {...p}
    allowLeadingZeros
    prefix={E.left("$")}
    numberOfDecimalPlaces={p.numberOfDecimalPlaces}
    allowNegative={false}
  />;

type PercentInputProps<PC extends DataCodec, KV> = Omit<NumericFormatInputProps<PC, KV>, "allowLeadingZeroes" | "prefix" | "postfix" | "allowNegative">;
export const PercentInput = <PC extends DataCodec, KV>(p: PercentInputProps<PC, KV>): ReactElement =>
  <NumericFormatInput
    {...p}
    postfix={E.left("%")}
    allowLeadingZeros
    numberOfDecimalPlaces={p.numberOfDecimalPlaces}
    allowNegative={false}
  />;

export function EmailInput<PC extends DataCodec, KV>(p: InputPropsStrict<PC, KV>): ReactElement {
  return (
    <Input {...p} placeholder={O.none} inputMode="email" type="text" />
  );
}

export function TelInput<PC extends DataCodec, KV>(p: InputPropsStrict<PC, KV>): ReactElement {
  return (
    <Input {...p} placeholder={O.none} inputMode="tel" type="text" />
  );
}

export function URLInput<PC extends DataCodec, KV>(p: InputPropsStrict<PC, KV>): ReactElement {
  return (
    <Input {...p} placeholder={O.some("https://")} inputMode="url" type="text" autoCapitalize="none" />
  );
}

export const SocialInput = <PC extends DataCodec, KV>(props: Omit<InputBaseProps<PC, KV>, "placeholder" | "labelComponent" | "type"> & { icon: ReactElement }) => (
  <div {...klass("input-with-icon")}>
    <div {...klass("icon-circle", O.isSome(props.value) ? "accent-2-700-bg" : "gray-300-bg")}>
      {props.icon}
    </div>
    <InputBase
      {...props}
      type={"text"}
      placeholder={O.some("Paste link here")}
      containerKlasses={["w-100"]}
      labelComponent={O.some(<TextInputLabelRaw id={props.ariaLabelledById} klasses={O.isNone(props.value) ? ["disabled"] : []} required={props.requiredOverride ?? isRequiredC(props.codec)} label={toLabelString(props.labelOrAriaLabel)} />)}
    />
  </div>
);

export type TextAreaProps<PC extends DataCodec, KV> = Omit<InputBaseProps<PC, KV>, "type" | "value" | "ariaLabelledById" | "labelComponent">;

export function TextArea<PC extends DataCodec, KV>(p: TextAreaProps<PC, KV>): ReactElement {
  const ariaLabelledById = idFromLabelOrAriaLabel(p.labelOrAriaLabel);
  const [ve, localValue, onChangeFn] = useVeOnChangeTransition(p.state, p.lens, p.codec);

  return (
    <div {...klassPropO(["form-input textarea", disabledClass(p.disabled), errorClass(ve.err)])(p.klasses)}>
      {mapOrEmpty(() =>
        <TextInputLabel
          codec={p.codec}
          labelOrAriaLabel={p.labelOrAriaLabel}
          value={localValue}
          required={p.requiredOverride ?? isRequiredC(p.codec)}
          label={toLabelString(p.labelOrAriaLabel)}
          id={ariaLabelledById}
        />)(getLabel(p.labelOrAriaLabel))}
      <textarea
        aria-labelledby={ariaLabelledById}
        aria-label={toLabelString(p.labelOrAriaLabel)}
        disabled={p.disabled}
        onChange={onChangeFn(p.setState, p.lens, p.codec)}
        placeholder={O.getOrElse(() => "")(p.placeholder)}
        value={O.getOrElse(() => "")(localValue)}
      />
      {valErrMsgEls(O.some(toLabelString(p.labelOrAriaLabel)), ve.val)(ve.err)}
    </div>
  );
}
