export const makeJumplinkId = (sectionName: string) => `anchor-${sectionName}`;

// News & Events
export const upcomingEventsJumplinkId = makeJumplinkId("upcoming-events");
export const newsPressReleasesJumplinkId = makeJumplinkId("news-press-releases");

// Bonds Page
export const bondSalesJumplinkId = makeJumplinkId("bond-sales");
export const rfpsSectionJumplinkId = makeJumplinkId("rfps-section");
export const programsSectionJumplinkId = makeJumplinkId("programs-section");

// RFP page
export const questionsJumplinkId = makeJumplinkId("questions");
export const winningBidJumplinkId = makeJumplinkId("winning-bid");

// Ratings page
export const makeProgramRatingJumplinkId = (programId: number) => makeJumplinkId(`rating-${programId}`);

export const issuerRatingsJumplinkId = makeJumplinkId("issuer-ratings");

// Programs page
export const programOverviewJumplinkId = makeJumplinkId("program-overview");
export const cusip9sJumplinkId = makeJumplinkId("cusip-9s");

// About page
export const aboutSectionJumplinkId = makeJumplinkId("about-section");
export const esgProgramJumplinkId = makeJumplinkId("esg-program");
export const teamJumplinkId = makeJumplinkId("team");

// Shared Jumplink Ids
export const generalOfferingInfoJumplinkId = makeJumplinkId("general-offering-info");
export const offeringParticipantsJumplinkId = makeJumplinkId("participants");
export const relatedContentJumplinkId = makeJumplinkId("related-content");
export const roadshowsJumplinkId = makeJumplinkId("roadshows");
export const summaryJumplinkId = makeJumplinkId("summary");
export const ratingsJumplinkId = makeJumplinkId("ratings");
export const relatedOfferingsJumplinkId = makeJumplinkId("related-offerings");
export const relatedRfpsJumplinkId = makeJumplinkId("related-rfps");
export const relatedProjectsJumplinkId = makeJumplinkId("related-projects");
export const relatedNewsJumplinkId = makeJumplinkId("related-news");
export const relatedDocumentsJumplinkId = makeJumplinkId("related-documents");
