import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const moodys = {
  _tag: `Moodys`,
  agencyName: `Moody's Investors Service`,
  id: 1,
  shortName: `Moody's`
} as const;

export const moodysTaggedC = t.type({
  _tag: t.literal(`Moodys`)
});
export type MoodysTaggedC = typeof moodysTaggedC;
export type MoodysTagged = t.TypeOf<MoodysTaggedC>;
export type Moodys = MoodysTagged & typeof moodys;
export const moodysC = pipe(moodysTaggedC, c => new t.Type<Moodys, MoodysTagged>(
  `Moodys`,
  (u: unknown): u is Moodys => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Moodys> => pipe(c.decode(u), E.map(x => ({ ...x, ...moodys }))),
  (x: Moodys): MoodysTagged => ({ ...x, _tag: `Moodys`}),
));
export type MoodysC = typeof moodysC;


export const sandP = {
  _tag: `SandP`,
  agencyName: `S&P Global Ratings`,
  id: 2,
  shortName: `S&P`
} as const;

export const sandPTaggedC = t.type({
  _tag: t.literal(`SandP`)
});
export type SandPTaggedC = typeof sandPTaggedC;
export type SandPTagged = t.TypeOf<SandPTaggedC>;
export type SandP = SandPTagged & typeof sandP;
export const sandPC = pipe(sandPTaggedC, c => new t.Type<SandP, SandPTagged>(
  `SandP`,
  (u: unknown): u is SandP => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SandP> => pipe(c.decode(u), E.map(x => ({ ...x, ...sandP }))),
  (x: SandP): SandPTagged => ({ ...x, _tag: `SandP`}),
));
export type SandPC = typeof sandPC;


export const fitch = {
  _tag: `Fitch`,
  agencyName: `Fitch Ratings`,
  id: 3,
  shortName: `Fitch`
} as const;

export const fitchTaggedC = t.type({
  _tag: t.literal(`Fitch`)
});
export type FitchTaggedC = typeof fitchTaggedC;
export type FitchTagged = t.TypeOf<FitchTaggedC>;
export type Fitch = FitchTagged & typeof fitch;
export const fitchC = pipe(fitchTaggedC, c => new t.Type<Fitch, FitchTagged>(
  `Fitch`,
  (u: unknown): u is Fitch => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Fitch> => pipe(c.decode(u), E.map(x => ({ ...x, ...fitch }))),
  (x: Fitch): FitchTagged => ({ ...x, _tag: `Fitch`}),
));
export type FitchC = typeof fitchC;


export const kroll = {
  _tag: `Kroll`,
  agencyName: `Kroll Bond Rating Agency`,
  id: 4,
  shortName: `Kroll`
} as const;

export const krollTaggedC = t.type({
  _tag: t.literal(`Kroll`)
});
export type KrollTaggedC = typeof krollTaggedC;
export type KrollTagged = t.TypeOf<KrollTaggedC>;
export type Kroll = KrollTagged & typeof kroll;
export const krollC = pipe(krollTaggedC, c => new t.Type<Kroll, KrollTagged>(
  `Kroll`,
  (u: unknown): u is Kroll => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Kroll> => pipe(c.decode(u), E.map(x => ({ ...x, ...kroll }))),
  (x: Kroll): KrollTagged => ({ ...x, _tag: `Kroll`}),
));
export type KrollC = typeof krollC;


export const assuredGuaranty = {
  _tag: `AssuredGuaranty`,
  agencyName: `Assured Guaranty`,
  id: 5,
  shortName: `Assured Guaranty`
} as const;

export const assuredGuarantyTaggedC = t.type({
  _tag: t.literal(`AssuredGuaranty`)
});
export type AssuredGuarantyTaggedC = typeof assuredGuarantyTaggedC;
export type AssuredGuarantyTagged = t.TypeOf<AssuredGuarantyTaggedC>;
export type AssuredGuaranty = AssuredGuarantyTagged & typeof assuredGuaranty;
export const assuredGuarantyC = pipe(assuredGuarantyTaggedC, c => new t.Type<AssuredGuaranty, AssuredGuarantyTagged>(
  `AssuredGuaranty`,
  (u: unknown): u is AssuredGuaranty => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AssuredGuaranty> => pipe(c.decode(u), E.map(x => ({ ...x, ...assuredGuaranty }))),
  (x: AssuredGuaranty): AssuredGuarantyTagged => ({ ...x, _tag: `AssuredGuaranty`}),
));
export type AssuredGuarantyC = typeof assuredGuarantyC;


export const buildAmericaMutual = {
  _tag: `BuildAmericaMutual`,
  agencyName: `Build America Mutual`,
  id: 6,
  shortName: `Build America Mutual`
} as const;

export const buildAmericaMutualTaggedC = t.type({
  _tag: t.literal(`BuildAmericaMutual`)
});
export type BuildAmericaMutualTaggedC = typeof buildAmericaMutualTaggedC;
export type BuildAmericaMutualTagged = t.TypeOf<BuildAmericaMutualTaggedC>;
export type BuildAmericaMutual = BuildAmericaMutualTagged & typeof buildAmericaMutual;
export const buildAmericaMutualC = pipe(buildAmericaMutualTaggedC, c => new t.Type<BuildAmericaMutual, BuildAmericaMutualTagged>(
  `BuildAmericaMutual`,
  (u: unknown): u is BuildAmericaMutual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildAmericaMutual> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildAmericaMutual }))),
  (x: BuildAmericaMutual): BuildAmericaMutualTagged => ({ ...x, _tag: `BuildAmericaMutual`}),
));
export type BuildAmericaMutualC = typeof buildAmericaMutualC;


export const municipalAssuranceCorp = {
  _tag: `MunicipalAssuranceCorp`,
  agencyName: `Municipal Assurance Corp`,
  id: 7,
  shortName: `Municipal Assurance Corp`
} as const;

export const municipalAssuranceCorpTaggedC = t.type({
  _tag: t.literal(`MunicipalAssuranceCorp`)
});
export type MunicipalAssuranceCorpTaggedC = typeof municipalAssuranceCorpTaggedC;
export type MunicipalAssuranceCorpTagged = t.TypeOf<MunicipalAssuranceCorpTaggedC>;
export type MunicipalAssuranceCorp = MunicipalAssuranceCorpTagged & typeof municipalAssuranceCorp;
export const municipalAssuranceCorpC = pipe(municipalAssuranceCorpTaggedC, c => new t.Type<MunicipalAssuranceCorp, MunicipalAssuranceCorpTagged>(
  `MunicipalAssuranceCorp`,
  (u: unknown): u is MunicipalAssuranceCorp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalAssuranceCorp> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalAssuranceCorp }))),
  (x: MunicipalAssuranceCorp): MunicipalAssuranceCorpTagged => ({ ...x, _tag: `MunicipalAssuranceCorp`}),
));
export type MunicipalAssuranceCorpC = typeof municipalAssuranceCorpC;


export const nationalPublicFinanceGuarantee = {
  _tag: `NationalPublicFinanceGuarantee`,
  agencyName: `National Public Finance Guarantee`,
  id: 8,
  shortName: `National Public Finance Guarantee`
} as const;

export const nationalPublicFinanceGuaranteeTaggedC = t.type({
  _tag: t.literal(`NationalPublicFinanceGuarantee`)
});
export type NationalPublicFinanceGuaranteeTaggedC = typeof nationalPublicFinanceGuaranteeTaggedC;
export type NationalPublicFinanceGuaranteeTagged = t.TypeOf<NationalPublicFinanceGuaranteeTaggedC>;
export type NationalPublicFinanceGuarantee = NationalPublicFinanceGuaranteeTagged & typeof nationalPublicFinanceGuarantee;
export const nationalPublicFinanceGuaranteeC = pipe(nationalPublicFinanceGuaranteeTaggedC, c => new t.Type<NationalPublicFinanceGuarantee, NationalPublicFinanceGuaranteeTagged>(
  `NationalPublicFinanceGuarantee`,
  (u: unknown): u is NationalPublicFinanceGuarantee => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NationalPublicFinanceGuarantee> => pipe(c.decode(u), E.map(x => ({ ...x, ...nationalPublicFinanceGuarantee }))),
  (x: NationalPublicFinanceGuarantee): NationalPublicFinanceGuaranteeTagged => ({ ...x, _tag: `NationalPublicFinanceGuarantee`}),
));
export type NationalPublicFinanceGuaranteeC = typeof nationalPublicFinanceGuaranteeC;


export const syncora = {
  _tag: `Syncora`,
  agencyName: `Syncora`,
  id: 9,
  shortName: `Syncora`
} as const;

export const syncoraTaggedC = t.type({
  _tag: t.literal(`Syncora`)
});
export type SyncoraTaggedC = typeof syncoraTaggedC;
export type SyncoraTagged = t.TypeOf<SyncoraTaggedC>;
export type Syncora = SyncoraTagged & typeof syncora;
export const syncoraC = pipe(syncoraTaggedC, c => new t.Type<Syncora, SyncoraTagged>(
  `Syncora`,
  (u: unknown): u is Syncora => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Syncora> => pipe(c.decode(u), E.map(x => ({ ...x, ...syncora }))),
  (x: Syncora): SyncoraTagged => ({ ...x, _tag: `Syncora`}),
));
export type SyncoraC = typeof syncoraC;


export const unsafeSandPStateAidForMesirow = {
  _tag: `UnsafeSandPStateAidForMesirow`,
  agencyName: `S&P - State Aid`,
  id: 10,
  shortName: `S&P - State Aid`
} as const;

export const unsafeSandPStateAidForMesirowTaggedC = t.type({
  _tag: t.literal(`UnsafeSandPStateAidForMesirow`)
});
export type UnsafeSandPStateAidForMesirowTaggedC = typeof unsafeSandPStateAidForMesirowTaggedC;
export type UnsafeSandPStateAidForMesirowTagged = t.TypeOf<UnsafeSandPStateAidForMesirowTaggedC>;
export type UnsafeSandPStateAidForMesirow = UnsafeSandPStateAidForMesirowTagged & typeof unsafeSandPStateAidForMesirow;
export const unsafeSandPStateAidForMesirowC = pipe(unsafeSandPStateAidForMesirowTaggedC, c => new t.Type<UnsafeSandPStateAidForMesirow, UnsafeSandPStateAidForMesirowTagged>(
  `UnsafeSandPStateAidForMesirow`,
  (u: unknown): u is UnsafeSandPStateAidForMesirow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnsafeSandPStateAidForMesirow> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsafeSandPStateAidForMesirow }))),
  (x: UnsafeSandPStateAidForMesirow): UnsafeSandPStateAidForMesirowTagged => ({ ...x, _tag: `UnsafeSandPStateAidForMesirow`}),
));
export type UnsafeSandPStateAidForMesirowC = typeof unsafeSandPStateAidForMesirowC;


export const unsafeSandPUnderlyingForMesirow = {
  _tag: `UnsafeSandPUnderlyingForMesirow`,
  agencyName: `S&P - Underlying`,
  id: 11,
  shortName: `S&P - Underlying`
} as const;

export const unsafeSandPUnderlyingForMesirowTaggedC = t.type({
  _tag: t.literal(`UnsafeSandPUnderlyingForMesirow`)
});
export type UnsafeSandPUnderlyingForMesirowTaggedC = typeof unsafeSandPUnderlyingForMesirowTaggedC;
export type UnsafeSandPUnderlyingForMesirowTagged = t.TypeOf<UnsafeSandPUnderlyingForMesirowTaggedC>;
export type UnsafeSandPUnderlyingForMesirow = UnsafeSandPUnderlyingForMesirowTagged & typeof unsafeSandPUnderlyingForMesirow;
export const unsafeSandPUnderlyingForMesirowC = pipe(unsafeSandPUnderlyingForMesirowTaggedC, c => new t.Type<UnsafeSandPUnderlyingForMesirow, UnsafeSandPUnderlyingForMesirowTagged>(
  `UnsafeSandPUnderlyingForMesirow`,
  (u: unknown): u is UnsafeSandPUnderlyingForMesirow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnsafeSandPUnderlyingForMesirow> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsafeSandPUnderlyingForMesirow }))),
  (x: UnsafeSandPUnderlyingForMesirow): UnsafeSandPUnderlyingForMesirowTagged => ({ ...x, _tag: `UnsafeSandPUnderlyingForMesirow`}),
));
export type UnsafeSandPUnderlyingForMesirowC = typeof unsafeSandPUnderlyingForMesirowC;


export const morningstarDBRS = {
  _tag: `MorningstarDBRS`,
  agencyName: `Morningstar DBRS`,
  id: 12,
  shortName: `Morningstar DBRS`
} as const;

export const morningstarDBRSTaggedC = t.type({
  _tag: t.literal(`MorningstarDBRS`)
});
export type MorningstarDBRSTaggedC = typeof morningstarDBRSTaggedC;
export type MorningstarDBRSTagged = t.TypeOf<MorningstarDBRSTaggedC>;
export type MorningstarDBRS = MorningstarDBRSTagged & typeof morningstarDBRS;
export const morningstarDBRSC = pipe(morningstarDBRSTaggedC, c => new t.Type<MorningstarDBRS, MorningstarDBRSTagged>(
  `MorningstarDBRS`,
  (u: unknown): u is MorningstarDBRS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MorningstarDBRS> => pipe(c.decode(u), E.map(x => ({ ...x, ...morningstarDBRS }))),
  (x: MorningstarDBRS): MorningstarDBRSTagged => ({ ...x, _tag: `MorningstarDBRS`}),
));
export type MorningstarDBRSC = typeof morningstarDBRSC;


export const allRatingAgencyC = [moodysC, sandPC, fitchC, krollC, assuredGuarantyC, buildAmericaMutualC, municipalAssuranceCorpC, nationalPublicFinanceGuaranteeC, syncoraC, unsafeSandPStateAidForMesirowC, unsafeSandPUnderlyingForMesirowC, morningstarDBRSC] as const;
export const allRatingAgencyNames = [`Moodys`, `SandP`, `Fitch`, `Kroll`, `AssuredGuaranty`, `BuildAmericaMutual`, `MunicipalAssuranceCorp`, `NationalPublicFinanceGuarantee`, `Syncora`, `UnsafeSandPStateAidForMesirow`, `UnsafeSandPUnderlyingForMesirow`, `MorningstarDBRS`] as const;
export type RatingAgencyName = (typeof allRatingAgencyNames)[number];

export const RatingAgencyCU = t.union([moodysC, sandPC, fitchC, krollC, assuredGuarantyC, buildAmericaMutualC, municipalAssuranceCorpC, nationalPublicFinanceGuaranteeC, syncoraC, unsafeSandPStateAidForMesirowC, unsafeSandPUnderlyingForMesirowC, morningstarDBRSC]);
export type RatingAgencyCU = typeof RatingAgencyCU;
export type RatingAgencyU = t.TypeOf<RatingAgencyCU>;

export const ratingAgencyOrd: Ord.Ord<RatingAgencyU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRatingAgency = [moodys, sandP, fitch, kroll, assuredGuaranty, buildAmericaMutual, municipalAssuranceCorp, nationalPublicFinanceGuarantee, syncora, unsafeSandPStateAidForMesirow, unsafeSandPUnderlyingForMesirow, morningstarDBRS] as const;
export type RatingAgencyMap<A> = { [K in RatingAgencyName]: A };


