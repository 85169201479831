import type { ReactElement } from "react";
import { pipe } from "fp-ts/lib/function";

import { type BLConfigWithLog } from "@scripts/bondlink";
import { E, O, RNEA } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { AnchorIconUnsafe, type Target } from "@scripts/react/components/Anchor";
import { Tooltip } from "@scripts/react/components/Tooltip";
import { roadshowsJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";
import { pluralizeWithS } from "@scripts/util/pluralize";
import { deepLink } from "@scripts/util/url";

import roadshowIcon from "@svgs/roadshow.svg";

import { Badge, type BadgeProps } from "../Badge";
import type { LeafIconAsProp } from "../LeafIcon";
import { ContentItemCard, type ContentItemCardProps } from "./ContentItemCard";

export type DealCardProps = Omit<ContentItemCardProps, "header" | "link" | "name" | "taggedContent"> & {
  name: string;
  url: string;
  target: Target;
  leftBadges: ReadonlyArray<BadgeProps>;
  rightBadge: BadgeProps;
  taggedContent: TaggedContent<unknown>;
} & LeafIconAsProp;

export const DealCard = (props: DealCardProps) =>
  <ContentItemCard
    klass={props.klass}
    link={O.some({ url: props.url, target: props.target })}
    header={{ leftBadges: props.leftBadges, rightContent: Badge(props.rightBadge) }}
    issuer={props.issuer}
    name={O.some(props.name)}
    taggedContent={O.some({ content: props.taggedContent, leafIcon: props.leafIcon })}
    subscription={props.subscription}
  >{props.children}</ContentItemCard>;

export const dealCardRoadshowBadge: (
  roadshows: O.Option<RNEA.ReadonlyNonEmptyArray<WithId<TaggedContent<RoadShowData>>>>
) => O.Option<ContentItemCardProps["header"]["leftBadges"][number]> =
  O.map(rs => ({
    text: `${pluralizeWithS("Roadshow")(rs.length)} Available`,
    icon: O.some(roadshowIcon),
    variant: "tall",
    color: "accent-2-700",
    wrapperKlass: "flex-no-shrink",
  }));

export const dealCardRoadshowsLink = (
  config: BLConfigWithLog,
  issuer: Issuer,
  roadshows: O.Option<RNEA.ReadonlyNonEmptyArray<WithId<TaggedContent<RoadShowData>>>>,
  dealUrl: UrlInterface<"GET">,
  target: Target,
): O.Option<{ key: "roadshows", link: ReactElement }> => {
  const roadshowUrl = (r: WithId<TaggedContent<RoadShowData>>) => issuerSiteAbsUrl(config)(issuer, O.none)(ISR.issuersitesRoadShowControllerRoadShow)({ roadShowId: r.id }).url;

  return pipe(
    roadshows,
    O.map(rs => ({
      key: "roadshows",
      link: rs.length === 1
        ? <AnchorIconUnsafe
          href={roadshowUrl(RNEA.head(rs))}
          icon={roadshowIcon}
          target={target}
          textOrAriaLabel={E.left("View roadshow")}
        />
        : <Tooltip
          delay="default"
          headerBar={{ type: "HeaderBarNoButton", title: "Roadshows" }}
          listItems={rs.map(r => ({
            text: r.record.data.show.title,
            link: O.some({ href: roadshowUrl(r), target: target }),
          }))}
        >
          <AnchorIconUnsafe
            href={deepLink<object>({ type: "Basic", route: () => dealUrl, selector: roadshowsJumplinkId })({}).url}
            icon={roadshowIcon}
            target={target}
            textOrAriaLabel={E.left("View roadshows")}
          />
        </Tooltip>,
    })),
  );
};
