import { withIdC as imported216_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { IssuerRoleCU as imported1_IssuerRoleCU } from "../domaintables/roles";

export const issuerPortalUserC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  lastActive: optionFromNullable(LocalDateTimeFromIsoStringC),
  role: imported1_IssuerRoleCU
});
export type IssuerPortalUserC = typeof issuerPortalUserC;
export type IssuerPortalUser = t.TypeOf<IssuerPortalUserC>;


export const issuerPortalUserCreateC = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  roleId: t.number,
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number)
});
export type IssuerPortalUserCreateC = typeof issuerPortalUserCreateC;
export type IssuerPortalUserCreate = t.TypeOf<IssuerPortalUserCreateC>;


export const issuerPortalUserPageC = t.type({
  portalUsers: t.readonlyArray(imported216_withIdC(issuerPortalUserC))
});
export type IssuerPortalUserPageC = typeof issuerPortalUserPageC;
export type IssuerPortalUserPage = t.TypeOf<IssuerPortalUserPageC>;


export const issuerExistingUserUpdateC = t.type({
  userId: t.number,
  roleId: t.number
});
export type IssuerExistingUserUpdateC = typeof issuerExistingUserUpdateC;
export type IssuerExistingUserUpdate = t.TypeOf<IssuerExistingUserUpdateC>;


