import * as t from "io-ts";
import { documentActivityC as imported2_documentActivityC } from "./documentStatistics";
import { documentWithCategoryC as imported14_documentWithCategoryC } from "./document";
import { withIdC as imported197_withIdC } from "./threadThrough";

export const documentHistoryC = t.type({
  document: imported197_withIdC(imported14_documentWithCategoryC),
  history: t.readonlyArray(imported2_documentActivityC)
});
export type DocumentHistoryC = typeof documentHistoryC;
export type DocumentHistory = t.TypeOf<DocumentHistoryC>;


