import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { StateInfoCU as imported6_StateInfoCU } from "../domaintables/states";

export const addressC = t.type({
  address: t.string,
  city: t.string,
  state: imported6_StateInfoCU,
  postalCode: optionFromNullable(t.string)
});
export type AddressC = typeof addressC;
export type Address = t.TypeOf<AddressC>;


