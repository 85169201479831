import { Route, query, lit, end, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { urlInterface } from "../../routes/urlInterface";

const assetImgDealPortalDashboardHeaderJpgPath = lit("assets").then(lit("img")).then(lit("deal-portal-dashboard-header.jpg"));
const assetImgDealPortalDashboardHeaderJpgPathParts = ["assets", "img", "deal-portal-dashboard-header.jpg"] as const;
const assetImgDealPortalDashboardHeaderJpgQuery = t.strict({});
export type AssetImgDealPortalDashboardHeaderJpgParams = Describe<typeof assetImgDealPortalDashboardHeaderJpgPath._A & typeof assetImgDealPortalDashboardHeaderJpgQuery._A>;

export function assetImgDealPortalDashboardHeaderJpgRoute(): { match: Match<AssetImgDealPortalDashboardHeaderJpgParams>; pathParts: typeof assetImgDealPortalDashboardHeaderJpgPathParts };
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>): { match: Match<AssetImgDealPortalDashboardHeaderJpgParams & t.TypeOf<A>>; pathParts: typeof assetImgDealPortalDashboardHeaderJpgPathParts };
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>) {
  return {
    match: (q ? assetImgDealPortalDashboardHeaderJpgPath.then(query(t.intersection([assetImgDealPortalDashboardHeaderJpgQuery, q]))) : assetImgDealPortalDashboardHeaderJpgPath.then(query(assetImgDealPortalDashboardHeaderJpgQuery))).then(end),
    pathParts: assetImgDealPortalDashboardHeaderJpgPathParts,
  };
}

export const assetImgDealPortalDashboardHeaderJpg = () =>
  urlInterface("GET", assetImgDealPortalDashboardHeaderJpgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailBondlinkEmailLogoBannerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-logo-banner.png"));
const assetImgEmailBondlinkEmailLogoBannerPngPathParts = [
  "assets", "img", "email", "bondlink-email-logo-banner.png"
] as const;
const assetImgEmailBondlinkEmailLogoBannerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailLogoBannerPngParams = Describe<typeof assetImgEmailBondlinkEmailLogoBannerPngPath._A & typeof assetImgEmailBondlinkEmailLogoBannerPngQuery._A>;

export function assetImgEmailBondlinkEmailLogoBannerPngRoute(): { match: Match<AssetImgEmailBondlinkEmailLogoBannerPngParams>; pathParts: typeof assetImgEmailBondlinkEmailLogoBannerPngPathParts };
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>): { match: Match<AssetImgEmailBondlinkEmailLogoBannerPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailBondlinkEmailLogoBannerPngPathParts };
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailLogoBannerPngQuery, q]))) : assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(assetImgEmailBondlinkEmailLogoBannerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailLogoBannerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailLogoBannerPng = () =>
  urlInterface("GET", assetImgEmailBondlinkEmailLogoBannerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailBondlinkEmailDividerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-divider.png"));
const assetImgEmailBondlinkEmailDividerPngPathParts = ["assets", "img", "email", "bondlink-email-divider.png"] as const;
const assetImgEmailBondlinkEmailDividerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailDividerPngParams = Describe<typeof assetImgEmailBondlinkEmailDividerPngPath._A & typeof assetImgEmailBondlinkEmailDividerPngQuery._A>;

export function assetImgEmailBondlinkEmailDividerPngRoute(): { match: Match<AssetImgEmailBondlinkEmailDividerPngParams>; pathParts: typeof assetImgEmailBondlinkEmailDividerPngPathParts };
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>): { match: Match<AssetImgEmailBondlinkEmailDividerPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailBondlinkEmailDividerPngPathParts };
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailDividerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailDividerPngQuery, q]))) : assetImgEmailBondlinkEmailDividerPngPath.then(query(assetImgEmailBondlinkEmailDividerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailDividerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailDividerPng = () =>
  urlInterface("GET", assetImgEmailBondlinkEmailDividerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconBondPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-bond.png"));
const assetImgEmailIconBondPngPathParts = ["assets", "img", "email", "icon-bond.png"] as const;
const assetImgEmailIconBondPngQuery = t.strict({});
export type AssetImgEmailIconBondPngParams = Describe<typeof assetImgEmailIconBondPngPath._A & typeof assetImgEmailIconBondPngQuery._A>;

export function assetImgEmailIconBondPngRoute(): { match: Match<AssetImgEmailIconBondPngParams>; pathParts: typeof assetImgEmailIconBondPngPathParts };
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>): { match: Match<AssetImgEmailIconBondPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconBondPngPathParts };
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>) {
  return {
    match: (q ? assetImgEmailIconBondPngPath.then(query(t.intersection([assetImgEmailIconBondPngQuery, q]))) : assetImgEmailIconBondPngPath.then(query(assetImgEmailIconBondPngQuery))).then(end),
    pathParts: assetImgEmailIconBondPngPathParts,
  };
}

export const assetImgEmailIconBondPng = () =>
  urlInterface("GET", assetImgEmailIconBondPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconDocumentPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-document.png"));
const assetImgEmailIconDocumentPngPathParts = ["assets", "img", "email", "icon-document.png"] as const;
const assetImgEmailIconDocumentPngQuery = t.strict({});
export type AssetImgEmailIconDocumentPngParams = Describe<typeof assetImgEmailIconDocumentPngPath._A & typeof assetImgEmailIconDocumentPngQuery._A>;

export function assetImgEmailIconDocumentPngRoute(): { match: Match<AssetImgEmailIconDocumentPngParams>; pathParts: typeof assetImgEmailIconDocumentPngPathParts };
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>): { match: Match<AssetImgEmailIconDocumentPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconDocumentPngPathParts };
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>) {
  return {
    match: (q ? assetImgEmailIconDocumentPngPath.then(query(t.intersection([assetImgEmailIconDocumentPngQuery, q]))) : assetImgEmailIconDocumentPngPath.then(query(assetImgEmailIconDocumentPngQuery))).then(end),
    pathParts: assetImgEmailIconDocumentPngPathParts,
  };
}

export const assetImgEmailIconDocumentPng = () =>
  urlInterface("GET", assetImgEmailIconDocumentPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconQuestionAndAnswerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-question-and-answer.png"));
const assetImgEmailIconQuestionAndAnswerPngPathParts = [
  "assets", "img", "email", "icon-question-and-answer.png"
] as const;
const assetImgEmailIconQuestionAndAnswerPngQuery = t.strict({});
export type AssetImgEmailIconQuestionAndAnswerPngParams = Describe<typeof assetImgEmailIconQuestionAndAnswerPngPath._A & typeof assetImgEmailIconQuestionAndAnswerPngQuery._A>;

export function assetImgEmailIconQuestionAndAnswerPngRoute(): { match: Match<AssetImgEmailIconQuestionAndAnswerPngParams>; pathParts: typeof assetImgEmailIconQuestionAndAnswerPngPathParts };
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>): { match: Match<AssetImgEmailIconQuestionAndAnswerPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconQuestionAndAnswerPngPathParts };
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>) {
  return {
    match: (q ? assetImgEmailIconQuestionAndAnswerPngPath.then(query(t.intersection([assetImgEmailIconQuestionAndAnswerPngQuery, q]))) : assetImgEmailIconQuestionAndAnswerPngPath.then(query(assetImgEmailIconQuestionAndAnswerPngQuery))).then(end),
    pathParts: assetImgEmailIconQuestionAndAnswerPngPathParts,
  };
}

export const assetImgEmailIconQuestionAndAnswerPng = () =>
  urlInterface("GET", assetImgEmailIconQuestionAndAnswerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconRoadshowPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-roadshow.png"));
const assetImgEmailIconRoadshowPngPathParts = ["assets", "img", "email", "icon-roadshow.png"] as const;
const assetImgEmailIconRoadshowPngQuery = t.strict({});
export type AssetImgEmailIconRoadshowPngParams = Describe<typeof assetImgEmailIconRoadshowPngPath._A & typeof assetImgEmailIconRoadshowPngQuery._A>;

export function assetImgEmailIconRoadshowPngRoute(): { match: Match<AssetImgEmailIconRoadshowPngParams>; pathParts: typeof assetImgEmailIconRoadshowPngPathParts };
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>): { match: Match<AssetImgEmailIconRoadshowPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconRoadshowPngPathParts };
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>) {
  return {
    match: (q ? assetImgEmailIconRoadshowPngPath.then(query(t.intersection([assetImgEmailIconRoadshowPngQuery, q]))) : assetImgEmailIconRoadshowPngPath.then(query(assetImgEmailIconRoadshowPngQuery))).then(end),
    pathParts: assetImgEmailIconRoadshowPngPathParts,
  };
}

export const assetImgEmailIconRoadshowPng = () =>
  urlInterface("GET", assetImgEmailIconRoadshowPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconSocialTwitterPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-social-twitter.png"));
const assetImgEmailIconSocialTwitterPngPathParts = ["assets", "img", "email", "icon-social-twitter.png"] as const;
const assetImgEmailIconSocialTwitterPngQuery = t.strict({});
export type AssetImgEmailIconSocialTwitterPngParams = Describe<typeof assetImgEmailIconSocialTwitterPngPath._A & typeof assetImgEmailIconSocialTwitterPngQuery._A>;

export function assetImgEmailIconSocialTwitterPngRoute(): { match: Match<AssetImgEmailIconSocialTwitterPngParams>; pathParts: typeof assetImgEmailIconSocialTwitterPngPathParts };
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>): { match: Match<AssetImgEmailIconSocialTwitterPngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconSocialTwitterPngPathParts };
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>) {
  return {
    match: (q ? assetImgEmailIconSocialTwitterPngPath.then(query(t.intersection([assetImgEmailIconSocialTwitterPngQuery, q]))) : assetImgEmailIconSocialTwitterPngPath.then(query(assetImgEmailIconSocialTwitterPngQuery))).then(end),
    pathParts: assetImgEmailIconSocialTwitterPngPathParts,
  };
}

export const assetImgEmailIconSocialTwitterPng = () =>
  urlInterface("GET", assetImgEmailIconSocialTwitterPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconWebsitePngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-website.png"));
const assetImgEmailIconWebsitePngPathParts = ["assets", "img", "email", "icon-website.png"] as const;
const assetImgEmailIconWebsitePngQuery = t.strict({});
export type AssetImgEmailIconWebsitePngParams = Describe<typeof assetImgEmailIconWebsitePngPath._A & typeof assetImgEmailIconWebsitePngQuery._A>;

export function assetImgEmailIconWebsitePngRoute(): { match: Match<AssetImgEmailIconWebsitePngParams>; pathParts: typeof assetImgEmailIconWebsitePngPathParts };
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>): { match: Match<AssetImgEmailIconWebsitePngParams & t.TypeOf<A>>; pathParts: typeof assetImgEmailIconWebsitePngPathParts };
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>) {
  return {
    match: (q ? assetImgEmailIconWebsitePngPath.then(query(t.intersection([assetImgEmailIconWebsitePngQuery, q]))) : assetImgEmailIconWebsitePngPath.then(query(assetImgEmailIconWebsitePngQuery))).then(end),
    pathParts: assetImgEmailIconWebsitePngPathParts,
  };
}

export const assetImgEmailIconWebsitePng = () =>
  urlInterface("GET", assetImgEmailIconWebsitePngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlink2019SvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019.svg"));
const assetImgLogosBondlink2019SvgPathParts = ["assets", "img", "logos", "bondlink-2019.svg"] as const;
const assetImgLogosBondlink2019SvgQuery = t.strict({});
export type AssetImgLogosBondlink2019SvgParams = Describe<typeof assetImgLogosBondlink2019SvgPath._A & typeof assetImgLogosBondlink2019SvgQuery._A>;

export function assetImgLogosBondlink2019SvgRoute(): { match: Match<AssetImgLogosBondlink2019SvgParams>; pathParts: typeof assetImgLogosBondlink2019SvgPathParts };
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>): { match: Match<AssetImgLogosBondlink2019SvgParams & t.TypeOf<A>>; pathParts: typeof assetImgLogosBondlink2019SvgPathParts };
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019SvgPath.then(query(t.intersection([assetImgLogosBondlink2019SvgQuery, q]))) : assetImgLogosBondlink2019SvgPath.then(query(assetImgLogosBondlink2019SvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019SvgPathParts,
  };
}

export const assetImgLogosBondlink2019Svg = () =>
  urlInterface("GET", assetImgLogosBondlink2019SvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlink2019InvertedSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019-inverted.svg"));
const assetImgLogosBondlink2019InvertedSvgPathParts = ["assets", "img", "logos", "bondlink-2019-inverted.svg"] as const;
const assetImgLogosBondlink2019InvertedSvgQuery = t.strict({});
export type AssetImgLogosBondlink2019InvertedSvgParams = Describe<typeof assetImgLogosBondlink2019InvertedSvgPath._A & typeof assetImgLogosBondlink2019InvertedSvgQuery._A>;

export function assetImgLogosBondlink2019InvertedSvgRoute(): { match: Match<AssetImgLogosBondlink2019InvertedSvgParams>; pathParts: typeof assetImgLogosBondlink2019InvertedSvgPathParts };
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>): { match: Match<AssetImgLogosBondlink2019InvertedSvgParams & t.TypeOf<A>>; pathParts: typeof assetImgLogosBondlink2019InvertedSvgPathParts };
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019InvertedSvgPath.then(query(t.intersection([assetImgLogosBondlink2019InvertedSvgQuery, q]))) : assetImgLogosBondlink2019InvertedSvgPath.then(query(assetImgLogosBondlink2019InvertedSvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019InvertedSvgPathParts,
  };
}

export const assetImgLogosBondlink2019InvertedSvg = () =>
  urlInterface("GET", assetImgLogosBondlink2019InvertedSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlinkSocialSharePngPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-social-share.png"));
const assetImgLogosBondlinkSocialSharePngPathParts = ["assets", "img", "logos", "bondlink-social-share.png"] as const;
const assetImgLogosBondlinkSocialSharePngQuery = t.strict({});
export type AssetImgLogosBondlinkSocialSharePngParams = Describe<typeof assetImgLogosBondlinkSocialSharePngPath._A & typeof assetImgLogosBondlinkSocialSharePngQuery._A>;

export function assetImgLogosBondlinkSocialSharePngRoute(): { match: Match<AssetImgLogosBondlinkSocialSharePngParams>; pathParts: typeof assetImgLogosBondlinkSocialSharePngPathParts };
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>): { match: Match<AssetImgLogosBondlinkSocialSharePngParams & t.TypeOf<A>>; pathParts: typeof assetImgLogosBondlinkSocialSharePngPathParts };
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>) {
  return {
    match: (q ? assetImgLogosBondlinkSocialSharePngPath.then(query(t.intersection([assetImgLogosBondlinkSocialSharePngQuery, q]))) : assetImgLogosBondlinkSocialSharePngPath.then(query(assetImgLogosBondlinkSocialSharePngQuery))).then(end),
    pathParts: assetImgLogosBondlinkSocialSharePngPathParts,
  };
}

export const assetImgLogosBondlinkSocialSharePng = () =>
  urlInterface("GET", assetImgLogosBondlinkSocialSharePngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosLogoBondlinkPrimarySvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary.svg"));
const assetImgLogosLogoBondlinkPrimarySvgPathParts = ["assets", "img", "logos", "logo-bondlink-primary.svg"] as const;
const assetImgLogosLogoBondlinkPrimarySvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimarySvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimarySvgPath._A & typeof assetImgLogosLogoBondlinkPrimarySvgQuery._A>;

export function assetImgLogosLogoBondlinkPrimarySvgRoute(): { match: Match<AssetImgLogosLogoBondlinkPrimarySvgParams>; pathParts: typeof assetImgLogosLogoBondlinkPrimarySvgPathParts };
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>): { match: Match<AssetImgLogosLogoBondlinkPrimarySvgParams & t.TypeOf<A>>; pathParts: typeof assetImgLogosLogoBondlinkPrimarySvgPathParts };
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimarySvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimarySvgQuery, q]))) : assetImgLogosLogoBondlinkPrimarySvgPath.then(query(assetImgLogosLogoBondlinkPrimarySvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimarySvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimarySvg = () =>
  urlInterface("GET", assetImgLogosLogoBondlinkPrimarySvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosLogoBondlinkPrimaryFullColorSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary-full-color.svg"));
const assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts = [
  "assets", "img", "logos", "logo-bondlink-primary-full-color.svg"
] as const;
const assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPath._A & typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery._A>;

export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute(): { match: Match<AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>; pathParts: typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts };
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>): { match: Match<AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams & t.TypeOf<A>>; pathParts: typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts };
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery, q]))) : assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimaryFullColorSvg = () =>
  urlInterface("GET", assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetSvgsChevronUpSvgPath = lit("assets").then(lit("svgs")).then(lit("chevron-up.svg"));
const assetSvgsChevronUpSvgPathParts = ["assets", "svgs", "chevron-up.svg"] as const;
const assetSvgsChevronUpSvgQuery = t.strict({});
export type AssetSvgsChevronUpSvgParams = Describe<typeof assetSvgsChevronUpSvgPath._A & typeof assetSvgsChevronUpSvgQuery._A>;

export function assetSvgsChevronUpSvgRoute(): { match: Match<AssetSvgsChevronUpSvgParams>; pathParts: typeof assetSvgsChevronUpSvgPathParts };
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>): { match: Match<AssetSvgsChevronUpSvgParams & t.TypeOf<A>>; pathParts: typeof assetSvgsChevronUpSvgPathParts };
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>) {
  return {
    match: (q ? assetSvgsChevronUpSvgPath.then(query(t.intersection([assetSvgsChevronUpSvgQuery, q]))) : assetSvgsChevronUpSvgPath.then(query(assetSvgsChevronUpSvgQuery))).then(end),
    pathParts: assetSvgsChevronUpSvgPathParts,
  };
}

export const assetSvgsChevronUpSvg = () =>
  urlInterface("GET", assetSvgsChevronUpSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetSvgsTrashSvgPath = lit("assets").then(lit("svgs")).then(lit("trash.svg"));
const assetSvgsTrashSvgPathParts = ["assets", "svgs", "trash.svg"] as const;
const assetSvgsTrashSvgQuery = t.strict({});
export type AssetSvgsTrashSvgParams = Describe<typeof assetSvgsTrashSvgPath._A & typeof assetSvgsTrashSvgQuery._A>;

export function assetSvgsTrashSvgRoute(): { match: Match<AssetSvgsTrashSvgParams>; pathParts: typeof assetSvgsTrashSvgPathParts };
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>): { match: Match<AssetSvgsTrashSvgParams & t.TypeOf<A>>; pathParts: typeof assetSvgsTrashSvgPathParts };
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>) {
  return {
    match: (q ? assetSvgsTrashSvgPath.then(query(t.intersection([assetSvgsTrashSvgQuery, q]))) : assetSvgsTrashSvgPath.then(query(assetSvgsTrashSvgQuery))).then(end),
    pathParts: assetSvgsTrashSvgPathParts,
  };
}

export const assetSvgsTrashSvg = () =>
  urlInterface("GET", assetSvgsTrashSvgRoute().match.formatter.run(Route.empty, {}).toString(true));