import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";
import { mediaC as imported81_mediaC } from "./media";
import { CustomReportTypeCU as imported1_CustomReportTypeCU } from "../domaintables/customReportTypes";

export const customReportC = t.type({
  date: LocalDateC,
  name: t.string,
  document: imported81_mediaC,
  reportType: imported1_CustomReportTypeCU
});
export type CustomReportC = typeof customReportC;
export type CustomReport = t.TypeOf<CustomReportC>;


export const customReportsC = t.type({
  customReports: t.readonlyArray(customReportC)
});
export type CustomReportsC = typeof customReportsC;
export type CustomReports = t.TypeOf<CustomReportsC>;


