import React from "react";
import useResizeObserver from "@react-hook/resize-observer";

export type ElementSize = { height: number, width: number };

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [React.MutableRefObject<T | null>, ElementSize] => {
  const target = React.useRef<T | null>(null);
  const [size, setSize] = React.useState<ElementSize>({
    width: 0,
    height: 0,
  });
  React.useEffect(() => {
    if (target.current != null) {
      const data = target.current.getBoundingClientRect();
      setSize({ width: Math.round(data.width), height: Math.round(data.height) });
    }
  }, [target]);
  useResizeObserver(target, (resize) => {
    const sz = {
      height: Math.round(resize.contentRect.height),
      width: Math.round(resize.contentRect.width),
    };
    setSize(sz);
  });
  return [target, size];
};
