import { type PropsWithChildren } from "react";

import type { Breakpoint } from "@scripts/bondlinkStatic";
import { GridCol } from "@scripts/react/components/layout/Grid";
import { klass, klassConditional, type KlassProp, klassPropO } from "@scripts/react/util/classnames";

type BreakpointProps = { breakpoint?: Exclude<Breakpoint, "xs" | "ul"> };
const defaultBP = "md";

export const MainGridBase = (props: PropsWithChildren<BreakpointProps & { klasses: KlassProp }>) =>
  <GridCol cols={[".c-24", `.c-${props.breakpoint ?? defaultBP}-16`]} klasses={props.klasses}>
    {props.children}
  </GridCol>;

export const MainGridLeft = (props: PropsWithChildren<BreakpointProps>) =>
  <MainGridBase klasses={`pr-${props.breakpoint ?? defaultBP}-2`} breakpoint={props.breakpoint}>
    {props.children}
  </MainGridBase>;

export const MainGridRight = (props: PropsWithChildren<BreakpointProps>) =>
  <MainGridBase klasses={`pl-${props.breakpoint ?? defaultBP}-2`} breakpoint={props.breakpoint}>
    {props.children}
  </MainGridBase>;

export const SideBarGridBase = (props: PropsWithChildren<BreakpointProps & { klasses: KlassProp }>) =>
  <GridCol
    cols={[".c-24", `.c-${props.breakpoint ?? defaultBP}-8`]}
    klasses={props.klasses}
  >
    {props.children}
  </GridCol>;

export const SideBarGridRightWithBorder = (props: PropsWithChildren<BreakpointProps>) =>
  <SideBarGridBase
    klasses={[
      "bl-0",
      `bl-${props.breakpoint ?? defaultBP}-0125`,
      `pl-${props.breakpoint ?? defaultBP}-2`,
      "pt-1",
      `pt-${props.breakpoint ?? defaultBP}-0`,
      "accent-border-top",
      `no-accent-border-top-${props.breakpoint ?? defaultBP}`,
    ]}
  >
    <div {...klass("mb-3")}>
      {props.children}
    </div>
  </SideBarGridBase>;

export const DealSummaryGrid = (props: PropsWithChildren<{ klassProp?: KlassProp }>) => <div {...klassPropO("deal-summary-grid")(props.klassProp)}>{props.children}</div>;

export const UserProfileGrid = (props: PropsWithChildren<{ klassProp?: KlassProp }>) => <div {...klassPropO("user-activity-grid")(props.klassProp)}>{props.children}</div>;

export const DealViewGrid = (props: PropsWithChildren<{ klassProp?: KlassProp }>) => <div {...klassPropO("deal-view-grid")(props.klassProp)}>{props.children}</div>;

export const LeftGrid = (props: PropsWithChildren<object>) => <div className="left-col">{props.children}</div>;

export const MiddleGrid = (props: PropsWithChildren<object>) => <div className="middle-col">{props.children}</div>;

export const RightGrid = (props: PropsWithChildren<object>) => <div className="right-col">{props.children}</div>;

export const Grid7525 = (props: PropsWithChildren<object>) => <div {...klass("grid-75-25-simple")}>{props.children}</div>;

export const JumpLinksSidebarRight = (props: PropsWithChildren<object>) =>
  <div {...klass("grid-right jump-links-sticky")}>
    {props.children}
  </div>;

export const GridLeft = (props: PropsWithChildren<{ klasses?: KlassProp }>) => <div {...klassPropO("grid-left")(props.klasses)}>{props.children}</div>;
export const GridRight = (props: PropsWithChildren<{ hasBorder?: true }>) => <div {...klassConditional("border", "grid-right")(Boolean(props.hasBorder))}>{props.children}</div>;
