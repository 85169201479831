import { O, pipe } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { OfferingParticipant } from "@scripts/generated/models/offeringParticipant";
import { AnchorMailTo, AnchorWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { PageRouteLink } from "@scripts/routes/routing/base";

import { ThumbnailCard } from "./ThumbnailCard";

export const ParticipantCard = (props: { participant: OfferingParticipant }) => {
  const participant = props.participant;
  const logoUrl: O.Option<string> = O.map((data: { record: Media }) => data.record.uploadResponse.uri)(participant.photo);
  const pageRouteLink: O.Option<PageRouteLink> = O.map<string, PageRouteLink>((url: string) => ({
    title: participant.name,
    route: {
      method: "GET",
      url: url,
    },
  }))(participant.url);

  return (
    <ThumbnailCard
      cardKlasses={["card-hover"]}
      cardUrlPropsO={O.none}
      imgPropsO={pipe(
        logoUrl,
        O.map(_ => ({
          altText: `Logo for ${participant.name}.`,
          imgUrl: _,
        }))
      )}
    >
      <h5 className="mb-0">
        {O.fold<PageRouteLink, React.ReactNode>(
          () => participant.name,
          (route) =>
            <AnchorWithChildren
              target="_blank"
              route={route}
              externalLinkLocation="none"
            >
              {participant.name}
            </AnchorWithChildren>,
        )(pageRouteLink)}
      </h5>
      <div className="contact-info">
        {mapOrEmpty<string>((personName) => <p> {personName} </p>)(participant.contactName)}
        {mapOrEmpty<string>((phoneNumber) => <p> {phoneNumber} </p>)(participant.contactPhone)}
        {mapOrEmpty<string>((emailAddress) => <p><AnchorMailTo email={emailAddress} /></p>)(participant.contactEmail)}
      </div>
    </ThumbnailCard>
  );
};
