import type { PropsWithChildren, ReactElement } from "react";

import type { MarkdownTag } from "@scripts/codecs/markdown";
import { O, pipe } from "@scripts/fp-ts";
import { AnchorUnsafe } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Grid7525, GridLeft, JumpLinksSidebarRight } from "@scripts/react/components/layout/PageGrid";
import { Markdown } from "@scripts/react/components/Markdown";
import { klass } from "@scripts/react/util/classnames";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";

export const DirectSitesPageLayout = (props: PropsWithChildren<{
  headerComponent: ReactElement;
  sidebarContent: ReactElement;
}>) => <div {...klass("container")}>
    <Grid7525>
      <div {...klass("d-md-none")}>{props.headerComponent}</div>
      <JumpLinksSidebarRight>
        {props.sidebarContent}
      </JumpLinksSidebarRight>
      <GridLeft>
        <div {...klass("d-none-until-md")}>{props.headerComponent}</div>
        {props.children}
      </GridLeft>
    </Grid7525>
  </div>;


export const DirectSitesPageDescriptionLayout = (props: PropsWithChildren<{
  description: MarkdownTag;
  sidebarContent: ReactElement;
  relatedPage?: O.Option<{ text: string, url: string }>;
}>) => {
  const relPageO = fromNullableOrOption(props.relatedPage);
  const marginClasses = O.fold(() => "mb-1 mb-md-2", () => "mb-1")(relPageO);
  const relPageLink = pipe(
    relPageO,
    mapOrEmpty(({ text, url }) => <div>
      <AnchorUnsafe
        target="_self"
        href={url}
        title={text}
        klasses="pagelink mt-0 mb-1 d-inline-block"
      />
    </div>),
  );

  return <DirectSitesPageLayout
    {...props}
    headerComponent={<>
      <div {...klass(`large ${marginClasses}`)}>
        <Markdown content={props.description} klasses={["last-child-mb-0"]} />
      </div>
      {relPageLink}
    </>}
  />;
};
