import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { IssuerEsgPageData } from "@scripts/generated/models/issuerPageData";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { documentMap, RelatedContentListTruncated } from "@scripts/react/components/offering-pages/RelatedContent";
import { RoadshowCardGrid } from "@scripts/react/components/RoadshowCardGrid";
import { type JumpLink } from "@scripts/react/components/SidebarLinks";
import { esgProgram, projectsTitle, rfps } from "@scripts/routes/routing/ssr/issuersites";
import { relatedDocumentsJumplinkId, relatedNewsJumplinkId, relatedOfferingsJumplinkId, relatedProjectsJumplinkId, relatedRfpsJumplinkId, roadshowsJumplinkId, summaryJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { isRFPArchived } from "@scripts/syntax/rfp";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { newsMap } from "../../components/offering-pages/RelatedContent";
import { OverviewSection } from "../../components/Overview";
import { BondsSection, getNonEmptyItemsRecordO, RfpsSection } from "../../components/related-content/DealSection";
import { ProjectsSectionTruncated } from "../../components/related-content/ProjectsSection";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const relatedOfferingsSectionTitle = "Related Bond Offerings";
const relatedRoadshowsSectionTitle = "Related Roadshows";
const relatedDocumentsSectionTitle = "Related Documents";
const relatedNewsSectionTitle = "Related News";
const overviewSectionTitle = "About Our Program";

export const EsgProgram = (props: {
  data: IssuerEsgPageData;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  const rfpsPage = rfps({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const rfpsTitle = rfpsPage.title(pages);
  const relatedRfpsSectionTitle = `Related ${rfpsTitle}`;

  const projectsSectionTitle = projectsTitle(pages);
  const relatedProjectsSectionTitle = `Related ${projectsSectionTitle}`;

  const page = esgProgram({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, issuer);

  const showOverview = O.isSome(props.data.overview) || RA.isNonEmpty(props.data.callouts) || RA.isNonEmpty(props.data.photos);

  const bondOfferingsO = pipe(
    props.data.bondOfferings,
    RA.partition(_ => _.data.data.record.data.offering.isArchived),
    ({ left: active, right: archived }) => getNonEmptyItemsRecordO({ active, archived })
  );

  const rfpsO = pipe(
    props.data.rfps,
    RA.partition(_ => isRFPArchived(_.data.data.record.data.rfp)),
    ({ left: active, right: archived }) => getNonEmptyItemsRecordO({ active, archived }),
  );

  const roadshowsO = RNEA.fromReadonlyArray(props.data.roadshows);
  const relatedProjectsO = RNEA.fromReadonlyArray(props.data.projects);
  const documentsO = RNEA.fromReadonlyArray(props.data.documents);
  const relatedNewsO = RNEA.fromReadonlyArray(props.data.news);

  const jumpLinks: O.Option<RNEA.ReadonlyNonEmptyArray<JumpLink>> =
    RNEA.fromReadonlyArray(RA.compact([
      makeJumplinkO(overviewSectionTitle, summaryJumplinkId, showOverview),
      makeJumplinkO(relatedOfferingsSectionTitle, relatedOfferingsJumplinkId, O.isSome(bondOfferingsO)),
      makeJumplinkO(relatedRfpsSectionTitle, relatedRfpsJumplinkId, O.isSome(rfpsO)),
      makeJumplinkO(relatedRoadshowsSectionTitle, roadshowsJumplinkId, O.isSome(roadshowsO)),
      makeJumplinkO(relatedProjectsSectionTitle, relatedProjectsJumplinkId, O.isSome(relatedProjectsO)),
      makeJumplinkO(relatedDocumentsSectionTitle, relatedDocumentsJumplinkId, O.isSome(documentsO)),
      makeJumplinkO(relatedNewsSectionTitle, relatedNewsJumplinkId, O.isSome(relatedNewsO)),
    ]));

  const jumplinksEl = pipe(jumpLinks, mapOrEmpty(jl => <>
    <SidebarLinksSites
      headerLinkCustomAnchorContent={O.none}
      jumpLinks={jl}
      routeMeta={esgProgram({ issuerId: issuer.id, issuerSlug: issuer.slug })}
    />
    <GetAlertsActionSection containerKlass={"d-none-until-md"} />
  </>));

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={jumplinksEl}
  >
    {pipe(
      showOverview,
      trueOrEmpty(
        <OverviewSection
          title={overviewSectionTitle}
          sectionId={summaryJumplinkId}
          overview={pipe(props.data.overview, O.map(overview => overview.data))}
          facts={props.data.callouts}
          additionalFacts={[]}
          photos={props.data.photos}
          documentsDataO={O.none}
          linksDataO={O.none}
        />)
    )}
    {pipe(bondOfferingsO, mapOrEmpty(bonds =>
      <BondsSection itemName="Bonds" title={relatedOfferingsSectionTitle} sectionId={relatedOfferingsJumplinkId} deals={bonds} />
    ))}
    {pipe(rfpsO, mapOrEmpty(r =>
      <RfpsSection itemName={rfpsTitle} title={relatedRfpsSectionTitle} sectionId={relatedRfpsJumplinkId} deals={r} />
    ))}
    {pipe(
      roadshowsO,
      mapOrEmpty(roadshows =>
        <AccentDividerSection
          sectionId={roadshowsJumplinkId}
          title={O.some(relatedRoadshowsSectionTitle)}
          klasses="accent-border-top"
        >
          <RoadshowCardGrid
            issuer={issuer}
            leafIcon={IssuerSitesLeafIcon}
            roadshows={roadshows}
          />
        </AccentDividerSection>
      ))}
    {pipe(
      relatedProjectsO,
      mapOrEmpty(_ =>
        <ProjectsSectionTruncated
          projects={_}
          sectionId={relatedProjectsJumplinkId}
          title={relatedProjectsSectionTitle}
          truncateLength={4}
        />
      )
    )}
    {pipe(
      documentsO,
      mapOrEmpty(documents => <AccentDividerSection
        sectionId={relatedDocumentsJumplinkId}
        title={O.some(relatedDocumentsSectionTitle)}
        klasses="accent-border-top"
      >
        <RelatedContentListTruncated
          items={documents}
          issuer={issuer}
          headline="Documents"
          mapFn={documentMap((issuerId, mediaId) => SitesRouter.issuersitesReportsControllerViewFile({
            issuerId,
            mediaId,
            issuerSlug: issuer.slug,
          }), IssuerSitesLeafIcon)}
          truncateLength={4}
        />
      </AccentDividerSection>)
    )}
    {pipe(
      relatedNewsO,
      mapOrEmpty(news => <AccentDividerSection
        sectionId={relatedNewsJumplinkId}
        title={O.some(relatedNewsSectionTitle)}
        klasses="accent-border-top"
      >
        <RelatedContentListTruncated
          items={news}
          issuer={issuer}
          headline="News"
          mapFn={newsMap}
          truncateLength={4}
        />
      </AccentDividerSection>)
    )}
  </DirectSitesPageDescriptionLayout>;
};
