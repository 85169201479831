import { O } from "@scripts/fp-ts";
import type { BondOfferingRelatedContent } from "@scripts/generated/models/bondOffering";
import type { BondOffering } from "@scripts/generated/models/bondOfferingBase";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { BondCard, type BondCardProps } from "@scripts/react/components/card/BondCard";
import type { ContentItemCardSubscriptionProps } from "@scripts/react/components/card/ContentItemCard";

import { IssuerSitesLeafIcon } from "./LeafIcon";

export type IssuerSitesBondCardProps = {
  bond: Subscribed<WithStatusU<TaggedContent<{ offering: BondOffering, relatedContent: Pick<BondOfferingRelatedContent, "roadShows" | "documents"> }>>>;
} & Pick<BondCardProps, "issuer"> & O.Infer<ContentItemCardSubscriptionProps["subscription"]>;

export const IssuerSitesBondCard = (props: IssuerSitesBondCardProps) =>
  <BondCard
    issuer={props.issuer}
    displayIssuer={false}
    offering={{ ...props.bond.data.data, record: { ...props.bond.data.data.record, data: props.bond.data.data.record.data.offering } }}
    url={_ => _.url}
    roadshows={props.bond.data.data.record.data.relatedContent.roadShows.map(_ => _.data)}
    documents={props.bond.data.data.record.data.relatedContent.documents.map(_ => _.data.data)}
    links={["details", "roadshows"]}
    subscription={O.some({ isSubscribed: props.isSubscribed, onSubscribe: props.onSubscribe })}
    target="_self"
    leafIcon={IssuerSitesLeafIcon}
  />;
