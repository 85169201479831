import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { MediaCategoryCU as imported6_MediaCategoryCU } from "../domaintables/mediaCategories";
import { taggedContentC as imported121_taggedContentC } from "./taggedContent";
import { emmaDateQualifierC as imported2_emmaDateQualifierC, emmaSubcategoryC as imported2_emmaSubcategoryC, emmaDisclosureTypeC as imported2_emmaDisclosureTypeC, emmaCategoryC as imported2_emmaCategoryC } from "./emmaCategories";
import { postRelatedC as imported33_postRelatedC, relatedReminderLinkC as imported1_relatedReminderLinkC, relatedCusip9LinkC as imported6_relatedCusip9LinkC, postAttachedC as imported13_postAttachedC, relatedCusip9LinkC as imported7_relatedCusip9LinkC } from "./relatedContent";
import { WithStatusCU as imported178_WithStatusCU, withModInfoC as imported127_withModInfoC, WithStatusCU as imported179_WithStatusCU, withIdC as imported196_withIdC, withIdC as imported195_withIdC, hasManyLinkC as imported74_hasManyLinkC, withIdC as imported194_withIdC, WithStatusCU as imported180_WithStatusCU, hasManyLinkC as imported73_hasManyLinkC } from "./threadThrough";
import { emptyObjectC as imported2_emptyObjectC } from "./emptyObject";
import { mediaUploadResponseC as imported12_mediaUploadResponseC, mediaC as imported48_mediaC, mediaC as imported49_mediaC, mediaUploadResponseC as imported11_mediaUploadResponseC, mediaUploadResponseC as imported10_mediaUploadResponseC } from "./media";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { cusip9C as imported5_cusip9C } from "./cusip";
import { reminderC as imported2_reminderC, reminderC as imported3_reminderC } from "./reminders";
import { dateQualifierC as imported13_dateQualifierC } from "./dateQualifier";

export const documentTypeC = t.type({
  id: t.number,
  categoryName: t.string
});
export type DocumentTypeC = typeof documentTypeC;
export type DocumentType = t.TypeOf<DocumentTypeC>;


export const documentWithCategoryC = t.type({
  document: imported48_mediaC,
  category: imported6_MediaCategoryCU,
  docType: optionFromNullable(documentTypeC)
});
export type DocumentWithCategoryC = typeof documentWithCategoryC;
export type DocumentWithCategory = t.TypeOf<DocumentWithCategoryC>;


export const documentTypesC = t.type({
  documentTypes: t.readonlyArray(documentTypeC)
});
export type DocumentTypesC = typeof documentTypesC;
export type DocumentTypes = t.TypeOf<DocumentTypesC>;


export const emmaContactInfoC = t.type({
  address: t.string,
  city: t.string,
  state: t.string,
  postalCode: t.string,
  organizationName: t.string,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string)
});
export type EmmaContactInfoC = typeof emmaContactInfoC;
export type EmmaContactInfo = t.TypeOf<EmmaContactInfoC>;


export const emmaDataC = t.type({
  submissionId: optionFromNullable(t.string),
  asOfDate: optionFromNullable(LocalDateC),
  beginDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  categoryDateDescription: t.string,
  extraDescription: optionFromNullable(t.string),
  disclosureType: imported2_emmaDisclosureTypeC,
  category: imported2_emmaCategoryC,
  subcategory: optionFromNullable(imported2_emmaSubcategoryC),
  timePeriod: imported2_emmaDateQualifierC,
  contactInfo: emmaContactInfoC
});
export type EmmaDataC = typeof emmaDataC;
export type EmmaData = t.TypeOf<EmmaDataC>;


export const emmaReceiptC = t.type({
  emmaSubmissionId: t.string,
  created: LocalDateTimeFromIsoStringC
});
export type EmmaReceiptC = typeof emmaReceiptC;
export type EmmaReceipt = t.TypeOf<EmmaReceiptC>;


export const emmaDocumentRelatedContentC = t.type({
  cusip9s: t.readonlyArray(imported73_hasManyLinkC(imported179_WithStatusCU(imported5_cusip9C), imported6_relatedCusip9LinkC)),
  reminders: t.readonlyArray(imported74_hasManyLinkC(imported180_WithStatusCU(imported2_reminderC), imported1_relatedReminderLinkC)),
  receipts: t.readonlyArray(imported195_withIdC(emmaReceiptC))
});
export type EmmaDocumentRelatedContentC = typeof emmaDocumentRelatedContentC;
export type EmmaDocumentRelatedContent = t.TypeOf<EmmaDocumentRelatedContentC>;


export const emmaDocumentWithRelatedContentC = t.type({
  emmaDoc: emmaDataC,
  relatedContent: emmaDocumentRelatedContentC
});
export type EmmaDocumentWithRelatedContentC = typeof emmaDocumentWithRelatedContentC;
export type EmmaDocumentWithRelatedContent = t.TypeOf<EmmaDocumentWithRelatedContentC>;


export const documentWithEmmaDataC = t.type({
  document: documentWithCategoryC,
  emmaData: optionFromNullable(imported194_withIdC(emmaDocumentWithRelatedContentC))
});
export type DocumentWithEmmaDataC = typeof documentWithEmmaDataC;
export type DocumentWithEmmaData = t.TypeOf<DocumentWithEmmaDataC>;


export const documentsListC = t.type({
  docs: t.readonlyArray(imported178_WithStatusCU(imported127_withModInfoC(imported121_taggedContentC(documentWithEmmaDataC))))
});
export type DocumentsListC = typeof documentsListC;
export type DocumentsList = t.TypeOf<DocumentsListC>;


export const documentTypePostC = t.type({
  id: optionFromNullable(t.number),
  categoryName: t.string
});
export type DocumentTypePostC = typeof documentTypePostC;
export type DocumentTypePost = t.TypeOf<DocumentTypePostC>;


export const documentPostBodyC = t.type({
  id: optionFromNullable(t.number),
  archived: t.number,
  caption: optionFromNullable(t.string),
  altText: optionFromNullable(t.string),
  mediaCategoryId: optionFromNullable(t.number),
  documentTypeId: optionFromNullable(t.number),
  mediaDate: imported13_dateQualifierC,
  mediaOrder: t.number,
  uploadResponse: imported10_mediaUploadResponseC,
  esgRelated: t.boolean
});
export type DocumentPostBodyC = typeof documentPostBodyC;
export type DocumentPostBody = t.TypeOf<DocumentPostBodyC>;


export const emmaDetailsC = t.type({
  disclosureTypeId: t.number,
  categoryId: t.number,
  subcategoryId: optionFromNullable(t.number),
  description: optionFromNullable(t.string),
  timePeriodId: t.number,
  beginDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  asOfDate: optionFromNullable(LocalDateC),
  contact: emmaContactInfoC
});
export type EmmaDetailsC = typeof emmaDetailsC;
export type EmmaDetails = t.TypeOf<EmmaDetailsC>;


export const emmaDocC = t.type({
  published: t.boolean,
  document: imported196_withIdC(imported49_mediaC)
});
export type EmmaDocC = typeof emmaDocC;
export type EmmaDoc = t.TypeOf<EmmaDocC>;


export const emmaDocumentPostRelatedContentC = t.type({
  cusip9s: imported33_postRelatedC(imported7_relatedCusip9LinkC),
  reminders: imported13_postAttachedC(imported3_reminderC, imported2_emptyObjectC)
});
export type EmmaDocumentPostRelatedContentC = typeof emmaDocumentPostRelatedContentC;
export type EmmaDocumentPostRelatedContent = t.TypeOf<EmmaDocumentPostRelatedContentC>;


export const emmaDocPostBodyC = t.type({
  id: optionFromNullable(t.number),
  media: documentPostBodyC,
  emmaDoc: emmaDetailsC,
  makeContactDefault: optionFromNullable(t.boolean),
  relatedContent: emmaDocumentPostRelatedContentC
});
export type EmmaDocPostBodyC = typeof emmaDocPostBodyC;
export type EmmaDocPostBody = t.TypeOf<EmmaDocPostBodyC>;


export const irmaLetterPostC = t.type({
  id: optionFromNullable(t.number),
  uploadResponse: imported11_mediaUploadResponseC
});
export type IrmaLetterPostC = typeof irmaLetterPostC;
export type IrmaLetterPost = t.TypeOf<IrmaLetterPostC>;


export const documentCategoryIdC = t.type({
  documentTypeId: optionFromNullable(t.number),
  mediaCategoryId: optionFromNullable(t.number)
});
export type DocumentCategoryIdC = typeof documentCategoryIdC;
export type DocumentCategoryId = t.TypeOf<DocumentCategoryIdC>;


export const sortDocCategoriesC = t.type({
  categories: readonlyNonEmptyArrayC(documentCategoryIdC)
});
export type SortDocCategoriesC = typeof sortDocCategoriesC;
export type SortDocCategories = t.TypeOf<SortDocCategoriesC>;


export const privateDocumentPostBodyC = t.type({
  offeringId: t.number,
  uploadResponse: imported12_mediaUploadResponseC
});
export type PrivateDocumentPostBodyC = typeof privateDocumentPostBodyC;
export type PrivateDocumentPostBody = t.TypeOf<PrivateDocumentPostBodyC>;


