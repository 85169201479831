import type { BLConfigWithLog } from "@scripts/bondlink";
import type { BLIssuerSitesSession } from "@scripts/generated/models/blWindow";
import type { IssuerSitesRouteMetaWithRender } from "@scripts/routes/routing/ssr/issuersites";

import { reactHydrate } from "@scripts-ssr/client";
import type { PageData } from "@scripts-ssr/syntax/pageData";

import { PageLoader } from "../components/PageLoader";
import type { IssuerSitesInitialState } from "../state/state";
import { createIssuerSitesStore } from "../state/store";

export const hydratePage =
  (config: BLConfigWithLog) =>
    <SessionA extends BLIssuerSitesSession, SessionO, StoreA extends IssuerSitesInitialState, StoreO, PropsA, PropsO>(
      pageWithRender: IssuerSitesRouteMetaWithRender<PropsA, PropsO>
  ) =>
    (pageData: PageData<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO>) => {
      const store = createIssuerSitesStore(config)(pageData.initialState);

      reactHydrate(
        <PageLoader
          config={config}
          pageTitle={pageData.title}
          render={() => pageWithRender.render(pageData.props)}
          store={store}
          url={pageWithRender.url()}
        />
      );
    };
