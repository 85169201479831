import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const contentNoteC = t.type({
  content: t.string
});
export type ContentNoteC = typeof contentNoteC;
export type ContentNote = t.TypeOf<ContentNoteC>;


export const contentNotePostC = t.type({
  content: t.string,
  offeringId: t.number,
  id: optionFromNullable(t.number)
});
export type ContentNotePostC = typeof contentNotePostC;
export type ContentNotePost = t.TypeOf<ContentNotePostC>;


