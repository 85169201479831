import type { PropsWithChildren } from "react";

import { klass } from "@scripts/react/util/classnames";

import BLLogo from "../../../../../assets/img/logos/bondlink-2019-inverted.svg";
import { AnchorUnsafeWithChildren } from "../Anchor";
import { Svg } from "../Svg";

type BondlinkBarBodyProps = PropsWithChildren<{
  logoHref: string;
  portalLink: JSX.Element;
}>;

export const BondlinkBarBody = (props: BondlinkBarBodyProps) => {
  return <div {...klass("nav-bondlink-bar")}>
    <div className="bondlink-logo-portal">
      <AnchorUnsafeWithChildren
        aria-label="BondLink Homepage"
        href={props.logoHref}
        target="_self"
        klasses={["no-decoration", "d-flex"]}
      >
        <Svg src={BLLogo} />
      </AnchorUnsafeWithChildren>
      <div {...klass("inverted")}>
        {props.portalLink}
      </div>
    </div>
    {props.children}
  </div>;
};
