import { WithStatusCU as imported319_WithStatusCU, withIdC as imported279_withIdC, WithStatusCU as imported324_WithStatusCU, WithStatusCU as imported317_WithStatusCU, WithStatusCU as imported314_WithStatusCU, WithStatusCU as imported318_WithStatusCU, WithStatusCU as imported312_WithStatusCU, WithStatusCU as imported323_WithStatusCU, WithStatusCU as imported321_WithStatusCU, WithStatusCU as imported315_WithStatusCU, WithStatusCU as imported310_WithStatusCU, WithStatusCU as imported309_WithStatusCU, WithStatusCU as imported311_WithStatusCU, WithStatusCU as imported316_WithStatusCU, WithStatusCU as imported313_WithStatusCU, WithStatusCU as imported322_WithStatusCU, withIdC as imported280_withIdC, WithStatusCU as imported320_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { rfpWithRoadshowsAndDocsC as imported7_rfpWithRoadshowsAndDocsC } from "./rfp";
import { issuerRatingC as imported11_issuerRatingC, programRatingC as imported15_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported42_RatingAgencyCU, RatingAgencyCU as imported43_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { mediaC as imported85_mediaC, mediaC as imported86_mediaC, mediaC as imported87_mediaC, mediaC as imported88_mediaC } from "./media";
import { taggedContentC as imported206_taggedContentC, taggedContentC as imported199_taggedContentC, taggedContentC as imported202_taggedContentC, taggedContentC as imported209_taggedContentC, taggedContentC as imported204_taggedContentC, taggedContentC as imported203_taggedContentC, taggedContentC as imported208_taggedContentC, taggedContentC as imported201_taggedContentC, taggedContentC as imported207_taggedContentC, taggedContentC as imported205_taggedContentC, taggedContentC as imported200_taggedContentC } from "./taggedContent";
import { quickFactC as imported16_quickFactC, quickFactC as imported17_quickFactC } from "./quickFact";
import { bondProgramC as imported23_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported33_subscribedC, subscribedC as imported32_subscribedC } from "./subscribed";
import { featuredItemC as imported2_featuredItemC } from "./featuredItem";
import { customPageDataOC as imported6_customPageDataOC, customPageDataOC as imported7_customPageDataOC } from "./customPages";
import { either } from "io-ts-types/lib/either";
import { bondOfferingC as imported25_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { officerC as imported14_officerC, officerC as imported15_officerC } from "./officer";
import { projectWithPhotosC as imported2_projectWithPhotosC, projectWithPhotosC as imported3_projectWithPhotosC } from "./project";
import { MediaCategoryCU as imported9_MediaCategoryCU, MediaCategoryCU as imported10_MediaCategoryCU, MediaCategoryCU as imported11_MediaCategoryCU } from "../domaintables/mediaCategories";
import { issuerNewsC as imported19_issuerNewsC, issuerNewsC as imported20_issuerNewsC } from "./issuerNewsBase";
import { documentTypeC as imported13_documentTypeC, documentTypeC as imported14_documentTypeC, documentTypeC as imported15_documentTypeC, documentWithCategoryC as imported20_documentWithCategoryC } from "./document";
import { bondOfferingWithRoadshowsAndDocsC as imported7_bondOfferingWithRoadshowsAndDocsC } from "./bondOffering";
import { clientTextItemC as imported24_clientTextItemC, clientTextItemC as imported25_clientTextItemC, clientTextItemC as imported26_clientTextItemC } from "./clientTextItem";

export const issuerHomepageDataC = t.type({
  featuredItems: optionFromNullable(t.readonlyArray(imported199_taggedContentC(imported2_featuredItemC))),
  officer: optionFromNullable(imported309_WithStatusCU(imported14_officerC)),
  welcome: optionFromNullable(imported24_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported42_RatingAgencyCU, readonlyNonEmptyArrayC(imported310_WithStatusCU(imported11_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported279_withIdC(imported23_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported43_RatingAgencyCU, readonlyNonEmptyArrayC(imported311_WithStatusCU(imported15_programRatingC))]))])),
  offerings: t.readonlyArray(imported312_WithStatusCU(imported200_taggedContentC(imported25_bondOfferingC)))
});
export type IssuerHomepageDataC = typeof issuerHomepageDataC;
export type IssuerHomepageData = t.TypeOf<IssuerHomepageDataC>;


export const issuerAboutPageDataC = t.type({
  about: optionFromNullable(imported25_clientTextItemC),
  news: t.readonlyArray(imported313_WithStatusCU(imported201_taggedContentC(imported19_issuerNewsC))),
  projects: t.readonlyArray(imported314_WithStatusCU(imported202_taggedContentC(imported2_projectWithPhotosC))),
  team: t.readonlyArray(imported315_WithStatusCU(imported15_officerC)),
  callouts: t.readonlyArray(imported16_quickFactC),
  photos: t.readonlyArray(imported316_WithStatusCU(imported85_mediaC)),
  customPages: t.readonlyArray(imported6_customPageDataOC)
});
export type IssuerAboutPageDataC = typeof issuerAboutPageDataC;
export type IssuerAboutPageData = t.TypeOf<IssuerAboutPageDataC>;


export const issuerEsgPageDataC = t.type({
  overview: optionFromNullable(imported26_clientTextItemC),
  callouts: t.readonlyArray(imported17_quickFactC),
  photos: t.readonlyArray(imported317_WithStatusCU(imported86_mediaC)),
  bondOfferings: t.readonlyArray(imported32_subscribedC(imported318_WithStatusCU(imported203_taggedContentC(imported7_bondOfferingWithRoadshowsAndDocsC)))),
  rfps: t.readonlyArray(imported33_subscribedC(imported319_WithStatusCU(imported204_taggedContentC(imported7_rfpWithRoadshowsAndDocsC)))),
  roadshows: t.readonlyArray(imported280_withIdC(imported205_taggedContentC(imported26_roadShowDataC))),
  projects: t.readonlyArray(imported320_WithStatusCU(imported206_taggedContentC(imported3_projectWithPhotosC))),
  documents: t.readonlyArray(imported321_WithStatusCU(imported207_taggedContentC(imported87_mediaC))),
  news: t.readonlyArray(imported322_WithStatusCU(imported208_taggedContentC(imported20_issuerNewsC)))
});
export type IssuerEsgPageDataC = typeof issuerEsgPageDataC;
export type IssuerEsgPageData = t.TypeOf<IssuerEsgPageDataC>;


export const issuerDocumentCategoriesPageDataC = t.type({
  categories: t.readonlyArray(t.tuple([either(imported13_documentTypeC, imported9_MediaCategoryCU), t.number])),
  archivedCategories: t.readonlyArray(t.tuple([either(imported14_documentTypeC, imported10_MediaCategoryCU), t.number])),
  irmaLetter: optionFromNullable(imported323_WithStatusCU(imported88_mediaC)),
  customPages: t.readonlyArray(imported7_customPageDataOC)
});
export type IssuerDocumentCategoriesPageDataC = typeof issuerDocumentCategoriesPageDataC;
export type IssuerDocumentCategoriesPageData = t.TypeOf<IssuerDocumentCategoriesPageDataC>;


export const issuerDownloadsPageDataC = t.type({
  documents: t.readonlyArray(imported324_WithStatusCU(imported209_taggedContentC(imported20_documentWithCategoryC))),
  category: optionFromNullable(either(imported15_documentTypeC, imported11_MediaCategoryCU))
});
export type IssuerDownloadsPageDataC = typeof issuerDownloadsPageDataC;
export type IssuerDownloadsPageData = t.TypeOf<IssuerDownloadsPageDataC>;


