import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import { constTrue, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as t from "io-ts";
import { NumberFromString, option, optionFromNullable } from "io-ts-types";
import { regexp } from "regex-prepared-statements";

import type { DeepLinkModalIdU } from "@scripts/generated/domaintables/deepLinkTypes";
import { allDeepLinkModalId, allDeepLinkTabId } from "@scripts/generated/domaintables/deepLinkTypes";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { getUrl } from "@scripts/react/router";
import type { PageRouting } from "@scripts/routes/routing/base";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { parentIdOrId } from "@scripts/syntax/threadThrough";

const modalIds = allDeepLinkModalId.map(_ => _.id);
type ModalId = typeof modalIds[number];

const tabIds = allDeepLinkTabId.map(_ => _.id);
type TabId = typeof tabIds[number];

export type DeepLinkTarget = {
  modal?: ModalId;
  tab?: TabId;
  dataId?: number;
  options?: DeepLinkOptions;
};

type DeepLinkTargetO = { [K in keyof DeepLinkTarget]-?: O.Option<DeepLinkTarget[K]> };

export type WithStatusData = WithStatusU<object>;

export type DeepLinkModalWithData = {
  modalId: DeepLinkModalIdU;
  data: WithStatusData;
};

export type DeepLinkModalWithId = {
  modalId: DeepLinkModalIdU;
  id: number;
};

const fragDelimiter = "&";
const targetDelimiter = "~";

const makeDeepLinkOptions = (options: DeepLinkOptions) => options.length > 0
  ? `${fragDelimiter}${optionsPrefix}${options.join(optionsSeperator)}`
  : "";

export const makeDeepLinkModalUrl = (route: PageRouting | UrlInterface<"GET">) =>
  (modalId: DeepLinkModalIdU, options: DeepLinkOptions) =>
    `${getUrl(route)}#${modalPrefix}${modalId.id}${makeDeepLinkOptions(options)}`;
export const makeDeepLinkModalWithDataUrlBase = <M extends DeepLinkModalWithData | DeepLinkModalWithId>(getId: (emi: M) => number) => (route: PageRouting | UrlInterface<"GET">) =>
  (emi: M, options: DeepLinkOptions) =>
    `${getUrl(route)}#${modalPrefix}${emi.modalId.id}${fragDelimiter}${dataIdPrefix}${getId(emi)}${makeDeepLinkOptions(options)}`;
export const makeDeepLinkModalWithDataUrl = makeDeepLinkModalWithDataUrlBase<DeepLinkModalWithData>(emi => parentIdOrId(emi.data));
export const makeDeepLinkModalWithDataIdUrl = makeDeepLinkModalWithDataUrlBase<DeepLinkModalWithId>(emi => emi.id);

const reFn = regexp("<_>([-a-z0-9]+)", "i");

const modalPrefix = `modal${targetDelimiter}`;
const modalIdRe = reFn(modalPrefix);
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const isModalId = (m: unknown): m is ModalId => modalIds.includes(m as ModalId);

const tabPrefix = `tab${targetDelimiter}`;
const tabIdRe = reFn(tabPrefix);
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const isTabId = (s: unknown): s is TabId => tabIds.includes(s as TabId);

const dataIdPrefix = `dataId${targetDelimiter}`;
const dataIdRe = reFn(dataIdPrefix);
const isDataId = (u: unknown): u is string => typeof u === "string" && !isNaN(parseInt(u, 10));

const getDataFromId = (r: RegExp) => (s: string): O.Option<string> => pipe(
  r.exec(s),
  O.fromNullable,
  O.chain(regExArray => pipe(regExArray[1], O.fromNullable)),
);

const parseIdC = <A>(r: RegExp, typeGuard: (s: unknown) => s is A) => new t.Type<O.Option<A>>(
  "parseId",
  (u: unknown): u is O.Option<A> => pipe(
    isStringC.decode(u),
    E.fold(
      () => false,
      O.fold(
        () => true,
        flow(
          getDataFromId(r),
          O.fold(() => true, id => typeGuard(id) ? true : false)
        ))
    ),
  ),
  (u: unknown, c: t.Context) => pipe(
    isStringC.decode(u),
    E.chain(
      O.fold(
        () => t.success(O.none),
        flow(
          getDataFromId(r),
          O.fold(() => t.success(O.none), id => typeGuard(id) ? t.success(O.some(id)) : t.failure(u, c))
        )
      )
    )
  ),
  identity
);

export type DeepLinkOptions = ReadonlyArray<string>;

const optionsSeperator = ",";
const optionsPrefix = `deepLinkOptions${targetDelimiter}`;
const optionsRe = regexp("<_>([^&]+)")(optionsPrefix);

const optionsC = new t.Type<O.Option<ReadonlyArray<string>>, O.Option<string>>(
  "optionsC",
  (u: unknown): u is O.Option<ReadonlyArray<string>> => pipe(
    isStringC.decode(u),
    E.fold(
      () => false,
      constTrue
    ),
  ),
  (u: unknown) => pipe(
    isStringC.decode(u),
    E.chain(
      O.fold(
        () => t.success(O.none),
        flow(
          getDataFromId(optionsRe),
          O.fold(() => t.success(O.none), id => t.success(O.some(id.split(optionsSeperator))))
        )
      )
    )
  ),
  O.map(_ => _.join(optionsSeperator))
);

const isStringC = optionFromNullable(t.string);

export const modalTargetC = parseIdC(modalIdRe, isModalId);
export const tabTargetC = parseIdC(tabIdRe, isTabId);
export const dataIdC = parseIdC(dataIdRe, isDataId).pipe(option(NumberFromString));

export const deepLinkTargetC = new t.Type<DeepLinkTarget, O.Option<string>>(
  "DeepLinkTarget",
  (input: unknown): input is DeepLinkTarget =>
    E.isRight(modalTargetC.decode(input))
    || E.isRight(tabTargetC.decode(input))
    || E.isRight(dataIdC.decode(input))
    || E.isRight(optionsC.decode(input)),
  (input, context): t.Validation<DeepLinkTarget> => pipe(
    isStringC.validate(input, context),
    E.chain(
      O.fold(
        () => E.right<t.Errors, DeepLinkTargetO>({
          modal: O.none,
          tab: O.none,
          dataId: O.none,
          options: O.none,
        }),
        (fragment) => sequenceS(E.Apply)({
          modal: modalTargetC.validate(fragment, context),
          tab: tabTargetC.validate(fragment, context),
          dataId: dataIdC.validate(fragment, context),
          options: optionsC.validate(fragment, context),
        })
      )
    ),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    E.map(R.compact as (fa: Record<string, O.Option<unknown>>) => DeepLinkTarget)
  ),
  dlt => {
    const e = Object.entries(dlt);
    return e.length > 0 ? O.some(e.map(([k, v]) => `${k}${targetDelimiter}${t.readonlyArray(t.string).is(v) ? v.join(optionsSeperator) : v}`).join(fragDelimiter)) : O.none;
  }
);

export const replicateBondOption = "replicateBond";
