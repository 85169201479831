import "@scripts-ssr/client/preIndex";

import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import type * as t from "io-ts";

import { Config } from "@scripts/csr-only/config";
import { bLIssuerSitesSessionC } from "@scripts/generated/models/blWindow";
import { parseDeepLinks } from "@scripts/globals";
import type { IssuerSitesRouteMetaWithRender } from "@scripts/routes/routing/ssr/issuersites";
import { removeUrlParam } from "@scripts/routes/urlInterface";
import { bootstrapSentry } from "@scripts/sentry";
import { makeConfigLogger } from "@scripts/util/log";

import { bootstrapPage, handleDecodeErrors, readPageData } from "@scripts-ssr/client";
import { makePageDataC } from "@scripts-ssr/syntax/pageData";

import { parseLocation } from "../router";
import { issuerSitesInitialStateC } from "../state/state";
import { hydratePage } from "./render";

const config = Config(makeConfigLogger());

const pageData = readPageData();

parseDeepLinks(window.location.hash);

const decodePageData = <A, O>(propsCodec: t.Type<A, O>) =>
  makePageDataC(bLIssuerSitesSessionC, issuerSitesInitialStateC, propsCodec).decode(pageData);

const handlePage = (url: string) => pipe(
  parseLocation(url),
  <A, O>(pwr: IssuerSitesRouteMetaWithRender<A, O>) => E.fold(
    handleDecodeErrors("Error decoding PageData"),
    hydratePage(config)(pwr)
  )(decodePageData(pwr.propsCodec))
);

bootstrapSentry(config, "Ssr");
removeUrlParam("noXdRedir");

bootstrapPage(pageData, handlePage);
