// This file was generated by postcss-transpiler. Do not edit by hand.

export const grid = {
  ".container": {
    css: ".container",
    html: "container"
  },
  ".container-fluid": {
    css: ".container-fluid",
    html: "container-fluid"
  },
  ".masthead": {
    css: ".masthead",
    html: "masthead",
    ".page-name-container": {
      css: ".page-name-container",
      html: "page-name-container",
      ".page-name": {
        css: ".page-name",
        html: "page-name"
      }
    }
  },
  ".page-margins": {
    css: ".page-margins",
    html: "page-margins"
  },
  ".portal-table-header": {
    css: ".portal-table-header",
    html: "portal-table-header",
    attrs: {
      ".mobile-activity-filters": {
        css: ".mobile-activity-filters",
        html: "mobile-activity-filters"
      }
    },
    ".checkbox-group": {
      css: ".checkbox-group",
      html: "checkbox-group"
    },
    ".toolbar": {
      css: ".toolbar",
      html: "toolbar"
    }
  },
  "body": {
    css: "body",
    html: "body"
  },
  ".page-container": {
    css: ".page-container",
    html: "page-container"
  },
  ".page-container-width": {
    css: ".page-container-width",
    html: "page-container-width"
  },
  ".file-viewer": {
    css: ".file-viewer",
    html: "file-viewer",
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid": {
    css: ".grid",
    html: "grid",
    attrs: {
      ".input-row": {
        css: ".input-row",
        html: "input-row"
      },
      ".grid-sx-1": {
        css: ".grid-sx-1",
        html: "grid-sx-1"
      },
      ".grid-sx-15": {
        css: ".grid-sx-15",
        html: "grid-sx-15"
      },
      ".grid-sx-2": {
        css: ".grid-sx-2",
        html: "grid-sx-2"
      },
      ".grid-sa-1": {
        css: ".grid-sa-1",
        html: "grid-sa-1"
      },
      ".grid-sa-15": {
        css: ".grid-sa-15",
        html: "grid-sa-15"
      },
      ".grid-sa-2": {
        css: ".grid-sa-2",
        html: "grid-sa-2"
      },
      ".grid-sx-md-3": {
        css: ".grid-sx-md-3",
        html: "grid-sx-md-3"
      },
      ".grid-sxy-sm-4-2": {
        css: ".grid-sxy-sm-4-2",
        html: "grid-sxy-sm-4-2"
      },
      ".grid-sxy-1-075": {
        css: ".grid-sxy-1-075",
        html: "grid-sxy-1-075"
      }
    },
    "div": {
      css: "div",
      html: "div",
      ".multi-col-spacing": {
        css: ".multi-col-spacing",
        html: "multi-col-spacing"
      },
      ":first-of-type": {
        css: ":first-of-type",
        html: "first-of-type"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".input-row": {
    css: ".input-row",
    html: "input-row",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "div": {
      css: "div",
      html: "div",
      ".multi-col-spacing": {
        css: ".multi-col-spacing",
        html: "multi-col-spacing"
      },
      ":first-of-type": {
        css: ":first-of-type",
        html: "first-of-type"
      }
    }
  },
  ".grid-sx-1": {
    css: ".grid-sx-1",
    html: "grid-sx-1",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sx-15": {
    css: ".grid-sx-15",
    html: "grid-sx-15",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sx-2": {
    css: ".grid-sx-2",
    html: "grid-sx-2",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sa-1": {
    css: ".grid-sa-1",
    html: "grid-sa-1",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sa-15": {
    css: ".grid-sa-15",
    html: "grid-sa-15",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sa-2": {
    css: ".grid-sa-2",
    html: "grid-sa-2",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sx-md-3": {
    css: ".grid-sx-md-3",
    html: "grid-sx-md-3",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sxy-sm-4-2": {
    css: ".grid-sxy-sm-4-2",
    html: "grid-sxy-sm-4-2",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".grid-sxy-1-075": {
    css: ".grid-sxy-1-075",
    html: "grid-sxy-1-075",
    attrs: {
      ".file-viewer": {
        css: ".file-viewer",
        html: "file-viewer"
      }
    },
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".c-1": {
    css: ".c-1",
    html: "c-1"
  },
  ".c-10": {
    css: ".c-10",
    html: "c-10"
  },
  ".c-11": {
    css: ".c-11",
    html: "c-11"
  },
  ".c-12": {
    css: ".c-12",
    html: "c-12"
  },
  ".c-13": {
    css: ".c-13",
    html: "c-13"
  },
  ".c-14": {
    css: ".c-14",
    html: "c-14"
  },
  ".c-15": {
    css: ".c-15",
    html: "c-15"
  },
  ".c-16": {
    css: ".c-16",
    html: "c-16"
  },
  ".c-17": {
    css: ".c-17",
    html: "c-17"
  },
  ".c-18": {
    css: ".c-18",
    html: "c-18"
  },
  ".c-19": {
    css: ".c-19",
    html: "c-19"
  },
  ".c-2": {
    css: ".c-2",
    html: "c-2"
  },
  ".c-20": {
    css: ".c-20",
    html: "c-20"
  },
  ".c-21": {
    css: ".c-21",
    html: "c-21"
  },
  ".c-22": {
    css: ".c-22",
    html: "c-22"
  },
  ".c-23": {
    css: ".c-23",
    html: "c-23"
  },
  ".c-24": {
    css: ".c-24",
    html: "c-24"
  },
  ".c-3": {
    css: ".c-3",
    html: "c-3"
  },
  ".c-4": {
    css: ".c-4",
    html: "c-4"
  },
  ".c-5": {
    css: ".c-5",
    html: "c-5"
  },
  ".c-6": {
    css: ".c-6",
    html: "c-6"
  },
  ".c-7": {
    css: ".c-7",
    html: "c-7"
  },
  ".c-8": {
    css: ".c-8",
    html: "c-8"
  },
  ".c-9": {
    css: ".c-9",
    html: "c-9"
  },
  ".c-lg-1": {
    css: ".c-lg-1",
    html: "c-lg-1"
  },
  ".c-lg-10": {
    css: ".c-lg-10",
    html: "c-lg-10"
  },
  ".c-lg-11": {
    css: ".c-lg-11",
    html: "c-lg-11"
  },
  ".c-lg-12": {
    css: ".c-lg-12",
    html: "c-lg-12"
  },
  ".c-lg-13": {
    css: ".c-lg-13",
    html: "c-lg-13"
  },
  ".c-lg-14": {
    css: ".c-lg-14",
    html: "c-lg-14"
  },
  ".c-lg-15": {
    css: ".c-lg-15",
    html: "c-lg-15"
  },
  ".c-lg-16": {
    css: ".c-lg-16",
    html: "c-lg-16"
  },
  ".c-lg-17": {
    css: ".c-lg-17",
    html: "c-lg-17"
  },
  ".c-lg-18": {
    css: ".c-lg-18",
    html: "c-lg-18"
  },
  ".c-lg-19": {
    css: ".c-lg-19",
    html: "c-lg-19"
  },
  ".c-lg-2": {
    css: ".c-lg-2",
    html: "c-lg-2"
  },
  ".c-lg-20": {
    css: ".c-lg-20",
    html: "c-lg-20"
  },
  ".c-lg-21": {
    css: ".c-lg-21",
    html: "c-lg-21"
  },
  ".c-lg-22": {
    css: ".c-lg-22",
    html: "c-lg-22"
  },
  ".c-lg-23": {
    css: ".c-lg-23",
    html: "c-lg-23"
  },
  ".c-lg-24": {
    css: ".c-lg-24",
    html: "c-lg-24"
  },
  ".c-lg-3": {
    css: ".c-lg-3",
    html: "c-lg-3"
  },
  ".c-lg-4": {
    css: ".c-lg-4",
    html: "c-lg-4"
  },
  ".c-lg-5": {
    css: ".c-lg-5",
    html: "c-lg-5"
  },
  ".c-lg-6": {
    css: ".c-lg-6",
    html: "c-lg-6"
  },
  ".c-lg-7": {
    css: ".c-lg-7",
    html: "c-lg-7"
  },
  ".c-lg-8": {
    css: ".c-lg-8",
    html: "c-lg-8"
  },
  ".c-lg-9": {
    css: ".c-lg-9",
    html: "c-lg-9"
  },
  ".c-md-1": {
    css: ".c-md-1",
    html: "c-md-1"
  },
  ".c-md-10": {
    css: ".c-md-10",
    html: "c-md-10"
  },
  ".c-md-11": {
    css: ".c-md-11",
    html: "c-md-11"
  },
  ".c-md-12": {
    css: ".c-md-12",
    html: "c-md-12"
  },
  ".c-md-13": {
    css: ".c-md-13",
    html: "c-md-13"
  },
  ".c-md-14": {
    css: ".c-md-14",
    html: "c-md-14"
  },
  ".c-md-15": {
    css: ".c-md-15",
    html: "c-md-15"
  },
  ".c-md-16": {
    css: ".c-md-16",
    html: "c-md-16"
  },
  ".c-md-17": {
    css: ".c-md-17",
    html: "c-md-17"
  },
  ".c-md-18": {
    css: ".c-md-18",
    html: "c-md-18"
  },
  ".c-md-19": {
    css: ".c-md-19",
    html: "c-md-19"
  },
  ".c-md-2": {
    css: ".c-md-2",
    html: "c-md-2"
  },
  ".c-md-20": {
    css: ".c-md-20",
    html: "c-md-20"
  },
  ".c-md-21": {
    css: ".c-md-21",
    html: "c-md-21"
  },
  ".c-md-22": {
    css: ".c-md-22",
    html: "c-md-22"
  },
  ".c-md-23": {
    css: ".c-md-23",
    html: "c-md-23"
  },
  ".c-md-24": {
    css: ".c-md-24",
    html: "c-md-24"
  },
  ".c-md-3": {
    css: ".c-md-3",
    html: "c-md-3"
  },
  ".c-md-4": {
    css: ".c-md-4",
    html: "c-md-4"
  },
  ".c-md-5": {
    css: ".c-md-5",
    html: "c-md-5"
  },
  ".c-md-6": {
    css: ".c-md-6",
    html: "c-md-6"
  },
  ".c-md-7": {
    css: ".c-md-7",
    html: "c-md-7"
  },
  ".c-md-8": {
    css: ".c-md-8",
    html: "c-md-8"
  },
  ".c-md-9": {
    css: ".c-md-9",
    html: "c-md-9"
  },
  ".c-sm-1": {
    css: ".c-sm-1",
    html: "c-sm-1"
  },
  ".c-sm-10": {
    css: ".c-sm-10",
    html: "c-sm-10"
  },
  ".c-sm-11": {
    css: ".c-sm-11",
    html: "c-sm-11"
  },
  ".c-sm-12": {
    css: ".c-sm-12",
    html: "c-sm-12"
  },
  ".c-sm-13": {
    css: ".c-sm-13",
    html: "c-sm-13"
  },
  ".c-sm-14": {
    css: ".c-sm-14",
    html: "c-sm-14"
  },
  ".c-sm-15": {
    css: ".c-sm-15",
    html: "c-sm-15"
  },
  ".c-sm-16": {
    css: ".c-sm-16",
    html: "c-sm-16"
  },
  ".c-sm-17": {
    css: ".c-sm-17",
    html: "c-sm-17"
  },
  ".c-sm-18": {
    css: ".c-sm-18",
    html: "c-sm-18"
  },
  ".c-sm-19": {
    css: ".c-sm-19",
    html: "c-sm-19"
  },
  ".c-sm-2": {
    css: ".c-sm-2",
    html: "c-sm-2"
  },
  ".c-sm-20": {
    css: ".c-sm-20",
    html: "c-sm-20"
  },
  ".c-sm-21": {
    css: ".c-sm-21",
    html: "c-sm-21"
  },
  ".c-sm-22": {
    css: ".c-sm-22",
    html: "c-sm-22"
  },
  ".c-sm-23": {
    css: ".c-sm-23",
    html: "c-sm-23"
  },
  ".c-sm-24": {
    css: ".c-sm-24",
    html: "c-sm-24"
  },
  ".c-sm-3": {
    css: ".c-sm-3",
    html: "c-sm-3"
  },
  ".c-sm-4": {
    css: ".c-sm-4",
    html: "c-sm-4"
  },
  ".c-sm-5": {
    css: ".c-sm-5",
    html: "c-sm-5"
  },
  ".c-sm-6": {
    css: ".c-sm-6",
    html: "c-sm-6"
  },
  ".c-sm-7": {
    css: ".c-sm-7",
    html: "c-sm-7"
  },
  ".c-sm-8": {
    css: ".c-sm-8",
    html: "c-sm-8"
  },
  ".c-sm-9": {
    css: ".c-sm-9",
    html: "c-sm-9"
  },
  ".c-xl-1": {
    css: ".c-xl-1",
    html: "c-xl-1"
  },
  ".c-xl-10": {
    css: ".c-xl-10",
    html: "c-xl-10"
  },
  ".c-xl-11": {
    css: ".c-xl-11",
    html: "c-xl-11"
  },
  ".c-xl-12": {
    css: ".c-xl-12",
    html: "c-xl-12"
  },
  ".c-xl-13": {
    css: ".c-xl-13",
    html: "c-xl-13"
  },
  ".c-xl-14": {
    css: ".c-xl-14",
    html: "c-xl-14"
  },
  ".c-xl-15": {
    css: ".c-xl-15",
    html: "c-xl-15"
  },
  ".c-xl-16": {
    css: ".c-xl-16",
    html: "c-xl-16"
  },
  ".c-xl-17": {
    css: ".c-xl-17",
    html: "c-xl-17"
  },
  ".c-xl-18": {
    css: ".c-xl-18",
    html: "c-xl-18"
  },
  ".c-xl-19": {
    css: ".c-xl-19",
    html: "c-xl-19"
  },
  ".c-xl-2": {
    css: ".c-xl-2",
    html: "c-xl-2"
  },
  ".c-xl-20": {
    css: ".c-xl-20",
    html: "c-xl-20"
  },
  ".c-xl-21": {
    css: ".c-xl-21",
    html: "c-xl-21"
  },
  ".c-xl-22": {
    css: ".c-xl-22",
    html: "c-xl-22"
  },
  ".c-xl-23": {
    css: ".c-xl-23",
    html: "c-xl-23"
  },
  ".c-xl-24": {
    css: ".c-xl-24",
    html: "c-xl-24"
  },
  ".c-xl-3": {
    css: ".c-xl-3",
    html: "c-xl-3"
  },
  ".c-xl-4": {
    css: ".c-xl-4",
    html: "c-xl-4"
  },
  ".c-xl-5": {
    css: ".c-xl-5",
    html: "c-xl-5"
  },
  ".c-xl-6": {
    css: ".c-xl-6",
    html: "c-xl-6"
  },
  ".c-xl-7": {
    css: ".c-xl-7",
    html: "c-xl-7"
  },
  ".c-xl-8": {
    css: ".c-xl-8",
    html: "c-xl-8"
  },
  ".c-xl-9": {
    css: ".c-xl-9",
    html: "c-xl-9"
  },
  ".grid-85-15": {
    css: ".grid-85-15",
    html: "grid-85-15",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar"
    }
  },
  ".grid-75-25": {
    css: ".grid-75-25",
    html: "grid-75-25",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar"
    }
  },
  ".grid-60-40": {
    css: ".grid-60-40",
    html: "grid-60-40",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar"
    }
  },
  ".grid-65-35": {
    css: ".grid-65-35",
    html: "grid-65-35",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar",
      attrs: {
        ".divider": {
          css: ".divider",
          html: "divider"
        }
      }
    }
  },
  ".grid-50-50": {
    css: ".grid-50-50",
    html: "grid-50-50",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar"
    }
  },
  ".grid-35-65": {
    css: ".grid-35-65",
    html: "grid-35-65",
    ".c-main": {
      css: ".c-main",
      html: "c-main"
    },
    ".c-sidebar": {
      css: ".c-sidebar",
      html: "c-sidebar"
    }
  },
  ".grid-75-25-simple": {
    css: ".grid-75-25-simple",
    html: "grid-75-25-simple",
    attrs: {
      ".single-row": {
        css: ".single-row",
        html: "single-row"
      }
    },
    ".grid-left": {
      css: ".grid-left",
      html: "grid-left",
      ".data-vertical": {
        css: ".data-vertical",
        html: "data-vertical",
        "dd": {
          css: "dd",
          html: "dd",
          attrs: {
            ".large": {
              css: ".large",
              html: "large"
            }
          }
        }
      },
      "p": {
        css: "p",
        html: "p",
        attrs: {
          ".large": {
            css: ".large",
            html: "large"
          }
        }
      }
    },
    ".grid-right": {
      css: ".grid-right",
      html: "grid-right",
      attrs: {
        ".border": {
          css: ".border",
          html: "border"
        }
      },
      ".sidebar-link": {
        css: ".sidebar-link",
        html: "sidebar-link"
      }
    }
  },
  ".data-vertical": {
    css: ".data-vertical",
    html: "data-vertical",
    ".grid-75-25-simple": {
      css: ".grid-75-25-simple",
      html: "grid-75-25-simple",
      attrs: {
        ".single-row": {
          css: ".single-row",
          html: "single-row"
        }
      },
      ".grid-left": {
        css: ".grid-left",
        html: "grid-left",
        "dd": {
          css: "dd",
          html: "dd",
          attrs: {
            ".large": {
              css: ".large",
              html: "large"
            }
          }
        }
      }
    }
  },
  ".card-grid": {
    css: ".card-grid",
    html: "card-grid",
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".related-issuer-grid": {
    css: ".related-issuer-grid",
    html: "related-issuer-grid",
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".card-category-grid": {
    css: ".card-category-grid",
    html: "card-category-grid",
    "*": {
      css: "*",
      html: "*"
    }
  }
};
