import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { activeRfps, archivedRfps } from "@scripts/generated/domaintables/featureFlags";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { RfpRelatedContent } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";

import { isFFEnabled } from "./featureFlags";

export const rfpUrl = (rfpId: number, issuer: Issuer, config: BLConfigWithLog) =>
  issuerSiteAbsUrl(config)(issuer, O.none)(
    SitesRouter.issuersitesRfpsControllerRfp
  )({ rfpId });

export const rfpToFF = (rfp: Rfp) => rfp.biddingState.name === "BidAwarded" ? archivedRfps : activeRfps;

export const isRFPViewable = (iffs: ClientFeatureFlags) => (rfp: Rfp) => isFFEnabled(rfpToFF(rfp))(iffs);

// An RFP is archived if it has been awarded or if it is more than 2 weeks past the bid due date. The back-end sets the biddingState to BidAwarded once two weeks have passed.
export const isRFPArchived = (rfp: Rfp) => rfp.biddingState._tag === "BidAwarded";

export const isQuestionAndAnswerAvailible = (rfp: Rfp) => rfp.allowQuestions && !isRFPArchived(rfp);

export const isQuestionsAndClarificationsAvailble = (rfp: Rfp, faqs: RfpRelatedContent["faqs"]) => RA.isNonEmpty(faqs) || isQuestionAndAnswerAvailible(rfp);
