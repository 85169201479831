import { O } from "@scripts/fp-ts";
import type { Drafted, HasManyLink, Published, WithStatusU } from "@scripts/generated/models/threadThrough";

export function isDrafted<A>(obj: WithStatusU<A>): obj is Drafted<A> {
  return obj._tag === "Drafted";
}

export function isPublished<A>(obj: WithStatusU<A>): obj is Published<A> {
  return obj._tag === "Published";
}

export const parentIdOrId = <A>(a: WithStatusU<A>): number =>
  isDrafted(a) ? O.getOrElse(() => a.data.id)(a.parentId) : a.data.id;

export const equalsParentIdOrId = (n: number) => <A>(a: WithStatusU<A>): boolean =>
  a.data.id === n || parentIdOrId(a) === n;

export const hasBeenPublished = <A>(a: WithStatusU<A>) => a._tag === "Published" || O.isSome(a.parentId);

export const withStatusFold = <A, B extends WithStatusU<A>, C>(onPublished: (a: B) => C, onDrafted: (a: B) => C, onNeverPublished: (a: B) => C) => (a: B) => {
  if (hasBeenPublished(a)) {
    return onPublished(a);
  } else if (isDrafted(a)) {
    return onDrafted(a);
  } else {
    return onNeverPublished(a);
  }
};

export const getHasManyLinkDraftId = <R, L>(a: HasManyLink<WithStatusU<R>, L>): number => parentIdOrId(a.data);
