import { O } from "@scripts/fp-ts";
import type { BiddingStateU } from "@scripts/generated/domaintables/biddingStates";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import { Badge, type BadgeProps } from "@scripts/react/components/Badge";

const badgeDetails: Record<Rfp["biddingState"]["name"], { text: string, color: "gray-600" | "yellow-700" | "green-700" }> = {
  Unscheduled: { text: "Unscheduled", color: "gray-600" },
  BiddingOpen: { text: "Open for Bids", color: "green-700" },
  BiddingClosed: { text: "Bidding Closed", color: "yellow-700" },
  BidAwarded: { text: "Archived", color: "gray-600" },
};

export const rfpBadgeProps = (biddingState: BiddingStateU, variant: BadgeProps["variant"]): BadgeProps => ({
  icon: O.none,
  text: badgeDetails[biddingState.name].text,
  color: badgeDetails[biddingState.name].color,
  variant,
});

export const RfpBadge = (props: { biddingState: BiddingStateU }) =>
  <Badge {...rfpBadgeProps(props.biddingState, "short")} />;
