import type { PropsWithChildren } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { formatS3CdnUrl } from "@scripts/bondlink";
import type { MediaUploadResponse } from "@scripts/generated/models/media";

import imageIcon from "@svgs/image.svg";

import { useConfig } from "../context/Config";
import type { Klass, KlassProp } from "../util/classnames";
import { klass, klassPropO } from "../util/classnames";
import { Svg } from "./Svg";

export const ImageBackground = (props: PropsWithChildren<{
  klasses: Klass[];
  url: O.Option<string>;
}>) => {
  const config = useConfig();

  return (
    <div
      {...klass(...props.klasses)}
      style={pipe(
        props.url,
        O.fold(
          () => ({}),
          // eslint-disable-next-line curly-quotes/no-straight-quotes
          (url) => ({ backgroundImage: `url("${formatS3CdnUrl(config)(url)}")` })
        )
      )}
    >
      {props.children}
    </div>
  );
};

type ImageProps = {
  alt: string;
  src: string;
  klasses?: KlassProp;
  style?: { [property: string]: string };
};

export const Image = (props: ImageProps) => {
  const config = useConfig();

  return <img
    {...klassPropO([])(props.klasses)}
    src={formatS3CdnUrl(config)(props.src)}
    alt={props.alt}
    style={pipe(O.fromNullable(props.style), O.getOrElse(() => ({})))}
  />;
};

export const ImageWithCount = (props: { image: MediaUploadResponse, count: number }) => {
  const config = useConfig();

  return <div {...klass("image-container")}>
    <img
      src={formatS3CdnUrl(config)(props.image.uri)}
      alt={props.image.viewName}
    />
    {props.count > 1
      && <div {...klass("image-count-overlay")}>
        <div {...klass("image-count inverted")}>
          {props.count}<Svg className="ml-025" src={imageIcon} />
        </div>
      </div>
    }
  </div>;
};
