import { subscribedC as imported26_subscribedC, subscribedC as imported27_subscribedC } from "./subscribed";
import { issuerWithAddressC as imported6_issuerWithAddressC, issuerWithAddressC as imported7_issuerWithAddressC } from "./issuer";
import * as t from "io-ts";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";

export const issuerSearchPostC = t.type({
  term: t.string,
  searchId: t.string,
  page: t.number,
  origin: t.string,
  searchByCusip: t.boolean
});
export type IssuerSearchPostC = typeof issuerSearchPostC;
export type IssuerSearchPost = t.TypeOf<IssuerSearchPostC>;


export const issuerSearchRequestPostC = t.type({
  issuer: t.string,
  captcha: t.string
});
export type IssuerSearchRequestPostC = typeof issuerSearchRequestPostC;
export type IssuerSearchRequestPost = t.TypeOf<IssuerSearchRequestPostC>;


export const issuerSearchResultC = t.type({
  link: t.string,
  main: t.string,
  secondary: t.string,
  resultType: t.string,
  issuer: imported26_subscribedC(imported6_issuerWithAddressC)
});
export type IssuerSearchResultC = typeof issuerSearchResultC;
export type IssuerSearchResult = t.TypeOf<IssuerSearchResultC>;


export const issuerSearchResultsC = t.type({
  page: t.number,
  pageSize: t.number,
  results: t.readonlyArray(issuerSearchResultC)
});
export type IssuerSearchResultsC = typeof issuerSearchResultsC;
export type IssuerSearchResults = t.TypeOf<IssuerSearchResultsC>;


export const issuersBySectorPostC = t.type({
  sectors: readonlyNonEmptyArrayC(t.number)
});
export type IssuersBySectorPostC = typeof issuersBySectorPostC;
export type IssuersBySectorPost = t.TypeOf<IssuersBySectorPostC>;


export const issuersCusip6SearchPostC = t.type({
  cusips: readonlyNonEmptyArrayC(t.string)
});
export type IssuersCusip6SearchPostC = typeof issuersCusip6SearchPostC;
export type IssuersCusip6SearchPost = t.TypeOf<IssuersCusip6SearchPostC>;


export const issuersCusip6SearchResultsC = t.type({
  valid: t.record(t.string, t.readonlyArray(imported27_subscribedC(imported7_issuerWithAddressC))),
  invalid: t.readonlyArray(t.string)
});
export type IssuersCusip6SearchResultsC = typeof issuersCusip6SearchResultsC;
export type IssuersCusip6SearchResults = t.TypeOf<IssuersCusip6SearchResultsC>;


