// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import "@js-joda/timezone";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import * as J from "@js-joda/core";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";


const IsLocalDate = (i: unknown): i is J.LocalDate => i instanceof J.LocalDate;
export const LocalDateC = new t.Type<J.LocalDate, string>(
  "LocalDateC",
  IsLocalDate,
  (i, c) => IsLocalDate(i) ? t.success(i) : pipe(
    i,
    t.string.decode,
    E.chain((s) => E.tryCatch(
      () => J.LocalDate.parse(s),
      (): t.Errors => [{ value: i, context: c, message: `Invalid ISO8601 date string. Expected: YYYY-MM-DD. Recieved: ${s}` }]
    )),
  ),
  (ld) => ld.toString()
);

export const ET = J.ZoneId.of("America/New_York");
const IsLocalDateTime = (i: unknown): i is J.LocalDateTime => i instanceof J.LocalDateTime;
export const LocalDateTimeFromIsoStringC = new t.Type<J.LocalDateTime, unknown>(
  "LocalDateTimeC",
  IsLocalDateTime,
  (i, c) => IsLocalDateTime(i) ? t.success(i) : pipe(
    i,
    t.string.decode,
    E.chain((s) => E.tryCatch(
      () => J.LocalDateTime.from(J.ZonedDateTime.parse(s).withZoneSameInstant(ET)),
      (): t.Errors => [{ value: i, context: c, message: `Invalid ISO 8601 Zoned Date with Time string. Expected: YYYY-MM-DDTHH:MM:SS.DDDZ. Recieved: ${s}` }]
    )),
  ),
  (ldt) => ldt.atZone(ET).withZoneSameInstant(J.ZoneId.UTC).toString()
);
