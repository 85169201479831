import { WithStatusCU as imported276_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported74_mediaC, mediaUploadResponseC as imported17_mediaUploadResponseC } from "./media";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported9_addressC, addressC as imported10_addressC } from "./address";
import { markdownC } from "../../codecs/markdown";

export const officerC = t.type({
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  address: optionFromNullable(imported9_addressC),
  media: optionFromNullable(imported74_mediaC)
});
export type OfficerC = typeof officerC;
export type Officer = t.TypeOf<OfficerC>;


export const issuerOfficersC = t.type({
  officers: t.readonlyArray(imported276_WithStatusCU(officerC))
});
export type IssuerOfficersC = typeof issuerOfficersC;
export type IssuerOfficers = t.TypeOf<IssuerOfficersC>;


export const officerPostBodyC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  address: optionFromNullable(imported10_addressC),
  media: optionFromNullable(imported17_mediaUploadResponseC)
});
export type OfficerPostBodyC = typeof officerPostBodyC;
export type OfficerPostBody = t.TypeOf<OfficerPostBodyC>;


export const postPrimaryOfficerC = t.type({
  id: t.number
});
export type PostPrimaryOfficerC = typeof postPrimaryOfficerC;
export type PostPrimaryOfficer = t.TypeOf<PostPrimaryOfficerC>;


export const welcomeLetterSigneeC = t.type({
  welcomeSigneeId: optionFromNullable(t.number)
});
export type WelcomeLetterSigneeC = typeof welcomeLetterSigneeC;
export type WelcomeLetterSignee = t.TypeOf<WelcomeLetterSigneeC>;


