import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { mediaUploadResponseC as imported14_mediaUploadResponseC } from "./media";

export const logoPostC = t.type({
  uploadResponse: imported14_mediaUploadResponseC
});
export type LogoPostC = typeof logoPostC;
export type LogoPost = t.TypeOf<LogoPostC>;


export const logoAndColorsC = t.type({
  logo: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  issuerName: t.string,
  subheader: optionFromNullable(t.string),
  customHeaderText: optionFromNullable(t.string),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
});
export type LogoAndColorsC = typeof logoAndColorsC;
export type LogoAndColors = t.TypeOf<LogoAndColorsC>;


export const logoAndColorsPostC = t.type({
  logoUri: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  issuerName: t.string,
  subheader: optionFromNullable(t.string),
  customHeaderText: optionFromNullable(t.string),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
});
export type LogoAndColorsPostC = typeof logoAndColorsPostC;
export type LogoAndColorsPost = t.TypeOf<LogoAndColorsPostC>;


