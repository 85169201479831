import { O } from "@scripts/fp-ts";
import { rfpsPage } from "@scripts/generated/domaintables/pages";
import type { RfpRelatedContent } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import type { ContentItemCardSubscriptionProps } from "@scripts/react/components/card/ContentItemCard";
import { RfpCard, type RfpCardProps } from "@scripts/react/components/card/RfpCard";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";

import { useIssuerSitesSelector } from "../state/store";
import { IssuerSitesLeafIcon } from "./LeafIcon";

export type IssuerSitesRfpCardProps = {
  rfp: Subscribed<WithStatusU<TaggedContent<{ rfp: Rfp, relatedContent: Pick<RfpRelatedContent, "roadShows" | "documents"> }>>>;
} & Pick<RfpCardProps, "issuer"> & O.Infer<ContentItemCardSubscriptionProps["subscription"]>;

export const IssuerSitesRfpCard = (props: IssuerSitesRfpCardProps) => {
  const pages = useIssuerSitesSelector("pages");

  return <RfpCard
    issuer={props.issuer}
    displayIssuer={false}
    rfp={{ ...props.rfp.data.data, record: { ...props.rfp.data.data.record, data: props.rfp.data.data.record.data.rfp } }}
    url={_ => _.url}
    customRfpsTitle={getCustomTitleO(rfpsPage)(pages)}
    roadshows={props.rfp.data.data.record.data.relatedContent.roadShows.map(_ => _.data)}
    links={["details", "roadshows"]}
    subscription={O.some({ isSubscribed: props.isSubscribed, onSubscribe: props.onSubscribe })}
    target="_self"
    leafIcon={IssuerSitesLeafIcon}
  />;
};
