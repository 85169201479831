import type { ReactElement } from "react";
import * as t from "io-ts";
import { singular } from "pluralize";
import type { CreativeWork, Event, Thing, WithContext } from "schema-dts";

import { type BLConfigWithLog } from "@scripts/bondlink";
import type { Markdown } from "@scripts/codecs/markdown";
import { E, flow, O, pipe, RA } from "@scripts/fp-ts";
import type {
  PageU,
  PhotoEditableU,
} from "@scripts/generated/domaintables/pages";
import {
  aboutPage,
  bondArchive as bondArchivePage,
  bondProgramsPage,
  bondRatings,
  bondsPage,
  documentsPage,
  esgProgram as esgProgramPage,
  faq as faqPage,
  financeTeam,
  homepage,
  infrastructureProjects,
  news,
  newsEventsPage,
  resourcesPage,
  rfpsPage,
} from "@scripts/generated/domaintables/pages";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { NewsAndEventsData, NewsAndEventsDataC } from "@scripts/generated/models/issuerNews";
import type { IssuerHomepageData, IssuerHomepageDataC } from "@scripts/generated/models/issuerPageData";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { RfpsSitesList, RfpsSitesListC } from "@scripts/generated/models/rfp";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { type DataMetaBase, esgProgramMeta, programMeta, programsMeta, rfpMeta, rfpsMeta } from "@scripts/meta/dataMeta";
import type { RouteMeta } from "@scripts/routes/routing/ssr";
import { getEditableTitleOrDefault, getPageCustomDescriptionOrDefault } from "@scripts/routes/routing/ssr";
import { issuerShortNameOrName } from "@scripts/syntax/issuer";
import { coerceStringAsMarkdown } from "@scripts/syntax/markdown";
import { getPage } from "@scripts/syntax/pageConfig";
import {
  makeSchemaOrgCreativeWork,
  type SchemaOrgOutput,
} from "@scripts/syntax/schemaOrg";
import { tagEq } from "@scripts/util/compare";

import { format } from "../base";
import { projectsMeta, teamMeta } from "../issuerportal/dataMeta";
import { makeBondSchemaOrgEventO, makeEventSchemaOrgEventO, makeRfpSchemaOrgEventO } from "./issuerSitesSchemaOrg";

type DescriptionFn<A> = (pages: ReadonlyArray<PageConfig<PageU>>, issuer: Issuer) => A;

type TitleFn = (pages: ReadonlyArray<PageConfig<PageU>>, issuer: Issuer) => string;

type MakeSchemaOrg<A, T extends Thing = Thing> = (
  data: A,
  config: BLConfigWithLog,
  issuer: Issuer
) => SchemaOrgOutput<T>;

export type IssuerSitesRouteMeta<A, Output> = Omit<RouteMeta<A, Output>, "description" | "title"> & {
  description: DescriptionFn<Markdown>;
  descriptionMeta: DescriptionFn<string>;
  photoPage: PhotoEditableU;
  title: TitleFn;
  titleMeta: TitleFn;
  makeSchemaOrg: O.Option<MakeSchemaOrg<A>>;
};

export type IssuerSitesRouteMetaWithRender<A, O> = IssuerSitesRouteMeta<A, O> & {
  render: (props: A) => ReactElement<A>;
};

type DescriptionTextFn = (issuer: Issuer, page: O.Option<PageConfig<PageU>>, pages: ReadonlyArray<PageConfig<PageU>>) => string;

const makeDescription = (page: PageU, makeDescriptionText: DescriptionTextFn): DescriptionFn<Markdown> =>
  (pages, issuer) => getPageCustomDescriptionOrDefault(
    page,
    (p) => coerceStringAsMarkdown(makeDescriptionText(issuer, p, pages)),
  )(pages);

export const makeDescriptionMeta = (page: PageU, makeDescriptionText: DescriptionTextFn) => (pages: ReadonlyArray<PageConfig<PageU>>, issuer: Issuer) => {
  return pipe(
    getPage(page)(pages),
    (p) => makeDescriptionText(issuer, p, pages)
  );
};

const getPageTitleOrDefault = (pageMeta: DataMetaBase<string>) => flow(
  O.chain((_: PageConfig<PageU>) => _.title),
  O.getOrElse(() => pageMeta.type)
);

// HOME PAGE
const makeHomeDescription = (issuer: Issuer) =>
  `The ${issuerShortNameOrName(issuer)} website features bond offerings and ratings, financial documents, news & updates about our municipality, and other information about our municipal bond programs. Powered by BondLink.`;

export const homeMatch = SR.issuersitesIssuerControllerIndexRoute().match;
export type HomeUrlParams = typeof homeMatch._A;

export const home = (reqParams: SR.IssuersitesIssuerControllerIndexParams) => ({
  // The homepage doesn't have a description
  description: () => coerceStringAsMarkdown(""),
  descriptionMeta: (_, issuer) => makeHomeDescription(issuer),
  _tag: "home",
  propsCodec: SR.issuersitesIssuerControllerIndexCodecs.ssrInput,
  url: () => format(homeMatch, reqParams),
  title: (_, issuer) => issuer.name,
  titleMeta: () => "Bonds, Documents, Resources",
  photoPage: homepage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<IssuerHomepageData, t.OutputOf<IssuerHomepageDataC>>);

// RFP SUMMARY PAGE
export const rfpsMatch = SR.issuersitesRfpsControllerRfpsRoute().match;

export type RfpsUrlParams = typeof rfpsMatch._A;

export const rfps = (reqParams: SR.IssuersitesRfpsControllerRfpsParams) => {
  return {
    description: makeDescription(
      rfpsPage,
      (_, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)}.`,
    ),
    descriptionMeta: makeDescriptionMeta(
      rfpsPage,
      (issuer, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)} from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "rfps",
    propsCodec: SR.issuersitesRfpsControllerRfpsCodecs.ssrInput,
    title: getEditableTitleOrDefault(rfpsPage, rfpsMeta),
    titleMeta: getEditableTitleOrDefault(rfpsPage, rfpsMeta),
    url: () => format(rfpsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
  } as const satisfies IssuerSitesRouteMeta<RfpsSitesList, t.OutputOf<RfpsSitesListC>>;
};

// RFP PAGE
export const rfpMatch = SR.issuersitesRfpsControllerRfpRoute().match;
export type RfpUrlParams = typeof rfpMatch._A;

export type RfpPageDataC = typeof SR.issuersitesRfpsControllerRfpCodecs.ssrInput;

const rfpDesc: DescriptionTextFn = (issuer, page) =>
  `Get details about this ${getPageTitleOrDefault(rfpMeta)(page)} from ${issuerShortNameOrName(issuer)}, including project information, submission requirements, and other related content.`;

export const rfp = (reqParams: SR.IssuersitesRfpsControllerRfpParams) => ({
  description: makeDescription(
    rfpsPage,
    rfpDesc
  ),
  descriptionMeta: makeDescriptionMeta(
    rfpsPage,
    rfpDesc
  ),
  _tag: "rfp",
  propsCodec: SR.issuersitesRfpsControllerRfpCodecs.ssrInput,
  url: () => format(rfpMatch, reqParams),
  title: (pages) => singular(getEditableTitleOrDefault(rfpsPage, rfpMeta)(pages)),
  titleMeta: (pages) => singular(getEditableTitleOrDefault(rfpsPage, rfpMeta)(pages)),
  photoPage: bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) => RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
    makeRfpSchemaOrgEventO(E.toUnion(data).rfp.data, config, issuer),
    O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
  ])),
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<RfpPageDataC>, t.OutputOf<RfpPageDataC>>);

// BONDS SUMMARY PAGE
export const bondsMatch = SR.issuersitesBondOfferingsControllerIndexRoute().match;

export type BondsPageDataC = typeof SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput;

export const bonds = (reqParams: SR.IssuersitesBondOfferingsControllerIndexParams) => {
  return {
    description: makeDescription(
      bondsPage,
      () => "Get an overview of our bonds and ratings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondsPage,
      (issuer) => `Get an overview of bonds and ratings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bonds",
    propsCodec: SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput,
    title: () => "Bonds",
    titleMeta: () => "Bonds",
    url: () => format(bondsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<BondsPageDataC>, t.OutputOf<BondsPageDataC>>;
};

// BOND ARCHIVE PAGE
export const bondArchiveMatch = SR.issuersitesBondOfferingsControllerArchivedBondsRoute().match;

export type BondArchivePageDataC = typeof SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput;

export const bondArchive = (reqParams: SR.IssuersitesBondOfferingsControllerArchivedBondsParams) => {
  return {
    description: makeDescription(
      bondArchivePage,
      () => "Sort, filter, and view archived bond offerings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondArchivePage,
      (issuer) => `Sort, filter, and view archived bond offerings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bonds",
    propsCodec: SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput,
    title: () => "Bond Archive",
    titleMeta: () => "Bond Archive",
    url: () => format(bondArchiveMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<BondArchivePageDataC>, t.OutputOf<BondArchivePageDataC>>;
};

// OFFERING PAGE
const offeringPageDescription = (issuer: Issuer) =>
  `Details and offering statement about this municipal bond offering from ${issuerShortNameOrName(issuer)}.`;

export const offeringPageMatch = SR.issuersitesBondOfferingsControllerOfferingRoute().match;

export type OfferingPageDataC = typeof SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput;

export const offeringPage = (reqParams: SR.IssuersitesBondOfferingsControllerOfferingParams) => ({
  description: makeDescription(
    bondsPage,
    offeringPageDescription
  ),
  descriptionMeta: makeDescriptionMeta(
    bondsPage,
    offeringPageDescription
  ),
  _tag: "offering-page",
  propsCodec: SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput,
  url: () => format(offeringPageMatch, reqParams),
  title: () => "Bond Offering",
  titleMeta: () => "Bond Offering",
  photoPage: bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) =>
    RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
      makeBondSchemaOrgEventO(E.toUnion(data).offering.data, config, issuer),
      O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
    ])
  ),
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<OfferingPageDataC>, t.OutputOf<OfferingPageDataC>>);

// NEWS AND EVENTS PAGE
const makeNewsAndEventsDescription: DescriptionTextFn = (issuer) =>
  `Learn about the latest News & Events for ${issuerShortNameOrName(issuer)}, and sign up to receive news updates.`;

export const newsAndEventsMatch = SR.issuersitesAboutControllerNewsEventsRoute().match;
export type NewsAndEventsUrlParams = typeof newsAndEventsMatch._A;

export const newsPressReleasesSectionTitle = (pages: ReadonlyArray<PageConfig<PageU>>, defaultTitle: string) => pipe(
  pages,
  RA.findFirst(_ => tagEq().equals(_.page, news)),
  O.chain(_ => _.title),
  O.getOrElse(() => defaultTitle)
);

export const newsAndEvents = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams) => ({
  description: makeDescription(
    newsEventsPage,
    makeNewsAndEventsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    newsEventsPage,
    makeNewsAndEventsDescription
  ),
  _tag: "news-and-events",
  propsCodec: SR.issuersitesAboutControllerNewsEventsCodecs.ssrInput,
  url: () => format(newsAndEventsMatch, reqParams),
  title: () => "News & Events",
  titleMeta: () => "News & Events",
  photoPage: aboutPage,
  makeSchemaOrg: O.some((data, config, issuer) => pipe(
    data.eventItems,
    RA.filterMap(flow(
      E.fold(
        eventData => makeEventSchemaOrgEventO(eventData, config, issuer),
        E.fold(
          bondData => makeBondSchemaOrgEventO(bondData.data, config, issuer),
          rfpData => makeRfpSchemaOrgEventO(rfpData.data, config, issuer),
        ))
    )
    )),
  ),
} as const satisfies IssuerSitesRouteMeta<NewsAndEventsData, t.OutputOf<NewsAndEventsDataC>>);

// RATINGS PAGE
export type RatingsPageDataC = typeof SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput;

export const ratingsMatch = SR.issuersitesBondOfferingsControllerRatingsRoute().match;

export const ratings = (reqParams: SR.IssuersitesBondOfferingsControllerRatingsParams) => {
  return {
    description: makeDescription(
      bondRatings,
      () => "View ratings for our bond programs from rating agencies such as Moody’s Investors Service, S&P Global Ratings, and Fitch Ratings. Ratings for individual bonds are shown on their respective offering pages."
    ),
    descriptionMeta: makeDescriptionMeta(
      bondRatings,
      (issuer) => `View ratings for ${issuerShortNameOrName(issuer)} and our bond programs from agencies such as Moody’s, S&P, Fitch, and Kroll.`
    ),
    _tag: "ratings",
    propsCodec: SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput,
    title: () => "Ratings",
    titleMeta: () => "Ratings",
    url: () => format(ratingsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<RatingsPageDataC>, t.OutputOf<RatingsPageDataC>>;
};

// PROGRAMS PAGE
export type ProgramsPageDataC = typeof SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput;

export const programsMatch = SR.issuersitesBondProgramsControllerBondProgramsRoute().match;

export const programs = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramsParams) => ({
  description: makeDescription(
    bondProgramsPage,
    (_, page) => `Learn about our ${getPageTitleOrDefault(programsMeta)(page)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    (issuer, page) => `Learn about ${getPageTitleOrDefault(programsMeta)(page)} from ${issuerShortNameOrName(issuer)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  _tag: "programs",
  propsCodec: SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput,
  title: getEditableTitleOrDefault(bondProgramsPage, programsMeta),
  titleMeta: getEditableTitleOrDefault(bondProgramsPage, programsMeta),
  url: () => format(programsMatch, { ...reqParams }),
  photoPage: bondsPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<ProgramsPageDataC>, t.OutputOf<ProgramsPageDataC>>);

// PROGRAM PAGE
const programPageDataC = SR.issuersitesBondProgramsControllerBondProgramCodecs.ssrInput;
export type ProgramPageDataC = typeof programPageDataC;
export type ProgramPageData = t.TypeOf<ProgramPageDataC>;

const makeProgramDescription: DescriptionTextFn = (issuer, page) => pipe(
  getPageTitleOrDefault(programMeta)(page),
  singular,
  pageTitle => `Get Details about this ${pageTitle} from ${issuerShortNameOrName(issuer)}, including related bond offerings, ratings, CUSIPs, and more.`
);

export const programMatch = SR.issuersitesBondProgramsControllerBondProgramRoute().match;

export const program = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramParams) => ({
  description: makeDescription(
    bondProgramsPage,
    makeProgramDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    makeProgramDescription
  ),
  _tag: "bond-program",
  propsCodec: programPageDataC,
  title: pages => singular(getEditableTitleOrDefault(bondProgramsPage, programMeta)(pages)),
  titleMeta: pages => singular(getEditableTitleOrDefault(bondProgramsPage, programMeta)(pages)),
  url: () => format(programMatch, { ...reqParams }),
  photoPage: bondsPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<ProgramPageData, t.OutputOf<ProgramPageDataC>>);

// FAQs PAGE
const faqPageDataC = SR.issuersitesResourcesControllerFaqCodecs.ssrInput;
export type FAQPageDataC = typeof faqPageDataC;
export type FAQPageData = t.TypeOf<FAQPageDataC>;

export const faqMatch = SR.issuersitesResourcesControllerFaqRoute().match;

const makefaqDescription: DescriptionTextFn = (issuer) => `View frequently asked questions (FAQ) for ${issuer.name}.`;

export const faq = (reqParams: SR.IssuersitesResourcesControllerFaqParams) => ({
  description: makeDescription(faqPage, makefaqDescription),
  descriptionMeta: makeDescriptionMeta(faqPage, makefaqDescription),
  _tag: "faq",
  propsCodec: faqPageDataC,
  title: () => "FAQ",
  titleMeta: () => "FAQ",
  url: () => format(faqMatch, { ...reqParams }),
  photoPage: resourcesPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<FAQPageData, t.OutputOf<FAQPageDataC>>);

// ABOUT PAGE
const aboutPageDataC = SR.issuersitesAboutControllerIndexCodecs.ssrInput;
export type AboutPageDataC = typeof aboutPageDataC;
export type AboutPageData = t.TypeOf<AboutPageDataC>;

export const aboutMatch = SR.issuersitesAboutControllerIndexRoute().match;

const aboutDescription: DescriptionTextFn = (issuer, _, pages) =>
  `Learn about ${issuer.name}, including our ESG Program, Featured News, Key Projects, and ${teamTitle(pages)}.`;

export const about = (reqParams: SR.IssuersitesAboutControllerIndexParams) => ({
  description: makeDescription(
    aboutPage,
    aboutDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    aboutPage,
    aboutDescription
  ),
  _tag: "about",
  propsCodec: aboutPageDataC,
  title: () => "About",
  titleMeta: () => "Team, News, Projects",
  url: () => format(aboutMatch, { ...reqParams }),
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<AboutPageData, t.OutputOf<AboutPageDataC>>);

// ESG PROGRAM PAGE
export type EsgProgramPageDataC = typeof SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput;

export const esgProgramMatch = SR.issuersitesAboutControllerEsgProgramRoute().match;
export const esgTitle = getEditableTitleOrDefault(esgProgramPage, esgProgramMeta);

export const esgDescription = makeDescription(
  esgProgramPage,
  () => "Learn about our environmental, social, and governance program, and how we bring those values to life with green bonds, sustainable projects, and more."
);

export const esgProgram = (reqParams: SR.IssuersitesAboutControllerEsgProgramParams) => {
  return {
    description: esgDescription,
    descriptionMeta: makeDescriptionMeta(
      esgProgramPage,
      (issuer) => `Learn about the environmental, social, and governance program from ${issuerShortNameOrName(issuer)}, and how we bring those values to life with green bonds, sustainable projects, and more.`
    ),
    _tag: "esg-program",
    propsCodec: SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput,
    title: esgTitle,
    titleMeta: esgTitle,
    url: () => format(esgProgramMatch, { ...reqParams }),
    photoPage: aboutPage,
    makeSchemaOrg: O.none,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<EsgProgramPageDataC>, t.OutputOf<EsgProgramPageDataC>>;
};

// Team Page
export const teamTitle = getEditableTitleOrDefault(financeTeam, teamMeta);

const teamPageDataC = SR.issuersitesAboutControllerTeamCodecs.ssrInput;
export type TeamPageDataC = typeof teamPageDataC;
export type TeamPageData = t.TypeOf<TeamPageDataC>;

export const teamMatch = SR.issuersitesAboutControllerTeamRoute().match;

const teamDescription: DescriptionTextFn = (issuer) =>
  `Learn about the team at ${issuerShortNameOrName(issuer)}.`;
export const team = (reqParams: SR.IssuersitesAboutControllerTeamParams) => ({
  description: makeDescription(
    financeTeam,
    teamDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    financeTeam,
    teamDescription
  ),
  _tag: "team",
  title: teamTitle,
  titleMeta: teamTitle,
  propsCodec: teamPageDataC,
  url: () => format(teamMatch, reqParams),
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
}) as const satisfies IssuerSitesRouteMeta<TeamPageData, t.OutputOf<TeamPageDataC>>;

// Projects Page

export const projectsTitle = getEditableTitleOrDefault(infrastructureProjects, projectsMeta);

const projectsPageDataC = SR.issuersitesAboutControllerProjectsCodecs.ssrInput;
export type ProjectsPageDataC = typeof projectsPageDataC;
export type ProjectsPageData = t.TypeOf<ProjectsPageDataC>;

export const projectsMatch = SR.issuersitesAboutControllerProjectsRoute().match;

const projectsDescription: DescriptionTextFn = (issuer, _) =>
  `Learn about ${getPageTitleOrDefault(projectsMeta)(_)} for ${issuerShortNameOrName(issuer)}.`;

export const projects = (reqParams: SR.IssuersitesAboutControllerProjectsParams) => ({
  description: makeDescription(
    infrastructureProjects,
    projectsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    infrastructureProjects,
    projectsDescription
  ),
  _tag: "projects",
  propsCodec: projectsPageDataC,
  url: () => format(projectsMatch, reqParams),
  title: projectsTitle,
  titleMeta: projectsTitle,
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<ProjectsPageData, t.OutputOf<ProjectsPageDataC>>);


// DOWNLOADS PAGE
const downloadsPageDataC = SR.issuersitesReportsControllerDownloadsCodecs.ssrInput;
export type DownloadsPageDataC = typeof downloadsPageDataC;
export type DownloadsPageData = t.TypeOf<DownloadsPageDataC>;

export const downloadsMatch = SR.issuersitesReportsControllerDownloadsRoute().match;

const downloadsDescription: DescriptionTextFn = (issuer) =>
  `Download documents from ${issuer.name}, sort and filter documents, and sign up to receive document updates.`;

export const downloads = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    downloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    downloadsDescription
  ),
  _tag: "downloads",
  propsCodec: downloadsPageDataC,
  title: () => "Downloads",
  titleMeta: () => "Downloads",
  url: () => format(downloadsMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>);

// ARCHIVED DOWNLOADS PAGE

export const archivedDownloadsMatch = SR.issuersitesReportsControllerArchiveDownloadsRoute().match;

const archivedDownloadsDescription: DescriptionTextFn = (issuer) =>
  `Download archived documents from ${issuer.name}.`;

export const archivedDocuments = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    archivedDownloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    archivedDownloadsDescription
  ),
  _tag: "archived-documents",
  propsCodec: downloadsPageDataC,
  title: () => "Archived Documents",
  titleMeta: () => "Archived Documents",
  url: () => format(archivedDownloadsMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>);

// NOT FOUND

export const notFound = {
  _tag: "NotFound",
  propsCodec: t.unknown,
  title: () => "Not Found",
  titleMeta: () => "Not Found",
  url: () => "/",
  description: () => coerceStringAsMarkdown(`Not Found`),
  descriptionMeta: () => `Not Found`,
  photoPage: homepage,
  makeSchemaOrg: O.none,
} as const satisfies IssuerSitesRouteMeta<unknown, t.OutputOf<t.UnknownC>>;
