import { hasManyLinkC as imported126_hasManyLinkC, WithStatusCU as imported289_WithStatusCU, WithStatusCU as imported282_WithStatusCU, hasManyLinkC as imported131_hasManyLinkC, WithStatusCU as imported281_WithStatusCU, WithStatusCU as imported284_WithStatusCU, WithStatusCU as imported280_WithStatusCU, WithStatusCU as imported277_WithStatusCU, hasManyLinkC as imported130_hasManyLinkC, WithStatusCU as imported278_WithStatusCU, WithStatusCU as imported288_WithStatusCU, hasManyLinkC as imported132_hasManyLinkC, hasManyLinkC as imported123_hasManyLinkC, hasManyLinkC as imported125_hasManyLinkC, hasManyLinkC as imported127_hasManyLinkC, hasManyLinkC as imported124_hasManyLinkC, WithStatusCU as imported279_WithStatusCU, hasManyLinkC as imported122_hasManyLinkC, WithStatusCU as imported283_WithStatusCU, WithStatusCU as imported286_WithStatusCU, hasManyLinkC as imported128_hasManyLinkC, WithStatusCU as imported285_WithStatusCU, hasManyLinkC as imported129_hasManyLinkC, WithStatusCU as imported287_WithStatusCU, hasManyLinkC as imported121_hasManyLinkC } from "./threadThrough";
import * as t from "io-ts";
import { externalLinkC as imported21_externalLinkC, externalLinkC as imported20_externalLinkC } from "./externalLink";
import { issuerNewsC as imported16_issuerNewsC } from "./issuerNewsBase";
import { imageUploadResponseC as imported8_imageUploadResponseC, mediaC as imported75_mediaC, mediaC as imported78_mediaC, mediaUploadResponseWithCategoryC as imported12_mediaUploadResponseWithCategoryC, mediaC as imported77_mediaC, mediaC as imported76_mediaC } from "./media";
import { taggedContentC as imported171_taggedContentC, taggedContentC as imported173_taggedContentC, taggedContentC as imported170_taggedContentC, taggedContentC as imported169_taggedContentC, taggedContentC as imported172_taggedContentC } from "./taggedContent";
import { quickFactC as imported12_quickFactC, quickFactC as imported11_quickFactC, quickFactC as imported13_quickFactC } from "./quickFact";
import { bondProgramC as imported21_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported14_eventC } from "./event";
import { sortOrderC as imported32_sortOrderC, sortOrderC as imported33_sortOrderC, sortOrderC as imported34_sortOrderC, sortOrderC as imported35_sortOrderC } from "./sortOrder";
import { relatedDocumentLinkC as imported31_relatedDocumentLinkC, postAttachedC as imported23_postAttachedC, postRelatedC as imported59_postRelatedC, postRelatedC as imported56_postRelatedC, postRelatedAndAttachedC as imported12_postRelatedAndAttachedC, relatedRfpLinkC as imported10_relatedRfpLinkC, relatedOfferingLinkC as imported10_relatedOfferingLinkC, relatedPhotoLinkC as imported7_relatedPhotoLinkC, relatedDocumentLinkC as imported30_relatedDocumentLinkC, relatedEventLinkC as imported16_relatedEventLinkC, relatedNewsLinkC as imported16_relatedNewsLinkC, postRelatedC as imported57_postRelatedC, relatedProgramLinkC as imported8_relatedProgramLinkC, relatedProgramLinkC as imported9_relatedProgramLinkC, relatedPhotoLinkC as imported6_relatedPhotoLinkC, relatedEventLinkC as imported17_relatedEventLinkC, relatedPhotoLinkC as imported5_relatedPhotoLinkC, relatedQuickFactLinkC as imported5_relatedQuickFactLinkC, isAttachedC as imported18_isAttachedC, relatedExternalLinkLinkC as imported10_relatedExternalLinkLinkC, postAttachedC as imported22_postAttachedC, relatedRfpLinkC as imported11_relatedRfpLinkC, postRelatedC as imported55_postRelatedC, relatedOfferingLinkC as imported11_relatedOfferingLinkC, postRelatedC as imported58_postRelatedC, postAttachedC as imported24_postAttachedC, relatedQuickFactLinkC as imported4_relatedQuickFactLinkC, relatedNewsLinkC as imported17_relatedNewsLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { bondOfferingC as imported21_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported54_rfpC } from "./rfpBase";

export const projectC = t.type({
  projectTitle: t.string,
  projectText: markdownC,
  projectOrder: t.number
});
export type ProjectC = typeof projectC;
export type Project = t.TypeOf<ProjectC>;


export const projectRelatedContentC = t.type({
  offerings: t.readonlyArray(imported121_hasManyLinkC(imported278_WithStatusCU(imported170_taggedContentC(imported21_bondOfferingC)), imported11_relatedOfferingLinkC)),
  news: t.readonlyArray(imported122_hasManyLinkC(imported279_WithStatusCU(imported171_taggedContentC(imported16_issuerNewsC)), imported17_relatedNewsLinkC)),
  events: t.readonlyArray(imported123_hasManyLinkC(imported280_WithStatusCU(imported14_eventC), imported17_relatedEventLinkC)),
  quickFacts: t.readonlyArray(imported124_hasManyLinkC(imported281_WithStatusCU(imported12_quickFactC), imported4_relatedQuickFactLinkC)),
  photos: t.readonlyArray(imported125_hasManyLinkC(imported282_WithStatusCU(imported75_mediaC), imported5_relatedPhotoLinkC)),
  documents: t.readonlyArray(imported126_hasManyLinkC(imported283_WithStatusCU(imported172_taggedContentC(imported76_mediaC)), imported18_isAttachedC(imported31_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported127_hasManyLinkC(imported284_WithStatusCU(imported21_externalLinkC), imported10_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported128_hasManyLinkC(imported285_WithStatusCU(imported173_taggedContentC(imported54_rfpC)), imported11_relatedRfpLinkC)),
  programs: t.readonlyArray(imported129_hasManyLinkC(imported286_WithStatusCU(imported21_bondProgramC), imported9_relatedProgramLinkC))
});
export type ProjectRelatedContentC = typeof projectRelatedContentC;
export type ProjectRelatedContent = t.TypeOf<ProjectRelatedContentC>;


export const projectWithRelatedContentC = t.type({
  project: projectC,
  relatedContent: projectRelatedContentC
});
export type ProjectWithRelatedContentC = typeof projectWithRelatedContentC;
export type ProjectWithRelatedContent = t.TypeOf<ProjectWithRelatedContentC>;


export const projectsListC = t.readonlyArray(imported277_WithStatusCU(imported169_taggedContentC(projectWithRelatedContentC)));
export type ProjectsListC = typeof projectsListC;
export type ProjectsList = t.TypeOf<ProjectsListC>;


export const projectPostRelatedContentC = t.type({
  offerings: imported55_postRelatedC(imported10_relatedOfferingLinkC),
  news: imported56_postRelatedC(imported16_relatedNewsLinkC),
  events: imported57_postRelatedC(imported16_relatedEventLinkC),
  quickFacts: imported22_postAttachedC(imported11_quickFactC, imported32_sortOrderC),
  photos: imported23_postAttachedC(imported8_imageUploadResponseC, imported33_sortOrderC),
  documents: imported12_postRelatedAndAttachedC(imported30_relatedDocumentLinkC, imported12_mediaUploadResponseWithCategoryC, imported34_sortOrderC),
  externalLinks: imported24_postAttachedC(imported20_externalLinkC, imported35_sortOrderC),
  rfps: imported58_postRelatedC(imported10_relatedRfpLinkC),
  programs: imported59_postRelatedC(imported8_relatedProgramLinkC)
});
export type ProjectPostRelatedContentC = typeof projectPostRelatedContentC;
export type ProjectPostRelatedContent = t.TypeOf<ProjectPostRelatedContentC>;


export const projectPostBodyC = t.type({
  id: optionFromNullable(t.number),
  projectTitle: t.string,
  projectText: markdownC,
  projectOrder: t.number,
  esgRelated: t.boolean,
  relatedContent: projectPostRelatedContentC
});
export type ProjectPostBodyC = typeof projectPostBodyC;
export type ProjectPostBody = t.TypeOf<ProjectPostBodyC>;


export const projectWithPhotosC = t.type({
  project: projectC,
  photos: t.readonlyArray(imported130_hasManyLinkC(imported287_WithStatusCU(imported77_mediaC), imported6_relatedPhotoLinkC))
});
export type ProjectWithPhotosC = typeof projectWithPhotosC;
export type ProjectWithPhotos = t.TypeOf<ProjectWithPhotosC>;


export const projectWithPhotosAndQuickFactsC = t.type({
  project: projectC,
  photos: t.readonlyArray(imported131_hasManyLinkC(imported288_WithStatusCU(imported78_mediaC), imported7_relatedPhotoLinkC)),
  quickFacts: t.readonlyArray(imported132_hasManyLinkC(imported289_WithStatusCU(imported13_quickFactC), imported5_relatedQuickFactLinkC))
});
export type ProjectWithPhotosAndQuickFactsC = typeof projectWithPhotosAndQuickFactsC;
export type ProjectWithPhotosAndQuickFacts = t.TypeOf<ProjectWithPhotosAndQuickFactsC>;


