import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { offeringParticipantC as imported16_offeringParticipantC, offeringParticipantC as imported14_offeringParticipantC, offeringParticipantC as imported15_offeringParticipantC } from "./offeringParticipant";
import { externalLinkC as imported15_externalLinkC, externalLinkC as imported14_externalLinkC } from "./externalLink";
import { projectC as imported12_projectC, projectWithPhotosAndQuickFactsC as imported2_projectWithPhotosAndQuickFactsC } from "./project";
import { issuerNewsC as imported12_issuerNewsC } from "./issuerNewsBase";
import { participantTypeC as imported7_participantTypeC, participantTypeC as imported8_participantTypeC } from "./participantType";
import { rfpC as imported48_rfpC, rfpC as imported51_rfpC, dateWithOptionalTimeC as imported6_dateWithOptionalTimeC, rfpC as imported49_rfpC, rfpC as imported50_rfpC, dateWithOptionalTimeC as imported7_dateWithOptionalTimeC, rfpC as imported47_rfpC } from "./rfpBase";
import { eventC as imported10_eventC } from "./event";
import { roadShowDataC as imported20_roadShowDataC, roadShowDataC as imported17_roadShowDataC, roadShowDataC as imported18_roadShowDataC, roadShowDataC as imported19_roadShowDataC } from "./roadshow";
import { officerC as imported9_officerC } from "./officer";
import { markdownC } from "../../codecs/markdown";
import { WithStatusCU as imported228_WithStatusCU, withIdC as imported217_withIdC, withIdC as imported226_withIdC, WithStatusCU as imported222_WithStatusCU, WithStatusCU as imported219_WithStatusCU, withIdC as imported228_withIdC, hasManyLinkC as imported90_hasManyLinkC, hasManyLinkC as imported88_hasManyLinkC, hasManyLinkC as imported81_hasManyLinkC, withIdC as imported221_withIdC, WithStatusCU as imported235_WithStatusCU, hasManyLinkC as imported86_hasManyLinkC, WithStatusCU as imported217_WithStatusCU, WithStatusCU as imported221_WithStatusCU, WithStatusCU as imported223_WithStatusCU, hasManyLinkC as imported82_hasManyLinkC, WithStatusCU as imported224_WithStatusCU, WithStatusCU as imported220_WithStatusCU, hasManyLinkC as imported89_hasManyLinkC, WithStatusCU as imported231_WithStatusCU, withIdC as imported225_withIdC, WithStatusCU as imported230_WithStatusCU, withIdC as imported220_withIdC, withIdC as imported227_withIdC, WithStatusCU as imported232_WithStatusCU, withIdC as imported218_withIdC, hasManyLinkC as imported83_hasManyLinkC, WithStatusCU as imported226_WithStatusCU, withIdC as imported224_withIdC, hasManyLinkC as imported85_hasManyLinkC, WithStatusCU as imported233_WithStatusCU, WithStatusCU as imported234_WithStatusCU, hasManyLinkC as imported87_hasManyLinkC, withIdC as imported219_withIdC, WithStatusCU as imported229_WithStatusCU, withIdC as imported223_withIdC, WithStatusCU as imported227_WithStatusCU, withIdC as imported222_withIdC, WithStatusCU as imported218_WithStatusCU, WithStatusCU as imported225_WithStatusCU, hasManyLinkC as imported84_hasManyLinkC } from "./threadThrough";
import { issuerC as imported42_issuerC, issuerC as imported43_issuerC, issuerC as imported44_issuerC } from "./issuer";
import { mediaUploadResponseWithCategoryC as imported9_mediaUploadResponseWithCategoryC, mediaC as imported62_mediaC, mediaC as imported61_mediaC, mediaC as imported63_mediaC, mediaC as imported60_mediaC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import { attachedFaqC as imported3_attachedFaqC, attachedFaqC as imported2_attachedFaqC } from "./faq";
import { taggedContentC as imported138_taggedContentC, taggedContentC as imported146_taggedContentC, taggedContentC as imported129_taggedContentC, taggedContentC as imported136_taggedContentC, taggedContentC as imported130_taggedContentC, taggedContentC as imported141_taggedContentC, taggedContentC as imported144_taggedContentC, taggedContentC as imported132_taggedContentC, taggedContentC as imported147_taggedContentC, taggedContentC as imported139_taggedContentC, taggedContentC as imported134_taggedContentC, taggedContentC as imported142_taggedContentC, taggedContentC as imported131_taggedContentC, taggedContentC as imported133_taggedContentC, taggedContentC as imported140_taggedContentC, taggedContentC as imported145_taggedContentC, taggedContentC as imported137_taggedContentC, taggedContentC as imported135_taggedContentC, taggedContentC as imported143_taggedContentC } from "./taggedContent";
import { bankC as imported16_bankC } from "./bank";
import { subscribedC as imported21_subscribedC, subscribedC as imported22_subscribedC, subscribedC as imported23_subscribedC, subscribedC as imported24_subscribedC } from "./subscribed";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondProgramWithRatingsC as imported2_bondProgramWithRatingsC } from "./bondProgramWithRatings";
import { userC as imported12_userC } from "./user";
import { sortOrderC as imported23_sortOrderC, sortOrderC as imported24_sortOrderC, sortOrderC as imported25_sortOrderC } from "./sortOrder";
import { isAttachedC as imported13_isAttachedC, relatedDocumentLinkC as imported22_relatedDocumentLinkC, relatedOfferingParticipantLinkC as imported7_relatedOfferingParticipantLinkC, relatedFaqLinkC as imported1_relatedFaqLinkC, relatedOfferingParticipantLinkC as imported8_relatedOfferingParticipantLinkC, relatedProjectLinkC as imported14_relatedProjectLinkC, postRelatedC as imported43_postRelatedC, postRelatedC as imported44_postRelatedC, relatedRoadShowLinkC as imported9_relatedRoadShowLinkC, relatedProjectLinkC as imported15_relatedProjectLinkC, postAttachedC as imported15_postAttachedC, relatedNewsLinkC as imported9_relatedNewsLinkC, postRelatedAndAttachedC as imported9_postRelatedAndAttachedC, isAttachedC as imported12_isAttachedC, relatedEventLinkC as imported10_relatedEventLinkC, relatedEventLinkC as imported9_relatedEventLinkC, postRelatedC as imported40_postRelatedC, relatedExternalLinkLinkC as imported7_relatedExternalLinkLinkC, relatedDocumentLinkC as imported23_relatedDocumentLinkC, relatedDocumentLinkC as imported21_relatedDocumentLinkC, postAttachedC as imported16_postAttachedC, relatedRoadShowLinkC as imported8_relatedRoadShowLinkC, postRelatedC as imported41_postRelatedC, relatedRoadShowLinkC as imported10_relatedRoadShowLinkC, relatedNewsLinkC as imported10_relatedNewsLinkC, postRelatedC as imported42_postRelatedC } from "./relatedContent";
import { clientTextItemC as imported19_clientTextItemC } from "./clientTextItem";

export const rfpRelatedContentC = t.type({
  projects: t.readonlyArray(imported83_hasManyLinkC(imported224_WithStatusCU(imported140_taggedContentC(imported12_projectC)), imported15_relatedProjectLinkC)),
  news: t.readonlyArray(imported84_hasManyLinkC(imported225_WithStatusCU(imported141_taggedContentC(imported12_issuerNewsC)), imported10_relatedNewsLinkC)),
  events: t.readonlyArray(imported85_hasManyLinkC(imported226_WithStatusCU(imported10_eventC), imported10_relatedEventLinkC)),
  roadShows: t.readonlyArray(imported86_hasManyLinkC(imported228_withIdC(imported142_taggedContentC(imported20_roadShowDataC)), imported10_relatedRoadShowLinkC)),
  participants: t.readonlyArray(imported87_hasManyLinkC(imported227_WithStatusCU(imported16_offeringParticipantC), imported8_relatedOfferingParticipantLinkC)),
  documents: t.readonlyArray(imported88_hasManyLinkC(imported228_WithStatusCU(imported143_taggedContentC(imported63_mediaC)), imported13_isAttachedC(imported23_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported89_hasManyLinkC(imported229_WithStatusCU(imported15_externalLinkC), imported7_relatedExternalLinkLinkC)),
  faqs: t.readonlyArray(imported90_hasManyLinkC(imported230_WithStatusCU(imported3_attachedFaqC), imported1_relatedFaqLinkC)),
  program: optionFromNullable(imported231_WithStatusCU(imported2_bondProgramWithRatingsC))
});
export type RfpRelatedContentC = typeof rfpRelatedContentC;
export type RfpRelatedContent = t.TypeOf<RfpRelatedContentC>;


export const rfpWithRelatedContentC = t.type({
  rfp: imported51_rfpC,
  relatedContent: rfpRelatedContentC
});
export type RfpWithRelatedContentC = typeof rfpWithRelatedContentC;
export type RfpWithRelatedContent = t.TypeOf<RfpWithRelatedContentC>;


export const bankRfpSitesDataC = t.type({
  bank: imported16_bankC,
  user: optionFromNullable(imported12_userC),
  rfp: imported21_subscribedC(imported217_WithStatusCU(imported129_taggedContentC(rfpWithRelatedContentC))),
  participants: t.readonlyArray(t.tuple([imported217_withIdC(imported7_participantTypeC), readonlyNonEmptyArrayC(imported218_WithStatusCU(imported14_offeringParticipantC))])),
  issuerAboutText: optionFromNullable(imported19_clientTextItemC),
  officers: t.readonlyArray(imported219_WithStatusCU(imported9_officerC)),
  projects: t.readonlyArray(imported220_WithStatusCU(imported130_taggedContentC(imported2_projectWithPhotosAndQuickFactsC)))
});
export type BankRfpSitesDataC = typeof bankRfpSitesDataC;
export type BankRfpSitesData = t.TypeOf<BankRfpSitesDataC>;


export const directRfpSitesDataC = t.type({
  rfp: imported22_subscribedC(imported221_WithStatusCU(imported131_taggedContentC(rfpWithRelatedContentC))),
  participants: t.readonlyArray(t.tuple([imported218_withIdC(imported8_participantTypeC), readonlyNonEmptyArrayC(imported222_WithStatusCU(imported15_offeringParticipantC))]))
});
export type DirectRfpSitesDataC = typeof directRfpSitesDataC;
export type DirectRfpSitesData = t.TypeOf<DirectRfpSitesDataC>;


export const rfpWithIssuerC = t.type({
  rfp: imported47_rfpC,
  issuer: imported42_issuerC
});
export type RfpWithIssuerC = typeof rfpWithIssuerC;
export type RfpWithIssuer = t.TypeOf<RfpWithIssuerC>;


export const rfpWithRoadshowsAndDocsC = t.type({
  rfp: imported48_rfpC,
  roadShows: t.readonlyArray(imported81_hasManyLinkC(imported219_withIdC(imported132_taggedContentC(imported17_roadShowDataC)), imported8_relatedRoadShowLinkC)),
  documents: t.readonlyArray(imported82_hasManyLinkC(imported223_WithStatusCU(imported133_taggedContentC(imported60_mediaC)), imported12_isAttachedC(imported21_relatedDocumentLinkC)))
});
export type RfpWithRoadshowsAndDocsC = typeof rfpWithRoadshowsAndDocsC;
export type RfpWithRoadshowsAndDocs = t.TypeOf<RfpWithRoadshowsAndDocsC>;


export const rfpPostRelatedContentC = t.type({
  projects: imported40_postRelatedC(imported14_relatedProjectLinkC),
  news: imported41_postRelatedC(imported9_relatedNewsLinkC),
  events: imported42_postRelatedC(imported9_relatedEventLinkC),
  roadShows: imported43_postRelatedC(imported9_relatedRoadShowLinkC),
  participants: imported44_postRelatedC(imported7_relatedOfferingParticipantLinkC),
  documents: imported9_postRelatedAndAttachedC(imported22_relatedDocumentLinkC, imported9_mediaUploadResponseWithCategoryC, imported23_sortOrderC),
  externalLinks: imported15_postAttachedC(imported14_externalLinkC, imported24_sortOrderC),
  faqs: imported16_postAttachedC(imported2_attachedFaqC, imported25_sortOrderC)
});
export type RfpPostRelatedContentC = typeof rfpPostRelatedContentC;
export type RfpPostRelatedContent = t.TypeOf<RfpPostRelatedContentC>;


export const rfpPostC = t.type({
  id: optionFromNullable(t.number),
  order: t.number,
  name: t.string,
  hidden: t.boolean,
  bondProgramId: optionFromNullable(t.number),
  sectorId: t.number,
  stateId: t.number,
  loanAmount: optionFromNullable(t.number),
  typeOfServicingId: optionFromNullable(t.number),
  projectDescription: optionFromNullable(markdownC),
  submissionRequirements: optionFromNullable(markdownC),
  allowQuestions: t.boolean,
  contactEmail: optionFromNullable(t.string),
  dateListed: optionFromNullable(LocalDateC),
  deadlineForQuestions: optionFromNullable(imported6_dateWithOptionalTimeC(t.number)),
  bidsDue: optionFromNullable(imported7_dateWithOptionalTimeC(t.number)),
  bidsDueOngoing: t.boolean,
  bidAwarded: t.boolean,
  awardDate: optionFromNullable(LocalDateC),
  awardDateTentative: t.boolean,
  contractStartDate: optionFromNullable(LocalDateC),
  contractStartDateTentative: t.boolean,
  showWinningBid: t.boolean,
  winningBidCompany: optionFromNullable(t.string),
  winningBidRate: optionFromNullable(t.number),
  winningBidNetInterestCost: optionFromNullable(t.number),
  winningBidReceived: optionFromNullable(LocalDateC),
  winningBidAdditionalInfo: optionFromNullable(markdownC),
  esgRelated: t.boolean,
  relatedContent: rfpPostRelatedContentC
});
export type RfpPostC = typeof rfpPostC;
export type RfpPost = t.TypeOf<RfpPostC>;


export const subscribedRfpC = t.type({
  rfp: imported220_withIdC(imported134_taggedContentC(imported49_rfpC)),
  issuer: imported221_withIdC(imported43_issuerC),
  documents: t.readonlyArray(imported222_withIdC(imported135_taggedContentC(imported61_mediaC))),
  roadShows: t.readonlyArray(imported223_withIdC(imported136_taggedContentC(imported18_roadShowDataC)))
});
export type SubscribedRfpC = typeof subscribedRfpC;
export type SubscribedRfp = t.TypeOf<SubscribedRfpC>;


export const calendarRfpC = t.type({
  rfp: imported224_withIdC(imported137_taggedContentC(imported50_rfpC)),
  issuer: imported225_withIdC(imported44_issuerC),
  subscribed: t.boolean,
  documents: t.readonlyArray(imported226_withIdC(imported138_taggedContentC(imported62_mediaC))),
  roadShows: t.readonlyArray(imported227_withIdC(imported139_taggedContentC(imported19_roadShowDataC)))
});
export type CalendarRfpC = typeof calendarRfpC;
export type CalendarRfp = t.TypeOf<CalendarRfpC>;


export const rfpsListC = t.type({
  active: t.readonlyArray(imported232_WithStatusCU(imported144_taggedContentC(rfpWithRelatedContentC))),
  archived: t.readonlyArray(imported233_WithStatusCU(imported145_taggedContentC(rfpWithRelatedContentC)))
});
export type RfpsListC = typeof rfpsListC;
export type RfpsList = t.TypeOf<RfpsListC>;


export const rfpsSitesListC = t.type({
  active: t.readonlyArray(imported23_subscribedC(imported234_WithStatusCU(imported146_taggedContentC(rfpWithRelatedContentC)))),
  archived: t.readonlyArray(imported24_subscribedC(imported235_WithStatusCU(imported147_taggedContentC(rfpWithRelatedContentC))))
});
export type RfpsSitesListC = typeof rfpsSitesListC;
export type RfpsSitesList = t.TypeOf<RfpsSitesListC>;


