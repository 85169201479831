import { useCallback, useEffect, useMemo, useState } from "react";
import * as A from "fp-ts/lib/Array";
import type { Lazy } from "fp-ts/lib/function";
import { constVoid, pipe } from "fp-ts/lib/function";
import { not } from "fp-ts/lib/Predicate";

import { O, RA, RNEA } from "@scripts/fp-ts";
import { klassConditional } from "@scripts/react/util/classnames";
import { useScrollPositionEffect } from "@scripts/react/util/useScrollPosition";
import { remsToPx } from "@scripts/util/remsToPx";
import { isServerSide } from "@scripts/util/ssr";

import type { ReactChild } from "../syntax/react";

type ElementId = `#${string}`;
export const elementId = (id: string): ElementId => `#${id}`;

export type JumpLink = {
  link: ElementId;
  anchorContent: ReactChild;
};

export type SidebarJumpLinks = {
  jumpLinks: ReadonlyArray<JumpLink>;
};

type SidebarLinkProps = SidebarJumpLinks & {
  headerLink: JumpLink;
  defaultNavOffset: number;
};

const isServerSideO = () => {
  // O.fromPredicate needs to be passed a value, but in this case, we do not care about said value
  return O.fromPredicate(not(isServerSide))("");
};

const getScrollContainer = () => globalThis;

const getScrollMaxY = () => {
  const defaultScrollMaxY = 0;

  return pipe(
    isServerSideO(),
    O.chain(() => O.fromNullable(document.scrollingElement)),
    O.fold(
      () => defaultScrollMaxY,
      (scrollElement) => Math.floor(scrollElement.scrollHeight) - Math.floor(scrollElement.clientHeight)
    )
  );
};


const getMainNavElement = () => isServerSide() ? O.none : O.fromNullable(document.body.querySelector(".page-header"));

const getCurrentMainNavHeight = (defaultNavOffset: number) => {
  return pipe(
    getMainNavElement(),
    O.map((mainNav) => mainNav.getBoundingClientRect().height),
    O.getOrElse(() => defaultNavOffset)
  );
};

const calculateNavOffset = (mainNavHeight: number): number => mainNavHeight + remsToPx(1);

const jumpToLink = (jumpLinkId: JumpLink["link"], mainNavHeight: number) => pipe(
  O.fromNullable(document.querySelector(jumpLinkId)),
  O.fold(
    constVoid,
    (targetElem) =>
      globalThis.scrollBy(0, targetElem.getBoundingClientRect().top - calculateNavOffset(mainNavHeight))
  ),
);

const useJumpLinks = (defaultNavHeight: number) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !isServerSide() && globalThis.location.hash && jumpToLink(globalThis.location.hash as "#", getCurrentMainNavHeight(defaultNavHeight));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const getCurrentScrollDistance = () => Math.floor(getScrollContainer().scrollY);

const isScrollMaxedY = () => {
  const scrollMaxY = getScrollMaxY();
  const currentScrollDistY = getCurrentScrollDistance();
  const threshold = 10;

  return (scrollMaxY - currentScrollDistY) <= threshold;
};

const isInRange = (scrollY: number, mainNavHeight: number) => (link: { range: { start: number, end: number } }): boolean => {
  const sY = scrollY + calculateNavOffset(mainNavHeight);
  const wH = globalThis.innerHeight - mainNavHeight;
  return sY >= link.range.start - (wH * 0.85) && sY < link.range.end - (wH * 0.15);
};

const scrollDistanceAtPageTop = 0;
const threshold = 10;
const isPageTop = (scrollPosition: number) => (scrollPosition - scrollDistanceAtPageTop) <= threshold;

export const SidebarLinks = (props: SidebarLinkProps) => {
  const [currentLink, setCurrentLink] = useState<ElementId>();
  const [mainNavHeight, setMainNavHeight] = useState<number>(getCurrentMainNavHeight(props.defaultNavOffset));

  useJumpLinks(props.defaultNavOffset);

  const fullJumpLinks: RNEA.ReadonlyNonEmptyArray<JumpLink> = pipe(props.jumpLinks, RA.prepend(props.headerLink));

  const linkDistances = useMemo(() => pipe(
    fullJumpLinks,
    RA.filterMap((jumpLinkObj) => pipe(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      O.fromNullable(globalThis.document?.getElementById(jumpLinkObj.link.slice(1))),
      O.map((linkElement: HTMLElement) => ({ linkObj: jumpLinkObj, linkElement }))
    )),
    RA.map(link => ({ ...link, range: { start: link.linkElement.offsetTop, end: link.linkElement.offsetTop + link.linkElement.offsetHeight } }))
  ), [fullJumpLinks]);

  const scrollHandler = useCallback((scroll: number) => {
    if (!isPageTop(scroll)) {
      pipe(
        linkDistances,
        RA.findFirst(isInRange(scroll, mainNavHeight)),
        O.fold(
          () => setCurrentLink(props.headerLink.link),
          l => isScrollMaxedY() ? setCurrentLink(RNEA.last(fullJumpLinks).link) : setCurrentLink(l.linkObj.link)
        )
      );
    } else if (currentLink !== props.headerLink.link) {
      setCurrentLink(props.headerLink.link);
    }
  }, [linkDistances, props.headerLink.link, mainNavHeight, currentLink, fullJumpLinks]);

  useScrollPositionEffect(scrollHandler);

  const setSidebarLinksPosition = useCallback((nodeRef: HTMLElement | null) => {
    if (!nodeRef) return;
    const sidebarLinksWrapper = nodeRef.parentElement;

    pipe(
      sidebarLinksWrapper,
      O.fromNullable,
      O.fold(
        constVoid,
        (sidebarLinksParentElem) => sidebarLinksParentElem.style.top = `${calculateNavOffset(mainNavHeight)}px`
      )
    );
  }, [mainNavHeight]);

  const handleCurrentLinkChange = (jumpLinkInfo: Pick<JumpLink, "link">) => () => jumpToLink(jumpLinkInfo.link, mainNavHeight);

  const trackMainNavHeight = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      pipe(entries, A.head, O.fold(constVoid, (mainNavEntry) => setMainNavHeight(mainNavEntry.contentRect.height)));
    });

    return O.fold(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      () => ({ unobserve: (_: Element) => constVoid(), disconnect: constVoid }),
      (elem: Element) => {
        resizeObserver.observe(elem);
        return resizeObserver;
      }
    )(getMainNavElement());
  };

  const restoreLastPosition = useCallback(() => {
    const currentScrollPosition = getCurrentScrollDistance();
    const mainNav = getMainNavElement();
    const isScrolledToTop = isPageTop(currentScrollPosition);
    const transitionListener = () => scrollHandler(currentScrollPosition);

    if (isScrolledToTop) {
      setCurrentLink(props.headerLink.link);
      return constVoid;
    }

    return O.fold<Element, Lazy<void>>(
      () => constVoid,
      (mainNavElem) => {
        mainNavElem.addEventListener("transitionend", transitionListener, {
          once: true,
        });
        return () => mainNavElem.removeEventListener("transitionend", transitionListener);
      }
    )(mainNav);
  }, [props.headerLink.link, scrollHandler]);

  useEffect(() => {
    const cleanup = restoreLastPosition();
    const mainNavResizeObserver = trackMainNavHeight();

    return () => {
      cleanup();
      mainNavResizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLink]);

  const isCurrentLink = useCallback((link: ElementId) => currentLink === link, [currentLink]);

  return (
    <nav
      {...klassConditional("react-jump-links-empty", ["react-jump-links", "jump-links"])(RA.isEmpty(props.jumpLinks))}
      ref={setSidebarLinksPosition}
    >
      <h4>Jump To:</h4>
      <div>
        <div onClick={handleCurrentLinkChange(props.headerLink)} {...klassConditional("current", "jump-link")(isCurrentLink(props.headerLink.link))}>
          <h5>
            <a>{props.headerLink.anchorContent}</a>
          </h5>
        </div>
        {pipe(props.jumpLinks, RA.map((jumpLink) =>
          <div
            onClick={handleCurrentLinkChange(jumpLink)}
            key={jumpLink.link}
            {...klassConditional("current", "jump-link")(isCurrentLink(jumpLink.link))}
          >
            <a>{jumpLink.anchorContent}</a>
          </div>
        ))}
      </div>
    </nav>
  );
};
