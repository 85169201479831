import { useState } from "react";
import { singular } from "pluralize";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { b, E, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import { sitesEventModal } from "@scripts/generated/domaintables/deepLinkTypes";
import { newsC, pressReleaseC } from "@scripts/generated/domaintables/postTypes";
import type { EventWithRelatedContent } from "@scripts/generated/models/eventWithRelatedContent";
import type { NewsAndEventsData } from "@scripts/generated/models/issuerNews";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { elementId, type JumpLink } from "@scripts/react/components/SidebarLinks";
import { TabRow, type TabRowProps } from "@scripts/react/components/TabRow";
import { EventModal, EventModalViewDetailsButton } from "@scripts/react/components/timeline/EventModal";
import { TimelineItemSmall } from "@scripts/react/components/timeline/TimelineItem";
import { useConfig } from "@scripts/react/context/Config";
import { klass } from "@scripts/react/util/classnames";
import { useModalDeepLinkWithData } from "@scripts/react/util/useDeepLink";
import { useModalStableO } from "@scripts/react/util/useModal";
import { newsAndEvents, newsPressReleasesSectionTitle, rfps } from "@scripts/routes/routing/ssr/issuersites";
import { newsPressReleasesJumplinkId, upcomingEventsJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { qualifierFormat } from "@scripts/syntax/date/dateQualifier";
import { dateTBDConst, humanDateFull, humanDateFullO } from "@scripts/syntax/date/joda";

import { useBondSubscribe, useRfpSubscribe } from "../../api/watchlist";
import { IssuerSitesBondCard } from "../../components/BondCard";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { NewsCard } from "../../components/NewsCard";
import { IssuerSitesRfpCard } from "../../components/RfpCard";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesDispatch, useIssuerSitesSelector } from "../../state/store";

type NewsAndPressReleasesView = "all" | "news" | "press-releases";

const makeTabRowProps: (
  isSm: boolean,
  view: NewsAndPressReleasesView,
  setView: (view: NewsAndPressReleasesView) => void
) => TabRowProps<string> = (isSm, view, setView) => ({
  current: view,
  orientation: "horizontal" as const,
  isSm,
  items: [
    {
      icon: O.none,
      onClick: () => setView("all"),
      title: "All",
      value: "all",
    },
    {
      icon: O.none,
      onClick: () => setView("news"),
      title: "News",
      value: "news",
    },
    {
      icon: O.none,
      onClick: () => setView("press-releases"),
      title: "Press Releases",
      value: "press-releases",
    },
  ],
  variant: "default",
});

const newsText = "news stories";
const pressReleasesText = "press releases";
const joinText = " or ";
const makeViewText = (config: BLConfigWithLog, view: NewsAndPressReleasesView) => {
  switch (view) {
    case "all":
      return newsText + joinText + pressReleasesText;
    case "news":
      return newsText;
    case "press-releases":
      return pressReleasesText;
  }

  return config.exhaustive(view);
};

const eventHasModal = (event: WithStatusU<EventWithRelatedContent>) =>
  O.isSome(event.data.record.event.eventBlurb) || pipe(event.data.record.relatedContent, R.some(a => a.length > 0));

const upcomingEventsSectionTitle = "Upcoming Events";

export const NewsAndEvents = (props: {
  data: NewsAndEventsData;
  issuerId: number;
  issuerSlug: string;
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const user = useIssuerSitesSelector("user");
  const isUserSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");
  const dispatch = useIssuerSitesDispatch();

  const rfpsTitle = rfps({ issuerSlug: issuer.slug, issuerId: issuer.id }).title(pages);
  const rfpEntityName = singular(rfpsTitle);

  const [view, setView] = useState<NewsAndPressReleasesView>("all");

  const [eventModalIsOpen, eventO, openEventModal, closeEventModal] =
    useModalStableO<WithStatusU<EventWithRelatedContent>>("EventModal");

  const makeBondSubscribe = useBondSubscribe();
  const makeRfpOnSubscribe = useRfpSubscribe();

  const page = newsAndEvents({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, issuer);

  const newsSectionTitle = newsPressReleasesSectionTitle(pages, "News & Press Releases");

  const [allUpcomingEventsData, setAllUpcomingEventsData] = useState(props.data.eventItems);

  const upcomingEvents: ReadonlyArray<WithStatusU<EventWithRelatedContent>> = pipe(
    allUpcomingEventsData,
    RA.filterMap(O.getLeft),
  );

  useModalDeepLinkWithData(
    sitesEventModal,
    openEventModal,
    upcomingEvents,
    dispatch,
    eventHasModal,
  );

  const showUpcomingEvents = allUpcomingEventsData.length > 0;

  const jumpLinks: RNEA.ReadonlyNonEmptyArray<JumpLink> = RNEA.concat<JumpLink>(
    [{ link: elementId(newsPressReleasesJumplinkId), anchorContent: newsSectionTitle }]
  )(
    RA.compact([makeJumplinkO(upcomingEventsSectionTitle, upcomingEventsJumplinkId, showUpcomingEvents)]),
  );

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkCustomAnchorContent={O.none}
        jumpLinks={jumpLinks}
        routeMeta={newsAndEvents({ issuerId: issuer.id, issuerSlug: issuer.slug })}
      />
      <GetAlertsActionSection containerKlass={"d-none-until-md"} />
    </>}
  >
    {showUpcomingEvents && <section id={upcomingEventsJumplinkId} {...klass("accent-border-top")}>
      <h2 {...klass("title", "mb-15")}>{upcomingEventsSectionTitle}</h2>
      {pipe(
        allUpcomingEventsData,
        RA.mapWithIndex((i, item) => pipe(
          item,
          E.fold(
            event => <TimelineItemSmall
              key={`event-${event.data.id}`}
              content={pipe(
                eventHasModal(event),
                trueOrEmpty(<EventModalViewDetailsButton data={event} openModal={openEventModal} />)
              )}
              primary={humanDateFullO(dateTBDConst)(event.data.record.event.eventDate)}
              header={event.data.record.event.eventTitle}
              isCompact={false}
              secondary={pipe(event.data.record.event.endDate, O.map(_ => `- ${humanDateFull(_)}`))}
            />,
            deal => pipe(
              deal,
              E.fold(
                bond => <TimelineItemSmall
                  key={`bond-${bond.data.data.id}`}
                  content={<div {...klass("mt-1")}>
                    <IssuerSitesBondCard
                      issuer={issuer}
                      bond={bond}
                      isSubscribed={bond.subscribed}
                      onSubscribe={makeBondSubscribe(
                        { issuerId: O.some(issuer.id), bankId: O.none },
                        !bond.subscribed,
                        bond.data.data.id,
                        (isSubscribed) => {
                          pipe(
                            allUpcomingEventsData,
                            RA.updateAt(i, E.right(E.left({ ...bond, subscribed: isSubscribed }))),
                            O.map(setAllUpcomingEventsData)
                          );
                        },
                        user,
                        isUserSubscribedToIssuer
                      )}
                    />
                  </div>}
                  primary={pipe(
                    bond.data.data.record.data.offering.firstSaleDate,
                    O.map(_ => pipe(
                      _,
                      E.toUnion,
                      __ => qualifierFormat(config)(__)
                    )),
                    O.getOrElse(dateTBDConst)
                  )}
                  header={"Bond Sale"}
                  isCompact={false}
                  secondary={O.none}
                />,
                rfp => <TimelineItemSmall
                  key={`rfp-${rfp.data.data.id}`}
                  content={<div {...klass("mt-1")}>
                    <IssuerSitesRfpCard
                      issuer={issuer}
                      rfp={rfp}
                      isSubscribed={rfp.subscribed}
                      onSubscribe={makeRfpOnSubscribe(
                        { issuerId: O.some(issuer.id), bankId: O.none },
                        !rfp.subscribed,
                        rfp.data.data.id,
                        (isSubscribed) => {
                          pipe(
                            allUpcomingEventsData,
                            RA.updateAt(i, E.right(E.right({ ...rfp, subscribed: isSubscribed }))),
                            O.map(setAllUpcomingEventsData)
                          );
                        },
                        user,
                        isUserSubscribedToIssuer
                      )}
                    />
                  </div>}
                  primary={humanDateFullO(dateTBDConst)(rfp.data.data.record.data.rfp.dateListed)}
                  header={`${rfpEntityName} Bids Due`}
                  isCompact={false}
                  secondary={O.none}
                />,
              )
            )
          )
        ))
      )}
      <EventModal
        modalOpen={eventModalIsOpen}
        dismissAction={closeEventModal}
        data={eventO}
        issuer={issuer}
        rfpsTitle={rfpsTitle}
        leafIcon={IssuerSitesLeafIcon}
      />
    </section>}
    <section id={newsPressReleasesJumplinkId} {...klass("mb-0", "accent-border-top")}>
      <h2 {...klass("title", "mb-1")}>{newsSectionTitle}</h2>
      <TabRow
        {...makeTabRowProps(true, view, setView)}
        klasses={["d-none-until-sm"]}
      />
      <TabRow
        {...makeTabRowProps(true, view, setView)}
        klasses={["d-sm-none", "mb-2"]}
      />
      {pipe(
        view === "all",
        b.fold(
          () => pipe(
            props.data.news,
            RA.filter(view === "news"
              ? _ => newsC.is(_.data.record.data.postType)
              : _ => pressReleaseC.is(_.data.record.data.postType)
            )
          ),
          () => props.data.news
        ),
        RNEA.fromReadonlyArray,
        O.fold(
          // eslint-disable-next-line react/jsx-no-useless-fragment
          () => <>{`No recent ${makeViewText(config, view)}. Add this issuer to your watchlist to get alerts about important updates.`}</>,
          _ => <>{pipe(_, RA.map(__ => <NewsCard key={__.data.id} news={__} />))}</>
        )
      )}
    </section>
  </DirectSitesPageDescriptionLayout>;
};
