import "./preIndex";

import { createRoot, hydrateRoot } from "react-dom/client";
import * as t from "io-ts";

import { Config } from "@scripts/csr-only/config";
import { E, pipe } from "@scripts/fp-ts";
import { RendererError } from "@scripts/react/components/error/RendererError";
import { errorsToLogErrors, makeConfigLogger } from "@scripts/util/log";

import { pageDataAttribute, pageDataId } from "@scripts-ssr/syntax/pageData";

const config = Config(makeConfigLogger());

export const handleDecodeErrors = (errorMessage: string) =>
  (errors: t.Errors) => pipe(
    errors,
    errorsToLogErrors("error", errorMessage),
    E.right,
    RendererError(config),
    reactRender
  );

export const readPageData = () => {
  const pageDataElement = document.getElementById(pageDataId);

  if (!pageDataElement) {
    config.log.fatal("PageData element not found.");
  }

  const pageDataValue = pageDataElement?.dataset[pageDataAttribute];

  if (pageDataElement && !pageDataValue) {
    config.log.fatal("PageData value is undefined.");
  }

  let parsed: Record<string, unknown> = {};

  try {
    parsed = JSON.parse(pageDataValue ?? "{}");
  } catch (e) {
    config.log.fatal("Failed to parse PageData", e);
  }

  return parsed;
};

export const bootstrapPage = (
  pageData: Record<string, unknown>,
  hydrate: (url: string) => void
) => pipe(
  t.string.decode(pageData["url"]),
  E.fold(
    handleDecodeErrors("Error decoding URL"),
    hydrate
  )
);

export const readUrl = () => globalThis.location.pathname + globalThis.location.search;

export const reactHydrate = (pageLoader: JSX.Element) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  hydrateRoot(document.getElementById("root")!, pageLoader);
};

export const reactRender = (pageLoader: JSX.Element) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.getElementById("root")!).render(pageLoader);
};
