import { useState } from "react";

import type { RNEA } from "@scripts/fp-ts";
import { O } from "@scripts/fp-ts";
import { activeRfps as activeRfpsFF, archivedRfps as archivedRfpsFF } from "@scripts/generated/domaintables/featureFlags";
import type { RfpsSitesList } from "@scripts/generated/models/rfp";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import type { RfpsUrlParams } from "@scripts/routes/routing/ssr/issuersites";
import { rfps } from "@scripts/routes/routing/ssr/issuersites";
import { isFFEnabled } from "@scripts/syntax/featureFlags";

import { useRfpSubscribe } from "../../api/watchlist";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesRfpCard, type IssuerSitesRfpCardProps } from "../../components/RfpCard";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export type RfpsProps = Required<RfpsUrlParams> & { rfps: RfpsSitesList };

const RfpCard = (props: { rfp: IssuerSitesRfpCardProps["rfp"] }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const [isSubscribed, setIsSubscribed] = useState(props.rfp.subscribed);

  const makeOnSubscribe = useRfpSubscribe();

  return <IssuerSitesRfpCard
    issuer={issuer}
    rfp={props.rfp}
    isSubscribed={isSubscribed}
    onSubscribe={makeOnSubscribe(
      { issuerId: O.some(issuer.id), bankId: O.none },
      !isSubscribed,
      props.rfp.data.data.id,
      setIsSubscribed,
      user,
      userSubscribedToIssuer
    )}
  />;
};

const activeRfps = "anchor-active-rfps";
const archivedRfps = "anchor-archived-rfps";

const archivedJumpLink = (title: string): JumpLink => ({ link: `#${archivedRfps}`, anchorContent: `Archived ${title}` });
const archivedJumpLinkOptional = (title: string, showArchive: boolean): JumpLink[] => showArchive ? [archivedJumpLink(title)] : [];

const optionalJumpLinks = (title: string, showActive: boolean, showArchive: boolean): [JumpLink, ...JumpLink[]] => showActive
  ? [{ link: `#${activeRfps}`, anchorContent: `Active ${title}` }, ...archivedJumpLinkOptional(title, showArchive)]
  : [archivedJumpLink(title)];

const jumpLinks = (
  title: string,
  showActive: boolean,
  showArchive: boolean,
): RNEA.ReadonlyNonEmptyArray<JumpLink> => optionalJumpLinks(title, showActive, showArchive);

export const Rfps = (props: RfpsProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const page = rfps({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const pageTitle = page.title(pages);
  const description = page.description(pages, issuer);

  const showArchive = isFFEnabled(archivedRfpsFF)(iffs);
  const showActive = isFFEnabled(activeRfpsFF)(iffs);

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkCustomAnchorContent={O.none}
        jumpLinks={jumpLinks(pageTitle, showActive, showArchive)}
        routeMeta={page}
      />
      <GetAlertsActionSection containerKlass={"d-none-until-md"} />
    </>}
  >
    {showActive
      && <section id={activeRfps} {...klass("accent-border-top")}>
        <h2 {...klass("title")}>Active {pageTitle}</h2>
        {!props.rfps.active.length && <p>There are currently no active {pageTitle} listed.</p>}
        <div {...klass("grid card-grid")}>
          {props.rfps.active.map((rfp) => <div key={rfp.data.data.id} {...klass("c-24")}>
            <RfpCard rfp={rfp} />
          </div>)}
        </div>
      </section>
    }
    {showArchive
      && <section id={archivedRfps} {...klass("accent-border-top")}>
        <h2 {...klass("title")}>Archived {pageTitle}</h2>
        {!props.rfps.archived.length && <p>There are currently no archived {pageTitle} listed.</p>}
        <div {...klass("grid card-grid")}>
          {props.rfps.archived.map((rfp) => <div key={rfp.data.data.id} {...klass("c-24")}>
            <RfpCard rfp={rfp} />
          </div>)}
        </div>
      </section>
    }
  </DirectSitesPageDescriptionLayout>;
};
