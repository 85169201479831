import { taggedContentC as imported197_taggedContentC, taggedContentC as imported198_taggedContentC } from "./taggedContent";
import { bondOfferingWithRelatedContentC as imported16_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported17_bondOfferingWithRelatedContentC } from "./bondOffering";
import { WithStatusCU as imported307_WithStatusCU, WithStatusCU as imported308_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";

export const portalOfferingsC = t.type({
  active: t.readonlyArray(imported307_WithStatusCU(imported197_taggedContentC(imported16_bondOfferingWithRelatedContentC))),
  archived: t.readonlyArray(imported308_WithStatusCU(imported198_taggedContentC(imported17_bondOfferingWithRelatedContentC)))
});
export type PortalOfferingsC = typeof portalOfferingsC;
export type PortalOfferings = t.TypeOf<PortalOfferingsC>;


