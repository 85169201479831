import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const userSettingsModalId = {
  _tag: `UserSettingsModalId`,
  id: `user-settings`
} as const;

export const userSettingsModalIdTaggedC = t.type({
  _tag: t.literal(`UserSettingsModalId`)
});
export type UserSettingsModalIdTaggedC = typeof userSettingsModalIdTaggedC;
export type UserSettingsModalIdTagged = t.TypeOf<UserSettingsModalIdTaggedC>;
export type UserSettingsModalId = UserSettingsModalIdTagged & typeof userSettingsModalId;
export const userSettingsModalIdC = pipe(userSettingsModalIdTaggedC, c => new t.Type<UserSettingsModalId, UserSettingsModalIdTagged>(
  `UserSettingsModalId`,
  (u: unknown): u is UserSettingsModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserSettingsModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userSettingsModalId }))),
  (x: UserSettingsModalId): UserSettingsModalIdTagged => ({ ...x, _tag: `UserSettingsModalId`}),
));
export type UserSettingsModalIdC = typeof userSettingsModalIdC;


export const addDocumentModalId = {
  _tag: `AddDocumentModalId`,
  id: `add-document`
} as const;

export const addDocumentModalIdTaggedC = t.type({
  _tag: t.literal(`AddDocumentModalId`)
});
export type AddDocumentModalIdTaggedC = typeof addDocumentModalIdTaggedC;
export type AddDocumentModalIdTagged = t.TypeOf<AddDocumentModalIdTaggedC>;
export type AddDocumentModalId = AddDocumentModalIdTagged & typeof addDocumentModalId;
export const addDocumentModalIdC = pipe(addDocumentModalIdTaggedC, c => new t.Type<AddDocumentModalId, AddDocumentModalIdTagged>(
  `AddDocumentModalId`,
  (u: unknown): u is AddDocumentModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddDocumentModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addDocumentModalId }))),
  (x: AddDocumentModalId): AddDocumentModalIdTagged => ({ ...x, _tag: `AddDocumentModalId`}),
));
export type AddDocumentModalIdC = typeof addDocumentModalIdC;


export const addBondModalId = {
  _tag: `AddBondModalId`,
  id: `add-bond`
} as const;

export const addBondModalIdTaggedC = t.type({
  _tag: t.literal(`AddBondModalId`)
});
export type AddBondModalIdTaggedC = typeof addBondModalIdTaggedC;
export type AddBondModalIdTagged = t.TypeOf<AddBondModalIdTaggedC>;
export type AddBondModalId = AddBondModalIdTagged & typeof addBondModalId;
export const addBondModalIdC = pipe(addBondModalIdTaggedC, c => new t.Type<AddBondModalId, AddBondModalIdTagged>(
  `AddBondModalId`,
  (u: unknown): u is AddBondModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddBondModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addBondModalId }))),
  (x: AddBondModalId): AddBondModalIdTagged => ({ ...x, _tag: `AddBondModalId`}),
));
export type AddBondModalIdC = typeof addBondModalIdC;


export const addBondRatingModalId = {
  _tag: `AddBondRatingModalId`,
  id: `add-bond-rating`
} as const;

export const addBondRatingModalIdTaggedC = t.type({
  _tag: t.literal(`AddBondRatingModalId`)
});
export type AddBondRatingModalIdTaggedC = typeof addBondRatingModalIdTaggedC;
export type AddBondRatingModalIdTagged = t.TypeOf<AddBondRatingModalIdTaggedC>;
export type AddBondRatingModalId = AddBondRatingModalIdTagged & typeof addBondRatingModalId;
export const addBondRatingModalIdC = pipe(addBondRatingModalIdTaggedC, c => new t.Type<AddBondRatingModalId, AddBondRatingModalIdTagged>(
  `AddBondRatingModalId`,
  (u: unknown): u is AddBondRatingModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddBondRatingModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addBondRatingModalId }))),
  (x: AddBondRatingModalId): AddBondRatingModalIdTagged => ({ ...x, _tag: `AddBondRatingModalId`}),
));
export type AddBondRatingModalIdC = typeof addBondRatingModalIdC;


export const addCusip6ModalId = {
  _tag: `AddCusip6ModalId`,
  id: `add-cusip6`
} as const;

export const addCusip6ModalIdTaggedC = t.type({
  _tag: t.literal(`AddCusip6ModalId`)
});
export type AddCusip6ModalIdTaggedC = typeof addCusip6ModalIdTaggedC;
export type AddCusip6ModalIdTagged = t.TypeOf<AddCusip6ModalIdTaggedC>;
export type AddCusip6ModalId = AddCusip6ModalIdTagged & typeof addCusip6ModalId;
export const addCusip6ModalIdC = pipe(addCusip6ModalIdTaggedC, c => new t.Type<AddCusip6ModalId, AddCusip6ModalIdTagged>(
  `AddCusip6ModalId`,
  (u: unknown): u is AddCusip6ModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddCusip6ModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addCusip6ModalId }))),
  (x: AddCusip6ModalId): AddCusip6ModalIdTagged => ({ ...x, _tag: `AddCusip6ModalId`}),
));
export type AddCusip6ModalIdC = typeof addCusip6ModalIdC;


export const addCusip9ModalId = {
  _tag: `AddCusip9ModalId`,
  id: `add-cusip9`
} as const;

export const addCusip9ModalIdTaggedC = t.type({
  _tag: t.literal(`AddCusip9ModalId`)
});
export type AddCusip9ModalIdTaggedC = typeof addCusip9ModalIdTaggedC;
export type AddCusip9ModalIdTagged = t.TypeOf<AddCusip9ModalIdTaggedC>;
export type AddCusip9ModalId = AddCusip9ModalIdTagged & typeof addCusip9ModalId;
export const addCusip9ModalIdC = pipe(addCusip9ModalIdTaggedC, c => new t.Type<AddCusip9ModalId, AddCusip9ModalIdTagged>(
  `AddCusip9ModalId`,
  (u: unknown): u is AddCusip9ModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddCusip9ModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addCusip9ModalId }))),
  (x: AddCusip9ModalId): AddCusip9ModalIdTagged => ({ ...x, _tag: `AddCusip9ModalId`}),
));
export type AddCusip9ModalIdC = typeof addCusip9ModalIdC;


export const addNewsModalId = {
  _tag: `AddNewsModalId`,
  id: `add-news`
} as const;

export const addNewsModalIdTaggedC = t.type({
  _tag: t.literal(`AddNewsModalId`)
});
export type AddNewsModalIdTaggedC = typeof addNewsModalIdTaggedC;
export type AddNewsModalIdTagged = t.TypeOf<AddNewsModalIdTaggedC>;
export type AddNewsModalId = AddNewsModalIdTagged & typeof addNewsModalId;
export const addNewsModalIdC = pipe(addNewsModalIdTaggedC, c => new t.Type<AddNewsModalId, AddNewsModalIdTagged>(
  `AddNewsModalId`,
  (u: unknown): u is AddNewsModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddNewsModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addNewsModalId }))),
  (x: AddNewsModalId): AddNewsModalIdTagged => ({ ...x, _tag: `AddNewsModalId`}),
));
export type AddNewsModalIdC = typeof addNewsModalIdC;


export const addProjectModalId = {
  _tag: `AddProjectModalId`,
  id: `add-project`
} as const;

export const addProjectModalIdTaggedC = t.type({
  _tag: t.literal(`AddProjectModalId`)
});
export type AddProjectModalIdTaggedC = typeof addProjectModalIdTaggedC;
export type AddProjectModalIdTagged = t.TypeOf<AddProjectModalIdTaggedC>;
export type AddProjectModalId = AddProjectModalIdTagged & typeof addProjectModalId;
export const addProjectModalIdC = pipe(addProjectModalIdTaggedC, c => new t.Type<AddProjectModalId, AddProjectModalIdTagged>(
  `AddProjectModalId`,
  (u: unknown): u is AddProjectModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddProjectModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addProjectModalId }))),
  (x: AddProjectModalId): AddProjectModalIdTagged => ({ ...x, _tag: `AddProjectModalId`}),
));
export type AddProjectModalIdC = typeof addProjectModalIdC;


export const addQuickFactModalId = {
  _tag: `AddQuickFactModalId`,
  id: `add-quick-fact`
} as const;

export const addQuickFactModalIdTaggedC = t.type({
  _tag: t.literal(`AddQuickFactModalId`)
});
export type AddQuickFactModalIdTaggedC = typeof addQuickFactModalIdTaggedC;
export type AddQuickFactModalIdTagged = t.TypeOf<AddQuickFactModalIdTaggedC>;
export type AddQuickFactModalId = AddQuickFactModalIdTagged & typeof addQuickFactModalId;
export const addQuickFactModalIdC = pipe(addQuickFactModalIdTaggedC, c => new t.Type<AddQuickFactModalId, AddQuickFactModalIdTagged>(
  `AddQuickFactModalId`,
  (u: unknown): u is AddQuickFactModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddQuickFactModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addQuickFactModalId }))),
  (x: AddQuickFactModalId): AddQuickFactModalIdTagged => ({ ...x, _tag: `AddQuickFactModalId`}),
));
export type AddQuickFactModalIdC = typeof addQuickFactModalIdC;


export const addEventModalId = {
  _tag: `AddEventModalId`,
  id: `add-event`
} as const;

export const addEventModalIdTaggedC = t.type({
  _tag: t.literal(`AddEventModalId`)
});
export type AddEventModalIdTaggedC = typeof addEventModalIdTaggedC;
export type AddEventModalIdTagged = t.TypeOf<AddEventModalIdTaggedC>;
export type AddEventModalId = AddEventModalIdTagged & typeof addEventModalId;
export const addEventModalIdC = pipe(addEventModalIdTaggedC, c => new t.Type<AddEventModalId, AddEventModalIdTagged>(
  `AddEventModalId`,
  (u: unknown): u is AddEventModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddEventModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addEventModalId }))),
  (x: AddEventModalId): AddEventModalIdTagged => ({ ...x, _tag: `AddEventModalId`}),
));
export type AddEventModalIdC = typeof addEventModalIdC;


export const addRFPModalId = {
  _tag: `AddRFPModalId`,
  id: `add-rfp`
} as const;

export const addRFPModalIdTaggedC = t.type({
  _tag: t.literal(`AddRFPModalId`)
});
export type AddRFPModalIdTaggedC = typeof addRFPModalIdTaggedC;
export type AddRFPModalIdTagged = t.TypeOf<AddRFPModalIdTaggedC>;
export type AddRFPModalId = AddRFPModalIdTagged & typeof addRFPModalId;
export const addRFPModalIdC = pipe(addRFPModalIdTaggedC, c => new t.Type<AddRFPModalId, AddRFPModalIdTagged>(
  `AddRFPModalId`,
  (u: unknown): u is AddRFPModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddRFPModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addRFPModalId }))),
  (x: AddRFPModalId): AddRFPModalIdTagged => ({ ...x, _tag: `AddRFPModalId`}),
));
export type AddRFPModalIdC = typeof addRFPModalIdC;


export const findIssuersModalId = {
  _tag: `FindIssuersModalId`,
  id: `find-issuers`
} as const;

export const findIssuersModalIdTaggedC = t.type({
  _tag: t.literal(`FindIssuersModalId`)
});
export type FindIssuersModalIdTaggedC = typeof findIssuersModalIdTaggedC;
export type FindIssuersModalIdTagged = t.TypeOf<FindIssuersModalIdTaggedC>;
export type FindIssuersModalId = FindIssuersModalIdTagged & typeof findIssuersModalId;
export const findIssuersModalIdC = pipe(findIssuersModalIdTaggedC, c => new t.Type<FindIssuersModalId, FindIssuersModalIdTagged>(
  `FindIssuersModalId`,
  (u: unknown): u is FindIssuersModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FindIssuersModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...findIssuersModalId }))),
  (x: FindIssuersModalId): FindIssuersModalIdTagged => ({ ...x, _tag: `FindIssuersModalId`}),
));
export type FindIssuersModalIdC = typeof findIssuersModalIdC;


export const onboardingModalId = {
  _tag: `OnboardingModalId`,
  id: `account-setup`
} as const;

export const onboardingModalIdTaggedC = t.type({
  _tag: t.literal(`OnboardingModalId`)
});
export type OnboardingModalIdTaggedC = typeof onboardingModalIdTaggedC;
export type OnboardingModalIdTagged = t.TypeOf<OnboardingModalIdTaggedC>;
export type OnboardingModalId = OnboardingModalIdTagged & typeof onboardingModalId;
export const onboardingModalIdC = pipe(onboardingModalIdTaggedC, c => new t.Type<OnboardingModalId, OnboardingModalIdTagged>(
  `OnboardingModalId`,
  (u: unknown): u is OnboardingModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OnboardingModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...onboardingModalId }))),
  (x: OnboardingModalId): OnboardingModalIdTagged => ({ ...x, _tag: `OnboardingModalId`}),
));
export type OnboardingModalIdC = typeof onboardingModalIdC;


export const sitesEventModal = {
  _tag: `SitesEventModal`,
  id: `sites-event-modal`
} as const;

export const sitesEventModalTaggedC = t.type({
  _tag: t.literal(`SitesEventModal`)
});
export type SitesEventModalTaggedC = typeof sitesEventModalTaggedC;
export type SitesEventModalTagged = t.TypeOf<SitesEventModalTaggedC>;
export type SitesEventModal = SitesEventModalTagged & typeof sitesEventModal;
export const sitesEventModalC = pipe(sitesEventModalTaggedC, c => new t.Type<SitesEventModal, SitesEventModalTagged>(
  `SitesEventModal`,
  (u: unknown): u is SitesEventModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SitesEventModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...sitesEventModal }))),
  (x: SitesEventModal): SitesEventModalTagged => ({ ...x, _tag: `SitesEventModal`}),
));
export type SitesEventModalC = typeof sitesEventModalC;


export const allDeepLinkModalIdC = [userSettingsModalIdC, addDocumentModalIdC, addBondModalIdC, addBondRatingModalIdC, addCusip6ModalIdC, addCusip9ModalIdC, addNewsModalIdC, addProjectModalIdC, addQuickFactModalIdC, addEventModalIdC, addRFPModalIdC, findIssuersModalIdC, onboardingModalIdC, sitesEventModalC] as const;
export const allDeepLinkModalIdNames = [`UserSettingsModalId`, `AddDocumentModalId`, `AddBondModalId`, `AddBondRatingModalId`, `AddCusip6ModalId`, `AddCusip9ModalId`, `AddNewsModalId`, `AddProjectModalId`, `AddQuickFactModalId`, `AddEventModalId`, `AddRFPModalId`, `FindIssuersModalId`, `OnboardingModalId`, `SitesEventModal`] as const;
export type DeepLinkModalIdName = (typeof allDeepLinkModalIdNames)[number];

export const DeepLinkModalIdCU = t.union([userSettingsModalIdC, addDocumentModalIdC, addBondModalIdC, addBondRatingModalIdC, addCusip6ModalIdC, addCusip9ModalIdC, addNewsModalIdC, addProjectModalIdC, addQuickFactModalIdC, addEventModalIdC, addRFPModalIdC, findIssuersModalIdC, onboardingModalIdC, sitesEventModalC]);
export type DeepLinkModalIdCU = typeof DeepLinkModalIdCU;
export type DeepLinkModalIdU = t.TypeOf<DeepLinkModalIdCU>;

export const deepLinkModalIdOrd: Ord.Ord<DeepLinkModalIdU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDeepLinkModalId = [userSettingsModalId, addDocumentModalId, addBondModalId, addBondRatingModalId, addCusip6ModalId, addCusip9ModalId, addNewsModalId, addProjectModalId, addQuickFactModalId, addEventModalId, addRFPModalId, findIssuersModalId, onboardingModalId, sitesEventModal] as const;
export type DeepLinkModalIdMap<A> = { [K in DeepLinkModalIdName]: A };


export const userSettingsTabId = {
  _tag: `UserSettingsTabId`,
  id: `settings`
} as const;

export const userSettingsTabIdTaggedC = t.type({
  _tag: t.literal(`UserSettingsTabId`)
});
export type UserSettingsTabIdTaggedC = typeof userSettingsTabIdTaggedC;
export type UserSettingsTabIdTagged = t.TypeOf<UserSettingsTabIdTaggedC>;
export type UserSettingsTabId = UserSettingsTabIdTagged & typeof userSettingsTabId;
export const userSettingsTabIdC = pipe(userSettingsTabIdTaggedC, c => new t.Type<UserSettingsTabId, UserSettingsTabIdTagged>(
  `UserSettingsTabId`,
  (u: unknown): u is UserSettingsTabId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserSettingsTabId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userSettingsTabId }))),
  (x: UserSettingsTabId): UserSettingsTabIdTagged => ({ ...x, _tag: `UserSettingsTabId`}),
));
export type UserSettingsTabIdC = typeof userSettingsTabIdC;


export const allDeepLinkTabIdC = [userSettingsTabIdC] as const;
export const allDeepLinkTabIdNames = [`UserSettingsTabId`] as const;
export type DeepLinkTabIdName = (typeof allDeepLinkTabIdNames)[number];

export const DeepLinkTabIdCU = userSettingsTabIdC;
export type DeepLinkTabIdCU = typeof DeepLinkTabIdCU;
export type DeepLinkTabIdU = t.TypeOf<DeepLinkTabIdCU>;

export const deepLinkTabIdOrd: Ord.Ord<DeepLinkTabIdU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDeepLinkTabId = [userSettingsTabId] as const;
export type DeepLinkTabIdMap<A> = { [K in DeepLinkTabIdName]: A };


