import { type PropsWithChildren, useState } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { ESG } from "@scripts/generated/domaintables/contentTagTypes";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { hasTag } from "@scripts/models/taggedContent";
import { Anchor, AnchorButton, AnchorIcon } from "@scripts/react/components/Anchor";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { ShowHideToggle } from "@scripts/react/components/ShowMore";
import { Svg } from "@scripts/react/components/Svg";
import { ThumbnailCard } from "@scripts/react/components/ThumbnailCard";
import { klass } from "@scripts/react/util/classnames";
import { type AboutPageData } from "@scripts/routes/routing/ssr/issuersites";

import projectIcon from "@svgs/construction.svg";
import leafIcon from "@svgs/leaf.svg";

import { useIssuerSitesSelector } from "../../state/store";

type ProjectsSectionProps = {
  sectionId: string;
  title: string;
  projects: RNEA.ReadonlyNonEmptyArray<AboutPageData["projects"][number]>;
  maxLength?: number;
};

type ProjectsSectionBaseProps = PropsWithChildren<ProjectsSectionProps>;


export const ProjectCard = (props: { project: AboutPageData["projects"][number] }) => {
  const issuer = useIssuerSitesSelector("issuer");

  const projectRoute = SR.issuersitesAboutControllerProjectItem({
    issuerId: issuer.id,
    issuerSlug: issuer.slug,
    projectId: props.project.data.id,
  });

  return (
    <ThumbnailCard
      bodyKlasses={["h-100"]}
      cardKlasses={["card-link"]}
      cardUrlPropsO={O.some({
        target: "_self",
        url: projectRoute.url,
      })}
      imgPropsO={pipe(
        props.project.data.record.data.photos,
        RA.head,
        O.map(_ => ({
          imgUrl: _.data.data.record.uploadResponse.uri,
          altText: `Preview photo for ${props.project.data.record.data.project.projectTitle}.`,
        }))
      )}
    >
      <div {...klass("d-flex", "flex-col", "justify-content-between", "h-100")}>
        <h5>
          <Anchor
            target={"_self"}
            route={{
              title: props.project.data.record.data.project.projectTitle,
              route: projectRoute,
            }}
          />
          {pipe(
            hasTag(ESG)(props.project.data.record),
            trueOrEmpty(<Svg src={leafIcon} {...klass("ml-025")} />)
          )}
        </h5>
        <AnchorIcon
          icon={projectIcon}
          target={"_self"}
          route={{
            title: "View Project",
            route: projectRoute,
          }}
        />
      </div>
    </ThumbnailCard>
  );
};

export const ProjectsSectionBase = (props: ProjectsSectionBaseProps) => {
  return (
    <AccentDividerSection title={O.some(props.title)} sectionId={props.sectionId} klasses="accent-border-top">
      <Grid klasses={"card-grid"} attrs={O.none}>
        {pipe(
          props.projects,
          RA.map(_ =>
            <GridCol cols={[".c-24", ".c-lg-12"]} klasses={[]} key={_.data.id}>
              <ProjectCard project={_} />
            </GridCol>
          )
        )}
      </Grid>
      {props.children}
    </AccentDividerSection>
  );
};

export const ProjectsSection = (props: ProjectsSectionProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <ProjectsSectionBase {...props} projects={props.maxLength ? RNEA.firstNRaw(props.maxLength)(props.projects) : props.projects}>
      <AnchorButton
        externalLinkLocation="none"
        target="_self"
        route={{
          title: `View ${props.title}`,
          route: SR.issuersitesAboutControllerProjects({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
          }),
        }}
        variant="primary"
        klasses={"mt-2"}
      />
    </ProjectsSectionBase>

  );
};

export const ProjectsSectionTruncated = (props: ProjectsSectionProps & { truncateLength: number }) => {
  const [showMore, setShowMore] = useState(false);
  const projects = showMore ? props.projects : RNEA.firstNRaw(props.truncateLength)(props.projects);
  return <ProjectsSectionBase
    {...props}
    projects={projects}
  >
    {props.projects.length > props.truncateLength && <div {...klass("mt-1")}>
      <ShowHideToggle isShowingMore={showMore} setIsShowingMore={setShowMore} text={{ show: "Show all", hide: "Show less" }} appendIcon />
    </div>}
  </ProjectsSectionBase>;
};
