
import * as E from "fp-ts/lib/Either";
import { identity, pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import type { BondOfferingWithIssuer } from "@scripts/generated/models/bondOffering";
import type { RfpWithIssuer } from "@scripts/generated/models/rfp";
import type { WithModInfo, WithStatusU } from "@scripts/generated/models/threadThrough";

const rfpIdRegex = /^r(\d+)$/;
type RfpId = `r${number}`;
export const rfpIdC = new t.Type<RfpId, string>(
  "rfpId",
  (u: unknown): u is RfpId => typeof u === "string" && rfpIdRegex.test(u),
  (u: unknown, c: t.Context): t.Validation<RfpId> => {
    if (typeof u === "string") {
      const prefix = u[0];
      const id = rfpIdRegex.exec(u)?.[1];

      return pipe(
        t.literal("r").validate(prefix, c),
        E.chain(validatedPrefix => pipe(
          NumberFromString.validate(id, c),
          E.map((validatedId): RfpId => `${validatedPrefix}${validatedId}`)
        ))
      );
    }
    return E.left([{ value: u, context: c, message: "Wrong type for rfpId" }]);
  },
  identity,
);

const bondIdRegex = /^b(\d+)$/;
type BondId = `b${number}`;
export const bondIdC = new t.Type<BondId, string>(
  "bondId",
  (u: unknown): u is BondId => typeof u === "string" && bondIdRegex.test(u),
  (u: unknown, c: t.Context): t.Validation<BondId> => {
    if (typeof u === "string") {
      const prefix = u[0];
      const id = bondIdRegex.exec(u)?.[1];

      return pipe(
        t.literal("b").validate(prefix, c),
        E.chain(validatedPrefix => pipe(
          NumberFromString.validate(id, c),
          E.map((validatedId): BondId => `${validatedPrefix}${validatedId}`)
        ))
      );
    }
    return E.left([{ value: u, context: c, message: "Wrong type for bondId" }]);
  },
  identity,
);

export type DealId = RfpId | BondId;

export const makeBondIdFromId = (id: number): DealId => `b${id}`;
export const makeRfpIdFromId = (id: number): DealId => `r${id}`;
export const makeBondId = (offering: BondOfferingWithIssuer): DealId => makeBondIdFromId(offering.offering.data.id);
export const makeRfpId = (rfp: WithStatusU<WithModInfo<RfpWithIssuer>>): DealId => makeRfpIdFromId(rfp.data.id);
export const makeDealId = E.fold(makeRfpId, makeBondId);

export const dealIdC: t.Type<DealId, string> = t.union([rfpIdC, bondIdC]);
