import Big from "big.js"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import * as b from "fp-ts/lib/boolean";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import type { Context, Errors, Validation } from "io-ts";
import * as t from "io-ts";

import { Struct } from "@scripts/fp-ts";
import { tap } from "@scripts/util/tap";

export type ReadonlyBig = Readonly<Big>;

/**
 * @throws
 * If you provide it with a non-number string
 * @throws
 * If you provide it with a number greater than Number.MAX_SAFE_INTEGER
 *
 * Configuration
 *
 * @remarks
 * The constructor will throw only on strict = true
 * The methods on the constructor will throw only when called
 * See B.test.ts for coverage of the following methods/configuration as of Big.js 6.2.1
 *
 * @throws
 * Thrown if strict = true calling the following functions valueOf, toPrecision, toNumber
 * @throws
 * Thrown if strict = true and number is used a constructor
 * @throws
 * Thrown if NaN or Infinity
 * @throws
 * Thrown if there are non-number characters in the string, anything not "1.00"
 * @throws
 * Thrown if configuration RM rounding mode is the incorrect type and calling div toExponential toFixed toPrecision round
 * @throws
 * Thrown if configuration DP decimal places is the incorrect type and calling div sqrt pow
 */
const _UnsafeBigConstructor = pipe(
  Big(),
  tap(_ => _.DP = 15),
  tap(_ => _.RM = Big.roundUp),
);

export const formatBigNumberError = <A>(_: A) => `Unable to create Big from ${typeof _} : ${JSON.stringify(_)}`;

export const isBigNumber = (a: unknown): a is Big => Struct.is(a) && "abs" in a && "div" in a && "lte" in a;

const validateSafeInteger = (a: number, c: Context) => b.fold<Validation<number>>(
  () => t.failure(a, c),
  () => t.success(a)
)(Number.MIN_SAFE_INTEGER <= a && a <= Number.MAX_SAFE_INTEGER);

const validateStringOrNumber = (a: unknown, c: Context): Validation<string | number> => pipe(
  t.number.validate(a, c),
  E.chain(_ => validateSafeInteger(_, c)),
  E.altW(() => t.string.validate(a, c)),
);

const validateBigNumber = <A>(a: A, c: Context): Validation<ReadonlyBig> => isBigNumber(a) ? t.success(a) : t.failure(a, c);

export const validateBig = (a: unknown, c: Context): Validation<ReadonlyBig> => pipe(
  validateStringOrNumber(a, c),
  E.altW(() => validateBigNumber(a, c)),
  E.chain((_: number | string | Big) =>
    E.tryCatch(
      () => _UnsafeBigConstructor(_),
      (): Errors => [{
        value: _,
        context: [],
        message: formatBigNumberError(_),
      }]
    )
  )
);

export const zero: ReadonlyBig = _UnsafeBigConstructor("0");
export const one: ReadonlyBig = _UnsafeBigConstructor("1");

export const bigNumberC = new t.Type(
  "Big Number",
  (a: unknown): a is ReadonlyBig => E.isRight(validateBig(a, [])),
  validateBig,
  _ => _.toPrecision()
);
