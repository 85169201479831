import { withModInfoC as imported145_withModInfoC, withIdC as imported247_withIdC, WithStatusCU as imported275_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported73_mediaC, mediaUploadResponseC as imported16_mediaUploadResponseC } from "./media";
import { HrefLocationCU as imported2_HrefLocationCU, HrefLocationCU as imported3_HrefLocationCU } from "../domaintables/hrefLocations";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BucketCU as imported9_BucketCU } from "../domaintables/pages";

export const hrefC = t.type({
  href: optionFromNullable(t.string),
  text: t.string,
  calloutText: optionFromNullable(t.string),
  sortOrder: t.number,
  location: imported2_HrefLocationCU,
  pageHeader: optionFromNullable(imported9_BucketCU),
  media: optionFromNullable(imported247_withIdC(imported145_withModInfoC(imported73_mediaC))),
  url: t.string
});
export type HrefC = typeof hrefC;
export type Href = t.TypeOf<HrefC>;


export const hrefsListC = t.type({
  hrefs: t.readonlyArray(imported275_WithStatusCU(hrefC))
});
export type HrefsListC = typeof hrefsListC;
export type HrefsList = t.TypeOf<HrefsListC>;


export const hrefPostBodyC = t.type({
  id: optionFromNullable(t.number),
  href: optionFromNullable(t.string),
  location: imported3_HrefLocationCU,
  text: t.string,
  pageHeaderId: optionFromNullable(t.number),
  media: optionFromNullable(imported16_mediaUploadResponseC),
  mediaId: optionFromNullable(t.number),
  sortOrder: t.number
});
export type HrefPostBodyC = typeof hrefPostBodyC;
export type HrefPostBody = t.TypeOf<HrefPostBodyC>;


