import { WithStatusCU as imported291_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { MediaCategoryCU as imported8_MediaCategoryCU } from "../domaintables/mediaCategories";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { dateQualifierC as imported23_dateQualifierC, dateQualifierC as imported24_dateQualifierC } from "./dateQualifier";

export const imageUploadResponseC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string,
  caption: optionFromNullable(t.string)
});
export type ImageUploadResponseC = typeof imageUploadResponseC;
export type ImageUploadResponse = t.TypeOf<ImageUploadResponseC>;


export const imagePostBodyC = t.type({
  id: optionFromNullable(t.number),
  mediaCategory: imported8_MediaCategoryCU,
  uploadResponse: imageUploadResponseC
});
export type ImagePostBodyC = typeof imagePostBodyC;
export type ImagePostBody = t.TypeOf<ImagePostBodyC>;


export const mediaUploadResponseC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string
});
export type MediaUploadResponseC = typeof mediaUploadResponseC;
export type MediaUploadResponse = t.TypeOf<MediaUploadResponseC>;


export const mediaC = t.type({
  caption: optionFromNullable(t.string),
  altText: optionFromNullable(t.string),
  mediaDate: imported23_dateQualifierC,
  mediaOrder: t.number,
  documentTypeId: optionFromNullable(t.number),
  uploadResponse: mediaUploadResponseC,
  archived: optionFromNullable(LocalDateTimeFromIsoStringC),
  mediaCategoryId: t.number
});
export type MediaC = typeof mediaC;
export type Media = t.TypeOf<MediaC>;


export const mediaListC = t.readonlyArray(imported291_WithStatusCU(mediaC));
export type MediaListC = typeof mediaListC;
export type MediaList = t.TypeOf<MediaListC>;


export const mediaUploadResponseWithCategoryC = t.type({
  uri: t.string,
  fileSize: t.number,
  viewName: t.string,
  mediaCategoryId: optionFromNullable(t.number),
  mediaDate: imported24_dateQualifierC
});
export type MediaUploadResponseWithCategoryC = typeof mediaUploadResponseWithCategoryC;
export type MediaUploadResponseWithCategory = t.TypeOf<MediaUploadResponseWithCategoryC>;


export const mediaPreviewC = t.type({
  fileSize: t.number,
  preview: t.string,
  uri: t.string,
  viewName: t.string
});
export type MediaPreviewC = typeof mediaPreviewC;
export type MediaPreview = t.TypeOf<MediaPreviewC>;


export const previewUploadResponseC = t.type({
  response: mediaPreviewC
});
export type PreviewUploadResponseC = typeof previewUploadResponseC;
export type PreviewUploadResponse = t.TypeOf<PreviewUploadResponseC>;


