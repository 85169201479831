import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { compose, fromOptionK, not } from "fp-ts/lib/Refinement";

import type { Match } from "@scripts/fp-ts/lib/types";

export const isString = fromOptionK((u: unknown): Option<string> =>
  typeof u === "string" ? O.some(u) : O.none
);

export const isEmptyString = <S extends string>(s: S | ""): s is "" => s === "";
export const isNonEmptyString = pipe(isString, compose(not(isEmptyString)));

export const isNumber = fromOptionK((u: unknown): Option<number> =>
  typeof u === "number" ? O.some(u) : O.none
);

export const isFunction = (u: unknown): u is Match.AnyFunction => typeof u === "function";

export const isNotNull = <A>(a: A): a is Exclude<A, null> => a != null;
export const isNull = (u: unknown): u is null => u === null;

export const isDefined = <A>(a: A): a is Exclude<A, undefined> => typeof a !== "undefined";
export const isUndefined = (u: unknown): u is undefined => u === "undefined";

/** `A & {}` is substitutable for `NonNullable<A>` */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isNonNullable = <A>(a: A): a is A & {} => a !== null;
