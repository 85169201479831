import { useCallback, useRef, useState } from "react";

import { Static } from "@scripts/bondlinkStatic";
import { useScrollPositionEffect } from "@scripts/react/util/useScrollPosition";


const baseTransitionDelay = Static.baseTransitionDelay;

type MainNavKlass = "" | "pre-sticky";

export const useStickyNav = <E extends HTMLElement>(): [(container: E) => void, MainNavKlass, boolean] => {

  const mainNavRef = useRef<E | null>(null);
  const [mainNavKlass, setMainNavKlass] = useState<MainNavKlass>("");
  const [stuck, setStuck] = useState<boolean>(false);


  const callbackRef = useCallback((container: E) => {
    mainNavRef.current = container;
  }, []);

  const stickOrUnstick = (isStuck: boolean) => {
    setMainNavKlass("pre-sticky");
    globalThis.setTimeout(() => {
      setMainNavKlass("");
      setStuck(isStuck);
    }, baseTransitionDelay);
  };

  const handleScroll = useCallback((scrollTop: number) => {
    const origNavHeight = mainNavRef.current?.scrollHeight || 0;
    if (scrollTop >= origNavHeight && !stuck) {
      stickOrUnstick(true);
    } else if (scrollTop < origNavHeight && stuck) {
      stickOrUnstick(false);
    }
  }, [stuck]);

  useScrollPositionEffect(handleScroll);


  return [callbackRef, mainNavKlass, stuck];
};
