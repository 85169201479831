import type { PropsWithChildren } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { grid as g } from "@scripts/generated/assets/stylesheets/utilities/_grid";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klassProp } from "@scripts/react/util/classnames";

const gridAttrs = g[".grid"].attrs;
type GridAttrs = keyof typeof gridAttrs;

export const Grid = (props: PropsWithChildren<{ klasses: KlassProp, attrs: O.Option<GridAttrs> }>) => {
  const defaultKlasses = pipe(g[".grid"], (defaultK) => pipe(props.attrs, O.fold(() => [defaultK], (attrs) => [defaultK, g[".grid"].attrs[attrs]])));
  return (
    <div {...klassProp(defaultKlasses)(props.klasses)}>
      {props.children}
    </div>
  );
};

export type GridCols = keyof typeof g;
export const GridCol = (props: PropsWithChildren<{ cols: ReadonlyArray<GridCols>, klasses: KlassProp }>) => {
  return (
    <div {...{ ...klassProp(props.cols.map(kl => g[kl]))(props.klasses) }}>
      {props.children}
    </div>
  );
};

export const TwoColRow = (props: PropsWithChildren<object>) => (
  <GridCol cols={[".c-24", ".c-md-12"]} klasses={O.none}>
    {props.children}
  </GridCol>
);
export const OneColRow = (props: PropsWithChildren<object>) => (
  <GridCol cols={[".c-24"]} klasses={O.none}>
    {props.children}
  </GridCol>
);
