import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { RatingAgencyCU as imported33_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { WithStatusCU as imported190_WithStatusCU } from "./threadThrough";

export const analystC = t.type({
  ratingAgencyId: imported33_RatingAgencyCU,
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
});
export type AnalystC = typeof analystC;
export type Analyst = t.TypeOf<AnalystC>;


export const analystsC = t.readonlyArray(imported190_WithStatusCU(analystC));
export type AnalystsC = typeof analystsC;
export type Analysts = t.TypeOf<AnalystsC>;


export const analystPostC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
});
export type AnalystPostC = typeof analystPostC;
export type AnalystPost = t.TypeOf<AnalystPostC>;


