import { pipe } from "fp-ts/lib/function";

import { O, RA } from "@scripts/fp-ts";
import type { UserPersonaU } from "@scripts/generated/domaintables/userPersonas";
import { allUserPersona, UserPersonaCU } from "@scripts/generated/domaintables/userPersonas";
import type { DeepPartialWithOptions } from "@scripts/types";

import { tagLookupC } from "./tagLookup";

export const allUserPersonaLookupC = tagLookupC(UserPersonaCU);

export const userPersonaOrAll = (personas?: DeepPartialWithOptions<ReadonlyArray<UserPersonaU>>): ReadonlyArray<UserPersonaU> => pipe(
  O.fromNullable(personas),
  O.map(RA.filter(allUserPersonaLookupC.is)),
  O.getOrElse((): ReadonlyArray<UserPersonaU> => [])
);

export const unspecifiedPersona = "Unspecified";
export const getUserPersonaName = (personaId: O.Option<number>) => pipe(
  personaId,
  O.chain((id) => O.fromNullable(allUserPersona.find(up => up.id === id))),
  O.fold(() => unspecifiedPersona, up => up.personaName),
);
