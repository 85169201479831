import type { MarkdownTag } from "@scripts/codecs/markdown";
import { O, pipe, RA } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { Officer } from "@scripts/generated/models/officer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { AnchorMailTo } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Image } from "@scripts/react/components/Image";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { klass } from "@scripts/react/util/classnames";
import { team, type TeamPageDataC } from "@scripts/routes/routing/ssr/issuersites";
import { phoneWithExtension } from "@scripts/syntax/phoneNumber";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplink } from "../../components/sidebar/sidebarLinksSyntax";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const makeOfficerJumplink = (officer: WithStatusU<Officer>) => `officer-${officer.data.id}`;
const getOfficerFullName = (officer: Officer) => `${officer.firstName} ${officer.lastName}`;

const OfficerInfo = (props: {
  title: string;
  phone: O.Option<string>;
  email: O.Option<string>;
}) => <div {...klass("mb-1")}>
    <h5 {...klass("mb-0")}>{props.title}</h5>
    {mapOrEmpty((num: string) => <div>{num}</div>)(props.phone)}
    {mapOrEmpty((emailAddress: string) => <AnchorMailTo email={emailAddress} />)(props.email)}
  </div>;

const OfficerCard = (props: { officer: WithStatusU<Officer>, topBorder: boolean }) => {
  const {
    title,
    email,
    phoneNumber,
    phoneExtension,
    blurb,
    media,
  } = props.officer.data.record;

  const fullName = getOfficerFullName(props.officer.data.record);
  const phone = phoneWithExtension(phoneNumber, phoneExtension);

  return <AccentDividerSection
    title={O.none}
    klasses={["floated-content"].concat(props.topBorder ? ["accent-border-top"] : [])}
    sectionId={makeOfficerJumplink(props.officer)}
  >
    <h2 {...klass("d-md-none")}>{fullName}</h2>
    <div>
      {mapOrEmpty((m: Media) =>
        <Image alt={`Photo of ${fullName}`}
          src={m.uploadResponse.uri}
          klasses={["officer-card-image", "float-right", "w-40", "w-md-35"]}
        />)(media)}
      <h2 {...klass("title", "d-none", "d-md-block")}>{fullName}</h2>
      <OfficerInfo
        title={title}
        phone={phone}
        email={email}
      />
      {mapOrEmpty((b: MarkdownTag) => <Markdown content={b} />)(blurb)}
    </div>
  </AccentDividerSection >;
};

export const TeamPage = (props: {
  officers: TeamPageDataC["_A"];
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const teamRouteMeta = team({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const visibleOfficers = props.officers.filter(_ => _.data.record.officerPageVisibility);
  const officerJumplinks = pipe(
    visibleOfficers,
    RA.map((officer) => makeJumplink(getOfficerFullName(officer.data.record), makeOfficerJumplink(officer)))
  );

  return <DirectSitesPageDescriptionLayout
    description={teamRouteMeta.description(pages, issuer)}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkCustomAnchorContent={O.none}
        jumpLinks={officerJumplinks}
        routeMeta={teamRouteMeta}
      />
      <ContactUsActionSection containerKlass={"d-none d-md-block"} />
    </>
    }
  >
    <div {...klass("accent-border-top", "pt-0", "team-officer-cards")}>
      {pipe(
        visibleOfficers,
        RA.mapWithIndex((i, officerData) => <OfficerCard key={officerData.data.id} officer={officerData} topBorder={i !== 0} />)
      )}
    </div>
    <ContactUsActionSection containerKlass={"d-md-none"} />
  </DirectSitesPageDescriptionLayout>;
};
