import { WithStatusCU as imported303_WithStatusCU, WithStatusCU as imported300_WithStatusCU, WithStatusCU as imported297_WithStatusCU, WithStatusCU as imported304_WithStatusCU, hasManyLinkC as imported138_hasManyLinkC, hasManyLinkC as imported136_hasManyLinkC, WithStatusCU as imported299_WithStatusCU, WithStatusCU as imported301_WithStatusCU, hasManyLinkC as imported135_hasManyLinkC, hasManyLinkC as imported137_hasManyLinkC, WithStatusCU as imported298_WithStatusCU, WithStatusCU as imported305_WithStatusCU, WithStatusCU as imported306_WithStatusCU, hasManyLinkC as imported133_hasManyLinkC, WithStatusCU as imported302_WithStatusCU, hasManyLinkC as imported134_hasManyLinkC } from "./threadThrough";
import * as t from "io-ts";
import { rfpWithRelatedContentC as imported17_rfpWithRelatedContentC } from "./rfp";
import { externalLinkC as imported22_externalLinkC, externalLinkC as imported23_externalLinkC } from "./externalLink";
import { projectC as imported20_projectC } from "./project";
import { taggedContentC as imported193_taggedContentC, taggedContentC as imported191_taggedContentC, taggedContentC as imported195_taggedContentC, taggedContentC as imported192_taggedContentC, taggedContentC as imported196_taggedContentC, taggedContentC as imported190_taggedContentC, taggedContentC as imported194_taggedContentC } from "./taggedContent";
import { bondProgramC as imported22_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported31_subscribedC, subscribedC as imported30_subscribedC } from "./subscribed";
import { either } from "io-ts-types/lib/either";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { sortOrderC as imported36_sortOrderC, sortOrderC as imported37_sortOrderC } from "./sortOrder";
import { markdownC } from "../../codecs/markdown";
import { issuerNewsC as imported17_issuerNewsC, issuerNewsC as imported18_issuerNewsC } from "./issuerNewsBase";
import { eventWithRelatedContentC as imported5_eventWithRelatedContentC } from "./eventWithRelatedContent";
import { mediaC as imported84_mediaC, mediaUploadResponseWithCategoryC as imported13_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported22_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported55_rfpC } from "./rfpBase";
import { bondOfferingWithRelatedContentC as imported15_bondOfferingWithRelatedContentC } from "./bondOffering";
import { relatedExternalLinkLinkC as imported11_relatedExternalLinkLinkC, postRelatedC as imported60_postRelatedC, relatedOfferingLinkC as imported13_relatedOfferingLinkC, postRelatedC as imported62_postRelatedC, postRelatedC as imported61_postRelatedC, relatedOfferingLinkC as imported12_relatedOfferingLinkC, relatedProgramLinkC as imported10_relatedProgramLinkC, relatedDocumentLinkC as imported32_relatedDocumentLinkC, postRelatedAndAttachedC as imported13_postRelatedAndAttachedC, relatedRfpLinkC as imported13_relatedRfpLinkC, relatedRfpLinkC as imported12_relatedRfpLinkC, relatedProjectLinkC as imported23_relatedProjectLinkC, relatedProgramLinkC as imported11_relatedProgramLinkC, postRelatedC as imported63_postRelatedC, isAttachedC as imported19_isAttachedC, relatedDocumentLinkC as imported33_relatedDocumentLinkC, relatedProjectLinkC as imported22_relatedProjectLinkC, postAttachedC as imported25_postAttachedC } from "./relatedContent";

export const issuerNewsRelatedContentC = t.type({
  projects: t.readonlyArray(imported133_hasManyLinkC(imported297_WithStatusCU(imported190_taggedContentC(imported20_projectC)), imported22_relatedProjectLinkC)),
  offerings: t.readonlyArray(imported134_hasManyLinkC(imported298_WithStatusCU(imported191_taggedContentC(imported22_bondOfferingC)), imported12_relatedOfferingLinkC)),
  documents: t.readonlyArray(imported135_hasManyLinkC(imported299_WithStatusCU(imported192_taggedContentC(imported84_mediaC)), imported19_isAttachedC(imported32_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported136_hasManyLinkC(imported300_WithStatusCU(imported22_externalLinkC), imported11_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported137_hasManyLinkC(imported301_WithStatusCU(imported193_taggedContentC(imported55_rfpC)), imported12_relatedRfpLinkC)),
  programs: t.readonlyArray(imported138_hasManyLinkC(imported302_WithStatusCU(imported22_bondProgramC), imported10_relatedProgramLinkC))
});
export type IssuerNewsRelatedContentC = typeof issuerNewsRelatedContentC;
export type IssuerNewsRelatedContent = t.TypeOf<IssuerNewsRelatedContentC>;


export const issuerNewsWithRelatedContentC = t.type({
  news: imported17_issuerNewsC,
  relatedContent: issuerNewsRelatedContentC
});
export type IssuerNewsWithRelatedContentC = typeof issuerNewsWithRelatedContentC;
export type IssuerNewsWithRelatedContent = t.TypeOf<IssuerNewsWithRelatedContentC>;


export const issuerNewsPostRelatedContentC = t.type({
  projects: imported60_postRelatedC(imported23_relatedProjectLinkC),
  offerings: imported61_postRelatedC(imported13_relatedOfferingLinkC),
  documents: imported13_postRelatedAndAttachedC(imported33_relatedDocumentLinkC, imported13_mediaUploadResponseWithCategoryC, imported36_sortOrderC),
  externalLinks: imported25_postAttachedC(imported23_externalLinkC, imported37_sortOrderC),
  rfps: imported62_postRelatedC(imported13_relatedRfpLinkC),
  programs: imported63_postRelatedC(imported11_relatedProgramLinkC)
});
export type IssuerNewsPostRelatedContentC = typeof issuerNewsPostRelatedContentC;
export type IssuerNewsPostRelatedContent = t.TypeOf<IssuerNewsPostRelatedContentC>;


export const issuerNewsPostBodyC = t.type({
  id: optionFromNullable(t.number),
  newsTitle: t.string,
  newsDate: optionFromNullable(LocalDateC),
  newsBlurb: markdownC,
  postTypeId: t.number,
  newsOrder: t.number,
  esgRelated: t.boolean,
  relatedContent: issuerNewsPostRelatedContentC
});
export type IssuerNewsPostBodyC = typeof issuerNewsPostBodyC;
export type IssuerNewsPostBody = t.TypeOf<IssuerNewsPostBodyC>;


export const newsAndEventsDataC = t.type({
  eventItems: t.readonlyArray(either(imported303_WithStatusCU(imported5_eventWithRelatedContentC), either(imported30_subscribedC(imported304_WithStatusCU(imported194_taggedContentC(imported15_bondOfferingWithRelatedContentC))), imported31_subscribedC(imported305_WithStatusCU(imported195_taggedContentC(imported17_rfpWithRelatedContentC)))))),
  news: t.readonlyArray(imported306_WithStatusCU(imported196_taggedContentC(imported18_issuerNewsC)))
});
export type NewsAndEventsDataC = typeof newsAndEventsDataC;
export type NewsAndEventsData = t.TypeOf<NewsAndEventsDataC>;


