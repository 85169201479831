import type { RNEA } from "@scripts/fp-ts";
import { O } from "@scripts/fp-ts";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { RatingBlockSection } from "@scripts/react/components/ratings/RatingBlock";
import type { SitesBondProgramWithRelatedContent } from "@scripts/syntax/bondProgram";

import { useIssuerSitesSelector } from "../../../state/store";

export const ratingsSectionTitle = "Ratings";
export const RatingsSection = (props: {
  ratings: RNEA.ReadonlyNonEmptyArray<SitesBondProgramWithRelatedContent["relatedContent"]["ratings"][number]>;
  sectionId: string;
  programName: string;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <AccentDividerSection
      title={O.some(ratingsSectionTitle)}
      sectionId={props.sectionId}
    >
      <RatingBlockSection name={O.none} modalName={props.programName} issuer={issuer} ratings={props.ratings} />
    </AccentDividerSection>
  );
};
