import { useCallback } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Faq } from "@scripts/react/components/Faq";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";
import { faq as faqRoute, type FAQPageData } from "@scripts/routes/routing/ssr/issuersites";
import { makeJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const makeFaqGroupJumplinkId = (faqGroup: FAQPageData["faqs"][number]) =>
  makeJumplinkId(`faq-group-${faqGroup[0].id}`);

const FaqGroup = (props: { faqGroup: FAQPageData["faqs"][number] }) =>
  <AccentDividerSection
    title={O.some(props.faqGroup[0].record.name)}
    sectionId={makeFaqGroupJumplinkId(props.faqGroup)}
  >
    {pipe(
      props.faqGroup[1],
      RA.map((faq) =>
        <Faq
          faq={{ question: faq.data.record.question, answer: faq.data.record.answer }}
          key={faq.data.record.question}
          klass={O.none}
        />
      )
    )}
  </AccentDividerSection>;

export const FaqPage = (props: { faqs: FAQPageData["faqs"] }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const faqPageMeta = faqRoute({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const description = faqPageMeta.description(pages, issuer);

  const faqGroupJumpLinks = pipe(
    props.faqs,
    RA.map((faqGroup) => makeJumplinkO(faqGroup[0].record.name, makeFaqGroupJumplinkId(faqGroup), true)),
    RA.compact,
    RNEA.fromReadonlyArray
  );

  const SidebarLinks = useCallback(() => pipe(
    faqGroupJumpLinks,

    mapOrEmpty((jumpLinks) =>
      <SidebarLinksSites
        jumpLinks={jumpLinks}
        headerLinkCustomAnchorContent={O.none}
        routeMeta={faqPageMeta}
      />
    )
  ), [faqGroupJumpLinks, faqPageMeta]);

  return (
    <DirectSitesPageDescriptionLayout
      description={description}
      sidebarContent={
        <>
          <SidebarLinks />
          <ContactUsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("accent-border-top", "pt-0")}>
        {pipe(
          RNEA.fromReadonlyArray(props.faqs),
          O.fold(
            () => <div {...klass("mt-1")}>There are currently no items in this FAQ.</div>,
            _ =>
            <>
              {pipe(
                _,
                RA.map(__ =>
                  <FaqGroup
                    faqGroup={__}
                    key={`${__[0].record.name}${__[0].id}`}
                  />
                )
              )}
            </>
          )
        )}
      </div>
      <ContactUsActionSection containerKlass={"d-md-none"} />
    </DirectSitesPageDescriptionLayout>
  );
};
