import type { PropsWithChildren, ReactEventHandler } from "react";
import { Fragment } from "react";
import type { Lazy } from "fp-ts/lib/function";
import { constVoid, pipe } from "fp-ts/lib/function";
import { fold, isSome } from "fp-ts/lib/Option";

import { O } from "@scripts/fp-ts";
import type { Variant } from "@scripts/react/components/Button";
import { ButtonIcon, ButtonLink, ButtonPrimary, ButtonsContainer, ButtonSecondary, ButtonSubmit, LoadingElement } from "@scripts/react/components/Button";
import type { NonDraftableProps, PortalActionButtonsProps } from "@scripts/react/components/form/PortalForm";
import { Svg } from "@scripts/react/components/Svg";
import type { DataCodec, FormStateBase } from "@scripts/react/form/form";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";

import checkmark from "@svgs/checkmark.svg";
import closeX from "@svgs/close-x.svg";
import trashIcon from "@svgs/trash.svg";

import { mapOrEmpty } from "../Empty";
import type { DeleteApiProps } from "../modal/Confirmation";
import { DeleteDialogue } from "../modal/DeleteDialogue";
import type { ModalDeleteButtonProps, ModalDeleteProps, ModalDismissable } from "../modal/Modal";
import { TooltipO } from "../Tooltip";

export const publish = ["Publish", "Publishing"] as const;
export const save = ["Save", "Saving"] as const;
export const send = ["Send", "Sending"] as const;
export const submit = ["Submit", "Submitting"] as const;

export const actions = { publish, save, send, submit } as const;
export type Actions = keyof typeof actions;

type ActionButtonsSubmit = {
  submitText: Actions;
};

type ActionButtonsSubmitCustom = {
  submitTextCustom: string;
  loadingTextCustom: string;
};

const isActionButtonsSubmitCustom = (a: ActionButtonsSubmit | ActionButtonsSubmitCustom): a is ActionButtonsSubmitCustom => "submitTextCustom" in a;

type ActionButtonsBase = (ActionButtonsSubmit | ActionButtonsSubmitCustom) & ModalDismissable & {
  disabledSubmit?: boolean;
  noDivider?: true;
};

export type ActionButtonsProps = ActionButtonsBase & ModalDeleteProps & {
  loading: boolean;
  onSubmit: ReactEventHandler;
  submitVariant?: Variant;
};

export const CancelButton = (p: ModalDismissable) =>
  <ButtonSecondary className={"mr-0 mt-0"} onClick={p.dismissAction}>Cancel</ButtonSecondary>;

export const DelBtn = (props: ModalDeleteButtonProps) =>
  <ButtonIcon
    disabled={isSome(props.disabledTooltip)}
    onClick={props.onClick}
    icon={trashIcon}
    variant={"primary"}
    {...klass("mr-1", "mt-0", "btn-reject")}
  >
    Delete
  </ButtonIcon>;

export const DelAndCancelBtn = (props: ModalDeleteButtonProps & ModalDismissable) =>
  <div {...klass("d-flex")}>
    <TooltipO tooltip={props.disabledTooltip}>
      <DelBtn
        disabledTooltip={props.disabledTooltip}
        onClick={props.onClick}
      />
    </TooltipO>
    <CancelButton dismissAction={props.dismissAction} />
  </div>;

export const ActionButtons = (props: ActionButtonsProps) => {
  const isCustomText = isActionButtonsSubmitCustom(props);
  const submitText = isCustomText ? props.submitTextCustom : actions[props.submitText][0];
  const loadingText = isCustomText ? props.loadingTextCustom : actions[props.submitText][1];

  return (
    <ButtonsContainer klasses={[props.noDivider ? "mt-15" : "divider pt-15"]}>
      <ButtonSubmit
        {...klass("mt-0")}
        disabled={props.disabledSubmit}
        text={submitText}
        loadingText={loadingText}
        loading={props.loading}
        onClick={props.onSubmit}
        variant={props.submitVariant}
      />
      {pipe(props.deleteButton,
        fold(
          () => <CancelButton dismissAction={props.dismissAction} />,
          (db) => <DelAndCancelBtn disabledTooltip={db.disabledTooltip} onClick={db.onClick} dismissAction={props.dismissAction} />
        )
      )}
    </ButtonsContainer>
  );
};

export const ModalActionsButtonsBase = (props: ActionButtonsBase & { onSubmit: () => void, klasses: KlassProp, dismissTextCustom?: string, divider?: true }) => {
  const isCustomText = isActionButtonsSubmitCustom(props);
  const submitText = isCustomText ? props.submitTextCustom : actions[props.submitText][0];

  return <ButtonsContainer klasses={props.klasses} divider={props.divider}>
    <ButtonPrimary
      disabled={props.disabledSubmit}
      onClick={props.onSubmit}
    >
      {submitText}
    </ButtonPrimary>
    <ButtonSecondary onClick={props.dismissAction}>{props.dismissTextCustom ?? "Cancel"}</ButtonSecondary>
  </ButtonsContainer>;
};

export const ModalActionsButtons = (props: ActionButtonsBase & { onSubmit: () => void }) => (
  <ModalActionsButtonsBase {...props} klasses={O.none} divider />
);

export const CloseButton = (props: ModalDismissable) => (
  <div {...klass("d-flex", "justify-content-end")}>
    <ButtonSecondary onClick={props.dismissAction}>Close</ButtonSecondary>
  </div>
);

export type InlineEditButtonsProps = PropsWithChildren<Pick<FormStateBase, "modified" | "loading"> & {
  onSave: ReactEventHandler;
  onDiscard: ReactEventHandler;
  klass?: KlassProp;
}>;

export const InlineEditButtons = (props: InlineEditButtonsProps) => (
  <div {...klassPropO(["inline-edit-buttons"])(props.klass)}>
    {props.children}
    <div {...klassPropO(["actions"])(props.modified ? "show" : "")}>
      <ButtonLink
        className="save"
        disabled={props.loading}
        type="submit"
        onClick={props.onSave}
      >
        <LoadingElement
          loading={props.loading}
          loadingText={"Loading"}
          text={
            <Fragment>
              <Svg src={checkmark} />Save Changes
            </Fragment>
          }
        />
      </ButtonLink>
      <ButtonLink
        className={props.loading ? "disabled" : "discard"}
        disabled={props.loading}
        onClick={props.onDiscard}
      >
        <Svg src={closeX} />Discard Changes
      </ButtonLink>
    </div>
  </div>
);

export const PortalActionButtons = <PC extends DataCodec>(
  props: PortalActionButtonsProps<PC, NonDraftableProps> & {
    deleteProps: O.Option<DeleteApiProps & { onSuccuss: Lazy<void> }>;
  }
) => {
  const [isOpen, open, close] = useModal("PortalActionButtonsDeleteConfirmation");

  return <>
    <ActionButtons
      loading={props.formState.loading}
      deleteButton={pipe(
        props.deleteProps,
        O.chain(() => O.some({
          disabledTooltip: O.none,
          onClick: open,
        }))
      )}
      dismissAction={props.dismissAction}
      disabledSubmit={!props.formState.modified}
      submitText={props.nonDraftableAction}
      onSubmit={constVoid}
      noDivider={props.noDivider}
    />
    {pipe(props.deleteProps, mapOrEmpty(_ =>
      <DeleteDialogue
        deleteApiProps={props.deleteProps}
        dismissAction={close}
        modalOpen={isOpen}
        successAction={_.onSuccuss}
      />)
    )}
  </>;
};
