import { spanStatusfromHttpCode } from "@sentry/tracing";
import crossFetch from "cross-fetch";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";
import * as Th from "fp-ts/lib/These";
import type * as t from "io-ts";
import { BadRequest, Forbidden, Unauthorized } from "ts-http-status-codes";

import { getCurrentTransaction, withNewChildSpanTE } from "@scripts/sentry";

import type { BLConfigWithLog } from "./bondlink";
import { Struct } from "./fp-ts";
import type { Method, UrlInterface } from "./routes/urlInterface";
import type { LogErrors, LogLevel } from "./util/log";
import { errorsToLogErrors, logValidation } from "./util/log";
import { mergeDeep } from "./util/merge";
import { tap } from "./util/tap";

export type RespOrErrors = Th.These<O.Option<Response>, LogErrors>;

type TextResp = {
  kind: "text";
  resp: Response;
  data: string;
};

type UnsafeJsonResp = {
  kind: "unsafeJson";
  resp: Response;
  data: unknown;
};

export type UnsafeResp = TextResp | UnsafeJsonResp;

export type FetchResp = TE.TaskEither<O.Option<Response>, Response>;
export type FetchUnsafeResp<R extends UnsafeResp = UnsafeResp> = TE.TaskEither<O.Option<Response>, R>;
export type FetchUnsafeText = FetchUnsafeResp<TextResp>;
export type FetchUnsafeJson = FetchUnsafeResp<UnsafeJsonResp>;
export type FetchParsedResp<A> = TE.TaskEither<RespOrErrors, [Response, A]>;

export type PostFormData = NonNullable<ConstructorParameters<typeof URLSearchParams>[0]>;

const headers = mergeDeep({ headers: { "X-Requested-With": "XMLHttpRequest" } });
const credentials = mergeDeep({ credentials: "same-origin" });
const checkStatus = TE.fromPredicate((r: Response) => r.status < 300, O.some);
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const origFetch = globalThis.fetch ?? crossFetch;
const isResponse = (x: unknown): x is Response => {
  return Struct.is(x) && x instanceof Response;
};

const _blFetch = (config: BLConfigWithLog) => (url: UrlInterface<Method>, opts?: RequestInit): FetchResp =>
  pipe(
    TE.tryCatch(
      () => origFetch(url.url, headers(credentials(Object.assign({}, opts || {}, { method: url.method })))),
      (e: unknown) => {
        config.log.warn("BLFetch Failed", e);
        return O.none;
      }
    ),
    TE.chain(checkStatus),
    TE.mapLeft(O.map(tap((r: Response) =>
      ([BadRequest, Unauthorized, Forbidden].includes(r.status) ? config.log.info : config.log.error)(`BLFetch Failed With Status: ${r.status} -- ${r.statusText}`, r))))
  );

export const blFetch = (config: BLConfigWithLog) =>
  (url: UrlInterface<Method>, options?: RequestInit, disableProfiling = false): FetchResp =>
    disableProfiling ? _blFetch(config)(url, options) : pipe(
      getCurrentTransaction(),
      O.fold(
        () => _blFetch(config)(url, options),
        (trx) => withNewChildSpanTE(
          {
            parent: trx,
            context: {
              description: url.url,
              op: "http-request",
              data: { url, requestInit: options },
            },
            onLeft: (span, err) => {
              if (isResponse(err)) {
                span.setStatus(spanStatusfromHttpCode(err.status));
                span.setHttpStatus(err.status);
                span.setData("statusText", err.statusText);
              } else {
                span.setStatus("unknown_error");
                span.setData("error", err);
              }
            },
            onRight: (span, resp) => {
              span.setStatus(spanStatusfromHttpCode(resp.status));
              span.setHttpStatus(resp.status);
            },
          },
          _blFetch(config)(url, options)
        )
      )
    );

export const parseFetchUnsafeResp = (config: BLConfigWithLog) => (resp: Response): FetchUnsafeResp =>
  pipe(
    O.fromNullable(resp.headers.get("content-type")),
    O.getOrElse(() => {
      config.log.fatal("No content-type header");
      return "text/plain";
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ct): ["text", () => Promise<string>] | ["unsafeJson", () => Promise<any>] => {
      if (ct.startsWith("text")) {
        return ["text", resp.text.bind(resp)];
      } else if (ct.includes("application/json")) {
        return ["unsafeJson", resp.json.bind(resp)];
      }
      config.log.fatal("Unknown content-type header", ct, resp);
      return ["text", resp.text.bind(resp)];
    },
    ([kind, fn]) => pipe(
      TE.tryCatch(
        () => fn(),
        (e: unknown) => {
          config.log.fatal("FetchType failed to retrieve:", kind, e);
          return O.some(resp);
        }
      ),
      // data is actually `any` when its json :|
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TE.map((data: any): UnsafeResp => ({ kind, resp, data }))
    )
  );

const _fetchUnsafeResp = (config: BLConfigWithLog) =>
  (url: UrlInterface<Method>, opts?: RequestInit): FetchUnsafeResp =>
    pipe(
      blFetch(config)(url, opts),
      TE.chain(parseFetchUnsafeResp(config)),
    );

export const fetchUnsafeResp = (config: BLConfigWithLog) =>
  (url: UrlInterface<Method>, options?: RequestInit, disableProfiling: boolean = false): FetchUnsafeResp =>
    disableProfiling
      ? _fetchUnsafeResp(config)(url, options)
      : pipe(
        getCurrentTransaction(),
        O.fold(
          () => _fetchUnsafeResp(config)(url, options),
          (trx) => withNewChildSpanTE(
            {
              parent: trx,
              context: {
                description: url.url,
                op: "http-request",
                data: { url, requestInit: options },
              },
              onLeft: (span, err) => {
                if (O.isSome(err)) {
                  span.setStatus(spanStatusfromHttpCode(err.value.status));
                  span.setHttpStatus(err.value.status);
                  span.setData("statusText", err.value.statusText);
                } else {
                  span.setStatus("unknown_error");
                }
              },
              onRight: (span, unsafeResp) => {
                span.setStatus(spanStatusfromHttpCode(unsafeResp.resp.status));
                span.setHttpStatus(unsafeResp.resp.status);
              },
            },
            _fetchUnsafeResp(config)(url, options)
          )
        )
      );

export const parseData = <A, O, I>(tpe: t.Type<A, O, I>, level: LogLevel, message: string) => (data: unknown) =>
  pipe(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    tpe.decode(data as I),
    E.mapLeft(errorsToLogErrors(level, message)),
    logValidation,
  );

export const parseUnsafeResp = <A, O, I>(tpe: t.Type<A, O, I>, level: LogLevel, message: string) => (r: UnsafeResp): FetchParsedResp<A> =>
  pipe(
    parseData(tpe, level, message)(r.data),
    E.fold(
      (e: LogErrors) => TE.left(Th.both(O.some(r.resp), e)),
      (a: A) => TE.right([r.resp, a]),
    )
  );

export const handleFetchedResp = <A, O, I>(tpe: t.Type<A, O, I>, message: string) => (ur: FetchUnsafeResp): FetchParsedResp<A> =>
  pipe(
    ur,
    TE.fold(
      (or: O.Option<Response>) => TE.left(Th.left(or)),
      parseUnsafeResp(tpe, "fatal", message)
    )
  );

export const fetchJson = (config: BLConfigWithLog) =>
  <A, O, I>(tpe: t.Type<A, O, I>, message: string) =>
    (url: UrlInterface<"GET">, opts?: RequestInit): FetchParsedResp<t.TypeOf<t.Type<A, O, I>>> => {
      return pipe(
        fetchUnsafeResp(config)(url, opts),
        handleFetchedResp(tpe, message)
      );
    };

export const fetchJsonUnsafe =
  (config: BLConfigWithLog) =>
    (data: unknown) =>
      (url: UrlInterface<"POST" | "DELETE">, options: RequestInit = {}): FetchUnsafeResp =>
        fetchUnsafeResp(config)(url, mergeDeep({
          cache: "no-cache",
          body: JSON.stringify(data),
          headers: {
            "Csrf-Token": config.csrf,
            "Content-Type": "application/json",
          },
      })(options));

export const fetchPostFormData =
  (config: BLConfigWithLog) =>
    (data: PostFormData) =>
      (url: UrlInterface<"POST">, options: RequestInit = {}): FetchUnsafeResp =>
        fetchUnsafeResp(config)(url, mergeDeep({
          cache: "no-cache",
          body: new URLSearchParams(data).toString(),
          headers: {
            "Csrf-Token": config.csrf,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })(options));
