import * as t from "io-ts";
import { rfpWithIssuerC as imported5_rfpWithIssuerC } from "./rfp";
import { SectorCU as imported19_SectorCU } from "../domaintables/sectors";
import { bondOfferingDetailsWithIssuerC as imported3_bondOfferingDetailsWithIssuerC } from "./bondOffering";
import { withIdC as imported278_withIdC, withIdC as imported277_withIdC } from "./threadThrough";
import { issuerC as imported53_issuerC } from "./issuer";

export const sectorDataC = t.type({
  sector: imported19_SectorCU,
  issuers: t.readonlyArray(imported53_issuerC),
  offerings: t.readonlyArray(imported277_withIdC(imported3_bondOfferingDetailsWithIssuerC)),
  rfps: t.readonlyArray(imported278_withIdC(imported5_rfpWithIssuerC))
});
export type SectorDataC = typeof sectorDataC;
export type SectorData = t.TypeOf<SectorDataC>;


