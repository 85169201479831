import { WithStatusCU as imported197_WithStatusCU, hasManyLinkC as imported78_hasManyLinkC, WithStatusCU as imported192_WithStatusCU, WithStatusCU as imported193_WithStatusCU, WithStatusCU as imported196_WithStatusCU, WithStatusCU as imported195_WithStatusCU, hasManyLinkC as imported76_hasManyLinkC, hasManyLinkC as imported80_hasManyLinkC, hasManyLinkC as imported75_hasManyLinkC, hasManyLinkC as imported79_hasManyLinkC, WithStatusCU as imported194_WithStatusCU, hasManyLinkC as imported77_hasManyLinkC } from "./threadThrough";
import * as t from "io-ts";
import { externalLinkC as imported12_externalLinkC, externalLinkC as imported13_externalLinkC } from "./externalLink";
import { projectC as imported11_projectC } from "./project";
import { taggedContentC as imported125_taggedContentC, taggedContentC as imported126_taggedContentC, taggedContentC as imported128_taggedContentC, taggedContentC as imported127_taggedContentC } from "./taggedContent";
import { bondProgramC as imported18_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported9_eventC } from "./event";
import { sortOrderC as imported20_sortOrderC, sortOrderC as imported21_sortOrderC } from "./sortOrder";
import { postRelatedC as imported35_postRelatedC, relatedProjectLinkC as imported12_relatedProjectLinkC, relatedRfpLinkC as imported8_relatedRfpLinkC, postRelatedC as imported36_postRelatedC, isAttachedC as imported11_isAttachedC, relatedProgramLinkC as imported6_relatedProgramLinkC, postRelatedC as imported37_postRelatedC, postAttachedC as imported14_postAttachedC, relatedOfferingLinkC as imported8_relatedOfferingLinkC, postRelatedC as imported34_postRelatedC, relatedExternalLinkLinkC as imported6_relatedExternalLinkLinkC, relatedProjectLinkC as imported13_relatedProjectLinkC, postRelatedAndAttachedC as imported8_postRelatedAndAttachedC, relatedDocumentLinkC as imported19_relatedDocumentLinkC, relatedProgramLinkC as imported7_relatedProgramLinkC, relatedOfferingLinkC as imported7_relatedOfferingLinkC, relatedDocumentLinkC as imported20_relatedDocumentLinkC, relatedRfpLinkC as imported7_relatedRfpLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported52_mediaC, mediaUploadResponseWithCategoryC as imported8_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported15_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported41_rfpC } from "./rfpBase";

export const eventRelatedContentC = t.type({
  projects: t.readonlyArray(imported75_hasManyLinkC(imported192_WithStatusCU(imported125_taggedContentC(imported11_projectC)), imported12_relatedProjectLinkC)),
  offerings: t.readonlyArray(imported76_hasManyLinkC(imported193_WithStatusCU(imported126_taggedContentC(imported15_bondOfferingC)), imported7_relatedOfferingLinkC)),
  documents: t.readonlyArray(imported77_hasManyLinkC(imported194_WithStatusCU(imported127_taggedContentC(imported52_mediaC)), imported11_isAttachedC(imported19_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported78_hasManyLinkC(imported195_WithStatusCU(imported12_externalLinkC), imported6_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported79_hasManyLinkC(imported196_WithStatusCU(imported128_taggedContentC(imported41_rfpC)), imported7_relatedRfpLinkC)),
  programs: t.readonlyArray(imported80_hasManyLinkC(imported197_WithStatusCU(imported18_bondProgramC), imported6_relatedProgramLinkC))
});
export type EventRelatedContentC = typeof eventRelatedContentC;
export type EventRelatedContent = t.TypeOf<EventRelatedContentC>;


export const eventWithRelatedContentC = t.type({
  event: imported9_eventC,
  relatedContent: eventRelatedContentC
});
export type EventWithRelatedContentC = typeof eventWithRelatedContentC;
export type EventWithRelatedContent = t.TypeOf<EventWithRelatedContentC>;


export const eventPostRelatedContentC = t.type({
  projects: imported34_postRelatedC(imported13_relatedProjectLinkC),
  offerings: imported35_postRelatedC(imported8_relatedOfferingLinkC),
  documents: imported8_postRelatedAndAttachedC(imported20_relatedDocumentLinkC, imported8_mediaUploadResponseWithCategoryC, imported20_sortOrderC),
  externalLinks: imported14_postAttachedC(imported13_externalLinkC, imported21_sortOrderC),
  rfps: imported36_postRelatedC(imported8_relatedRfpLinkC),
  programs: imported37_postRelatedC(imported7_relatedProgramLinkC)
});
export type EventPostRelatedContentC = typeof eventPostRelatedContentC;
export type EventPostRelatedContent = t.TypeOf<EventPostRelatedContentC>;


export const eventPostBodyC = t.type({
  id: optionFromNullable(t.number),
  eventDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  eventTitle: t.string,
  eventBlurb: optionFromNullable(markdownC),
  relatedContent: eventPostRelatedContentC
});
export type EventPostBodyC = typeof eventPostBodyC;
export type EventPostBody = t.TypeOf<EventPostBodyC>;


