import { issuerC as imported34_issuerC } from "./issuer";
import * as t from "io-ts";

export const privateSiteC = t.type({
  issuer: imported34_issuerC,
  redirect: t.string
});
export type PrivateSiteC = typeof privateSiteC;
export type PrivateSite = t.TypeOf<PrivateSiteC>;


export const confirmSitePasswordC = t.type({
  id: t.number,
  publishPassword: t.string,
  redirect: t.string
});
export type ConfirmSitePasswordC = typeof confirmSitePasswordC;
export type ConfirmSitePassword = t.TypeOf<ConfirmSitePasswordC>;


