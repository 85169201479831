import type { ReactElement } from "react";
import { pipe } from "fp-ts/lib/function";

import { E, identity, O, RA, RNEA } from "@scripts/fp-ts";
import type { BondOffering } from "@scripts/generated/models/bondOfferingBase";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Media } from "@scripts/generated/models/media";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { AnchorIconUnsafe } from "@scripts/react/components/Anchor";
import { Empty } from "@scripts/react/components/Empty";
import { Tooltip } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import { klass } from "@scripts/react/util/classnames";
import { relatedDocumentsJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { humanDateFull } from "@scripts/syntax/date/joda";
import { offeringUrl, saleDateFormat } from "@scripts/syntax/offering";
import { amountTBDConst, currency } from "@scripts/util/currency";
import { deepLink } from "@scripts/util/url";

import bondIcon from "@svgs/bond.svg";
import docIcon from "@svgs/document.svg";

import { type BadgeProps } from "../Badge";
import { FactsRow, makeFactO } from "../Facts";
import { ContentItemCardLinks, type ContentItemCardLinksProps, type ContentItemCardSubscriptionProps } from "./ContentItemCard";
import { DealCard, type DealCardProps, dealCardRoadshowBadge, dealCardRoadshowsLink } from "./DealCard";

type BondCardSeriesProps = {
  offering: WithId<TaggedContent<BondOffering>>;
};

const BondCardSeries = (props: BondCardSeriesProps) => {
  const config = useConfig();

  return <>
    {props.offering.record.data.series.map(series => (
      <div key={series.data.id} {...klass("series-wrapper")}>
        {props.offering.record.data.series.length > 1 ? <div {...klass("series-name")}>{series.data.record.name}</div> : <Empty />}
        <FactsRow
          items={pipe(
            [
              { title: "Sale Date", value: saleDateFormat(config)(series.data.record.saleDate, humanDateFull) },
              { title: "Par Amount", value: O.fold(amountTBDConst, currency)(series.data.record.parAmount) },
            ],
            RA.concat(RA.compact([
              makeFactO("Method of Sale", series.data.record.methodOfSale, _ => _.name),
              makeFactO("Tax Status", series.data.record.taxStatus, identity),
            ])),
          )}
        />
      </div>
    ))}
  </>;
};

export type BondCardLink = "details" | "roadshows" | "documents";

export type BondCardProps = {
  url: (sitesUrl: UrlInterface<"GET">) => string;
  issuer: Issuer;
  displayIssuer: boolean;
  offering: WithId<TaggedContent<BondOffering>>;
  roadshows: ReadonlyArray<WithId<TaggedContent<RoadShowData>>>;
  documents: ReadonlyArray<WithId<TaggedContent<Media>>>;
  links: ReadonlyArray<BondCardLink>;
  footer?: ReactElement;
} & Pick<DealCardProps, "klass" | "target" | "leafIcon"> & ContentItemCardSubscriptionProps;

export const BondCard = (props: BondCardProps) => {
  const config = useConfig();
  const isArchived = props.offering.record.data.isArchived;
  const roadshowsNEA = RNEA.fromReadonlyArray(props.roadshows);
  const docsToDisplay = 3;

  const bondUrl = offeringUrl(config)(props.offering, props.issuer);
  const downloadDocUrl = (m: WithId<TaggedContent<Media>>) => V2Router.investorPortalOfferingsControllerDownloadDocument({
    issuerId: props.issuer.id,
    offeringId: props.offering.id,
    mediaId: m.id,
  }).url;
  const allDocsUrl = deepLink<object>({ type: "Basic", route: () => bondUrl, selector: relatedDocumentsJumplinkId })({}).url;

  return <DealCard
    klass={props.klass}
    name={props.offering.record.data.name}
    issuer={O.fromPredicate(() => props.displayIssuer)(props.issuer)}
    url={props.url(bondUrl)}
    target={props.target}
    leftBadges={RA.compact<BadgeProps>([
      O.some({ text: "Bond Sale", icon: O.some(bondIcon), variant: "tall", color: "accent-2-700" }),
      dealCardRoadshowBadge(roadshowsNEA),
    ])}
    rightBadge={{
      text: isArchived ? "Archived" : "Active",
      icon: O.none,
      variant: "tall",
      color: isArchived ? "gray-600" : "green-700",
    }}
    taggedContent={props.offering.record}
    leafIcon={props.leafIcon}
    subscription={props.subscription}
  >
    <BondCardSeries offering={props.offering} />
    <ContentItemCardLinks
      links={pipe(
        props.links,
        RA.filterMap((key): O.Option<ContentItemCardLinksProps["links"][number]> => {
          switch (key) {
            case "details":
              return O.some({
                key,
                link: <AnchorIconUnsafe
                  key={key}
                  href={bondUrl.url}
                  icon={bondIcon}
                  target={props.target}
                  textOrAriaLabel={E.left("View details")}
                />,
              });

            case "roadshows":
              return dealCardRoadshowsLink(config, props.issuer, roadshowsNEA, bondUrl, props.target);

            case "documents":
              return pipe(
                props.documents,
                RNEA.fromReadonlyArray,
                O.map(docs => ({
                  key,
                  link: <Tooltip
                    key={key}
                    delay="default"
                    headerBar={{ type: "HeaderBarNoButton", title: "Documents" }}
                    listItems={RNEA.firstN(docsToDisplay)(docs).map(d => ({
                      text: d.record.data.uploadResponse.viewName,
                      link: O.some({ href: downloadDocUrl(d), target: props.target }),
                    })).concat(
                      docs.length > docsToDisplay
                        ? [{ text: `View all ${docs.length} documents`, link: O.some({ href: allDocsUrl, target: props.target }) }]
                        : []
                    )}
                  >
                    <AnchorIconUnsafe
                      href={allDocsUrl}
                      icon={docIcon}
                      target={props.target}
                      textOrAriaLabel={E.left("Download Documents")}
                    />
                  </Tooltip>,
                }))
              );

            default: return config.exhaustive(key);
          }
        }),
      )}
    />
    {props.footer}
  </DealCard>;
};
