import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const contactPostC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  body: t.string,
  captcha: t.string,
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type ContactPostC = typeof contactPostC;
export type ContactPost = t.TypeOf<ContactPostC>;


