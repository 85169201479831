import type { ReactElement, ReactNode, Ref, RefObject, SyntheticEvent } from "react";
import {
  createElement,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import * as A from "fp-ts/lib/Array";
import type { Lazy } from "fp-ts/lib/function";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useStableO } from "fp-ts-react-stable-hooks";
import type * as t from "io-ts";
import V from "voca";

import type { SVGString } from "*.svg";

import type { ApiData } from "@scripts/api/methods";
import type { BLConfigWithLog } from "@scripts/bondlink";
import type { DataMetaBase, UnsafeDataMetaSvg } from "@scripts/meta/dataMeta";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import type { DataCodec, FormState, ResponseCodec } from "@scripts/react/form/form";
import { type CloseModalFn, closeModalFn, type ModalOpen } from "@scripts/react/util/useModal";
import { delProp } from "@scripts/util/delProp";
import { pick } from "@scripts/util/pick";

import { modal as m } from "@styles/components/_modal";

import { displayName } from "../../../meta/dataMeta";
import { Portal } from "../../components/Portal";
import type { KlassList } from "../../util/classnames";
import { klass, klassPropO } from "../../util/classnames";
import type { WithHTMLAttrs } from "../../util/dom";
import { isEscKeyPress } from "../../util/eventHandlers";
import { CloseXButton } from "../Button";
import type { TooltipProps } from "../Tooltip";
import { Empty, mapOrEmpty } from "./../Empty";

let openModalCount = 0;

const getTitleCase = (title: string, ignoreTitleCase?: boolean) => ignoreTitleCase ? title : V.titleCase(title);

export type ModalActionTypes = "add" | "edit" | "upload" | "none";
export type ModalInitializer = Pick<ModalPropsI, "customHeader" | "escapable" | "open" | "size"> & {
  action: ModalActionTypes;
  titleOverride?: string;
  iconOverride?: SVGString;
  dataMeta: DataMetaBase<string> & UnsafeDataMetaSvg & { ignoreTitleCase?: boolean };
};
export const modalTitle = (config: BLConfigWithLog) => (title: string, ignoreTitleCase?: boolean) => (action: ModalActionTypes) => {
  const formattedTitle = getTitleCase(title, ignoreTitleCase);
  switch (action) {
    case "add": return `Add ${formattedTitle}`;
    case "edit": return `Edit ${formattedTitle}`;
    case "upload": return `Upload ${formattedTitle}`;
    case "none": return formattedTitle;
  }

  return config.exhaustive(action);
};

export type ModalOpenable = { modalOpen: ModalOpen };
export type ModalCallerActions = { showAction: () => void };
export type ModalDismissable = { dismissAction: CloseModalFn };
export type ModalDeletable = { deleteAction: Lazy<void> };
export type ModalDelete = { successAction: () => void };
export type ModalActions = ModalDismissable & ModalDeletable & ModalOpenable;
export type ModalConfirm = { onConfirm: Lazy<void> };
export type ModalConfirmActions = ModalActions & ModalConfirm;
export type ModalSuccessFn = <PC extends DataCodec, RC extends ResponseCodec>(s: FormState<PC>, a: ApiData<t.TypeOf<RC>>) => void;
export type ModalSuccess = { successAction: ModalSuccessFn };
export type ModalSuccessActions = ModalDismissable & ModalSuccess;
export type ModalDeleteActions = ModalDismissable & ModalDelete;
export type ModalDismissableWithSuccess = ModalOpenable & ModalDismissable & ModalSuccess;

export type ModalSize = "modal-lg" | "modal-sm";
export type ModalPropsI = ModalDismissable & {
  customHeader?: (dismissAction: CloseModalFn) => ReactElement;
  dialogKlasses?: KlassList;
  id: string;
  title: string;
  icon: O.Option<SVGString>;
  type: "primary";
  // Set to false for modals that require the user to make a decision
  escapable?: false;
  open: ModalOpen;
  body: ReactNode;
  size: ModalSize;
};

export type ModalProps = WithHTMLAttrs<ModalPropsI>;

export type ModalDeleteButtonProps = {
  disabledTooltip: O.Option<TooltipProps>;
  onClick: () => void;
};

export type ModalDeleteProps = {
  deleteButton: O.Option<ModalDeleteButtonProps>;
};
export type ModalActionsDeleteButton = ModalDismissable & ModalDeleteProps;

type ModalRef = {
  triggerFocus: () => void;
};

const getScrollbarWidth = (): number => {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.overflowY = "scroll";
  scrollDiv.style.visibility = "hidden";

  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  scrollDiv.remove();

  return scrollbarWidth;
};

export const modalOpenClass = "modal-open";

const initModal = (modalRef: RefObject<ModalRef>) => {
  openModalCount += 1;

  pipe(
    O.fromNullable(modalRef.current),
    O.map((mRef) => {
      mRef.triggerFocus();
      // Need to add `modal-open` class on open
      document.body.classList.add(modalOpenClass);
      document.body.style.marginRight = `${getScrollbarWidth()}px`;
    })
  );

  moveNestedModalsBack();
};

const cleanupModal = () => {
  openModalCount -= 1;

  if (openModalCount < 1) {
    document.body.classList.remove(modalOpenClass);
    document.body.removeAttribute("style");
  }

  moveNextModalToFront();
};

const moveNestedModalsBack = () => {
  const modalRoot = O.fromNullable(document.body);
  O.map(
    (mr: Element) => {
      const matches = Array.from(mr.querySelectorAll("div .modal-root"));

      matches.forEach(e => e.classList.add("modal-moved-back"));
    }
  )(modalRoot);
};


const moveNextModalToFront = () => {
  const modalRoot = O.fromNullable(document.body);
  O.map(
    (mr: Element) => {
      const matches: HTMLDivElement[] = Array.from(mr.querySelectorAll("div .modal-root.modal-moved-back"));

      // Only remove the last .modal-moved-back instance
      pipe(A.last(matches), O.map(e => {
        e.classList.remove("modal-moved-back");
        e.focus();
      }));
    }
  )(modalRoot);
};

type ModalHeaderProps = {
  size: ModalSize;
  title: string;
  icon: O.Option<SVGString>;
  requestClose: O.Option<(e: SyntheticEvent) => void>;
};

const ModalHeader = (p: ModalHeaderProps) => (
  <div {...klass("modal-header", "inverted")}>
    <div {...klass(m[".modal-title"])}>
      {pipe(p.icon, mapOrEmpty((i: SVGString) => <Svg src={i} />))}
      {createElement(p.size === "modal-lg" ? "h2" : "h3", { ...klass("modal-title-text") }, p.title)}
    </div>
    {pipe(p.requestClose, mapOrEmpty((e) => <CloseXButton onClick={e} />))}
  </div>
);

const ModalContainer = forwardRef((p: Omit<ModalProps, "open">, ref: Ref<ModalRef>) => {
  const [lastFocus] = useStableO(O.fromNullable(document.activeElement));
  const modalRef = useRef<HTMLDivElement>(null);

  const escapable = p.escapable ?? true;

  const isFocusable = (e: Element & { focus?: () => void }): e is Element & { focus: () => void } =>
    typeof e === "object" && typeof e.focus === "function";

  const requestClose = () => {
    pipe(
      lastFocus,
      O.filter(isFocusable),
      O.map(el => el.focus())
    );
    p.dismissAction();
  };

  useImperativeHandle(ref, () => ({
    triggerFocus: () =>
      pipe(
        O.fromNullable(modalRef.current),
        O.map((el) => el.focus())
      ),
  }));

  const keyDownHandler = (e: SyntheticEvent<HTMLElement, KeyboardEvent>) => {
    if (isEscKeyPress(e) && escapable) {
      e.stopPropagation();
      requestClose();
    }
  };

  return (
    <div ref={modalRef}
      {...klass(m[".modal"], "show", p.size, `modal-${p.type}`, O.fromNullable(p.className))}
      data-closeable={escapable}
      aria-modal={true}
      role="dialog"
      tabIndex={-1}
      onKeyDown={keyDownHandler}
    >
      <div {...klass(m[".modal-viewport"])}>
        <div {...klassPropO(m[".modal-dialog"])(p.dialogKlasses)}>
          <div {...klass("modal-content")}>
            {
              pipe(
                p.customHeader,
                O.fromNullable,
                O.fold(
                  () => <ModalHeader {...pick("size", "title", "icon")(p)} requestClose={escapable ? O.some(requestClose) : O.none} />,
                  _ => _(closeModalFn(escapable ? requestClose : constVoid))
                )
              )
            }
            <div {...klass("modal-body")}>
              {p.body}
            </div>
          </div >
        </div >
      </div >
    </div >
  );
});

const ModalPortal = (p: Omit<ModalProps, "open">) => {
  const modalRef = createRef<ModalRef>();

  useEffect(() => {
    initModal(modalRef);

    return cleanupModal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Portal id={`${p.id}-portal`}>
      <div {...klass("modal-root")} id={`${p.id}-modal-root`}>
        <ModalContainer {...p} ref={modalRef} />
        <div {...klass(m[".modal-background"], "show")} />
      </div>
    </Portal>
  );
};

export const Modal = (p: ModalProps) => p.open ? <ModalPortal {...delProp(p, "open")} /> : <Empty />;

export type ModalWithMetaProps = WithHTMLAttrs<ModalInitializer> & Pick<ModalPropsI, "open" | "dismissAction">;

export const makeModalWithMetaProps = (config: BLConfigWithLog) => (props: Omit<ModalWithMetaProps, "children">): Omit<ModalProps, "body" | "children"> => ({
  open: props.open,
  customHeader: props.customHeader,
  type: "primary",
  escapable: props.escapable,
  size: props.size,
  id: props.dataMeta.type,
  title: modalTitle(config)(
    pipe(
      O.fromNullable(props.titleOverride),
      O.getOrElse(() => displayName(props.dataMeta))
    ),
    props.dataMeta.ignoreTitleCase
  )(props.action),
  icon: O.fromNullable(props.iconOverride || props.dataMeta.svg),
  dismissAction: props.dismissAction,
});

export const ModalWithMeta = (props: ModalWithMetaProps & Pick<ModalPropsI, "body">) => {
  const config = useConfig();

  return <Modal {...makeModalWithMetaProps(config)(props)} body={props.body} />;
};
