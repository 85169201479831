import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import * as n from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";
import * as s from "fp-ts/lib/string";
import * as t from "io-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O, pipe } from "@scripts/fp-ts";
import type {
  Day as DqDay,
  DayToDay,
  DayToDayTaggedC,
  Ongoing,
} from "@scripts/generated/domaintables/dateQualifiers";
import {
  allDateQualifierC,
  day,
  dayC,
  dayToDayC,
  monthC,
  ongoingC,
  quarterC,
  weekC,
  yearC,
} from "@scripts/generated/domaintables/dateQualifiers";
import type { TimeZoneU } from "@scripts/generated/domaintables/timeZones";
import type { DateQualifier, DateQualifierC, DateQualifierRange } from "@scripts/generated/models/dateQualifier";
import { dateQualifierC } from "@scripts/generated/models/dateQualifier";
import type { DateWithOptionalTime, Rfp } from "@scripts/generated/models/rfpBase";
import type { BondSaleDate } from "@scripts/models/offering";
import type { UnsafeFormData } from "@scripts/react/form/form";
import type { DateFormatter } from "@scripts/syntax/date/joda";
import {
  dateTBD,
  fullMonthAndYear,
  humanDateFull,
  Joda,
  quarterWithYear,
  timeZoneToZoneId,
  year,
} from "@scripts/syntax/date/joda";

import { LocalDateOrd } from "./jodaSyntax";


export const dateQualifiersCU = t.union([dateQualifierC, dayToDayC]);
export type DateQualifiersU = t.TypeOf<typeof dateQualifiersCU>;

export const allDateQualifiersU = t.union([...allDateQualifierC]);
export const dayToDayQualifiersU = t.union([...allDateQualifierC, dayToDayC]);
export const ongoingQualifiersU = t.union([...allDateQualifierC, ongoingC]);
export type AllDateQualifiers = t.TypeOf<typeof allDateQualifiersU>;
export type DayToDayQualifiers = t.TypeOf<typeof dayToDayQualifiersU>;
export type OngoingQualifiers = t.TypeOf<typeof ongoingQualifiersU>;

export const dayToDayOrd: Ord_.Ord<DayToDay> = Ord_.contramap((d: DayToDay) => d._tag)(s.Ord);
export const dayToDayEq: Eq.Eq<DayToDay> = Eq.struct({
  _tag: s.Eq,
  name: s.Eq,
});

export const ongoingOrd: Ord_.Ord<Ongoing> = Ord_.contramap((o: Ongoing) => o._tag)(s.Ord);
export const ongoingEq: Eq.Eq<Ongoing> = Eq.struct({
  _tag: s.Eq,
  name: s.Eq,
});

export const qualifierOrd = (config: BLConfigWithLog): Ord_.Ord<DayToDayQualifiers | OngoingQualifiers> => pipe(
  n.Ord,
  Ord_.contramap((q: AllDateQualifiers | DayToDayQualifiers | OngoingQualifiers) => {
    switch (q._tag) {
      case "DayToDay": return 0;
      case "Ongoing": return 1;
      case "Day": return 2;
      case "Week": return 3;
      case "Month": return 4;
      case "Quarter": return 5;
      case "Year": return 6;
    }
    return config.exhaustive(q);
  })
);

export const dateQualifierEq = (config: BLConfigWithLog): Eq.Eq<DateQualifier> => Eq.struct({
  qualifier: qualifierOrd(config),
  date: LocalDateOrd,
});

export const saleDateFormDataEq = (config: BLConfigWithLog): Eq.Eq<UnsafeFormData<DateQualifierC> | UnsafeFormData<DayToDayTaggedC>> => Eq.fromEquals((x, y) => {
  if (dateQualifierC.is(x) && dateQualifierC.is(y)) return dateQualifierEq(config).equals(x, y);
  if (dayToDayC.is(x) && dayToDayC.is(y)) return dayToDayEq.equals(x, y);
  return false;
});

export const dateQualifierOrd = (config: BLConfigWithLog): Ord_.Ord<DateQualifier> => ({
  equals: dateQualifierEq(config).equals,
  compare: (a, b) =>
    Ord_.contramap((q: DateQualifier) => q.date)(LocalDateOrd).compare(a, b)
    || Ord_.contramap((q: DateQualifier) => q.qualifier)(qualifierOrd(config)).compare(a, b),
});

export const dateQualifierRangeOrd = (config: BLConfigWithLog): Ord_.Ord<DateQualifierRange> => ({
  equals: dateQualifierEq(config).equals,
  compare: (a, b) =>
    Ord_.contramap((q: DateQualifierRange) => q.endDate)(LocalDateOrd).compare(a, b)
    || Ord_.contramap((q: DateQualifierRange) => q.qualifier)(qualifierOrd(config)).compare(a, b),
});

export const bondSaleDateOrd = (config: BLConfigWithLog): Ord_.Ord<BondSaleDate> => O.getOrdNoneMax<E.Either<DateQualifierRange, DayToDay>>({
  equals: E.getEq(dateQualifierOrd(config), dayToDayOrd).equals,
  compare: (a: E.Either<DateQualifierRange, DayToDay>, b: E.Either<DateQualifierRange, DayToDay>) =>
    E.isRight(a) && E.isRight(b)
      ? 0
      : E.isRight(a)
        ? -1
        : E.isRight(b)
          ? 1
          : dateQualifierRangeOrd(config).compare(E.toUnion(a), E.toUnion(b)),
});

export const dateWithOptionalTimeToUTCDateTime = (config: BLConfigWithLog) => (d: DateWithOptionalTime<TimeZoneU>): Joda.LocalDateTime =>
  pipe(
    d.time,
    O.fold(
      () => d.date.atStartOfDay(Joda.ZoneId.UTC),
      time => d.date
        .atStartOfDay(timeZoneToZoneId(config)(time.timezone))
        .withHour(time.time.hour)
        .withMinute(time.time.minute)
        .withZoneSameInstant(Joda.ZoneId.UTC),
    ),
    _ => _.toLocalDateTime(),
  );

export const dateWithOptionalTimeOrd = (config: BLConfigWithLog): Ord_.Ord<DateWithOptionalTime<TimeZoneU>> =>
  Ord_.contramap(dateWithOptionalTimeToUTCDateTime(config))(LocalDateOrd);

export const rfpBidsDueDateOrd = (config: BLConfigWithLog): Ord_.Ord<Rfp["bidsDue"]> => O.getOrdNoneMax<E.Either<DateWithOptionalTime<TimeZoneU>, Ongoing>>({
  equals: E.getEq(dateWithOptionalTimeOrd(config), ongoingOrd).equals,
  compare: (a: E.Either<DateWithOptionalTime<TimeZoneU>, Ongoing>, b: E.Either<DateWithOptionalTime<TimeZoneU>, Ongoing>) =>
    E.isRight(a) && E.isRight(b)
      ? 0
      : E.isRight(a)
        ? -1
        : E.isRight(b)
          ? 1
          : dateWithOptionalTimeOrd(config).compare(E.toUnion(a), E.toUnion(b)),
});

export const dqDateAsString = (formatter: (d: Joda.LocalDate) => string) => (dq: DateQualifiersU): string =>
  dayToDayC.is(dq) ? dq.format : formatter(dq.date);

export const qualifierFormat = (config: BLConfigWithLog) => (
  dq: DateQualifiersU, formatFn: DateFormatter = humanDateFull
): string => {
    if (dayToDayC.is(dq)) {
      return dq.format;
    } else if (dayC.is(dq.qualifier)) {
      return formatFn(dq.date);
    } else if (weekC.is(dq.qualifier)) {
      return `Week of ${formatFn(dq.date)}`;
    } else if (monthC.is(dq.qualifier)) {
      return fullMonthAndYear(dq.date);
    } else if (quarterC.is(dq.qualifier)) {
      return quarterWithYear(dq.date);
    } else if (yearC.is(dq.qualifier)) {
      return year(dq.date);
    }

    return config.exhaustive(dq.qualifier);
  };

export const qualifierFormatO = (config: BLConfigWithLog) => (dq: O.Option<DateQualifiersU>): string => {
  return O.fold<DateQualifiersU, string>(() => dateTBD, qualifierFormat(config))(dq);
};

export const allDateQualifierToDay = E.fold<DateQualifierRange, DayToDay, E.Either<{ qualifier: DqDay, date: Joda.LocalDate, endDate: Joda.LocalDate }, DateQualifierRange | DayToDay>>(
  dq => dq.qualifier._tag === "Day" ? E.left({ qualifier: day, date: dq.date, endDate: dq.endDate }) : E.right(dq),
  dtd => E.right(dtd)
);
