import { A, O, pipe, RNEA } from "@scripts/fp-ts";
import type { DirectRfpSitesData } from "@scripts/generated/models/rfp";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { Participants, participantsSectionTitle } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRoadshowDataO, parseRoadshows, RoadshowsSection, roadshowsSectionTitle } from "@scripts/react/components/offering-pages/RelatedContent";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { RfpBadge } from "@scripts/react/rfp/rfpBadge";
import { klass } from "@scripts/react/util/classnames";
import { rfp, type RfpUrlParams } from "@scripts/routes/routing/ssr/issuersites";
import {
  offeringParticipantsJumplinkId,
  questionsJumplinkId,
  relatedContentJumplinkId,
  roadshowsJumplinkId,
  summaryJumplinkId,
  winningBidJumplinkId,
} from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { isQuestionAndAnswerAvailible, isQuestionsAndClarificationsAvailble } from "@scripts/syntax/rfp";
import { isDrafted } from "@scripts/syntax/threadThrough";

import { useRfpSubscribe } from "../../api/watchlist";
import { Chrome as DirectIssuerChrome } from "../../components/Chrome";
import { DirectSitesPageLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import {
  makeEventsDataO,
  makeNewsDataO,
  makeProjectsDataO,
  RelatedContentSection,
  relatedContentSectionTitle,
  SharedRelatedContent,
} from "../../components/offering-pages/RelatedContent";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import {
  makeWinningBidFacts,
  QuestionAndAnswerSectionBase,
  questionAndAnswerSectionTitle,
  RfpSummary,
  rfpSummarySectionTitle,
  showWinningBidSection,
  WinningBidSection,
  winningBidSectionTitle,
} from "./RfpRelatedContent";

type RfpSidebarLinkHandles = {
  summary: string;
  winningBid: string;
  roadshows: string;
  participants: string;
  questions: string;
  related: string;
};

export const setupSidebarLinkSections = (
  sidebarLinkIds: RfpSidebarLinkHandles,
  show: {
    winningBid: boolean;
    roadshows: boolean;
    participants: boolean;
    questions: boolean;
    relatedContent: boolean;
  }
): RNEA.ReadonlyNonEmptyArray<JumpLink> => [
    { link: `#${sidebarLinkIds.summary}`, anchorContent: rfpSummarySectionTitle },
    ...A.compact([
      makeJumplinkO(winningBidSectionTitle, sidebarLinkIds.winningBid, show.winningBid),
      makeJumplinkO(roadshowsSectionTitle, sidebarLinkIds.roadshows, show.roadshows),
      makeJumplinkO(participantsSectionTitle, sidebarLinkIds.participants, show.participants),
      makeJumplinkO(questionAndAnswerSectionTitle, sidebarLinkIds.questions, show.questions),
      makeJumplinkO(relatedContentSectionTitle, sidebarLinkIds.related, show.relatedContent),
    ]),
  ];

export type DirectRfpProps = Required<RfpUrlParams> & DirectRfpSitesData;

const DirectRfp = (props: DirectRfpProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const iffs = useIssuerSitesSelector("iffs");
  const pages = useIssuerSitesSelector("pages");
  const makeOnSubscribe = useRfpSubscribe();
  const page = rfp({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: props.rfpId });
  const pageTitle = page.title(pages);
  const rfpProps = props.rfp.data.data.record.data;

  const winningBidFacts = makeWinningBidFacts(props.rfp.data.data.record.data.rfp);
  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(rfpProps.relatedContent.roadShows));
  const participantsDataO = RNEA.fromReadonlyArray(props.participants);
  const projectsDataO = makeProjectsDataO(iffs, rfpProps.relatedContent.projects);
  const eventsDataO = makeEventsDataO(iffs, rfpProps.relatedContent.events);
  const newsDataO = makeNewsDataO(iffs, rfpProps.relatedContent.news);
  const documentsDataO = RNEA.fromReadonlyArray(rfpProps.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(rfpProps.relatedContent.externalLinks);
  const programsDataO = rfpProps.relatedContent.program;

  const showWinningBid = showWinningBidSection(props.rfp.data.data.record.data.rfp, winningBidFacts);
  const showHaveAQuestionSection = isQuestionAndAnswerAvailible(rfpProps.rfp);
  const showQuestionsAndClarificationsSection = isQuestionsAndClarificationsAvailble(rfpProps.rfp, rfpProps.relatedContent.faqs);
  const showRelatedContent = O.isSome(projectsDataO) || O.isSome(eventsDataO) || O.isSome(newsDataO);

  const sidebarLinkIds = {
    summary: summaryJumplinkId,
    winningBid: questionsJumplinkId,
    roadshows: roadshowsJumplinkId,
    participants: offeringParticipantsJumplinkId,
    questions: winningBidJumplinkId,
    related: relatedContentJumplinkId,
  };

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      winningBid: showWinningBid,
      roadshows: O.isSome(roadshowDataO),
      participants: O.isSome(participantsDataO),
      questions: showQuestionsAndClarificationsSection,
      relatedContent: showRelatedContent,
    }
  );

  return <DirectSitesPageLayout
    headerComponent={<>
      <div {...klass("d-flex", "justify-content-between", "w-100")}>
        <RfpBadge biddingState={rfpProps.rfp.biddingState} />
      </div>
      <HeaderWithSubscription
        isSubscribed={props.rfp.subscribed}
        makeOnSubscribe={makeOnSubscribe}
        subscriptionId={props.rfpId}
        taggedContent={O.some(props.rfp.data.data.record)}
        title={rfpProps.rfp.name}
        viewAllRoute={O.some({
          urlInterface: SR.issuersitesRfpsControllerRfps({ issuerSlug: issuer.slug, issuerId: issuer.id }),
          itemType: O.none,
        })}
        subscribeDisabled={isDrafted(props.rfp.data)}
      />
    </>}
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkCustomAnchorContent={O.some(props.rfp.data.data.record.data.rfp.name)}
          routeMeta={page}
          jumpLinks={sidebarLinks}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <RfpSummary
      documentsO={documentsDataO}
      linksO={linksDataO}
      rfp={props.rfp}
      programsO={programsDataO}
      sidebarLinkHandle={sidebarLinkIds.summary}
    />
    {pipe(
      showWinningBid,
      trueOrEmpty(
        <WinningBidSection
          facts={winningBidFacts}
          rfp={props.rfp.data.data.record.data.rfp}
          sidebarLinkHandle={sidebarLinkIds.winningBid}
        />
      )
    )}
    {pipe(
      roadshowDataO,
      mapOrEmpty(roadshows =>
        <RoadshowsSection
          issuer={issuer}
          roadshows={roadshows}
          sidebarLinkHandle={sidebarLinkIds.roadshows}
          leafIcon={IssuerSitesLeafIcon}
        />
      )
    )}
    {pipe(
      participantsDataO,
      mapOrEmpty(participants =>
        <Participants
          participants={participants}
          title="Participants"
          sidebarLinkHandle={sidebarLinkIds.participants}
        />
      )
    )}
    {pipe(
      showQuestionsAndClarificationsSection,
      trueOrEmpty(
        <QuestionAndAnswerSectionBase
          showHaveAQuestionSection={showHaveAQuestionSection}
          titleSingular={pageTitle}
          rfpWithRelatedContent={rfpProps}
          rfpId={props.rfpId}
          sidebarLinkHandle={sidebarLinkIds.questions}
        />
      )
    )}
    {pipe(
      showRelatedContent,
      trueOrEmpty(
        <RelatedContentSection sectionId={sidebarLinkIds.related}>
          <SharedRelatedContent
            events={eventsDataO}
            news={newsDataO}
            projects={projectsDataO}
          />
        </RelatedContentSection>
      )
    )}
  </DirectSitesPageLayout>;

};

export const DirectRfpWithChrome = (props: DirectRfpProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const page = rfp({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: props.rfp.data.data.id });

  return <DirectIssuerChrome routeMeta={page}><DirectRfp {...props} /></DirectIssuerChrome>;
};
