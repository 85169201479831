import { type ReactElement } from "react";
import { singular } from "pluralize";

import { E, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import { AnchorIconUnsafe } from "@scripts/react/components/Anchor";
import { type BadgeProps } from "@scripts/react/components/Badge";
import { useConfig } from "@scripts/react/context/Config";
import { rfpBadgeProps } from "@scripts/react/rfp/rfpBadge";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { dateTBDConst, humanDateFull } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { rfpUrl } from "@scripts/syntax/rfp";
import { currency } from "@scripts/util/currency";

import rfpIcon from "@svgs/rfp.svg";

import { FactsRow, makeFactO } from "../Facts";
import { ContentItemCardLinks, type ContentItemCardLinksProps, type ContentItemCardSubscriptionProps } from "./ContentItemCard";
import { DealCard, type DealCardProps, dealCardRoadshowBadge, dealCardRoadshowsLink } from "./DealCard";

export type RfpCardLink = "details" | "roadshows";

export type RfpCardProps = {
  url: (sitesUrl: UrlInterface<"GET">) => string;
  issuer: Issuer;
  displayIssuer: boolean;
  rfp: WithId<TaggedContent<Rfp>>;
  customRfpsTitle: O.Option<string>;
  roadshows: ReadonlyArray<WithId<TaggedContent<RoadShowData>>>;
  links: ReadonlyArray<RfpCardLink>;
  footer?: ReactElement;
} & Pick<DealCardProps, "klass" | "target" | "leafIcon"> & ContentItemCardSubscriptionProps;

export const RfpCard = (props: RfpCardProps) => {
  const config = useConfig();
  const roadshowsNEA = RNEA.fromReadonlyArray(props.roadshows);
  const rUrl = rfpUrl(props.rfp.id, props.issuer, config);

  return <DealCard
    klass={props.klass}
    name={props.rfp.record.data.name}
    issuer={O.fromPredicate(() => props.displayIssuer)(props.issuer)}
    url={props.url(rUrl)}
    target={props.target}
    leftBadges={RA.compact<BadgeProps>([
      O.some({ text: O.fold(() => "RFP", singular)(props.customRfpsTitle), icon: O.some(rfpIcon), variant: "tall", color: "accent-2-700" }),
      dealCardRoadshowBadge(roadshowsNEA),
    ])}
    rightBadge={rfpBadgeProps(props.rfp.record.data.biddingState, "tall")}
    taggedContent={props.rfp.record}
    leafIcon={props.leafIcon}
    subscription={props.subscription}
  >
    <div className="series-wrapper">
      <FactsRow
        items={pipe(
          [
            {
              title: "Bids Due",
              value: pipe(
                props.rfp.record.data.bidsDue,
                O.fold(dateTBDConst, E.fold(dateWithOptionalTimeToString(humanDateFull), _ => _.format)),
              ),
            },
          ],
          RA.concat(RA.compact([
            makeFactO("Total Loan Amount", props.rfp.record.data.loanAmount, currency),
            makeFactO("Service Type", props.rfp.record.data.typeOfServicing, _ => _.name),
          ])),
        )}
      />
    </div>
    <ContentItemCardLinks
      links={pipe(
        props.links,
        RA.filterMap((key): O.Option<ContentItemCardLinksProps["links"][number]> => {
          switch (key) {
            case "details":
              return O.some({
                key,
                link: <AnchorIconUnsafe
                  key={key}
                  href={rUrl.url}
                  icon={rfpIcon}
                  target={props.target}
                  textOrAriaLabel={E.left("View details")}
                />,
              });

            case "roadshows":
              return dealCardRoadshowsLink(config, props.issuer, roadshowsNEA, rUrl, props.target);

            default: return config.exhaustive(key);
          }
        }),
      )}
    />
    {props.footer}
  </DealCard>;
};
