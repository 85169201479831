import { userC as imported21_userC } from "./user";
import * as t from "io-ts";

export const issuerAdminUserC = t.type({
  user: imported21_userC,
  draftNotifications: t.boolean
});
export type IssuerAdminUserC = typeof issuerAdminUserC;
export type IssuerAdminUser = t.TypeOf<IssuerAdminUserC>;


export const issuerAdminUsersC = t.readonlyArray(issuerAdminUserC);
export type IssuerAdminUsersC = typeof issuerAdminUsersC;
export type IssuerAdminUsers = t.TypeOf<IssuerAdminUsersC>;


