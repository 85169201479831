import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";
import { withIdC as imported202_withIdC, withIdC as imported203_withIdC, WithStatusCU as imported211_WithStatusCU } from "./threadThrough";

export const attachedFaqC = t.type({
  question: t.string,
  answer: markdownC
});
export type AttachedFaqC = typeof attachedFaqC;
export type AttachedFaq = t.TypeOf<AttachedFaqC>;


export const faqSectionC = t.type({
  name: t.string,
  sortOrder: t.number
});
export type FaqSectionC = typeof faqSectionC;
export type FaqSection = t.TypeOf<FaqSectionC>;


export const faqC = t.type({
  question: t.string,
  answer: markdownC,
  faqSection: imported202_withIdC(faqSectionC),
  faqOrder: t.number
});
export type FaqC = typeof faqC;
export type Faq = t.TypeOf<FaqC>;


export const faqPostC = t.type({
  id: optionFromNullable(t.number),
  question: t.string,
  answer: markdownC,
  sectionId: t.number,
  faqOrder: t.number
});
export type FaqPostC = typeof faqPostC;
export type FaqPost = t.TypeOf<FaqPostC>;


export const faqSectionPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
});
export type FaqSectionPostC = typeof faqSectionPostC;
export type FaqSectionPost = t.TypeOf<FaqSectionPostC>;


export const issuerFaqsWithSectionsC = t.type({
  faqs: t.readonlyArray(t.tuple([imported203_withIdC(faqSectionC), t.readonlyArray(imported211_WithStatusCU(faqC))]))
});
export type IssuerFaqsWithSectionsC = typeof issuerFaqsWithSectionsC;
export type IssuerFaqsWithSections = t.TypeOf<IssuerFaqsWithSectionsC>;


