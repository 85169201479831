import type { RNEA } from "@scripts/fp-ts";
import { O, pipe, RA } from "@scripts/fp-ts";
import type { BondProgramWithRatings } from "@scripts/generated/models/bondProgramWithRatings";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { Empty, mapOrEmpty } from "@scripts/react/components/Empty";
import { klass } from "@scripts/react/util/classnames";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import { AccentDividerSection } from "../layout/Section";
import { defaultRatingBlockBreakpoint, RatingBlockSection } from "../ratings/RatingBlock";

export const ratingsSectionTitle = "Ratings";
export const RatingsSection = (props: {
  bondRatings: O.Option<RNEA.ReadonlyNonEmptyArray<CommonRatingsByAgency[number]>>;
  program: O.Option<WithStatusU<BondProgramWithRatings>>;
  isBLP: boolean;
  issuer: Issuer;
  sectionId?: string;
}) => {
  const programWithRatings = pipe(props.program, O.chain(O.fromPredicate(p => RA.isNonEmpty(p.data.record.ratings))));
  return O.isNone(props.bondRatings) && O.isNone(programWithRatings) ? <Empty />
    : <AccentDividerSection title={O.some(ratingsSectionTitle)} sectionId={props.sectionId}>
      {pipe(props.bondRatings, mapOrEmpty(r =>
        <RatingBlockSection
          ratings={r}
          name={props.isBLP ? O.none : O.some("Bond Offering Ratings")}
          modalName="Bond Offering Ratings"
          issuer={props.issuer}
          breakpoint={props.isBLP ? "xl" : defaultRatingBlockBreakpoint}
        />
      ))}
      {pipe(programWithRatings, mapOrEmpty(p =>
        <div {...klass("mt-2")}>
          <RatingBlockSection
            ratings={p.data.record.ratings}
            name={O.some(`Program Ratings: ${p.data.record.program.name}`)}
            modalName={p.data.record.program.name}
            issuer={props.issuer}
            breakpoint={props.isBLP ? "xl" : defaultRatingBlockBreakpoint}
          />
        </div>
      ))}

    </AccentDividerSection>;
};
