import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { issuerRatingC as imported9_issuerRatingC, programRatingC as imported11_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported29_RatingAgencyCU, RatingAgencyCU as imported30_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { mediaC as imported50_mediaC } from "./media";
import { bondProgramC as imported16_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { WithStatusCU as imported181_WithStatusCU, WithStatusCU as imported182_WithStatusCU, withIdC as imported198_withIdC } from "./threadThrough";
import { issuerC as imported35_issuerC } from "./issuer";

export const issuerOverviewCardC = t.type({
  issuer: imported35_issuerC,
  offerings: t.number,
  issuerRatings: t.readonlyArray(t.tuple([imported29_RatingAgencyCU, readonlyNonEmptyArrayC(imported181_WithStatusCU(imported9_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported198_withIdC(imported16_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported30_RatingAgencyCU, readonlyNonEmptyArrayC(imported182_WithStatusCU(imported11_programRatingC))]))])),
  subscribed: t.boolean,
  media: optionFromNullable(imported50_mediaC),
  outstandingDebt: optionFromNullable(t.number)
});
export type IssuerOverviewCardC = typeof issuerOverviewCardC;
export type IssuerOverviewCard = t.TypeOf<IssuerOverviewCardC>;


export const issuerOverviewCardsC = t.type({
  cards: t.readonlyArray(issuerOverviewCardC),
  nextPage: t.boolean
});
export type IssuerOverviewCardsC = typeof issuerOverviewCardsC;
export type IssuerOverviewCards = t.TypeOf<IssuerOverviewCardsC>;


