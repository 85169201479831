import { pipe } from "fp-ts/lib/function";
import type * as t from "io-ts";

import { O, RA, RNEA } from "@scripts/fp-ts";
import {
  activeRfps,
  archivedRfps,
  bondOfferings,
  bondOfferingsGeneralInfo,
  bondRatings,
  offeringParticipants,
  showArchivedBonds,
} from "@scripts/generated/domaintables/featureFlags";
import type { BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type {
  RfpsSitesList,
  RfpsSitesListC,
} from "@scripts/generated/models/rfp";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { ButtonsContainer } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import {
  offeringParticipantsSectionTitle,
  ParticipantCardGrid,
  ParticipantsSectionContainer,
} from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRelatedContentDataO, makeRoadshowDataO, RoadshowsSection, roadshowsSectionTitle } from "@scripts/react/components/offering-pages/RelatedContent";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import {
  bonds,
  type IssuerSitesRouteMeta,
  programs,
  rfps,
} from "@scripts/routes/routing/ssr/issuersites";
import {
  bondSalesJumplinkId,
  generalOfferingInfoJumplinkId,
  offeringParticipantsJumplinkId,
  programsSectionJumplinkId,
  ratingsJumplinkId,
  rfpsSectionJumplinkId,
  roadshowsJumplinkId,
} from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { urlInterface } from "@scripts/routes/urlInterface";
import { isFFEnabled } from "@scripts/syntax/featureFlags";

import { CustomPageSection, makeCustomPageJumplinkId } from "../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { GeneralOfferingInfo, generalOfferingInfoSectionTitle } from "../../components/offering-pages/GeneralOfferingInfo";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { BondSales, bondSalesSectionTitle } from "./BondSales";
import { ProgramsSection } from "./ProgramsSection";
import { RatingsSection, ratingsSectionTitle } from "./RatingsSection";

const sidebarLinkIds = {
  bondSales: bondSalesJumplinkId,
  rfps: rfpsSectionJumplinkId,
  roadshows: roadshowsJumplinkId,
  ratings: ratingsJumplinkId,
  programs: programsSectionJumplinkId,
  participants: offeringParticipantsJumplinkId,
  generalOfferingInfo: generalOfferingInfoJumplinkId,
};

const setupSidebarLinkSections = (
  linkIds: typeof sidebarLinkIds,
  show: { [K in keyof typeof sidebarLinkIds]: boolean },
  rfpName: string,
  programName: string,
  customPageJumpLinks: ReadonlyArray<O.Option<JumpLink>>
): ReadonlyArray<JumpLink> => RA.compact([
  makeJumplinkO(bondSalesSectionTitle, linkIds.bondSales, show.bondSales),
  makeJumplinkO(rfpName, linkIds.rfps, show.rfps),
  makeJumplinkO(roadshowsSectionTitle, linkIds.roadshows, show.roadshows),
  makeJumplinkO(ratingsSectionTitle, linkIds.ratings, show.ratings),
  makeJumplinkO(programName, linkIds.programs, show.programs),
  makeJumplinkO(offeringParticipantsSectionTitle, linkIds.participants, show.participants),
  makeJumplinkO(generalOfferingInfoSectionTitle, linkIds.generalOfferingInfo, show.generalOfferingInfo),
  ...customPageJumpLinks,
]);

const RfpsSection = (props: {
  sidebarLinkId: string;
  rfpsPage: IssuerSitesRouteMeta<RfpsSitesList, t.OutputOf<RfpsSitesListC>>;
  rfpsTitle: string;
}) =>
  <AccentDividerSection title={O.some(props.rfpsTitle)} sectionId={props.sidebarLinkId}>
    Get details and submission information about {props.rfpsTitle}.
    <ButtonsContainer klasses={[]}>
      <AnchorButton
        externalLinkLocation="none"
        route={{
          title: `View ${props.rfpsTitle}`,
          route: urlInterface("GET", props.rfpsPage.url()),
        }}
        target="_self"
        variant="primary"
      />
    </ButtonsContainer>
  </AccentDividerSection>;



export const Bonds = (props: BondsSsrData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const bondsRouteMeta = bonds({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const bondsDescription = bondsRouteMeta.description(pages, issuer);

  const rfpsRouteMeta = rfps({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const rfpsTitle = rfpsRouteMeta.title(pages);

  const programsRouteMeta = programs({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const programsTitle = programsRouteMeta.title(pages);

  const activeBonds = pipe(
    props.offerings,
    RA.filter(offering => !offering.data.data.record.data.offering.isArchived)
  );
  const isBondArchiveEnabled = isFFEnabled(showArchivedBonds)(iffs);
  const showBondSales = isFFEnabled(bondOfferings)(iffs) || isBondArchiveEnabled;
  const showRfps = isFFEnabled(activeRfps)(iffs) || isFFEnabled(archivedRfps)(iffs);
  const roadshowDataO = makeRoadshowDataO(iffs, props.roadshows);
  const showRatings = isFFEnabled(bondRatings)(iffs);
  const participantsDataO = makeRelatedContentDataO(offeringParticipants, iffs, props.participants);
  const showGeneralOfferingInfo = isFFEnabled(bondOfferingsGeneralInfo)(iffs) && O.isSome(props.generalInfo);
  const programsDataO = RNEA.fromReadonlyArray(props.programs);

  const customPageJumplinks = pipe(
    props.customPages,
    RA.map(cp => makeJumplinkO(cp.title, makeCustomPageJumplinkId(cp), true))
  );

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      bondSales: showBondSales,
      rfps: showRfps,
      participants: O.isSome(participantsDataO),
      roadshows: O.isSome(roadshowDataO),
      ratings: showRatings,
      programs: O.isSome(programsDataO),
      generalOfferingInfo: showGeneralOfferingInfo,
    },
    rfpsTitle,
    programsTitle,
    customPageJumplinks
  );

  return (
    <DirectSitesPageDescriptionLayout
      description={bondsDescription}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkCustomAnchorContent={O.none}
            jumpLinks={sidebarLinks}
            routeMeta={bondsRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      {showBondSales && (
        <BondSales
          activeBonds={activeBonds}
          isBondArchiveEnabled={isBondArchiveEnabled}
          sidebarLinkId={sidebarLinkIds.bondSales}
          sectionTitle={bondSalesSectionTitle}
        />
      )}
      {showRfps && (
        <RfpsSection
          rfpsPage={rfpsRouteMeta}
          rfpsTitle={rfpsTitle}
          sidebarLinkId={sidebarLinkIds.rfps}
        />
      )}
      {pipe(
        roadshowDataO,
        mapOrEmpty(roadshows =>
          <RoadshowsSection
            issuer={issuer}
            roadshows={roadshows}
            sidebarLinkHandle={sidebarLinkIds.roadshows}
            leafIcon={IssuerSitesLeafIcon}
          />
        )
      )}
      {showRatings && (
        <RatingsSection
          issuer={issuer}
          issuerRatings={props.issuerRatings}
          programRatings={props.programRatings}
          sectionId={sidebarLinkIds.ratings}
        />
      )}
      {pipe(
        programsDataO,
        mapOrEmpty((_) => <ProgramsSection sectionId={sidebarLinkIds.programs} programs={_} />)
      )}
      {pipe(
        participantsDataO,
        mapOrEmpty(participants =>
          <ParticipantsSectionContainer
            sidebarLinkHandle={sidebarLinkIds.participants}
            title={offeringParticipantsSectionTitle}
          >
            <ParticipantCardGrid participants={participants} />
          </ParticipantsSectionContainer>
        )
      )}
      {showGeneralOfferingInfo && pipe(
        props.generalInfo,
        mapOrEmpty(generalInfo =>
          <GeneralOfferingInfo
            offeringInfo={generalInfo}
            sidebarLinkHandle={sidebarLinkIds.generalOfferingInfo}
          />
        )
      )}
      {pipe(
        props.customPages,
        RA.map(customPage =>
          <CustomPageSection
            customPage={customPage}
            key={customPage.page.name}
          />
        )
      )}
    </DirectSitesPageDescriptionLayout>
  );
};
